export default {
  attendanceApiConfig: state => state.attendanceApiConfig,
  markTypesArr: state => state.markTypesArr,
  groupId: state => state.groupId,
  makeTimeSheetUrl: state => state.makeTimeSheetUrl,
  timeSheet: state => state.timeSheet,
  name: state => state.name,
  back: state => state.back,
  availableDates: state => state.availableDates,
  currentMonth: state => state.currentMonth,
  currentYear: state => state.currentYear,
  dateStrings: state => state.dateStrings,
  dayColumns: state => state.dayColumns,
  dayCount: state => state.dayCount,
  editMonth: state => state.editMonth,
  editYear: state => state.editYear,
  isGroupTrashed: state => state.isGroupTrashed,
  attendance: state => state.attendance,
  overallData: state => state.overallData,
  allowEditAll: state => state.allowEditAll,
  holidays: state => state.holidays,
};
