import { Form, eventBus } from '@common/mixins';
import { Helper } from '@common/src/helpers';
import _ from 'lodash';
import { QForm, QSlideTransition, QInput } from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';

export default {
  name: 'ProgramLesson',
  components: { QForm, QSlideTransition, QInput },
  mixins: [Form],
  data() {
    const fields = {
      parent_id: null,
      name: null,
      criteria: null,
    };
    return {
      fields,
    };
  },
  computed: {
    validate() {
      return this.fields.parent_id !== null && this.fields.name !== null;
    },
    chapters() {
      return !this.$store.getters.content.items
        ? []
        : this.$store.getters.content.items.map(el => ({
          id: el.id,
          name: el.name,
        }));
    },
  },

  methods: {
    async handleAjaxFormSubmit() {
      this.$globalLoading.show();
      const data = this.fields;
      const { curriculumProgramApiConfig, makeProgramUrl, parentId } = this.$store.getters;
      const res = await requestWrapper.call(this, {
        url: makeProgramUrl(curriculumProgramApiConfig.lessons, parentId),
        method: 'post',
        data,
      });

      if (!res.error) {
        await this.$store.dispatch('GET_CURRICULUM_PROGRAM_CONTENT_TABLE');
        this.$emit('onClose');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
