<template>
  <div class="s-homework-quiz-result-item">
    <div class="s-homework-quiz-result-item__label">
      {{ trans('label.question') }}:
    </div>
    <div
      class="s-homework-quiz-result-item__value"
      :class="
        item.isCorrect
          ? 's-homework-quiz-result-item__value-correct'
          : 's-homework-quiz-result-item__value-failed'
      "
      v-html="item.question"
    />
    <div
      class="s-form-filter__spoiler-head mb-20"
      @click="spoiler = !spoiler"
    >
      <h5 class="s-combine-title">
        <span
          class="s-combine-title__label"
        >
          {{ trans('label.watch_answers') }}
        </span>
        <span class="s-combine-title__line">
          <button
            class="s-combine-title__button"
            type="button"
          >
            <span
              class="s-str-ico s-str-ico--wider s-c-grey"
              :class="`s-str-ico--thm-angle-${spoiler ? 'up' : 'down'}`"
            />
          </button>
        </span>
      </h5>
    </div>
    <q-slide-transition>
      <div
        v-if="spoiler"
        class="row"
      >
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="s-homework-quiz-result-item__label">
            {{ trans('label.students_answer') }}:
          </div>
          <div
            class="s-homework-quiz-result-item__value"
            v-html="item.answer ? item.answer : `<p> ${trans('label.answer_not_defined')} </p>`"
          />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="s-homework-quiz-result-item__label">
            {{ trans('label.correct_answer') }}:
          </div>
          <div
            class="s-homework-quiz-result-item__value"
            v-html="
              item.correctAnswer
                ? item.correctAnswer
                : `<p> ${trans('label.answer_not_defined')} </p>`
            "
          />
        </div>
      </div>
    </q-slide-transition>
  </div>
</template>

<style lang="scss" scoped>
.s-combine-title__button {
  background-color: #FBFBFF;
}

.s-combine-title__label {
  color: #444C70;
  font-size: 14px;
  font-weight: 400;
}
</style>

<script>
import { QSlideTransition } from '@quasar/components';

export default {
  name: 'VExamQuizResultItem',
  components: {
    QSlideTransition,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      spoiler: false,
    };
  },
};
</script>
