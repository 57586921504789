import {Helper} from "@common/src/helpers";

export default {
  name: 'DormitoryToAnotherRoomModal',
  props: {
    action: {
      type: String,
    },
    method: {
      type: String,
    },
    organizationId: {
      type: Number,
      default: null,
    },
    sex: {
      type: String,
      required: false,
    },
    exclude: {
      type: Number,
      default: null,
    },
  },
  data() {
    const vm = this;
    const fields = {
      dormitory: {
        value: null,
        name: 'dormitory[dormitory]',
        component: 'q-select-field',
        labelTransKey: 'dormitory.apply.name',
        values: [],
        required: false,
        loadValues: {
          type: 'dormitories',
          where: {
            organizationId: vm.organizationId,
          },
        },
        events: {
          change: () => {
            if (vm.form.fields.dormitory.value) {
              vm.form.fields.floor.loadValues.where.dormitoryId = vm.form.fields.dormitory.value;
              vm.form.fields.floor.loadValues.reload();
            }
          },
        },
      },
      floor: {
        value: null,
        name: 'dormitory[floor]',
        component: 'q-select-field',
        labelTransKey: 'dormitory.apply.floor',
        values: [],
        required: false,
        loadValues: {
          autoLoad: false,
          type: 'dormitory-floors',
          where: {
            dormitoryId: null,
          },
        },
        events: {
          change: () => {
            if (vm.form.fields.floor.value) {
              vm.form.fields.room.loadValues.where.dormitoryId = vm.form.fields.dormitory.value;
              vm.form.fields.room.loadValues.where.floorId = vm.form.fields.floor.value;
              vm.form.fields.room.loadValues.reload();
            }
          },
        },
      },
      room: {
        value: null,
        name: 'dormitory[room]',
        component: 'q-select-field',
        labelTransKey: 'dormitory.apply.room',
        values: [],
        required: false,
        loadValues: {
          autoLoad: false,
          type: 'dormitory-rooms',
          where: {
            dormitoryId: null,
            floorId: null,
            sex: vm.sex,
            exclude: vm.exclude,
          },
        },
      },
    };
    return {
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
        },
      },
    };
  },
  mounted() {
    this.$trans.add(['dormitory', 'button']);
  },
  computed: {
    validate() {
      return this.form.fields.dormitory.value !== null
      && this.form.fields.floor.value !== null
      && this.form.fields.room.value !== null;
    },
  },
  methods: {
    async handleAjaxFormSubmit() {
      this.$globalLoading.show();
      const vm = this;
      const data = {};
      _.forEach(vm.form.fields, (el, key) => {
        if (el.hasOwnProperty('fill')) {
          el.fill(data);
        } else if (el.hasOwnProperty('name')) {
          _.set(data, vm.nameToDot(el.name), el.value);
        }
      });
      const status = 'checkInToAnother';
      const res = await requestWrapper.call(this, {
        url: Helper.urlGenerate(this.action, { status }),
        method: this.method,
        data,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$emit('onClose');
      this.$globalLoading.hide();
    },
    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleVShow(field) {
      const vm = this;
      if (field.v_show !== undefined) {
        if (_.isFunction(field.v_show)) {
          return field.v_show(vm);
        }
        return field.v_show;
      }

      return true;
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
};
