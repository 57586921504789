import { QBtn, QIcon, QSelect } from '@quasar/components';
import VFormInform from '@vjs/components/VFormInform';
import { Helper } from '@common/src/helpers';

export default {
  name: 'VTimeSheetFilter',
  components: {
    QBtn, QIcon, QSelect, VFormInform,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isExportable: {
      type: Boolean,
      default: false,
    },
    educatorName: {
      type: String,
      required: '',
    },
  },
  data() {
    const vm = this;
    return {
      month: null,
      year: null,
    };
  },
  computed: {
    currentMonth() {
      return this.$store.getters.currentMonth;
    },
    currentYear() {
      return this.$store.getters.currentYear;
    },
    availableDates() {
      return this.$store.getters.availableDates;
    },
    years() {
      const { availableDates } = this;
      return Object.keys(availableDates);
    },
    months() {
      const { availableDates, currentMonth, currentYear } = this;
      return typeof availableDates[currentYear] !== 'undefined'
        ? availableDates[currentYear]
        : [currentMonth];
    },
  },
  watch: {
    currentMonth() {
      this.month = this.currentMonth;
    },
    currentYear() {
      this.year = this.currentYear;
    },
  },
  methods: {
    async onChangeDate() {
      const date = `${this.year}-${this.month.value}`;
      await this.$store.dispatch('GET_TIMESHEET', { date });
    },
    handleExport() {
      this.$globalLoading.show();
      const vm = this;
      const {
        attendanceApiConfig, makeTimeSheetUrl, groupId,
      } = this.$store.getters;

      const url = makeTimeSheetUrl(attendanceApiConfig.timeSheetExport.apiUrl, groupId);
      const config = {
        responseType: 'blob',
        params: {
          method: 'GET',
          headers: {
            'Accept-Language': window.core_project.locale,
          },
        },
      };

      this.$http.get(url, config)
        .then(
          (response) => {
            vm.$globalLoading.hide();
            const origFilename = (response.headers.get('Content-Disposition') || '').split('filename*=utf-8\'\'')[1];
            let filename = '';
            try {
              filename = decodeURIComponent(origFilename);
            } catch (e) {
              filename = origFilename;
            }
            const result = document.createElement('a');
            result.href = window.URL.createObjectURL(response.body);
            result.download = filename;
            result.click();
          },
          (response) => {
            vm.$globalLoading.hide();
            Helper.handlerResponseError(response);
          },
        );
    },
  },
};
