import { StoreTranslator } from '@common/mixins';


export default {
  mixins: [StoreTranslator],
  props: {
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    refs: {
      type: Object,
      required: true,
    },
    urlSearchIin: {
      type: String,
      required: true,
    },
    urlBack: {
      type: String,
      required: false,
      default: null,
    },
    personnel: {
      type: [Array, Object],
      required: false,
    },
    hides: {
      type: [Array, Object],
      required: false,
      default: () => ([]),
    },
    canEditRoles: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const vm = this;
    const fields = {
      userId: {
        value: '',
        name: 'user[user_id]',
      },
      foreigner: {
        value: false,
        name: 'user[foreigner]',
        checkboxLabelTransKey: 'label.is_resident',
        component: 'boolean-field',
        events: {
          input: () => {
            if (vm.personnel === undefined) {
              if (vm.form.fields.foreigner.value) {
                vm.isSearch = false;
                vm.form.fields.iin.v_if = false;
                vm.form.fields.iin.required = false;
                vm.form.fields.document_number.v_if = true;
                vm.form.fields.document_number.required = true;
                vm.changeStatusValidIIN(false);
                vm.clearInputs();
              } else {
                vm.isSearch = true;
                vm.form.fields.iin.v_if = true;
                vm.form.fields.iin.required = true;
                vm.form.fields.document_number.v_if = false;
                vm.form.fields.document_number.required = false;
              }
            } else {
              vm.form.fields.foreigner.v_if = false;
              vm.form.fields.document_number.v_if = true;
              vm.form.fields.document_number.required = true;
              vm.form.fields.document_number.extraAttributes.disabled = true;
            }
          },
        },

      },
      is_usage_active_monitoring: {
        v_if: true,
        beforeTitle: 'table.additional_info',
        value: true,
        name: 'personnel[is_usage_active_monitoring]',
        component: 'boolean-field',
        checkboxLabelTransKey: 'fields.is_usage_active_monitoring',
      },
      roles: {
        v_if: vm.canEditRoles,
        value: [],
        name: 'roles',
        component: 'v-multi-select-field',
        labelTransKey: 'label.personnel_roles',
        values: _.get(vm.refs, 'roles', []),
        extraAttributes: {
          multiple: true,
        },
      },
      teacher_info: {
        value: '',
        name: 'personnel[teacher_info]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.teacher_info',
        values: _.get(vm.refs, 'teacher_info', []),
        required: true,
      },
      teacher_additional_info: {
        value: '',
        name: 'personnel[teacher_additional_info]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.teacher_additional_info',
        values: _.get(vm.refs, 'teacher_additional_info', []),
        required: true,
      },
      iin: {
        v_if: true,
        beforeTitle: 'label.personnel_info',
        value: '',
        name: 'user[iin]',
        component: 'iin-field',
        labelTransKey: 'label.iin',
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      document_number: {
        v_if: false,
        beforeTitle: vm.personnel ? undefined : 'label.personnel_info',
        value: '',
        name: 'user[document_number]',
        component: 'text-field',
        labelTransKey: 'label.identification_number',
        required: false,
        extraAttributes: {
          disabled: false,
        },
      },
      migration_status: {
        value: '',
        name: 'user[migration_status]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.migration_status',
        values: _.get(vm.refs, 'migrationStatuses', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      surname: {
        value: '',
        name: 'user[surname]',
        component: 'text-field',
        labelTransKey: 'label.surname',
        required: true,
        extraAttributes: {
          disabled: false,
        },

      },
      firstname: {
        value: '',
        name: 'user[firstname]',
        component: 'text-field',
        labelTransKey: 'label.firstname',
        required: true,
        extraAttributes: {
          disabled: false,
        },

      },
      lastname: {
        value: '',
        name: 'user[lastname]',
        component: 'text-field',
        labelTransKey: 'label.middle_name',
        extraAttributes: {
          disabled: false,
        },

      },
      born_date: {
        value: '',
        name: 'user[born_date]',
        component: 'date-field',
        labelTransKey: 'label.born_date',
        required: true,
        extraAttributes: {
          disabled: false,
        },


      },
      gender: {
        value: '',
        name: 'user[sex]',
        labelTransKey: 'label.gender',
        component: 'radio-field',
        extraAttributes: {
          disabled: false,
        },
        values: [
          {
            labelTransKey: 'label.male',
            value: 'm',
          },
          {
            labelTransKey: 'label.female',
            value: 'w',
          },
        ],
        required: true,

      },
      citizenship_id: {
        value: '',
        name: 'user[citizenship_id]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.citizenship',
        values: _.get(vm.refs, 'citizenships', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      nationality_id: {
        value: '',
        name: 'user[nationality_id]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.nationality',
        values: _.get(vm.refs, 'nationalities', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      email: {
        value: '',
        name: 'user[email]',
        component: 'text-field',
        label: 'E-mail',
        required: false,

      },
      phone: {
        value: '',
        name: 'user[phone]',
        component: 'phone-field',
        labelTransKey: 'label.phone',
        required: true,

      },
      education_kind: {
        value: '',
        name: 'personnel[education_kind]',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.education_kind',
        values: _.get(vm.refs, 'educationKinds', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      position: {
        value: '',
        name: 'personnel_movement[position]',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.position',
        values: _.get(vm.refs, 'positions', []),
        required: true,
        extraAttributes: {
          disabled: false,
          groupValues: 'children',
          groupLabel: 'name',
          groupSelect: false,
        },
        events: {
          change: () => {
            if ([406].includes(this.form.fields.position.value)) {
              this.form.fields.main_subject_load.v_show = true;
              this.form.fields.main_position_rate.v_show = true;
              this.form.fields.defectologist_edu.v_show = true;
            } else if ([405].includes(this.form.fields.position.value)) {
              this.form.fields.defectologist_edu.v_show = true;
            } else {
              this.form.fields.main_subject_load.value = '';
              this.form.fields.main_position_rate.value = '';
              this.form.fields.defectologist_edu.value = '';
              this.form.fields.main_subject_load.v_show = false;
              this.form.fields.main_position_rate.v_show = false;
              this.form.fields.defectologist_edu.v_show = false;
            }
          },
        },
      },
      defectologist_edu: {
        v_show(vm) {
          return [406, 405].includes(vm.form.fields.position.value);
        },
        value: '',
        name: 'personnel[defectologist_edu]',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.defectological_edu',
        values: _.get(vm.refs, 'yes_and_no', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      academic_degree: {
        value: '',
        name: 'personnel[academic_degree]',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.academic_degree',
        values: _.get(vm.refs, 'academicDegrees', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      academic_category: {
        value: '',
        name: 'personnel[academic_category]',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.category',
        values: _.get(vm.refs, 'academicCategories', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
        events: {
          change: () => {
            if (['1', '2', '3', '31', '32', '33', '34', '35'].includes(this.form.fields.academic_category.value)) {
              this.form.fields.category_year.v_show = true;
            } else {
              this.form.fields.category_year.value = '';
              this.form.fields.category_year.v_show = false;
            }
          },
        },
      },
      category_year: {
        v_show(vm) {
          return ['1', '2', '3', '31', '32', '33', '34', '35'].includes(vm.form.fields.academic_category.value);
        },
        value: '',
        name: 'personnel[category_year]',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.category_year',
        values: _.get(vm.refs, 'academicCategoryYears', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      pedagogical_experience: {
        value: '',
        name: 'personnel[pedagogical_experience]',
        component: 'number-field',
        labelTransKey: 'fields.teacher_experience',
        required: true,
        extraAttributes: {
          disabled: false,
        },
        config: {
          alias: 'decimal',
          digits: 2,
          rightAlign: false,
          groupSeparator: '.',
          repeat: 2,
          autoGroup: true,
        },
      },
      work_experience: {
        value: '',
        name: 'personnel[work_experience]',
        component: 'number-field',
        labelTransKey: 'fields.work_experience',
        required: true,
        extraAttributes: {
          disabled: false,
        },
        config: {
          alias: 'decimal',
          digits: 2,
          rightAlign: false,
          groupSeparator: '.',
          repeat: 2,
          autoGroup: true,
        },
      },
      ikt_training_passed: {
        value: '',
        name: 'personnel[ikt_training_passed]',
        labelTransKey: 'fields.ikt_courses_passed',
        component: 'v-multi-select-field',
        values: _.get(vm.refs, 'yes_and_no', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      // english_level: {
      //   value: '',
      //   name: 'personnel[english_level]',
      //   component: 'v-multi-select-field',
      //   labelTransKey: 'fields.english_level',
      //   values: _.get(vm.refs, 'englishLevels', []),
      //   required: false,
      //   extraAttributes: {
      //     disabled: false,
      //   },
      // },
      qual_training_passed: {
        value: '',
        name: 'personnel[qual_training_passed]',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.qualification_courses_passed',
        values: _.get(vm.refs, 'yes_and_no', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      main_subject_load: {
        v_show(vm) {
          return [406].includes(vm.form.fields.position.value);
        },
        value: '',
        name: 'personnel_movement[main_subject_load]',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.main_subject_load',
        values: _.get(vm.refs, 'mainSubjectLoads', []),
        required: true,
      },
      main_position_rate: {
        v_show(vm) {
          return [406].includes(vm.form.fields.position.value);
        },
        value: '',
        name: 'personnel_movement[main_position_rate]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.main_position_rate',
        values: _.get(vm.refs, 'positionRates', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      hiring_date: {
        value: '',
        name: 'personnel_movement[hiring_date]',
        component: 'date-field',
        labelTransKey: 'fields.hiring_date',
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      hiring_order_number: {
        value: '',
        name: 'personnel_movement[hiring_order_number]',
        component: 'text-field',
        labelTransKey: 'fields.hiring_order_number',
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      emp_contract_term: {
        value: '',
        name: 'personnel_movement[emp_contract_term]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.emp_contract_term',
        values: _.get(vm.refs, 'empContractTermTypes', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      employee_type: {
        value: '',
        name: 'personnel_movement[employee_type]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.employee_type',
        values: _.get(vm.refs, 'employeeTypes', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
    };
    let isSearch = true;
    if (vm.personnel !== undefined) {
      isSearch = false;
      _.forEach(vm.personnel, (value, key) => {
        if (fields.hasOwnProperty(key)) {
          fields[key].value = value;
        }
      });
    }
    if (vm.hides) {
      _.forEach(vm.hides, (attribute) => {
        if (fields.hasOwnProperty(attribute)) {
          _.set(fields, `${attribute}.extraAttributes.disabled`, true);
        }
      });
    }
    return {
      isSearch,
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
          // TODO привести к одному варианту
          if (data.redirect) {
            window.location = data.redirect;
          }
        },
      },
    };
  },
  beforeCreate() {
    this.$trans.add(['fields', 'warning_text', 'placeholder', 'label', 'button', 'main', 'notice', 'table']);
  },
  mounted() {
    const vm = this;
  },

  destroyed() {
  },
  methods: {
    changeStatusValidIIN(value) {
      const vm = this;

      if (value) {
        vm.form.fields.iin.extraAttributes.disabled = true;
        vm.form.fields.surname.extraAttributes.disabled = true;
        vm.form.fields.firstname.extraAttributes.disabled = true;
        vm.form.fields.lastname.extraAttributes.disabled = true;
        vm.form.fields.born_date.extraAttributes.disabled = true;
      } else {
        vm.form.fields.iin.extraAttributes.disabled = false;
        vm.form.fields.surname.extraAttributes.disabled = false;
        vm.form.fields.firstname.extraAttributes.disabled = false;
        vm.form.fields.lastname.extraAttributes.disabled = false;
        vm.form.fields.born_date.extraAttributes.disabled = false;
      }
    },
    clearInputs() {
      const vm = this;
      vm.form.fields.iin.value = '';
      vm.form.fields.surname.value = '';
      vm.form.fields.firstname.value = '';
      vm.form.fields.lastname.value = '';
      vm.form.fields.born_date.value = '';
    },
    manualCreate() {
      this.$set(this, 'isSearch', false);
    },
    backSearch() {
      if (this.urlBack) {
        window.location = this.urlBack;
      } else {
        this.$set(this, 'isSearch', true);
        this.changeStatusValidIIN(false);
      }
    },
    searchByIIN() {
      const vm = this;
      const { form } = vm;

      vm.$globalLoading.show();
      const config = {
        responseType: 'json',
        method: 'POST',
      };
      const { fields } = form;

      vm.$http.post(
        vm.urlSearchIin,
        {
          iin: fields.iin.value,
        },
        config,
      )
        .then(
          (response) => {
            vm.$globalLoading.hide();
            const { data } = response;
            if (data.user !== undefined) {
              vm.$set(vm, 'isSearch', false);
              vm.changeStatusValidIIN(true);
              _.forEach(response.data.user, (value, key) => {
                if (fields.hasOwnProperty(key)) {
                  vm.$set(fields[key], 'value', value);
                }
              });
            }
          },
          (response) => {
            vm.$globalLoading.hide();
            if (response.status === 422 || response.status === 423) {
              let errors = [];
              _.forEach(response.data.errors, (value, key) => {
                errors = errors.concat(value);
              });
              show_notice(errors, 'error');
              vm.$set(form, 'errors', response.data.errors);
            } else {
              console.log(response);
            }
          },
        );
    },
    handleVif(data_field) {
      const vm = this;
      if (data_field.component === undefined && data_field.action === undefined) {
        return false;
      }
      if (data_field.v_if !== undefined) {
        if (_.isFunction(data_field.v_if)) {
          return data_field.v_if(vm);
        }
        return data_field.v_if;
      }

      return true;
    },
    handleVShow(field) {
      const vm = this;
      if (field.v_show !== undefined) {
        if (_.isFunction(field.v_show)) {
          return field.v_show(vm);
        }
        return field.v_show;
      }

      return true;
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(form.fields, (el, key) => {
        if ((typeof el.value === 'object') && el.value !== null) {
          const arrField = [];
          _.forEach(el.value, (arrEl) => {
            arrField.push(arrEl.id);
          });
          _.set(data, key, arrField);
        } else if (el.hasOwnProperty('fill')) {
          el.fill(data);
        } else {
          _.set(data, vm.nameToDot(el.name), el.value);
        }
      });
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      vm.$globalLoading.show();

      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
};
