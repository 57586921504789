<template>
  <div>
    <v-wrapper-q-select
      v-model="form.calendar_thematic_plan_id"
      :options="options"
      :extra-binds="{
        label: trans('label.duplicate_ctp'),
        placeholder: trans('placeholder.select_from_list'),
        hideBottomSpace: true,
        required: true,
      }"
    />

    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :disable="!form.calendar_thematic_plan_id"
        :label="trans('button.approve')"
        @click="approve"
      />
    </div>
  </div>
</template>

<script>
import { Helper } from '@common/src/helpers';

export default {
  props: {
    action: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      form: {
        calendar_thematic_plan_id: null,
      },
    };
  },
  methods: {
    async approve() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.action,
        method: 'post',
        params: this.form,
      });
      this.$globalLoading.hide();
      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      } else {
        this.$emit('onApprove');
        this.$emit('onClose');
      }
    },
  },
};
</script>
