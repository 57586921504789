import Vue from 'vue';
import MobileMenu from '@vjs/components/MobileMenu/MobileMenu.vue';
import store from '@vjs/store';


if (document.getElementById('v-adaptive-menu-app')) {
  window.vMobileMenu = new Vue({
    el: '#v-adaptive-menu-app',
    components: { MobileMenu },
    store,
  });
}
