<template>
  <q-form
    autocorrect="off"
    autocapitalize="off"
    autocomplete="off"
    spellcheck="false"
  >
    <q-select
      v-model="form.qualification_id"
      class="sn-select__input--noclear"
      filled
      bottom-slots
      :options="options.qualifications"
      :label="trans('practice.labels.qualification')"
      hide-selected
      fill-input
      use-input
      emit-value
      map-options
      input-debounce="0"
      @filter="(val, update) => defaultFilterFn(val, update, 'qualifications', 'qualificationsFiltered')"
    />
    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :label="trans('button.approve')"
        @click="approve"
      />
    </div>
  </q-form>
</template>

<script>
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';

export default {
  props: {
    action: {
      type: String,
      default: '',
    },
    getQualifications: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {
        qualification_id: null,
      },
      options: {
        qualifications: [
          {
            label: this.trans('practice.labels.none'),
            value: null,
          },
        ],
        qualificationsFiltered: [],
      },
    };
  },
  async mounted() {
    await this.requestGetQualifications();
  },
  methods: {
    async requestGetQualifications() {
      const res = await requestWrapper.call(this, {
        url: this.getQualifications,
        params: {
          quasar: true,
        },
      });

      if (!res.error) {
        this.options.qualifications = this.options.qualifications.concat(res.data);
        this.options.qualificationsFiltered = this.options.qualifications;
      }
    },
    async approve() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.action,
        method: 'post',
        data: {
          qualification_id: this.form.qualification_id,
        },
      });

      if (!res.error) {
        this.$emit('onClose');
      } else {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
    defaultFilterFn(val, update, options, optionsFiltered) {
      if (val === '') {
        update(() => {
          this.options[options] = this.options[optionsFiltered];
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.options[options] = this.options[optionsFiltered]
          .filter(item => _.lowerCase(item.label).indexOf(needle) > -1);
      });
    },
  },
};
</script>
