<template>
  <div>
    <div
      :id="'modal-confirm-candidate-'+ statement.id"
      class="s-modal fade"
      tabindex="-1"
    >
      <div class="s-modal__container">
        <div class="s-modal__head">
          <div
            class="s-modal__close"
            data-toggle="modal"
            :data-target="'#modal-confirm-candidate-'+ statement.id"
          />
        </div>
        <div class="s-modal__body">
          <form>
            <v-wrapper-q-select
              v-model="files"
              :options="declineReasons"
              multiple
              :extra-binds="{
                required: true,
                label: trans('label.decline_reason_files'),
              }"
            />
            <div class="mb-30">
              <label class="s-form__label mb-10">{{ trans('label.decline_message') }}: <i>*</i></label>
              <textarea
                v-model="message"
                class="s-form__textarea"
              />
            </div>
            <div class="row-sort-between-btn">
              <div
                class="s-btn s-btn--thm-grey s-btn--w-150"
                data-toggle="modal"
                :data-target="'#modal-confirm-candidate-'+ statement.id"
              >
                {{ trans('button.cancel') }}
              </div>
              <div
                class="s-btn s-btn--thm-green s-btn--w-150"
                @click="submitDecline"
              >
                {{ trans('button.send') }}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      :id="'modal-confirm-candidate-rework-'+ statement.id"
      class="s-modal fade"
      tabindex="-1"
    >
      <div class="s-modal__container">
        <div class="s-modal__head">
          <div
            class="s-modal__close"
            data-toggle="modal"
            :data-target="'#modal-confirm-candidate-rework-'+ statement.id"
          />
        </div>
        <div class="s-modal__body">
          <form
            :action="setModalConfirmCandidateRework"
            method="post"
          >
            <div class="mb-30">
              <label
                class="s-form__label mb-10"
                for="average_point"
              >
                {{ trans('label.average_point') }}: <i>*</i>
              </label>
              <input
                id="average_point"
                class="s-form__input mb-10 pt-10 mt-20"
                name="average_point"
              >
              <label class="s-form__label mb-10">{{ trans('label.decline_message') }}:</label>
              <textarea
                class="s-form__textarea"
                name="message"
              />
              <input
                type="hidden"
                :value="csrfToken"
                name="_token"
              >
              <input
                id="sign-statement"
                type="hidden"
                name="sign"
                :value="signXml"
              >
            </div>
            <div class="row-sort-between-btn">
              <div
                class="s-btn s-btn--thm-grey s-btn--w-150"
                data-toggle="modal"
                :data-target="'#modal-confirm-candidate-rework-'+ statement.id"
              >
                {{ trans('button.cancel') }}
              </div>
              <div
                class="s-btn s-btn--thm-green s-btn--w-150"
                data-action="send-ajax"
              >
                {{ trans('button.send') }}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Helper } from '@common/src/helpers';

export default {
  name: 'VStatementSignModals',
  props: {
    signXml: {
      type: String,
      default: '',
    },
    csrfToken: {
      type: String,
      default: '',
    },
    statement: {
      type: Object,
      required: true,
    },
    setModalConfirmCandidateRework: {
      type: String,
      required: true,
    },
    statementDisapprove: {
      type: String,
      required: true,
    },
    declineReasons: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      message: null,
      files: [],
    };
  },
  methods: {
    async submitDecline() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.statementDisapprove,
        method: 'post',
        data: {
          message: this.message,
          disapproved_docs: this.files,
          sign: this.signXml,
        },
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      } else if (res.redirect) {
        window.location.href = res.redirect;
      }
    },
  },
};
</script>
