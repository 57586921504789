import VJournalPlanInfo from './components/VJournalPlanInfo';
import VJournalPlanDescription from './components/VJournalPlanDescription';
import VJournalPlanEditForm from './components/VJournalPlanEditForm';

export default {
  name: 'VJournalPlanForm',
  props: {
    uriPlanUpdate: {
      type: String,
      default: null,
    },
    uriPlanEdit: {
      type: String,
      default: null,
    },
    uriPlanDuplicate: {
      type: String,
      default: null,
    },
    uriPlanDescription: {
      type: String,
      default: null,
    },
    uriPlanInfo: {
      type: String,
      default: null,
    },
    uriUserFiles: {
      type: String,
      required: true,
    },
    canEditPlan: Boolean,
    canDuplicatePlan: Boolean,
    backLink: {
      type: String,
      default: null,
    },
    apiConfig: {
      type: [Array, Object],
      required: false,
    },
    extraConfigsFields: {
      type: [Array, Object],
      required: false,
    },
  },
  components: {
    VJournalPlanInfo,
    VJournalPlanDescription,
    VJournalPlanEditForm
  },
  data() {
    return {
      form: {
        theme: null,
        goals: null,
        expected_results: null,
        required_resources: null,
        lesson_progress: null,
      },
    };
  },
  async mounted() {
    await this.$trans.add(['label', 'table']);
  },
};
