import {
  requestWrapper,
  scrollTop,
} from '@vjs/helpers';
import { QPagination } from '@quasar/components';
import VExamQuizResultItem from './VExamQuizResultItem';


export default {
  components: {
    QPagination,
    VExamQuizResultItem,
  },
  props: {
    uriGetData: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      quizResult: {
        questions: [],
        name: '',
        result: {},
        loaded: false,
      },
      countByPage: 10,
      currentPage: 1,
    };
  },

  computed: {
    paginate() {
      const current = (this.currentPage - 1) * this.countByPage;
      return this.quizResult.questions.slice(current, current + this.countByPage);
    },
    countOfPages() {
      const count = Math.ceil(this.quizResult.questions.length / this.countByPage);
      this.currentPage = 1;
      return count <= 1 ? 0 : count;
    },
  },

  async mounted() {
    await this.$trans.add('label');
    this.$globalLoading.show();
    const res = await requestWrapper.call(this, {
      url: this.uriGetData,
    });

    if (!res.error) {
      this.quizResult = { ...res.data, loaded: true };
    }
    console.log(this.quizResult);
    this.$globalLoading.hide();
    this.activateMathJax();
  },
  updated() {
    this.activateMathJax();
  },
  methods: {
    activateMathJax() {
      // Активация MathJax, если MathJax найден
      if (window.MathJax) {
        window.MathJax.Hub.Config({
          tex2jax: {
            inlineMath: [['$', '$'], ['\\(', '\\)']],
            processEscapes: true,
          },
        });
        window.MathJax.Hub.Queue([
          'Typeset',
          window.MathJax.Hub,
        ],
        () => {
          // Фикс от скачков формул во время отрисовки
          const mathTex = document.querySelectorAll('.math-tex');
          Array.from(mathTex).forEach((item) => {
            item.style.opacity = '1';
          });
        });
      }
    },
    async changePagination() {
      scrollTop(
        window,
        window.scrollY,
        window.scrollY - Math.abs(this.$refs.examQuizResultRef.getBoundingClientRect().top),
        500,
      );
    },
  },
};
