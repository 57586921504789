export default {
  endpoints: {
    loadData: '',
    loadTime: '',
    storeAnswer: '',
    storeResult: '',
  },
  quizId: 0,
  curSubjectID: 0,
  curQuestionsMapPage: 0,
  loading: {
    common: false,
    answer: false,
    message: '',
  },
  helpWithLastQuestion: {
    id: 0,
    idx: 0,
  },
  test: {},
  data: [],
};

/**
 *
 * Статусы теста:
 *  - begin - тест активен
 *  - wait - тест активен, но на все вопросы уже отправлены ответы
 *  - end - тест завершён (по желанию пользователя или по истечению времени)
 *  - preview - просмотр неправильных ответов в режиме просмотра
 *
 */

/**

 Содержимое data: []

 {
    id: 75,
    name: 'Test',
    curQuestion: 51152,
    curQuestionIdx: 0,
    leftAnswers: 0,
    questions: [
      {
        id: 51152,
        question: 'Test question',
        answers: [
          {
            id: 123,
            answer: 'Test answer 1',
            checked: true,
          },
          {
            id: 321,
            answer: 'Test answer 2',
            checked: false,
          },
        ],
      },
    ],
  },

 */
