export default {
  name: 'VStatisticBlock',
  props: {
    langs: {
      type: Object,
      default: () => ({
        title: '',
        personal: '',
        college: '',
        contingent: '',
        service: '',
        more: '',
      }),
    },
    staticAddr: String,
    statisticsAddr: String,
    organizationUri: String,
    serviceUri: String,
    personalUri: String,
    contingentUri: String,
  },
  data() {
    return {
      countOrganization: '',
      countService: '',
      countPersonal: '',
      countContingent: '',
    };
  },
  async mounted() {
    this.countOrganization = (await this.request(this.organizationUri)).length.toString();
    this.countService = (await this.request(this.serviceUri)).reduce((acc, item) => acc + item.count, 0).toString();
    this.countPersonal = (await this.request(this.personalUri)).reduce((acc, item) => acc + item.count, 0).toString();
    this.countContingent = (await this.request(this.contingentUri)).reduce((acc, item) => acc + item.count, 0).toString();
  },
  methods: {
    beautyNum(num = '') {
      return num.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
    },
    async request(uri, params) {
      return new Promise(async (res, rej) => {
        try {
          const options = {
            url: uri,
            headers: {
              'Accept-Language': window.core_project.locale,
              'X-RegionId': window.core_project.region_id,
            },
          };

          if (params) {
            options.params = params;
          }

          const response = await this.$axios(options);
          const { data } = response;

          res(data);
        } catch (err) {
          rej((err && err.response && err.response.data) || err);
        }
      });
    },
  },
};
