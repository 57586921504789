import _ from 'lodash';

export default {
  name: 'VSteps',

  props: {
    uriStatic: {
      type: String,
      required: true,
    },
  },

  beforeCreate() {
    this.$trans.add('statement');
  },

  // Рендер блока с шагами
  render(h) {
    const {
      steps,
      currentStep,
    } = this.$store.state.vSteps;
    const items = [];

    _.forEach(steps, (val) => {
      if (!val.hidden) {
        items.push(h('div', {
          key: val.step,
          staticClass: 'col mb-30 d-flex justify-content-center',
        }, [h('div', {
          staticClass: 'sc-statement-steps',
          class: {
            'is-active': currentStep === val.step,
            'is-passed': currentStep > val.step,
          },
        }, [h('div', {
          staticClass: 'sc-statement-steps__item',
        }, [h('div', {
          staticClass: 'sc-statement-steps__icon',
        }, [h('img', {
          attrs: {
            src: currentStep >= val.step ? `${this.uriStatic}/${val.activeImage}` : `${this.uriStatic}/${val.image}`,
          },
        })])]), [h('div', {
          staticClass: 'sc-statement-steps__label',
        }, this.trans(val.label))]])]));
      }
    });

    return h('div', {
      staticClass: 'row align-items-stretch justify-content-center sc-statement-steps__wrapper mb-30',
    }, items);
  },
};
