import { mapGetters } from 'vuex';
import {
  QSpinnerGears,
} from '@quasar/components';
import VTestQuestion from './Components/VTestQuestion';
import VTestNavigation from './Components/VTestNavigation';

export default {
  name: 'VQuestionnaireProcess',
  components: {
    VTestQuestion,
    VTestNavigation,
    QSpinnerGears,
  },
  props: {
    questionnaire: {
      type: Object,
      default: null,
    },
    userName: {
      type: String,
      default: '',
    },
    apiProcessConfig: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showResult: false,
    };
  },
  async mounted() {
    await this.$trans.add(['main', 'test', 'label', 'warning_text', 'questionnaire']);
    this.$store.commit('SET_QUESTIONNAIRE_VM', this);
    this.$store.commit('SET_PROCESS_API_CONFIG', this.apiProcessConfig);
    await this.$store.dispatch('GET_PROCESS_DATA');

    if (this.questionGroup) {
      const curQuestionID = parseInt(localStorage.getItem('curQuestionID'), 10);
      const curQuestionIdx = parseInt(localStorage.getItem('curQuestionIdx'), 10);
      // Если находим сохранённый вопрос, то делаем его текущим выбранным
      if (curQuestionID
        && this.questionGroup.questions[curQuestionIdx]) {
        this.$store.commit('SET_CURRENT_PROCESS_QUESTION', { id: curQuestionID, idx: curQuestionIdx });
        // this.checkQuestionMap();
      } else {
        localStorage.removeItem('curQuestionIdx');
        localStorage.removeItem('curQuestionID');
      }
    }
    if (this.questionnaire.status === 'start') {
      window.onbeforeunload = (e) => {
        const dialogText = 'ВНИМАНИЕ! Тест ещё не закончен - вы уверены, что хотите покинуть страницу?';
        e.returnValue = dialogText;
        return dialogText;
      };
    }
  },
  computed: {
    ...mapGetters([
      'questionGroup',
      'loading',
    ]),
    validate() {
      let answeredQuestionsCount = 0;
      if (this.questionGroup) {
        answeredQuestionsCount = this.questionGroup.questions
          .filter(question => question.answers.filter(answer => answer.checked && (!answer.is_optional || answer.own_answer)).length).length;
      }
      return this.questionGroup.questions.length === answeredQuestionsCount;
    },
  },
  methods: {
    confirmEndOfTest() {
      const events = {
        onApprove: async () => {
          window.onbeforeunload = null;
          await this.$store.dispatch('SEND_PROCESS_RESULT');
          eventBus.$emit('onCloseAll');
        },
      };
      this.$sModal.open('v-modal-confirm', {
        title: this.trans('notice.are_you_sure'),
        component: 'v-modal-confirm',
        events,
      });
    },
  },
};
