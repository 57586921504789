import { Helper } from '@common/src/helpers';
import { required } from '@vjs/validations';
import Validation from '@vjs/mixins/Validation';

export default {
  mixins: [
    Validation,
  ],
  props: {
    action: {
      type: String,
      default: '',
    },
    searchUri: {
      type: String,
      default: '',
    },
    urlBack: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      validation: {},
      form: {
        iin: null,
        position: null,
        email: null,
        phone: null,
      },
    };
  },
  computed: {
    validate() {
      return this.form.iin && this.form.position && this.form.email && this.form.phone
        && this.validateIin(this.form.iin) && this.validateEmail(this.form.email)
        && this.form.phone.length > 17;
    },
    checkIinDisable() {
      return !this.validateIin(this.form.iin);
    },
    checkIinBtnDisable() {
      return !this.checkIinDisable;
    },
  },
  async mounted() {
    await this.$trans.add(['validation', 'button', 'label']);
  },
  methods: {
    clearForm() {
      this.form.position = null;
      this.form.email = null;
      this.form.phone = null;
    },
    async searchUser() {
      this.clearForm();
      if (!this.form.iin || this.form.iin.length !== 12) {
        return;
      }
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'POST',
        url: this.searchUri,
        data: this.form,
      });
      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      } else {
        this.form = res;
        if (Object.keys(res).length > 0) {
          this.existingEnterprise = true;
        }
      }
      this.$globalLoading.hide();
    },
    async handleSubmit() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'POST',
        url: this.action,
        data: this.form,
      });
      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
    required: message => required(message),
  },
};
