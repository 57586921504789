<template>
  <div>
    <div
      class="row no-gutters"
      :loading="loading"
    >
      <div
        v-for="(question, question_key) in questions"
        :key="question_key"
        class="col-auto mb-10"
        style="margin-right: 0.5rem; margin-left: 0.5rem;"
      >
        <q-btn
          class="s-btn--w-45"
          :color="question.is_visible ? 'aqua' : 'grey'"
          squire
          :loading="loading"
          :label="question_key+1"
          @click.prevent="questionChangeVisibility(question.id)"
        >
          <q-tooltip>
            <div v-html="question.question" />
          </q-tooltip>
        </q-btn>
      </div>
    </div>
    <div class="mt-20 row-sort-between-btn">
      <q-btn
        class="s-btn--w-200"
        color="aqua"
        :label="trans('button.show_all')"
        :loading="loading"
        @click="showAllQuestions"
      />
      <q-btn
        class="s-btn--w-200"
        color="grey"
        :label="trans('button.hide_all')"
        :loading="loading"
        @click="hideAllQuestions"
      />
      <q-btn
        class="s-btn--w-150"
        color="grey"
        :label="trans('button.close')"
        :loading="loading"
        @click="$emit('onClose')"
      />
    </div>
  </div>
</template>

<script>
import {
  QBtn,
  QTooltip,
} from '@quasar/components';
import { mapGetters } from 'vuex';

export default {
  name: 'VQuestionVisibilityModal',
  components: {
    QBtn,
    QTooltip,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['questions']),
  },
  methods: {
    async showAllQuestions() {
      this.loading = true;
      await this.$store.dispatch('HIDE_QUESTIONNAIRE_QUESTIONS', {
        is_visible: 1,
      });
      this.$emit('onClose', false);
      this.loading = false;
    },
    async hideAllQuestions() {
      this.loading = true;
      await this.$store.dispatch('HIDE_QUESTIONNAIRE_QUESTIONS', {
        is_visible: 0,
      });
      this.$emit('onClose', false);
      this.loading = false;
    },
    async questionChangeVisibility(id) {
      await this.$store.dispatch('HIDE_QUESTIONNAIRE_QUESTION', id);
    },
  },
};
</script>
