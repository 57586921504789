<template>
  <div>
    <div
      :id="'modal-decline-external-statement-' + statement.id"
      class="s-modal fade"
      tabindex="-1"
    >
      <div class="s-modal__container">
        <div class="s-modal__head">
          <div class="s-modal__title">
            {{ trans('statement.select_decline_reason') }}
          </div>
          <div
            class="s-modal__close"
            data-toggle="modal"
            :data-target="'#modal-decline-external-statement-' + statement.id"
          />
        </div>
        <div class="s-modal__body">
          <form
            :action="externalStatementDeclineUrl"
            method="post"
          >
            <div class="mb-30">
              <input
                type="hidden"
                :value="csrfToken"
                name="_token"
              >
              <input
                id="sign-statement"
                type="hidden"
                name="sign"
                :value="signXml"
              >
              <label
                class="s-form__label mb-10"
                for="external-statement-decline-message"
              >
                {{ trans('label.decline_message') }}
              </label>
              <select
                id="code"
                class="select2-custom"
                name="code"
              >
                <option
                  v-for="(label, code) in negativeResponses"
                  :value="code"
                >
                  {{ label }}
                </option>
              </select>
              <textarea
                id="external-statement-decline-message"
                class="s-form__textarea mb-10 pt-10 mt-20"
                name="message"
              />
            </div>
            <div class="row-sort-between-btn">
              <div
                class="s-btn s-btn--thm-grey s-btn--w-150"
                data-toggle="modal"
                :data-target="'#modal-decline-external-statement-' + statement.id"
              >
                {{ trans('button.cancel') }}
              </div>
              <div
                class="s-btn s-btn--thm-green s-btn--w-150"
                data-action="send-ajax"
              >
                {{ trans('button.send') }}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      :id="'modal-accept-external-statement-' + statement.id"
      class="s-modal fade"
      tabindex="-1"
    >
      <div class="s-modal__container">
        <div class="s-modal__head">
          <div
            class="s-modal__close"
            data-toggle="modal"
            :data-target="'#modal-accept-external-statement-' + statement.id"
          />
        </div>
        <div class="s-modal__body">
          <form
            :action="acceptExternalRequestUrl"
            method="post"
          >
            <div class="mb-30">
              <input
                type="hidden"
                :value="csrfToken"
                name="_token"
              >
              <input
                id="sign-statement"
                type="hidden"
                name="sign"
                :value="signXml"
              >
              <template v-if="isAdditionalData">
                <label
                  class="s-form__label mb-10"
                  for="order_number"
                >
                  {{ trans('label.order_number') }}:
                </label>
                <input
                  id="order_number"
                  class="s-form__input mb-10"
                  type="text"
                  name="order_number"
                >
                <label
                  class="s-form__label mb-10"
                  for="order_date"
                >
                  {{ trans('label.order_date') }}:
                </label>
                <input
                  id="order_date"
                  class="s-form__input mb-10 datepicker"
                  autocomplete="off"
                  type="text"
                  name="order_date"
                >
              </template>
              <template v-if="type === isDormitory">
                <label
                  class="s-form__label mb-10"
                  for="dorm_number"
                >
                  {{ trans('label.dormitory_number') }}
                </label>
                <input
                  id="dorm_number"
                  class="s-form__input mb-10"
                  type="text"
                  name="dorm_number"
                >
                <label
                  class="s-form__label mb-10"
                  for="dorm_address"
                >
                  {{ trans('label.dormitory_address') }}
                </label>
                <input
                  id="dorm_address"
                  class="s-form__input mb-10"
                  type="text"
                  name="dorm_address"
                >
              </template>
              <template v-if="type === isFreeFood">
                <div class="mb-10">
                  <label
                    class="s-form__label mb-10"
                    for="year"
                  >
                    {{ trans('label.education_year') }}
                  </label>
                  <select
                    id="year"
                    class="select2-custom"
                    name="year"
                  >
                    <option
                      v-for="(label, code) in yearFoodMaps"
                      :value="code"
                    >
                      {{ label }}
                    </option>
                  </select>
                </div>
              </template>
              <template v-if="type === isDuplicate">
                <label
                  class="s-form__label mb-10"
                  for="cabinet_num"
                >
                  {{ trans('label.cabinet') }}<i>*</i>:
                </label>
                <input
                  id="cabinet_num"
                  class="s-form__input mb-10"
                  type="text"
                  name="cabinet_num"
                >
              </template>
              <template v-if="type === isRestore">
                <label
                  class="s-form__label mb-10"
                  for="course"
                >
                  {{ trans('label.course') }}:
                </label>
                <input
                  id="course"
                  class="s-form__input mb-10"
                  type="text"
                  maxlength="1"
                  name="course"
                >
                <div class="mb-10">
                  <label
                    class="s-form__label mb-10"
                    for="edu_form"
                  >
                    {{ trans('statement.edu_form') }}
                  </label>

                  <select
                    id="edu_form"
                    class="select2-custom"
                    name="edu_form"
                  >
                    <option
                      v-for="(label, code) in educationForms"
                      :value="code"
                    >
                      {{ label }}
                    </option>
                  </select>
                </div>
                <div class="mb-10">
                  <label
                    class="s-form__label mb-10"
                    for="profession_code"
                  >
                    {{ trans('label.profession') }}
                  </label>

                  <select
                    id="profession_code"
                    class="select2-custom"
                    name="profession_code"
                  >
                    <option
                      v-for="profession in professions"
                      :value="profession.code"
                    >
                      {{ profession.name }}
                    </option>
                  </select>
                </div>
              </template>
            </div>
            <div class="row-sort-end">
              <div class="s-group">
                <div
                  class="s-btn s-btn--thm-green s-btn--w-150"
                  data-action="send-ajax"
                >
                  {{ trans('button.send') }}
                </div>
                <div
                  class="s-btn s-btn--thm-grey s-btn--w-150"
                  data-toggle="modal"
                  :data-target="'#modal-accept-external-statement-'+statement.id"
                >
                  {{ trans('button.cancel') }}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { StoreTranslator } from '@common/mixins';

export default {
  name: 'VStatementServiceSignModals',
  mixins: [StoreTranslator],
  props: {
    signXml: {
      type: String,
      default: '',
    },
    csrfToken: {
      type: String,
      default: '',
    },
    statement: {
      type: Object,
      required: true,
    },
    negativeResponses: {
      type: Object,
      required: false,
    },
    professions: {
      type: Array,
      required: false,
    },
    educationForms: {
      type: Object,
      required: false,
    },
    yearFoodMaps: {
      type: Object,
      required: false,
    },
    isAdditionalData: {
      type: String,
    },
    isDormitory: {
      type: String,
      required: true,
    },
    isFreeFood: {
      type: String,
      required: true,
    },
    isDuplicate: {
      type: String,
      required: true,
    },
    isRestore: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    externalStatementDeclineUrl: {
      type: String,
      required: true,
    },
    acceptExternalRequestUrl: {
      type: String,
      required: true,
    },
  },

};
</script>
