import { Helper } from '@common/src/helpers';

export default {
  name: 'ProgramDuplicate',
  data() {
    const fields = {
      program_id: null,
      discipline_id: null,
    };
    return {
      fields,
      disciplines: [],
    };
  },
  mounted() {
    _.forEach(this.resource, (attribute, key) => {
      if (this.fields.hasOwnProperty(key)) {
        this.fields[key] = attribute;
      }
    });
  },
  computed: {
    validate() {
      return this.fields.program_id !== null && (!this.disciplines?.length > 0 || this.fields.discipline_id !== null);
    },
    programs() {
      return this.$store.getters.duplicatePrograms ?? [];
    },
  },
  methods: {
    async handleGetDisciplines() {
      const { curriculumProgramApiConfig, makeProgramUrl } = this.$store.getters;
      const url = makeProgramUrl(curriculumProgramApiConfig.duplicate?.disciplines, this.fields.program_id);
      await requestGetDataHelper(this, {
        url,
        variableName: 'disciplines',
        hardSet: true,
      });
    },
    async handleAjaxFormSubmit() {
      this.$globalLoading.show();
      const data = this.fields;
      const { curriculumProgramApiConfig, makeProgramUrl, parentId } = this.$store.getters;
      const url = this.action ?? makeProgramUrl(curriculumProgramApiConfig.duplicate?.action, parentId);
      const res = await requestWrapper.call(this, {
        url,
        method: 'post',
        data,
      });

      if (!res.error) {
        await this.$store.dispatch('GET_CURRICULUM_PROGRAM_CONTENT_TABLE');
        this.$emit('onClose');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
