import {
  QTooltip,
  QInput,
  QForm,
  QBtn,
} from '@quasar/components';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { requestWrapper } from '@vjs/helpers';
import eventHub from '@vjs/config/eventHub';
import _ from 'lodash';

export default {
  name: 'VExamTicketAdd',
  components: {
    QTooltip,
    QInput,
    QForm,
    QBtn,
  },
  props: {
    uriAddTicket: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: '',
      loading: false,
      body: '',
      storeData: this.$store,
      editor: ClassicEditor,
      editorConfig: {
        language: 'ru',
        toolbar: {
          items: [
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'imageUpload',
            'blockQuote',
            'insertTable',
            'undo',
            'redo',
          ],
        },
      },
      UploadAdapter: function UploadAdapter(loader, store) {
        this.upload = () => loader.file
          .then(file => new Promise((resolve) => {
            const formData = new FormData();
            _.each(loader, (item, index) => {
              formData.append(index, item);
            });
            store.dispatch('FILE_TICKET', { payload: formData, file, resolve });
          }));
        this.abort = () => {
          console.log('Abort upload.');
        };
      },
    };
  },
  methods: {
    cancelAdd() {
      eventHub.$emit('cancelAdding', true);
    },
    onEditorReady(instance) {
      const fileRepository = instance.plugins.get('FileRepository');

      if (fileRepository != null) {
        fileRepository.createUploadAdapter = loader => new this.UploadAdapter(
          loader, this.storeData,
        );
      }
    },
    async requestAddTicket() {
      this.loading = true;
      const res = await requestWrapper.call(this, {
        url: this.uriAddTicket,
        method: 'post',
        data: {
          body: this.body,
          name: this.name,
        },
      }, true);

      if (!res.error) {
        if (res.data && res.data.message) {
          this.$notify({
            text: res.data.message,
            type: 'success',
          });
        }
        eventHub.$emit('addedTicket', true);
      }
      if (res.error && res.data.errors) {
        this.$notify({
          text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
          type: 'error',
        });
      }
      this.loading = false;
    },
  },
};
