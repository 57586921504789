import { StoreTranslator } from '@common/mixins';


export default {
  mixins: [StoreTranslator],
  props: {
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    refs: {
      type: Object,
      required: true,
    },
    urlBack: {
      type: String,
      required: false,
      default: null,
    },
    resource: {
      type: [Array, Object],
      required: false,
    },
    hides: {
      type: [Array, Object],
      required: false,
      default: () => ([]),
    },
    extraConfigsFields: {
      type: [Array, Object],
      required: false,
    },
    urlGetContingents: {
      type: String,
      required: true,
    },
    urlGetDateOfPoints: {
      type: String,
      required: true,
    },
    day: {
      type: [Array, Object],
      required: false,
    },
  },
  data() {
    const vm = this;
    const studentsOptions = [];
    const dateOfPointsOptions = [];
    const fields = {
      name: {
        value: null,
        name: 'name',
        component: 'text-field',
        labelTransKey: 'fields.name',
        values: [],
        required: true,
      },
      journal_id: {
        value: null,
        name: 'journal_id',
        component: 'v-multi-select-field',
        labelTransKey: 'menu.journal',
        values: [],
        required: true,
        v_show: true,
        loadValues: {
          autoLoad: true,
          type: 'journals',
          headers: {
            'X-Permission': 'homework-create',
          },
        },
        events: {
          change: () => {
            if (vm.method !== 'PATCH' && (!this.form.fields.students.value || !this.form.fields.date_point.value)) {
              this.form.fields.students.value = [];
              this.form.fields.date_point.value = [];
            }
            if (this.form.fields.journal_id.value) {
              this.form.fields.select_all_students.value = true;
              vm.loadContingents(this.form.fields.journal_id.value);
              vm.loadDateOfPoints(this.form.fields.journal_id.value);
            } else {
              this.studentsOptions = [];
              this.dateOfPointsOptions = [];
              this.form.fields.students.values = [];
              this.form.fields.date_point.values = [];
            }
          },
        },
      },
      select_all_students: {
        v_if(vm) {
          return !!vm.form.fields.journal_id.value && vm.method !== 'PATCH' && vm.form.fields.students.values.length > 1;
        },
        value: true,
        name: 'select_all_students',
        component: 'boolean-field',
        checkboxLabelTransKey: 'label.select_all_students',
        required: false,
        events: {
          input: () => {
            this.form.fields.students.value = [];
          },
        },
      },
      students: {
        v_if(vm) {
          return !!vm.form.fields.journal_id.value && (vm.form.fields.select_all_students.value === false || vm.method === 'PATCH');
        },
        value: [],
        name: 'students',
        component: 'v-multi-select-field',
        labelTransKey: 'label.students',
        values: [],
        required: true,
        extraAttributes: {
          multiple: true,
        },
      },
      homeworkWithTesting: {
        value: false,
        name: 'homeworkWithTesting',
        component: 'boolean-field',
        checkboxLabelTransKey: 'fields.homework_with_testing',
        events: {
          input: () => {
            this.form.fields.homework_id.value = null;
            this.form.fields.homework_question_group.value = null;
          },
        },
      },
      homework_id: {
        value: null,
        name: 'homework_id',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.homework_id',
        values: [],
        required: true,
        v_show: true,
        v_if(vm) {
          return !vm.form.fields.homeworkWithTesting.value;
        },
        loadValues: {
          autoLoad: true,
          type: 'homeworks',
        },
      },
      homework_question_group: {
        value: null,
        name: 'homework_question_group_id',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.homework_question_group',
        values: [],
        required: true,
        v_show: true,
        v_if(vm) {
          return vm.form.fields.homeworkWithTesting.value;
        },
        loadValues: {
          autoLoad: true,
          type: 'homework-question-groups',
        },
      },
      question_count: {
        component: 'number-field',
        value: null,
        name: 'question_count',
        labelTransKey: 'fields.question_count',
        required: true,
        v_show: true,
        v_if(vm) {
          return vm.form.fields.homeworkWithTesting.value;
        },
        config: {
          mask: ['999'],
          greedy: false,
          placeholder: '',
        },
      },
      date_end: {
        value: '',
        name: 'date_end',
        component: 'date-field',
        labelTransKey: 'fields.date_end',
        required: true,
      },
      time_end: {
        value: '',
        name: 'time_end',
        component: 'number-field',
        labelTransKey: 'fields.time',
        required: true,
        config: {
          alias: 'datetime',
          inputFormat: 'HH:MM',
          hourFormat: 24,
        },
      },
      date_point: {
        value: '',
        name: 'date_point',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.date_point',
        values: [],
        required: true,
        v_show: true,
        templateOption: 'Тема: {name} <br/> Дата: {date}',
        extraAttributes: {
          disabled: vm.method === 'patch',
          customLabel(option, label) {
            return option.date;
          },
        },
      },
    };

    if (vm.resource !== undefined) {
      if (vm.resource.values) {
        _.forEach(vm.resource.values, (value, key) => {
          if (fields.hasOwnProperty(key)) {
            fields[key].value = value;
          }
        });
      }
      if (vm.resource.hides) {
        _.forEach(vm.resource.hides, (attribute) => {
          if (fields.hasOwnProperty(attribute)) {
            _.set(fields, `${attribute}.extraAttributes.disabled`, true);
          }
        });
      }
    }
    if (vm.extraConfigsFields) {
      _.forEach(vm.extraConfigsFields, (configs, attribute) => {
        if (fields.hasOwnProperty(attribute)) {
          const field = fields[attribute];
          _.set(fields, attribute, _.assignIn(field, configs));
        }
      });
    }
    return {
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
          // TODO привести к одному варианту
          if (data.redirect) {
            window.location = data.redirect;
          }
        },
      },
    };
  },
  beforeCreate() {
    this.$trans.add([
      'fields', 'warning_text', 'placeholder', 'label', 'button', 'main', 'notice', 'cabinet', 'curriculum',
      'personnel_dicts',
      'training_schedule',
      'menu',
    ]);
  },
  mounted() {
    const vm = this;
    if (vm.day) {
      vm.$set(vm.form.fields.date_point, 'value', vm.day.id);
      vm.$set(vm.form.fields.journal_id, 'value', vm.day.journal_id);
    }
  },

  destroyed() {
  },
  methods: {
    loadContingents(journalId) {
      const vm = this;
      if (!journalId) {
        vm.$set(vm.form.fields.students, 'value', null);
        return;
      }
      const config = {
        responseType: 'json',
        method: 'GET',
        params: {
          journal_id: journalId,
        },
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      vm.$http.get(vm.urlGetContingents, config).then(
        (response) => {
          // const { data } = response;
          // vm.$set(vm, 'studentsOptions', response.body);
          vm.$set(vm.form.fields.students, 'values', response.body);
        },
        (response) => {
          console.log(response);
        },
      );
    },

    loadDateOfPoints(journalId) {
      const vm = this;
      if (!journalId) {
        vm.$set(vm.form.fields.date_point, 'value', null);
        return;
      }
      const config = {
        responseType: 'json',
        method: 'GET',
        params: {
          journalId,
        },
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      vm.$http.get(vm.urlGetDateOfPoints, config).then(
        (response) => {
          vm.$set(vm.form.fields.date_point, 'values', response.body);
        },
        (response) => {
          vm.$set(vm.form.fields.date_point, 'values', []);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
          } else {
            console.log(response);
          }
        },
      );
    },

    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleVShow(field) {
      const vm = this;
      if (field.v_show !== undefined) {
        if (_.isFunction(field.v_show)) {
          return field.v_show(vm);
        }
        return field.v_show;
      }

      return true;
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(form.fields, (el, key) => {
        _.set(data, vm.nameToDot(el.name), el.value);
      });
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      vm.$globalLoading.show();

      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
};
