export default {
  methods: {
    showForm(name_form, type, item) {
      const vm = this;
      const form = vm.forms[name_form];

      _.forEach(form.fields, (val, key) => {
        if (item && item[key] !== undefined) {
          vm.$set(form.fields[key], 'value', item[key]);
        } else {
          vm.$set(form.fields[key], 'value', null);
        }
      });
      if (type === 'store') {
        vm.$set(form, 'currentAction', form.actionStore);
        vm.$set(form, 'method', 'POST');
      } else {
        vm.$set(form, 'currentAction', form.actionUpdate);
        vm.$set(form, 'method', 'PATCH');
      }
      if (form.hasOwnProperty('onOpen')) {
        form.onOpen();
      }
      $(`#${form.modal}`).modal('show');
    },
    showConfirmDelete(curriculum_working_discipline) {
      const vm = this;
      vm.$set(vm.confirm, 'discipline', curriculum_working_discipline);
      $('#modal-discipline-delete').modal('show');
    },
    hideModal(form) {
      if (form.modal) {
        $(`#${form.modal}`).modal('hide');
      }
    },
  },

};
