import { StoreTranslator } from '@common/mixins';


export default {
  mixins: [StoreTranslator],
  props: {
    urlBack: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    resource: {
      type: [Array, Object],
      required: false,
    },
    isEducator: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const vm = this;
    const fields = {
      name: {
        value: null,
        name: 'name',
        component: 'text-field',
        labelTransKey: 'fields.name',
        values: [],
        required: true,
      },
      qualification_id: {
        value: null,
        name: 'qualification_id',
        component: 'v-multi-select-field',
        labelTransKey: 'label.qualification_id',
        values: [],
        required: true,
        v_show: true,
        loadValues: {
          autoLoad: true,
          type: 'qualifications/by-group',
          where: {
            is_educator: vm.isEducator,
          },
        },
        events: {
          change: () => {
            if (this.form.fields.qualification_id.value) {
              this.form.fields.group_id.loadValues.where.qualification_id = this.form.fields.qualification_id.value;
              this.form.fields.group_id.v_show = true;
              this.form.fields.group_id.loadValues.reload();
            } else {
              this.form.fields.group_id.loadValues.setEmpty();
              this.form.fields.group_id.v_show = false;
            }
          },
        },
      },
      group_id: {
        value: null,
        name: 'group_id',
        component: 'v-multi-select-field',
        labelTransKey: 'label.group',
        values: [],
        required: true,
        v_show: false,
        loadValues: {
          autoLoad: false,
          type: 'groups/filters',
          where: {
            qualification_id: null,
            is_educator: vm.isEducator,
          },
        },
        events: {
          change: () => {
            if (this.method === 'post') {
              this.form.fields.contingents.value = [];
            }
            if (this.form.fields.group_id.value) {
              this.form.fields.contingents.loadValues.where.group_id = this.form.fields.group_id.value;
              this.form.fields.contingents.v_show = true;
              this.form.fields.contingents.loadValues.reload();
            } else {
              this.form.fields.contingents.loadValues.setEmpty();
              this.form.fields.contingents.v_show = false;
            }
          },
        },
      },
      contingents: {
        value: [],
        name: 'contingents',
        component: 'v-multi-select-field',
        labelTransKey: 'label.students',
        values: [],
        required: true,
        v_show: false,
        extraAttributes: {
          multiple: true,
        },
        loadValues: {
          autoLoad: false,
          type: 'contingents/by-group',
          where: {
            group_id: null,
          },
        },
      },
    };
    if (vm.resource !== undefined) {
      if (vm.resource.values) {
        _.forEach(vm.resource.values, (value, key) => {
          if (fields.hasOwnProperty(key)) {
            fields[key].value = value;
          }
        });
      }
      if (vm.resource.hides) {
        _.forEach(vm.resource.hides, (attribute) => {
          if (fields.hasOwnProperty(attribute)) {
            _.set(fields, `${attribute}.extraAttributes.disabled`, true);
          }
        });
      }
    }
    return {
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
          // TODO привести к одному варианту
          if (data.redirect) {
            window.location = data.redirect;
          }
        },
      },
    };
  },
  beforeCreate() {
    this.$trans.add(['fields', 'warning_text', 'placeholder', 'label', 'button', 'main', 'notice', 'menu']);
  },
  destroyed() {
  },
  methods: {
    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleVShow(field) {
      const vm = this;
      if (field.v_show !== undefined) {
        if (_.isFunction(field.v_show)) {
          return field.v_show(vm);
        }
        return field.v_show;
      }

      return true;
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(form.fields, (el, key) => {
        if (key === 'contingents') {
          const students = [];
          _.forEach(el.value, (student) => {
            students.push(student.id);
          });
          _.set(data, vm.nameToDot(el.name), students);
        } else {
          _.set(data, vm.nameToDot(el.name), el.value);
        }
      });
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
};
