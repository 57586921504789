import Vue from 'vue';

import VRelatedPieCharts from './VueJS/views/VRelatedPieCharts';
import VBarChart from './VueJS/views/VBarChart';
import VPopulationPyramid from './VueJS/views/VPopulationPyramid';
import VRelatedBarPieChart from './VueJS/views/VRelatedBarPieChart';
import VTableChart from './VueJS/views/VTableChart';
import VStatisticBlock from './VueJS/views/VStatisticBlock';
import VPieChart from './VueJS/views/VPieChart';

if (document.getElementById('v-statistic-app')) {
  window.vm = new Vue({
    el: '#v-statistic-app',
    components: {
      // VPieChart,
      VRelatedPieCharts,
      VBarChart,
      VPopulationPyramid,
      VRelatedBarPieChart,
      VTableChart,
      VStatisticBlock,
      VPieChart,
    },
  });
}
