import { StoreTranslator } from '@common/mixins';
import Validation from '@vjs/mixins/Validation';
import { Helper } from '@common/src/helpers';
import {
  QCheckbox,
  QDialog,
  QField,
  QInput,
  QOptionGroup,
  QSelect,
} from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import VModal from '@vjs/components/VModal';
import VConfirmDialog from '@vjs/modals/VConfirmDialog.vue';

export default {
  mixins: [StoreTranslator, Validation],
  components: {
    QSelect,
    QField,
    QOptionGroup,
    QCheckbox,
    QInput,
    QDialog,
    VModal,
    VConfirmDialog,
  },
  props: {
    uriGetDisciplines: {
      type: String,
      required: true,
    },
    uriGetResults: {
      type: String,
      required: true,
    },
    uriGetThemes: {
      type: String,
      required: true,
    },
    accessEdit: {
      type: Boolean,
      default: false,
    },
    accessEditTime: {
      type: Boolean,
      default: false,
    },
    defaultTrainingSchedule: {
      type: [Number],
      default: null,
    },
    lessonConfig: {
      type: Object,
      required: true,
      default() {
        return {
          actionEdit: '',
          actionStore: '',
          actionDestroy: '',
        };
      },
    },
    actionDuplicateNextWeek: {
      type: String,
      required: true,
    },
    urls: {
      type: Object,
      required: true,
      default() {
        return {
          data: '',
          refs: '',
          schedules: '',
        };
      },
    },
    exportActive: {
      required: false,
      default: false,
    },
    exportUrl: {
      required: false,
    },
  },
  data() {
    const vm = this;

    return {
      modalScheduleLesson: false,
      selectedTime: null,
      startTime: '',
      endTime: '',
      processSend: false,
      current_lesson: {},
      training_schedule: vm.defaultTrainingSchedule,
      training_schedules: [],
      semester: 1,
      semesters: [],
      type: null,
      week: 1,
      weeks: [],
      weeksForDuplicate: [],
      weekForDuplicate: 0,
      days: [],
      disciplinesLoading: false,
      disableVirtualScrolling: false,
      isDisabledResult: false,
      currentDisciplinesPage: 1,
      disciplinesLastPage: 1,
      currentErrModalUrl: null,
      currentErrModalText: null,
      refs: {
        disciplineGroups: [],
        disciplines: [],
        teachers: [],
        times: [],
        types: [],
        contingent: [],
        subgroups: [],
      },
      forms: {
        lesson: {
          errors: {},
          action: '',
          actionEdit: vm.lessonConfig.actionEdit,
          actionStore: vm.lessonConfig.actionStore,
          actionDestroy: vm.lessonConfig.actionDestroy,
          method: '',
          fields: {
            is_common_cabinet: false,
            day_id: null,
            time_id: null,
            type: null,
            discipline_id: null,
            default_discipline_option: null,
            teacher_id: null,
            teacher_ids: null,
            contingents_all: false,
            contingent: null,
            cabinet: null,
            studentsType: null,
            subgroup_id: null,
            calendar_thematic_plan_theme_id: null,
            is_result: 0,
          },
          themes: [],
          afterCallback() {
            vm.loadData();
            vm.modalScheduleLesson = false;
            vm.clearForm(this);
          },
        },
      },
      isDuplicateModalOpen: false,
      isWeeksModalOpen: false,
      hasCtp: false,
      loadingModal: false,
      disciplineSelectInputValue: '',
    };
  },
  computed: {
    studentTypesOptions() {
      return [
        {
          value: 'contingents_all',
          label: this.trans('label.select_all_students'),
        },
        {
          value: 'subgroups',
          label: this.trans('training_schedule.fields.subgroup_select'),
        },
      ];
    },
    isResultOptions() {
      return [
        {
          value: 0,
          label: this.trans('training_schedule.fields.discipline_id'),
        },
        {
          value: 1,
          label: this.trans('training_schedule.fields.result'),
        },
      ];
    },
  },
  async mounted() {
    await this.$trans.add(['main', 'training_schedule', 'notice', 'button', 'placeholder', 'label', 'journal', 'calendar-thematic-plan', 'select']);
    const vm = this;
    await vm.loadSchedules();
    await vm.loadRefs();
    await vm.loadData();
    await this.requestGetDisciplines();
  },

  watch: {
    async training_schedule(new_val, old_val) {
      const vm = this;
      await vm.loadRefs();
      await vm.loadData();
    },

    'forms.lesson.fields.studentsType': function () {
      if (this.forms.lesson.fields.studentsType === 'contingents_all') {
        this.forms.lesson.fields.subgroup_id = null;
      }
    },

    'forms.lesson.fields.contingents_all': function (newValue) {
      if (newValue === true) {
        this.forms.lesson.fields.contingent = this.refs.contingent;
      } else if (newValue === false) {
        this.forms.lesson.fields.contingent = [];
      }
    },

  },
  methods: {
    async updateSelects() {
      this.forms.lesson.fields.discipline_id = null;
      await this.requestGetDisciplines();
    },
    async requestGetDisciplines(loadingOff = true) {
      this.disciplinesLoading = true;
      const res = await requestWrapper.call(this, {
        url: this.forms.lesson.fields.is_result ? this.uriGetResults : this.uriGetDisciplines,
        params: {
          page: this.currentDisciplinesPage,
          search: this.disciplineSelectInputValue.toLowerCase(),
        },
      });
      if (!res.error) {
        this.refs.disciplines = [...this.refs.disciplines, ...res.items];
        this.disciplinesLastPage = res.lastPage;
      }
      if (loadingOff) {
        this.disciplinesLoading = false;
      }
    },
    async requestGetThemes(setDefault = true) {
      if (
        !this.forms.lesson.fields.teacher_id
        || !this.forms.lesson.fields.discipline_id
        || !this.forms.lesson.fields.day_id
        || !this.hasCtp
      ) {
        return;
      }

      const res = await requestWrapper.call(this, {
        url: Helper.urlGenerate(this.uriGetThemes, {
          training_schedule: this.training_schedule,
        }),
        params: {
          disciplineId: this.forms.lesson.fields.discipline_id,
          teacherId: this.forms.lesson.fields.teacher_id,
          dayId: this.forms.lesson.fields.day_id,
        },
      });

      if (!res.error) {
        this.forms.lesson.themes = res;
        const defaultItem = this.forms.lesson.themes.find(item => item.selected);
        if (defaultItem && setDefault) {
          this.forms.lesson.fields.calendar_thematic_plan_theme_id = defaultItem.id;
        }
      }
    },
    // Обработка виртуал-скроллинга для селекта предметов
    async onSubScroll({ index }) {
      if (!this.disciplinesLoading
        && this.currentDisciplinesPage < this.disciplinesLastPage
        && index >= (this.refs.disciplines.length - 21)
      ) {
        this.currentDisciplinesPage += 1;
        await this.requestGetDisciplines(false);
        this.$nextTick(() => {
          this.$refs.subjectSelect.$refs['q-select-wrapper-ref'].refresh();
          this.disciplinesLoading = false;
        });
      }
    },
    async requestGetDisciplinesByFilter() {
      return new Promise(async (res, rej) => {
        const result = await requestWrapper.call(this, {
          url: this.forms.lesson.fields.is_result ? this.uriGetResults : this.uriGetDisciplines,
          params: {
            page: 1,
            search: this.disciplineSelectInputValue.toLowerCase(),
          },
        });

        if (!result.error) {
          res(result);
        } else {
          rej(result);
        }
      });
    },
    async requestSearchDisciplines(val, update) {
      this.disciplinesLoading = true;
      this.currentDisciplinesPage = 1;
      this.requestGetDisciplinesByFilter().then((res) => {
        update(() => {
          this.refs.disciplines = res.items;
          this.disciplinesLoading = false;
        });
      });
    },
    onModalClose() {
      this.forms.lesson.errors = [];
      this.modalScheduleLesson = false;
      this.isDisabledResult = false;
      this.disciplineSelectInputValue = '';
    },
    clearForm(form) {
      const vm = this;
      const { fields } = form;
      _.forEach(fields, (value, key) => {
        switch (key) {
          case 'contingents_all':
            fields[key] = false;
            break;
          case 'is_result':
            fields[key] = 0;
            break;
          case 'is_common_cabinet':
            fields[key] = false;
            break;
          default:
            fields[key] = null;
            break;
        }
      });
      vm.$set(form, 'fields', fields);
      vm.$set(form, 'action', '');
    },
    createLesson(dayId, timeId) {
      const vm = this;
      vm.clearForm(vm.forms.lesson);
      vm.$set(vm.forms.lesson, 'action', vm.forms.lesson.actionStore);
      vm.$set(vm.forms.lesson.fields, 'day_id', dayId);
      vm.$set(vm.forms.lesson.fields, 'time_id', timeId);
      vm.$set(vm.forms.lesson, 'method', 'POST');
      vm.$set(vm.forms.lesson, 'themes', []);
      if (vm.refs.teachers.length === 1) {
        vm.$set(vm.forms.lesson.fields, 'teacher_id', vm.refs.teachers[0].id);
      }
      this.modalScheduleLesson = true;
      this.$nextTick(() => {
        vm.modalScheduleLesson = true;
      });
    },
    editLesson(lesson) {
      const vm = this;
      vm.isDisabledResult = true;
      vm.clearForm(vm.forms.lesson);
      vm.$set(vm.forms.lesson, 'method', 'PATCH');
      vm.$set(vm.forms.lesson, 'action', Helper.urlGenerate(
        vm.forms.lesson.actionEdit,
        { lesson: lesson.id },
      ));
      vm.$set(vm.forms.lesson.fields, 'type', lesson.type.id);
      vm.$set(vm.forms.lesson.fields, 'discipline_id', lesson.discipline.id);
      vm.$set(vm.forms.lesson.fields, 'is_result', lesson.is_result);
      vm.$set(vm.forms.lesson.fields, 'default_discipline_option', lesson.discipline);
      vm.$set(vm.forms.lesson.fields, 'teacher_id', lesson.teacher.id);
      vm.$set(vm.forms.lesson.fields, 'subgroup_id', lesson.subgroup.id);
      vm.$set(vm.forms.lesson.fields, 'studentsType', lesson.subgroup.id ? 'subgroups' : 'contingents_all');

      const teachers = [];
      if (_.isArray(lesson.other_teachers)) {
        _.forEach(lesson.other_teachers, (otherTeacher) => {
          teachers.push(otherTeacher.teacher);
        });
      }
      vm.$set(vm.forms.lesson.fields, 'teacher_ids', teachers);

      if (vm.type === 'personal') {
        const existingContingent = [];
        if (_.isArray(lesson.contingent)) {
          _.forEach(lesson.contingent, (contingent) => {
            existingContingent.push(contingent.user);
          });
        }
        vm.$set(vm.forms.lesson.fields, 'contingent', existingContingent);
      }
      vm.$set(vm.forms.lesson.fields, 'time_id', lesson.org_education_time_id);
      vm.$set(vm.forms.lesson.fields, 'day_id', lesson.training_schedule_day_id);

      if (lesson.cabinet !== undefined) {
        vm.$set(vm.forms.lesson.fields, 'cabinet', lesson.cabinet.name);
      }

      if (lesson.theme?.id) {
        vm.$set(
          vm.forms.lesson.fields,
          'calendar_thematic_plan_theme_id',
          lesson.theme.id,
        );
      }
      this.requestGetThemes(false);

      this.modalScheduleLesson = true;
      this.$nextTick(() => {
        vm.modalScheduleLesson = true;
      });
    },
    showDestroyLesson(lesson) {
      const vm = this;
      vm.$set(vm, 'current_lesson', lesson);
      $('#modal-schedule-lesson-confirm-destroy').modal('show');
    },
    showEditTime(id) {
      const vm = this;
      vm.selectedTime = id;
      $('#modal-schedule-lesson-edit-time').modal('show');
    },
    duplicateNextWeek(force = false) {
      const vm = this;
      this.$globalLoading.show();

      const config = {
        responseType: 'json',
        method: 'POST',
        headers: {},
        params: {
          force: Number(force),
        },
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;
      const action = Helper.urlGenerate(vm.actionDuplicateNextWeek, {
        training_schedule: vm.training_schedule,
        semester: vm.semester,
        week: vm.week,
        weekForDuplicate: vm.weekForDuplicate,
      });
      vm.$http.post(action, [], config).then(
        function (response) {
          this.$globalLoading.hide();
          this.isWeeksModalOpen = false;
          const { data } = response;
          if (data.status === 'success') {
            show_notice(data.message, 'notice');
            vm.week = vm.weekForDuplicate;
            vm.loadData();
            this.onErrNoThemesModalClose();
          } else if (data.status === 'err_no_themes' && data.response_url) {
            this.currentErrModalUrl = data.response_url;
            this.currentErrModalText = data.message;
            this.isDuplicateModalOpen = true;
          }
        },
        function (response) {
          this.$globalLoading.hide();
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            $.each(response.data.errors, (i, val) => {
              errors = errors.concat(val);
            });
            show_notice(errors, 'error');
          } else {
            console.warn(response);
          }
        },
      );
    },
    saveTime() {
      const vm = this;
      this.$globalLoading.show();

      const config = {
        responseType: 'json',
        method: 'patch',
        headers: {},
        lessonId: vm.selectedTime,
        start_time: vm.startTime,
        end_time: vm.endTime,
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      vm.$http.patch(Helper.urlGenerate(vm.urls.actionSaveTime, { training_schedule: vm.training_schedule }), config)
        .then(
          function (response) {
            this.$globalLoading.hide();
            vm.loadData();
            vm.loadRefs();
            vm.selectedTime = null;
            vm.startTime = null;
            vm.endTime = null;
            $('#modal-schedule-lesson-edit-time').modal('hide');
          },
          function (response) {
            this.$globalLoading.hide();
            if (response.status === 422 || response.status === 423) {
              let errors = [];
              $.each(response.data.errors, (i, val) => {
                errors = errors.concat(val);
              });
              show_notice(errors, 'error');
            } else {
              console.warn(response);
            }
          },
        );
    },
    destroyLesson() {
      const vm = this;
      this.$globalLoading.show();

      const config = {
        responseType: 'json',
        method: 'DELETE',
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      vm.$http.delete(Helper.urlGenerate(vm.forms.lesson.actionDestroy, { lesson: vm.current_lesson.id }), [], config).then(
        function (response) {
          this.$globalLoading.hide();
          vm.loadData();
          $('#modal-schedule-lesson-confirm-destroy').modal('hide');
        },
        function (response) {
          this.$globalLoading.hide();
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            $.each(response.data.errors, (i, val) => {
              errors = errors.concat(val);
            });
            show_notice(errors, 'error');
          } else {
            console.warn(response);
          }
        },
      );
    },
    async loadSchedules() {
      const vm = this;
      this.$globalLoading.show();
      const config = {
        responseType: 'json',
        method: 'GET',
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      await vm.$http.get(vm.urls.schedules, config).then(
        function (response) {
          this.$globalLoading.hide();
          const { data } = response;
          vm.$set(vm, 'training_schedules', data.data);
        },
        function (response) {
          this.$globalLoading.hide();
          console.warn(response);
        },
      );
    },
    async loadData(week) {
      const vm = this;
      if (week) {
        vm.$set(vm, 'week', week);
      }
      this.$globalLoading.show();
      const config = {
        responseType: 'json',
        params: {
          semester: vm.semester,
          week: vm.week,
        },
        method: 'GET',
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      await vm.$http.get(Helper.urlGenerate(vm.urls.data, { training_schedule: vm.training_schedule }), config)
        .then(
          function (response) {
            this.$globalLoading.hide();
            const { data } = response;
            vm.$set(vm, 'days', data.days);
            vm.$set(vm, 'weeks', data.weeks);
            vm.$set(vm, 'weeksForDuplicate', data.weeksForDuplicate);
            vm.$set(vm, 'semesters', data.semesters);
            vm.$set(vm, 'accessEdit', data.accessEdit);
            vm.$set(vm, 'type', data.type);
            vm.$set(vm, 'hasCtp', data.hasCtp);
          },
          function (response) {
            this.$globalLoading.hide();
            console.warn(response);
          },
        );
    },

    async loadRefs() {
      const vm = this;
      this.$globalLoading.show();
      const config = {
        responseType: 'json',
        method: 'GET',
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      await vm.$http.get(Helper.urlGenerate(vm.urls.refs, { training_schedule: vm.training_schedule }), config).then(
        function (response) {
          this.$globalLoading.hide();
          const { data } = response;
          vm.$set(vm.refs, 'teachers', data.teachers);
          vm.$set(vm.refs, 'subgroups', data.subgroups);
          vm.$set(vm.refs, 'times', data.times);
          vm.$set(vm.refs, 'types', data.types);
          vm.$set(vm.refs, 'contingent', data.contingent);
          vm.$set(vm, 'semester', data.currentSemester);
          vm.$set(vm, 'week', data.currentWeek);
        },
        function (response) {
          this.$globalLoading.hide();
          console.warn(response);
        },
      );
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      this.$globalLoading.show();
      vm.$set(vm, 'processSend', true);

      _.forEach(form.fields, (value, key) => {
        if (_.isArray(value)) {
          const values = [];
          _.forEach(value, (selectValue) => {
            values.push(selectValue.id);
          });
          _.set(data, key, values);
        } else {
          _.set(data, key, value);
        }
      });
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          this.$globalLoading.hide();
          vm.$set(vm, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          vm.errors = [];
        },
        (response) => {
          this.$globalLoading.hide();
          vm.$set(vm, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            form.errors = formErrors;
            vm.validation.lesson = response.data.errors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return this.forms[form].errors[field] !== undefined;
    },
    getError(form, field) {
      return _.first(this.forms[form].errors[field]);
    },
    exportDownload() {
      const vm = this;

      this.$globalLoading.show();
      const config = {
        responseType: 'blob',
        params: {
          semester: vm.semester,
          week: vm.week,
        },
        method: 'GET',
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };

      vm.$http.get(Helper.urlGenerate(vm.urls.export, { training_schedule: vm.training_schedule }), config)
        .then(
          (response) => {
            this.$globalLoading.hide();
            const orig_filename = (response.headers.get('Content-Disposition') || '').split('filename=')[1];
            let filename = '';
            try {
              filename = decodeURIComponent(escape(orig_filename));
            } catch (e) {
              filename = orig_filename;
            }
            const result = document.createElement('a');
            result.href = window.URL.createObjectURL(response.body);
            result.download = filename;
            result.click();
          },
          (response) => {
            this.$globalLoading.hide();
            console.warn(response);
          },
        );
    },
    onErrNoThemesModalClose() {
      this.currentErrModalUrl = null;
      this.currentErrModalText = null;
      this.isDuplicateModalOpen = false;
    },
  },
};
