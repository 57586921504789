import {
  QBtn,
  QIcon,
  QInput,
  QTable,
  QTd,
  QTh,
  QTooltip,
  QTr,
} from '@quasar/components';
import VNoResults from '@vjs/components/VNoResults';
import VFormInform from '@vjs/components/VFormInform';

export default {
  name: 'VEducationResultsTable',
  components: {
    QTable,
    QTh,
    QTr,
    QTd,
    QBtn,
    QTooltip,
    QInput,
    QIcon,
    VNoResults,
    VFormInform,
  },
  props: {
    uriGetData: {
      type: String,
      required: true,
    },
    uriAddEduResultsForm: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      refs: {
        eduResults: [],
      },
      rows: [],
      currentPage: 1,
      lastPage: 1,
      nameFilter: null,
    };
  },
  async mounted() {
    await this.$trans.add(['placeholder', 'label', 'table', 'warning_text']);
    await requestGetDataHelper(this, {
      url: this.uriGetData,
      fullRes: true,
      hardSet: true,
      variableName: 'rows',
    });
  },
  computed: {
    columns() {
      return [
        {
          label: this.$trans.get('table.id'),
          field: 'educationResultId',
          align: 'center',
          style: 'width: 75px;',
        },
        {
          label: this.$trans.get('table.ru_name'),
          field: 'ruName',
          align: 'left',
          style: 'width: 390px;',
        },
        {
          label: this.$trans.get('table.kz_name'),
          field: 'kzName',
          align: 'left',
          style: 'width: 390px;',
        },
        {
          label: this.$trans.get('table.operations'),
          field: 'actions',
          align: 'center',
          style: 'width: 14rem',
        },
      ];
    },
  },
};
