import { StoreTranslator } from '@common/mixins';
import { Helper } from '@common/src/helpers';

export default {
  mixins: [StoreTranslator],
  props: {
    urlStatementFiles: {
      type: String,
      required: true,
    },
    urlStatementFileUpdate: {
      type: String,
      required: true,
    },
    urlUserFiles: {
      type: String,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    type: {
      type: Object,
      default: null,
    },
    goal: {
      goal: String,
      default: null,
    },
    isStudentStatement: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const vm = this;
    return {
      processSend: false,
      currentEditFile: {},
      openModalFiles: false,
      files: [],
    };
  },
  mounted() {
    const vm = this;
    vm.loadStatementFiles();
  },

  destroyed() {
  },
  methods: {
    loadStatementFiles() {
      const vm = this;
      const config = {
        responseType: 'json',
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      vm.$http.get(vm.urlStatementFiles, config).then(
        (response) => {
          vm.$set(vm, 'processSend', false);
          vm.$nextTick(() => {
            vm.$set(vm, 'files', response.data);
          });
        },
        (response) => {
          vm.$set(vm, 'processSend', false);

          if (response.status === 422 || response.status === 423) {
            let errors = [];
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
            });
            show_notice(errors, 'error');
          } else {
            console.log(response);
          }
        },
      );
    },
    openSelectFile(file) {
      const vm = this;
      vm.currentEditFile = file;
      vm.$set(vm, 'openModalFiles', true);
    },
    onSelectClose() {
      const vm = this;
      vm.$set(vm, 'openModalFiles', false);
    },
    selectFile(file) {
      const vm = this;
      vm.updateFile(vm.currentEditFile, {
        user_file_id: file.id,
      });
      // TODO изменять файл в массиве и отправлять запрос на сервер об изменении файла
      vm.$set(vm, 'openModalFiles', false);
    },
    updateFile(currentEditFile, data) {
      const vm = this;

      const url = Helper.urlGenerate(vm.urlStatementFileUpdate, { id: currentEditFile.id });
      const config = {
        responseType: 'json',
        headers: {
          'Accept-Language': window.core_project.locale,
          'X-CSRF-TOKEN': window.core_project.csrfToken,
        },
      };
      vm.$http.patch(url, data, config).then(
        (response) => {
          vm.$set(vm, 'processSend', false);
          vm.$set(vm, 'currentEditFile', false);
          vm.loadStatementFiles();
        },
        (response) => {
          vm.$set(vm, 'processSend', false);

          if (response.status === 422 || response.status === 423) {
            let errors = [];
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
            });
            show_notice(errors, 'error');
          } else {
            console.log(response);
          }
        },
      );
    },
  },
};
