import { StoreTranslator } from '@common/mixins';
import _ from 'lodash';


export default {
  name: 'VGovServiceSign',
  mixins: [StoreTranslator],
  props: {
    type: {
      type: Number,
      required: true,
    },
    docType: {
      type: [Number, String],
      default: null,
      required: false,
    },
    reason: {
      type: Number,
      required: true,
    },
    urlUserFiles: {
      type: String,
      required: true,
    },
    confirmUrl: {
      type: String,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
    organization: {
      type: String,
      required: true,
    },
    school: {
      type: String,
      required: true,
    },
    urlGetProfessions: {
      type: String,
      required: true,
    },
    canAddToGroup: {
      type: Boolean,
      required: false,
      default: false,
    },
    fileLabelTransKey: {
      type: String,
      default: 'files.statement_service_academ.copy_head_order',
    },
    hasDormitory: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const vm = this;
    const fields = {
      type: {
        value: vm.type,
        name: 'type',
      },
    };
    const file = {
      labelTransKey: vm.fileLabelTransKey,
      required: false,
      user_file_id: null,
      user_file_name: null,
      v_if: false,
    };

    if ([2, 4].includes(vm.type)) {
      fields.file = {
        value: null,
        name: 'file',
      };
    }

    if (vm.type === 3) {
      if (!vm.hasDormitory) {
        fields.dormitory_number = {
          value: '',
          name: 'dormitory_number',
          component: 'text-field',
          labelTransKey: 'label.dormitory_number',
          extraAttributes: {
            disabled: false,
          },
        };
        fields.dormitory_address = {
          value: '',
          name: 'dormitory_address',
          component: 'text-field',
          labelTransKey: 'label.dormitory_address',
          extraAttributes: {
            disabled: false,
          },
        };
        fields.appointment_date = {
          value: '',
          name: 'appointment_date',
          component: 'date-field',
          labelTransKey: 'label.appointment_date',
          extraAttributes: {
            disabled: false,
          },
        };
        fields.appointment_time = {
          value: '',
          name: 'appointment_time',
          component: 'text-field',
          labelTransKey: 'label.appointment_time',
          extraAttributes: {
            disabled: false,
            type: 'time',
          },
        };
      }
    } else if (vm.type === 5) {
      fields.confirmation_number = {
        value: '',
        name: 'confirmation_number',
        component: 'text-field',
        labelTransKey: 'label.order_number',
        required: true,
        extraAttributes: {
          disabled: false,
        },
      };
      fields.confirmation_date = {
        value: '',
        name: 'confirmation_date',
        component: 'date-field',
        labelTransKey: 'label.order_date',
        required: true,
        extraAttributes: {
          disabled: false,
        },
      };
      if (vm.reason === 6) {
        fields.course = {
          value: '',
          name: 'course',
          component: 'text-field',
          labelTransKey: 'label.course',
          required: true,
          extraAttributes: {
            disabled: false,
            min: 1,
            max: 4,
            type: 'number',
          },
        };
        fields.edu_form = {
          value: '',
          name: 'edu_form',
          component: 'v-select-field',
          labelTransKey: 'statement.edu_form',
          values: [],
          required: true,
          loadValues: {
            type: 'edu-forms',
          },
        };
        fields.profession = {
          value: '',
          name: 'profession',
          component: 'v-select-field',
          labelTransKey: 'label.profession',
          values: [],
          required: true,
        };
      }
    } else if (vm.type === 6) {
      fields.confirmation_number = {
        value: '',
        name: 'confirmation_number',
        component: 'text-field',
        labelTransKey: 'label.order_number',
        extraAttributes: {
          disabled: false,
        },
      };
      fields.confirmation_date = {
        value: '',
        name: 'confirmation_date',
        component: 'text-field',
        labelTransKey: 'label.order_date',
        extraAttributes: {
          disabled: false,
        },
      };
    } else if (vm.type === 4) {
      fields.confirmation_number = {
        value: '',
        name: 'address',
        required: true,
        component: 'text-field',
        labelTransKey: 'label.doc_duplic_address',
        extraAttributes: {
          disabled: false,
        },
      };
    } else if (vm.type === 2) {
      fields.edu_period = {
        labelTransKey: 'label.edu_period',
        withLabelMargin: true,
        name: 'edu_period',
        component: 'q-between-field',
        required: true,
        configComponent: {
          component: 'date-field',
          fieldFrom: {
            value: null,
            name: 'edu_period_from',
          },
          fieldTo: {
            value: null,
            name: 'edu_period_to',
          },
        },
      };
      fields.registration_number = {
        value: null,
        name: 'registration_number',
        component: 'q-text-field',
        labelTransKey: 'label.registration_number',
        required: true,
      };
    } else {
      fields.start_year = {
        value: '',
        name: 'start_year',
        component: 'text-field',
        labelTransKey: 'label.start_year',
        required: true,
        extraAttributes: {
          disabled: false,
          min: vm.year,
          max: vm.year + 5,
          type: 'number',
        },
      };
      fields.end_year = {
        value: '',
        name: 'end_year',
        component: 'text-field',
        labelTransKey: 'label.end_year',
        required: true,
        extraAttributes: {
          disabled: false,
          min: vm.year,
          max: vm.year + 5,
          type: 'number',
        },
      };
      if (vm.type === 1) {
        fields.copy_head_order = {
          value: null,
          name: 'copy_head_order',
        };
        if (vm.docType === 1 && vm.canAddToGroup) {
          fields.groups = {
            value: '',
            name: 'group',
            component: 'v-select-field',
            labelTransKey: 'label.group',
            values: [],
            required: false,
            loadValues: {
              type: 'groups',
              headers: {
                'X-Permission': 'group-show',
              },
            },
          };
        }
      }
    }
    if (vm.profile !== undefined) {
      _.forEach(vm.profile, (value, key) => {
        if (fields.hasOwnProperty(key)) {
          fields[key].value = value;
        }
      });
    }
    return {
      openModalFiles: false,
      file,
      currentSelectFile: {},
      form: {
        processSend: false,
        action: vm.confirmUrl,
        method: 'post',
        actionReject: vm.rejectUrl,
        actionRevise: vm.reviseUrl,
        errors: [],
        fields,

        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
        },
      },
    };
  },
  beforeCreate() {
    this.$trans.add(['statement', 'warning_text', 'placeholder', 'label', 'button', 'main', 'notice', 'files']);
  },
  mounted() {
    if (this.form.fields.profession) {
      this.loadRefs();
    }
  },
  methods: {
    selectFile(file) {
      const vm = this;
      vm.currentSelectFile.user_file_id = file.id;
      vm.currentSelectFile.user_file_name = file.name;
      let fieldName = 'copy_head_order';
      if ([2, 4].includes(vm.type)) {
        fieldName = 'file';
      }
      vm.form.fields[fieldName].value = file.id;
      vm.$set(vm, 'openModalFiles', false);
    },
    onSelectClose() {
      const vm = this;
      vm.$set(vm, 'openModalFiles', false);
    },
    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    async handleAjaxFormSubmit(action, method) {
      const vm = this;
      const data = {};
      _.forEach(vm.form.fields, (el) => {
        if (Object.prototype.hasOwnProperty.call(el, 'fill')) {
          el.fill(data);
        } else {
          _.set(data, vm.nameToDot(el.name), el.value);
        }
      });

      if (vm.form.processSend === true) {
        return false;
      }
      vm.$set(vm.form, 'processSend', true);
      vm.form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      await request.then(
        (response) => {
          vm.$set(vm.form, 'processSend', false);
          if (vm.form.afterCallback !== undefined) {
            vm.form.afterCallback(vm, response);
          }
          vm.form.errors = [];
        },
        (response) => {
          vm.$set(vm.form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            vm.form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
    openSelectFile(file) {
      const vm = this;
      vm.currentSelectFile = file;
      vm.$set(vm, 'openModalFiles', true);
    },
    loadRefs() {
      const vm = this;
      const config = {
        responseType: 'json',
        method: 'GET',
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      vm.$http.get(vm.urlGetProfessions, config).then(
        (response) => {
          vm.form.fields.profession.values = response.body.data;
        },
        (response) => {
          console.log(response);
        },
      );
    },
  },
};
