import { QCheckbox } from '@quasar/components';
import { Helper } from '@common/src/helpers';

export default {
  components: { QCheckbox },
  props: {
    action: {
      type: String,
      default: '',
    },
    urlBack: {
      type: String,
      default: '',
    },
    urlRefs: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {
        type: null,
        place: null,
        group: null,
        hasCommonHead: false,
        practiceHead: null,
        date: {
          from: null,
          to: null,
        },
        hours: null,
        semesters: null,
      },
      options: {
        groups: [],
        types: [],
        places: [],
        contingents: [],
        practiceHeads: [],
      },
    };
  },
  computed: {
    filteredContingents() {
      return this.options.contingents.filter(it => this.form.group && it.group_id === this.form.group);
    },
    validate() {
      return this.form.type && this.form.place && this.form.group
        && this.form.date.from && this.form.date.to
        && (
          (this.form.hasCommonHead && this.form.practiceHead)
          || (!this.form.hasCommonHead && this.filteredContingents.filter(it => !it.practice_head).length === 0)
        );
    },
  },
  async mounted() {
    await this.$trans.add(['warning_text', 'label', 'practice']);
    await this.loadRefs();
  },
  methods: {
    updateValues() {
      if (this.form.hasCommonHead) {
        this.filteredContingents.map((item) => {
          Vue.delete(item, 'practice_head');
          return item;
        });
      } else {
        this.form.practiceHead = null;
      }
    },
    async loadRefs() {
      await requestGetDataHelper(this, {
        url: this.urlRefs, variableName: 'options',
      });
    },
    async handleSubmit() {
      this.$globalLoading.show();
      const data = this.form;
      data.contingents = this.filteredContingents;
      const res = await requestWrapper.call(this, {
        method: 'POST',
        url: this.action,
        data,
      });
      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
