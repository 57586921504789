import { StoreTranslator } from '@common/mixins';


export default {
  mixins: [StoreTranslator],
  props: {
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    refs: {
      type: [Array, Object],
      required: true,
    },
    urlBack: {
      type: String,
      required: false,
      default: null,
    },
    resource: {
      type: [Array, Object],
      required: false,
    },
    hides: {
      type: [Array, Object],
      required: false,
    },
    personnelId: {
      type: Number,
      required: false,
    },
  },
  data() {
    const vm = this;
    const fields = {
      employees_current_status: {
        value: '',
        name: 'employees_current_status',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.employees_current_status',
        values: _.get(vm.refs, 'personnelStatuses', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      education_kind: {
        value: '',
        name: 'education_kind',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.education_kind',
        values: _.get(vm.refs, 'educationKinds', []),
        required: false,
        extraAttributes: {
          disabled: false,
        },
      },
      is_studying_in_university_current: {
        value: '',
        name: 'is_studying_in_university_current',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.is_studying_in_university_current',
        values: _.get(vm.refs, 'yesOrNo', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      sign_of_education: {
        value: '',
        name: 'sign_of_education',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.sign_of_education',
        values: _.get(vm.refs, 'educationSign', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      edu_lang: {
        value: '',
        name: 'edu_lang',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.edu_lang',
        values: _.get(vm.refs, 'eduLangs', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      english_level: {
        value: '',
        name: 'english_level',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.english_level',
        values: _.get(vm.refs, 'englishLevels', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      device_exists: {
        value: '',
        name: 'device_exists',
        component: 'v-multi-select-field',
        labelTransKey: 'label.device_exists',
        values: _.get(vm.refs, 'deviceExists', []),
        required: true,
        events: {
          change: () => {
            if (this.form.fields.device_exists.value === '-1') {
              this.form.fields.ethernet_access.v_show = false;
              this.form.fields.ethernet_access.value = '';
            } else {
              this.form.fields.ethernet_access.v_show = true;
            }
          },
        },
      },
      inventory_number: {
        v_if(vm) {
          return ['006', '007', '008'].includes(vm.form.fields.device_exists.value);
        },
        value: '',
        name: 'inventory_number',
        component: 'text-field',
        labelTransKey: 'label.inventory_number',
        required: true,
      },
      acquisition_source: {
        v_if(vm) {
          return ['006', '007', '008'].includes(vm.form.fields.device_exists.value);
        },
        value: '',
        name: 'acquisition_source',
        component: 'v-multi-select-field',
        labelTransKey: 'label.acquisition_source',
        values: _.get(vm.refs, 'acquisitionSources', []),
        required: true,
      },
      acquisition_year: {
        v_if(vm) {
          return ['006', '007', '008'].includes(vm.form.fields.device_exists.value);
        },
        value: '',
        name: 'acquisition_year',
        component: 'v-multi-select-field',
        labelTransKey: 'label.acquisition_year',
        values: _.get(vm.refs, 'acquisitionYears', []),
        required: true,
      },
      ethernet_access: {
        v_if(vm) {
          return vm.form.fields.device_exists.value !== '-1';
        },
        value: '',
        name: 'ethernet_access',
        component: 'v-multi-select-field',
        labelTransKey: 'label.ethernet_access',
        values: _.get(vm.refs, 'yesOrNo', []),
        required: true,
      },
      management_position_category: {
        value: '',
        name: 'management_position_category',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.management_position_category',
        values: _.get(vm.refs, 'positionCategories', []),
        required: true,
      },
      work_experience_over_5_years_tipo: {
        value: '',
        name: 'work_experience_over_5_years_tipo',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.work_experience_over_5_years_tipo',
        values: _.get(vm.refs, 'educationSign', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      correspondence_in_main_subject: {
        value: '',
        name: 'correspondence_in_main_subject',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.correspondence_in_main_subject',
        values: _.get(vm.refs, 'subjectCorrespondences', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      weekly_load: {
        value: '',
        name: 'weekly_load',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.weekly_load',
        values: _.get(vm.refs, 'weeklyLoads', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      registered_for_qual_training: {
        value: '',
        name: 'registered_for_qual_training',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.registered_for_qual_training',
        values: _.get(vm.refs, 'yesOrNo', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      passed_dual_trainging_program: {
        value: '',
        name: 'passed_dual_trainging_program',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.passed_dual_trainging_program',
        values: _.get(vm.refs, 'yesOrNo', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      internship_patronage: {
        value: '',
        name: 'internship_patronage',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.internship_patronage',
        values: _.get(vm.refs, 'yesOrNo', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      teaching_child_special_needs: {
        value: '',
        name: 'teaching_child_special_needs',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.teaching_child_special_needs',
        values: _.get(vm.refs, 'educatesChildInNeed', []),
        required: true,
      },
      qual_training_place: {
        beforeTitle: 'fields.qualification_courses_passed',
        beforeTitleWithoutMarginTop: true,
        value: '',
        name: 'qual_training_place',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.qual_training_place',
        values: _.get(vm.refs, 'certificationLocations', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      qual_training_syllabus: {
        value: '',
        name: 'qual_training_syllabus',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.qual_training_syllabus',
        values: _.get(vm.refs, 'certificationPrograms', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      qual_training_subject: {
        value: '',
        name: 'qual_training_subject',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.qual_training_subject',
        values: _.get(vm.refs, 'certificationSubjects', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      qual_training_form: {
        value: '',
        name: 'qual_training_form',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.qual_training_form',
        values: _.get(vm.refs, 'certificationForms', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      qual_training_lang: {
        value: '',
        name: 'qual_training_lang',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.qual_training_lang',
        values: _.get(vm.refs, 'certificationLangs', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      qual_training_duration: {
        value: '',
        name: 'qual_training_duration',
        component: 'number-field',
        labelTransKey: 'fields.qual_training_duration',
        required: true,
        extraAttributes: {
          disabled: false,
        },
        config: {
          alias: 'decimal',
          digits: 2,
          rightAlign: false,
          groupSeparator: '.',
          repeat: 3,
          autoGroup: true,
        },
      },
      qual_training_date_start: {
        value: '',
        name: 'qual_training_date_start',
        component: 'date-field',
        labelTransKey: 'fields.qual_training_date_start',
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      qual_training_date_end: {
        value: '',
        name: 'qual_training_date_end',
        component: 'date-field',
        labelTransKey: 'fields.qual_training_date_end',
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      qual_training_num_certificate: {
        value: '',
        name: 'qual_training_num_certificate',
        component: 'text-field',
        labelTransKey: 'fields.qual_training_num_certificate',
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
    };

    if (vm.resource !== undefined) {
      if (vm.resource.values) {
        _.forEach(vm.resource.values, (value, key) => {
          if (fields.hasOwnProperty(key)) {
            fields[key].value = value;
          }
        });
      }
      if (vm.resource.disable) {
        _.forEach(vm.resource.disable, (attribute) => {
          if (fields.hasOwnProperty(attribute)) {
            _.set(fields, `${attribute}.extraAttributes.disabled`, true);
          }
        });
      }
    }
    if (vm.hides !== undefined) {
      _.forEach(vm.hides, (attribute) => {
        if (fields.hasOwnProperty(attribute)) {
          _.set(fields, `${attribute}.v_show`, false);
        }
      });
    }

    return {
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
          // TODO привести к одному варианту
          if (data.redirect) {
            window.location = data.redirect;
          }
        },
      },
    };
  },
  beforeCreate() {
    this.$trans.add(['fields', 'warning_text', 'placeholder', 'label', 'button', 'main', 'notice', 'cabinet', 'curriculum', 'table']);
  },
  methods: {
    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleVShow(field) {
      const vm = this;
      if (field.v_show !== undefined) {
        if (_.isFunction(field.v_show)) {
          return field.v_show(vm);
        }
        return field.v_show;
      }

      return true;
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(form.fields, (el, key) => {
        if (
          (!el.hasOwnProperty('v_show') || el.v_show !== false)
            && vm.handleVif(el) !== false
        ) {
          if ((typeof el.value === 'object') && el.value !== null) {
            const arrField = [];
            _.forEach(el.value, (arrEl) => {
              arrField.push(arrEl.id);
            });
            _.set(data, key, arrField);
          } else {
            _.set(data, vm.nameToDot(el.name), el.value);
          }
        }
      });
      _.set(data, 'personnelId', vm.personnelId);
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      vm.$globalLoading.show();

      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            if (errors.length > 5) {
              show_notice(vm.trans('notice.has_not_filled_fields'), 'error');
            } else {
              show_notice(errors, 'error');
            }
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
};
