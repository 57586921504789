<template>
  <div>
    <q-field
      borderless
      :label="trans('student_transcript.select_position_type')"
      required
    >
      <q-option-group
        v-model="type"
        :options="typeOptions"
      />
    </q-field>

    <v-wrapper-q-select
      v-model="subject"
      :options="options"
      option-value="subjectId"
      :extra-binds="{
        required: true,
        label: trans('student_transcript.select_subject'),
        placeholder: trans('placeholder.select_from_list'),
        hideBottomSpace: true,
      }"
    />

    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :label="trans('button.approve')"
        @click="approve"
      />
    </div>
  </div>
</template>

<script>
import { QOptionGroup } from '@quasar/components';

export default {
  components: { QOptionGroup },

  props: {
    sourceId: {
      type: Number,
      required: true,
    },
    sourceKey: {
      type: Number,
      required: true,
    },
    transcripts: {
      type: Array,
      required: true,
    },
    transcriptsOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      type: 'before',
      subject: null,
    };
  },
  computed: {
    options() {
      return this.transcriptsOptions.filter(itm => itm.subjectId !== this.sourceId);
    },
    typeOptions() {
      return [
        {
          value: 'before',
          label: this.trans('student_transcript.before'),
        },
        {
          value: 'after',
          label: this.trans('student_transcript.after'),
        },
      ];
    },
  },
  methods: {
    async approve() {
      let targetKey = this.transcripts.findIndex(itm => itm.subjectId === this.subject);
      targetKey = this.type === 'after' ? targetKey : targetKey - 1;
      const result = {
        sourceKey: this.sourceKey,
        targetKey,
      };
      this.$emit('onApprove', result);
      this.$emit('onClose');
    },
  },
};
</script>
