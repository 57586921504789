import { requestWrapper } from '@vjs/helpers';

export default {
  GET_INFO_TABLE: async ({ state }) => {
    const { vm } = state;
    const res = await requestWrapper.call(vm, {
      url: state.makeJournalUrl(state.apiConfig.infoTable, state.journalId),
    });

    if (!res.error) {
      state.infoTable = res;
    }
  },
  GET_REMARKS_TABLE: async ({ state }) => {
    const { vm } = state;
    const res = await requestWrapper.call(vm, {
      url: state.makeJournalUrl(state.apiConfig.remarks.list, state.journalId),
    });

    if (!res.error) {
      state.remarks = res.remarks;
      state.journalTeachers = res.teachers;
      state.cans.changeRemarks = res.canChange;
      state.cans.seeRemarks = res.canSee;
    }
  },
  GET_STUDENTS: async ({ state }) => {
    const { vm } = state;
    vm.$globalLoading.show();
    const res = await requestWrapper.call(vm, {
      url: state.apiConfig.students,
    });

    if (!res.error) {
      state.students = res.students;
      state.isPersonal = res.isPersonal;
      if (state.isPersonal) {
        state.journalId = res.students?.[0]?.journalId;
      }
    }
    vm.$globalLoading.hide();
  },
  GET_REFS: async ({ state }) => {
    const { vm } = state;
    const res = await requestWrapper.call(vm, {
      url: state.makeJournalUrl(state.apiConfig.refs, state.journalId),
    });

    if (!res.error) {
      state.refs = { ...state.refs, ...res };
    }
  },
  GET_CALC_RESULT_DATA: async ({ state }, selectedData = null) => {
    const { vm } = state;
    vm.$globalLoading.show();
    const res = await requestWrapper.call(vm, {
      url: state.makeJournalUrl(state.apiConfig.calcResult.data, state.journalId),
      params: selectedData,
    });

    if (!res.error) {
      state.refs = { ...state.refs, ...res.refs };
      state.cans = { ...state.cans, ...res.cans };
    }
    vm.$globalLoading.hide();
  },
  GET_GENERATE_MONTHS: async ({ state }, moduleDisciplineId = null) => {
    const { vm } = state;
    vm.$globalLoading.show();

    if (state.isModule) {
      state.generateMonths = [];
    }

    const res = await requestWrapper.call(vm, {
      url: state.makeJournalUrl(state.apiConfig.generateWeek.filters, state.journalId),
      params: { moduleDisciplineId },
    });

    if (!res.error) {
      state.refs.generateMonths = res.months;
      if (state.isModule && !res.months.length) {
        vm.$notify({
          type: 'warn',
          text: vm.trans('notice.months_by_selected_subject_not_found'),
        });
      }
    }
    vm.$globalLoading.hide();
  },
  GET_JOURNAL: async ({ state }, params = {}) => {
    const { vm } = state;
    vm.$globalLoading.show();
    const res = await requestWrapper.call(vm, {
      url: state.makeJournalUrl(state.apiConfig.mainTable, state.journalId),
      params,
    });

    if (!res.error) {
      state.hasCtp = res.hasCtp;
      state.hasCwp = res.hasCwp;
      state.isModule = res.isModule;
      state.status = res.status;
      state.withResult = res.withResult;
      state.isStudent = res.isStudent;
      state.typeOfEvaluation = res.typeOfEvaluation;
      state.currentSemester = res.currentSemester;

      state.journalDays = res.days;
      state.totalHours = res.totalHours;
      state.refs.disciplines = res.availableDisciplines;
      state.refs.semesters = res.availableSemesters;
      state.refs.journals = res.journals;
      state.cans.calcResults = res.canCalcResults;
      state.cans.edit = res.canEdit;
      state.cans.addDay = res.canAddDay;
      state.cans.export = res.canExport;
      state.cans.generateDays = res.canGenerateDays;
      state.cans.restore = res.canRestore;
      state.cans.strictPointEdit = res.strictPointEdit;
      state.cans.canChangeStatus = res.canChangeStatus;
      if (localStorage) {
        const storageItems = JSON.parse(localStorage.getItem('hideRetired'));
        if (storageItems) {
          state.showRetiredStudents = storageItems === 1;
        }
      } else {
        localStorage.setItem('hideRetired', state.showRetiredStudents ? 1 : 2);
      }
    }
    vm.$globalLoading.hide();
  },
};
