import { QField } from '@quasar/components';

export default {
  name: 'VSelectFromListOption',

  components: { QField },

  props: {
    value: String,
    keyId: [String, Number],
    readonly: Boolean,
  },

  methods: {
    handleRemove() {
      this.$emit('remove', this.keyId);
    },
  },
};
