<template>
  <div
    id="modal-multi-export"
    class="s-modal s-modal--small fade"
    tabindex="-1"
    data-backdrop="static"
  >
    <div class="s-modal__container">
      <div class="s-modal__head">
        {{ trans('notice.select_export_type') }}
        <div
          class="s-modal__close"
          data-toggle="modal"
          data-target="#modal-multi-export"
        />
      </div>
      <div class="s-modal__body">
        <div class="row-sort-between-btn">
          <button
            v-for="(btn,i) in items"
            :key="i"
            type="button"
            class="s-btn s-btn--w-200 s-w-100 s-btn--thm-green"
            @click="exportTable(btn.url)"
          >
            {{ btn.label }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { StoreTranslator } from '@common/mixins';


export default {
  name: 'MultiExportModal',
  mixins: [StoreTranslator],
  props: {
    items: {
      type: Array,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
  },
  methods: {
    exportTable(url) {
      const vm = this;

      if (!url) {
        return;
      }

      vm.$globalLoading.show();
      const config = {
        responseType: 'json',
        method: 'GET',
        params: vm.params,
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };

      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      const request = vm.$http.get(url, config);

      request.then(
        (response) => {
          vm.$emit('success');
          vm.$globalLoading.hide();
        },
        (response) => {
          vm.$globalLoading.hide();
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
            });
            show_notice(errors, 'error');
            vm.$set(form, 'errors', response.data.errors);
          } else {
            console.log(response);
          }
        },
      );
    },
  },
};
</script>
