import { StoreTranslator } from '@common/mixins';


export default {
  mixins: [StoreTranslator],
  props: {
    urlBack: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    group: {
      type: Object,
      required: true,

    },
    statements: {
      type: Array,
      required: true,
    },
    educators: {
      type: Array,
      required: true,
    },
  },
  data() {
    const vm = this;
    const fields = {
      name: {
        value: vm.group.name,
        name: 'name',
        component: 'text-field',
        labelTransKey: 'label.name',
        required: true,
      },
      curator_id: {
        value: vm.group.curator_id,
        name: 'curator_id',
        component: 'select-field',
        labelTransKey: 'label.group_head',
        values: vm.educators,
        required: false,
      },
      edu_form: {
        value: vm.group.edu_form,
        name: 'edu_form',
        component: 'select-field',
        labelTransKey: 'main.edu_form',
        values: [],
        required: true,
        loadValues: {
          type: 'edu-forms',
        },
      },
      period_of_study: {
        value: vm.group.period_of_study,
        name: 'period_of_study',
        component: 'select-field',
        labelTransKey: 'curriculum.education_duration',
        values: [],
        required: true,
        loadValues: {
          type: 'period-of-studies',
        },
      },
      statements: {
        name: 'statements',
        value: vm.statements,
      },
    };

    return {
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
          // TODO привести к одному варианту
          if (data.redirect) {
            window.location = data.redirect;
          }
        },
      },
    };
  },
  mounted() {
    const vm = this;
  },

  destroyed() {
  },
  methods: {
    removeStatements(id) {
      const vm = this;

      const index = _.findIndex(vm.form.fields.statements.value, item => item.id === id);

      if (index !== -1) {
        vm.$delete(vm.form.fields.statements.value, index);
      }
    },
    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(form.fields, (el, key) => {
        _.set(data, vm.nameToDot(el.name), el.value);
      });
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
};
