import { requestWrapper } from '@vjs/helpers';
import { QCheckbox } from '@quasar/components';
import VSelectFromListOption from '../VSelectFromListOption';

export default {
  name: 'VOrganizationDisciplinesAddSubjectsForm',
  components: { VSelectFromListOption, QCheckbox },
  props: {
    uriBack: {
      type: String,
      required: true,
    },
    uriAction: {
      type: String,
      required: true,
    },
    uriGetDisciplines: {
      type: String,
      required: true,
    },
    usingAllDisciplines: Boolean,
  },
  data() {
    return {
      selectedList: [],
      selectedOption: '',
      disciplines: [],
      inputValue: '',
      disciplinesLoading: false,
      disableVirtualScrolling: false,
      currentDisciplinesPage: 1,
      disciplinesLastPage: 1,
      useAllDisciplines: this.usingAllDisciplines,
    };
  },
  async mounted() {
    await this.$trans.add(['organization_disciplines']);
    await this.requestGetDisciplines();
  },
  methods: {
    handleRemoveOption(id) {
      this.selectedList = this.selectedList.filter(item => item.id !== id);
    },
    handleSelect(val) {
      setTimeout(async () => {
        this.selectedOption = [];
        this.inputValue = '';
        this.selectedList.push(val[0]);
        if (this.options.length < 25) {
          await this.onSubScroll({ index: 99999 });
        }
      });
    },
    async handleSave() {
      this.$globalLoading.show();
      let data = {};
      if (this.useAllDisciplines) {
        data = { useAllDisciplines: this.useAllDisciplines };
      } else {
        data = {
          newDisciplines: _.map(this.selectedList, option => option.id),
        };
      }

      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriAction,
        data,
      });

      if (res.error && res.errorType !== 302) {
        if (res.data && res.data.errors && res.errorType === 422) {
          this.$notify({
            text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
            type: 'error',
          });
        }

        this.$globalLoading.hide();
      }
    },

    async requestSearchDisciplines(val, update) {
      this.disciplinesLoading = true;
      this.currentDisciplinesPage = 1;
      await this.requestGetDisciplinesByFilter().then((res) => {
        update(() => {
          this.disciplines = res.items;
          this.disciplinesLoading = false;
        });
      });
    },
    async requestGetDisciplinesByFilter() {
      return new Promise(async (res, rej) => {
        const result = await requestWrapper.call(this, {
          url: this.uriGetDisciplines,
          params: {
            page: 1,
            search: this.inputValue.toLowerCase(),
          },
        });

        if (!result.error) {
          res(result);
        } else {
          rej(result);
        }
      });
    },
    // Обработка виртуал-скроллинга для селекта предметов
    async onSubScroll({ index }) {
      if (!this.disciplinesLoading
        && this.currentDisciplinesPage < this.disciplinesLastPage
        && index >= (this.disciplines.length - 21)
      ) {
        this.currentDisciplinesPage += 1;
        await this.requestGetDisciplines(false);
        this.$nextTick(() => {
          this.$refs.subjectSelect.$refs['q-select-wrapper-ref'].refresh();
          this.disciplinesLoading = false;
        });
      }
    },
    async requestGetDisciplines(loadingOff = true) {
      this.disciplinesLoading = true;
      const res = await requestWrapper.call(this, {
        url: this.uriGetDisciplines,
        params: {
          page: this.currentDisciplinesPage,
          search: this.inputValue.toLowerCase(),
        },
      });

      if (!res.error) {
        this.disciplines = [...this.disciplines, ...res.items];
        this.disciplinesLastPage = res.lastPage;
      }
      if (loadingOff) {
        this.disciplinesLoading = false;
      }
    },
  },
  computed: {
    options() {
      if (this.disciplines && !_.isEmpty(this.disciplines)) {
        return _.differenceWith(this.disciplines, this.selectedList, _.isEqual);
      }
      return [];
    },
  },
};
