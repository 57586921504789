import {
  create,
  useTheme,
  percent,
  color,
} from '@amcharts/amcharts4/core';
import {
  PieChart,
  PieSeries,
  XYChart,
  CategoryAxis,
  ValueAxis,
  ColumnSeries,
} from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

useTheme(am4themes_animated);

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    valueAxisTitle: {
      type: String,
      required: false,
    },
    uri: {
      type: String,
      default: '',
    },
    langs: {
      type: Object,
      default: () => ({
        men: '',
        women: '',
        college: '',
        all: '',
        nodata: '',
        mobileOff: '',
      }),
    },
    innerRadius: Boolean,
    // outerLabels: Boolean,
    legends: Boolean,
    clickoff: Boolean,
    withoutLabels: Boolean,
  },
  data() {
    return {
      show: true,
      noData: false,
      ready: false,
    };
  },
  methods: {
    checkData(data) {
      return Array.isArray(data) && data.length > 0;
    },
    async request(uri, params, modify) {
      return new Promise(async (res, rej) => {
        try {
          if (!window.core_project) {
            window.core_project = {
              locale: 'ru',
              region_id: 15,
              trans_v: 1,
            };
          }

          const options = {
            url: uri,
            headers: {
              'Accept-Language': window.core_project.locale,
              'X-RegionId': window.core_project.region_id,
            },
          };

          if (params) {
            options.params = params;
          }

          const response = await this.$axios(options);
          let { data } = response;

          if (modify) {
            data = this.transformDataArray(response.data);
          }

          res(data);
        } catch (err) {
          rej((err && err.response && err.response.data) || err);
        }
      });
    },
    createPieChart(ref, data, innerRadius = false) {
      // if (this.charts[pieChartName]) {
      //   console.error('Выберите другое имя для диаграммы!');
      //   return false;
      // }

      const chart = create(ref, PieChart);
      chart.data = data;
      chart.contentWidth = 500;
      chart.contentHeight = 500;

      if (innerRadius) {
        chart.innerRadius = percent(35);
      }

      const pieSeries = chart.series.push(new PieSeries());
      pieSeries.dataFields.value = 'count';
      pieSeries.dataFields.category = 'name';
      pieSeries.dataFields.id = 'id';
      // pieSeries.slices.template.cornerRadius = 5;

      if (Array.isArray(data) && data[0].color) {
        pieSeries.slices.template.propertyFields.fill = 'color';
        pieSeries.slices.template.propertyFields.stroke = 'color';
      }

      // if (this.outerLabels) {
      //   pieSeries.alignLabels = false;
      //   pieSeries.labels.template.bent = true;
      //   pieSeries.calculatePercent = true;
      //   pieSeries.labels.template.text = '{value.percent.formatNumber("###.0")}%';
      // } else {
      pieSeries.labels.template.disabled = true;
      // }

      const hoverState = pieSeries.slices.template.states.getKey('hover');
      if (this.clickoff) {
        hoverState.properties.scale = 1;
        pieSeries.slices.template.clickable = false;
      } else {
        hoverState.properties.scale = 1.02;
      }

      pieSeries.slices.template.events.once('ready', () => {
        this.ready = true;
      });

      return {
        chart,
        pieSeries,
        data,
      };
    },
    createBarChart(ref, data, colors, templateTooltip) {
      const chart = create(ref, XYChart);
      chart.logo.disabled = true;
      chart.data = data.sort((a, b) => {
        if (a.count > b.count) {
          return -1;
        }

        if (a.count < b.count) {
          return 1;
        }

        return 0;
      });
      const categoryAxis = chart.xAxes.push(new CategoryAxis());
      categoryAxis.dataFields.category = 'name';
      categoryAxis.renderer.labels.template.disabled = true;
      const valueAxis = new ValueAxis();
      if (this.valueAxisTitle) {
        valueAxis.title.text = this.valueAxisTitle;
      }
      chart.yAxes.push(valueAxis);
      if (this.withoutLabels) {
        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.tooltip.disabled = true;
      }

      const series = chart.series.push(new ColumnSeries());
      series.tooltip.label.wrap = true;
      series.tooltip.label.fontSize = 14;
      series.tooltip.label.maxWidth = 300;
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = color('#fff');
      series.tooltip.label.fill = color('#000');
      series.columns.template.tooltipText = templateTooltip.replace('\\n', '\n');
      series.dataFields.valueY = 'count';
      series.dataFields.categoryX = 'name';
      series.dataFields.link = 'link';
      series.calculatePercent = true;
      series.columns.template.strokeWidth = 0;
      // series.columns.template.width = 30;
      // series.columns.template.column.cornerRadiusTopLeft = 20;
      // series.columns.template.column.cornerRadiusTopRight = 20;

      // Покраска
      if (colors) {
        chart.colors.list = colors;
        series.columns.template.events.once('inited', (event) => {
          event.target.fill = chart.colors.getIndex(event.target.dataItem.index);
        });
        series.columns.template.events.once('ready', () => {
          this.ready = true;
        });
      }

      series.columns.template.fillOpacity = 0.7;
      const hs = series.columns.template.states.create('active');
      hs.properties.fillOpacity = 1;

      return {
        chart,
        series,
        data,
      };
    },
    getPieLegend(chart) {
      if (chart && chart.pieSeries) {
        return chart.pieSeries.slices.values
          .map(item => ({
            ...item.dataItem.dataContext,
            color: item.fill.hex,
            isActive: item.isActive,
            percent: item.dataItem.values.value.percent.toFixed(1),
          })).sort((a, b) => {
            const fAPercent = parseFloat(a.percent);
            const fBPercent = parseFloat(b.percent);

            if (fAPercent > fBPercent) {
              return -1;
            }

            if (fAPercent < fBPercent) {
              return 1;
            }

            return 0;
          });
      }
      return { data: [] };
    },
    getBarLegend(chart) {
      if (chart && chart.series) {
        return chart.series.columns.values.map(item => ({
          ...item.dataItem.dataContext,
          color: item.fill,
          isActive: item.isActive,
          percent: item.dataItem.values.valueY.percent.toFixed(1),
        }));
      }
      return [];
    },
    getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
  },
};
