import {
  requestWrapper,
} from '@vjs/helpers';
import {
  QForm,
  QInput,
  QBtn,
  QIcon,
} from '@quasar/components';
import VFormInform from '@vjs/components/VFormInform';
import _ from 'lodash';
import SelectUserFile from '../SelectUserFile';
import Files from '../VStatementCreateView/mixins/Files';
import StatementFiles from '../VStatementCreateView/mixins/StatementFiles';


export default {
  name: 'VStatementFilesEdit',

  components: {
    QForm,
    QInput,
    QBtn,
    QIcon,
    VFormInform,
    SelectUserFile,
  },

  mixins: [Files, StatementFiles],

  props: {
    uriSendFiles: {
      type: String,
      required: true,
    },
    uriGetFiles: {
      type: String,
      required: true,
    },
    uriUserFiles: {
      type: String,
      required: true,
    },
    withPpz: false,
    withRelation: false,
  },

  data() {
    const form = {
      doc_statement: [],
      diploma: [],
      certificate: [],
      doc_photo: [],
      doc_medical_examination_086_y: [],
      // doc_medical_examination_086_flyura: [],
      doc_medical_examination_088: [],
      doc_for_privilege: [],
    };

    if (this.withPpz) {
      form.doc_ppz = [];
    }

    if (this.withRelation) {
      form.doc_relation = [];
    }
    return {
      loading: false,
      activeFile: null,
      modalFiles: false,
      form,
    };
  },

  async mounted() {
    this.$globalLoading.show();
    await this.$trans.add([
      'warning_text',
      'college',
      'label',
      'button',
      'statement',
      'notice',
    ]);

    await this.requestGetFiles();
    this.$globalLoading.hide();
  },

  computed: {
    validate() {
      return this.form.doc_statement.length !== 0
      && this.form.diploma.length !== 0
      && this.form.doc_photo.length !== 0
      // TODO: не стал удалять, если вдруг снова потребуют сделать обязательным
      // && (!this.withPpz || this.form.doc_ppz.length !== 0)
      && (!this.withRelation || this.form.doc_relation.length !== 0);
    },
  },

  methods: {
    async requestGetFiles() {
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.uriGetFiles,
      });

      if (res.error && res.data.errors) {
        this.$globalLoading.hide();
        this.$notify({
          text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
          type: 'error',
        });
      }

      if (!res.error) {
        this.form = res.data;
      }
    },
    async requestForm() {
      this.$globalLoading.show();
      const files = {};
      _.each(this.form, (item, index) => {
        if (item.length !== 0) {
          files[index] = item;
        }
      });

      const res = await requestWrapper.call(this, {
        method: 'patch',
        url: this.uriSendFiles,
        data: files,
      });

      if (res.error && res.data.errors) {
        this.$globalLoading.hide();
        this.$notify({
          text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
          type: 'error',
        });
        return;
      }

      if (!res.error) {
        this.$globalLoading.hide();
      }

      this.$globalLoading.hide();
    },
  },
};
