import { StoreTranslator } from '@common/mixins';

export default {
  mixins: [StoreTranslator],
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    urlStore: {
      type: String,
      required: true,
    },
    photoEdit: {
      type: Boolean,
    },
  },
  data() {
    const vm = this;
    return {
      processSend: false,
      currentFile: vm.value,
    };
  },
  mounted() {
    const vm = this;
  },

  destroyed() {
  },
  watch: {},
  computed: {
    styles() {
      const result = {};
      if (this.currentFile) {
        result.backgroundImage = `url(${this.currentFile})`;
      }
      return result;
    },
  },
  methods: {

    onClickInputFile() {
      const el = this.$refs.inputFile;
      if (el.onclick) {
        el.onclick();
      } else if (el.click) {
        el.click();
      }
    },
    onChangeInputFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      const file = files[0];
      e.target.value = null;
      this.upload(file);
    },
    upload(file) {
      const vm = this;
      if (vm.processSend === true) {
        return false;
      }
      vm.$set(vm, 'processSend', true);

      const formData = new FormData();

      formData.append('file', file);
      const config = {
        responseType: 'json',
        headers: {
          'X-CSRF-TOKEN': window.core_project.csrfToken,
          'Accept-Language': window.core_project.locale,
        },
      };
      vm.$http.post(vm.urlStore, formData, config).then(
        (response) => {
          const { data } = response;
          vm.$set(vm, 'processSend', false);
          vm.$set(vm, 'currentFile', data.url);
        },
        (response) => {
          vm.$set(vm, 'processSend', false);

          if (response.status === 422 || response.status === 423) {
            let errors = [];
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
            });
            show_notice(errors, 'error');
          } else {
            console.log(response);
          }
        },
      );
    },
  },
};
