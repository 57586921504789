import { Form, eventBus } from '@common/mixins';
import { Helper } from '@common/src/helpers';
import _ from 'lodash';
import { QForm, QSlideTransition, QInput } from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';

export default {
  name: 'ProgramChapter',
  components: { QForm, QSlideTransition, QInput },
  mixins: [Form],
  props: {
    resource: {
      type: Object,
      default: null,
    },
    action: {
      type: String,
      default: null,
    },
  },
  data() {
    const fields = {
      discipline_id: null,
      name: null,
      results: null,
    };
    return {
      fields,
    };
  },
  mounted() {
    _.forEach(this.resource, (attribute, key) => {
      if (this.fields.hasOwnProperty(key)) {
        this.fields[key] = attribute;
      }
    });
  },
  computed: {
    validate() {
      return this.fields.name !== null && this.fields.discipline_id !== null;
    },
    disciplines() {
      return this.$store.getters.disciplines.filter(el => !el.is_module);
    },
  },
  methods: {
    async handleAjaxFormSubmit() {
      this.$globalLoading.show();
      const data = this.fields;
      const { curriculumProgramApiConfig, makeProgramUrl, parentId } = this.$store.getters;
      const url = this.action ?? makeProgramUrl(curriculumProgramApiConfig.chapters, parentId);
      const res = await requestWrapper.call(this, {
        url,
        method: 'post',
        data,
      });

      if (!res.error) {
        await this.$store.dispatch('GET_CURRICULUM_PROGRAM_CONTENT_TABLE');
        this.$emit('onClose');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
