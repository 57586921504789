import Vue from 'vue';
import store from './VueJS/store';
import VContingentMonitoring from './VueJS/views/VContingentMonitoring/index';

if (document.getElementById('v-contingent-monitoring-app')) {
  window.vContingentMonitoringApp = new Vue({
    el: '#v-contingent-monitoring-app',
    components: { VContingentMonitoring },
    store,
  });
}
