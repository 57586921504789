import VModalConfirmExit from '../modals/VModalConfirmExit.vue';

export default {
  methods: {
    endOfTest() {
      const { status } = this.$store.state.test;
      if (status !== 'end' && status !== 'preview') {
        this.$store.dispatch('END_TEST');
      } else {
        this.$store.commit('SET_TEST_STATUS', 'end');
      }
    },

    async endOfFinishedTest() {
      const { timeSpent, testTimeLeft } = this.$store.state.test;
      if (timeSpent === undefined && testTimeLeft === 0) {
        await this.$store.dispatch('END_TEST');
      }
    },

    confirmEndOfTest() {
      this.$modal.open({
        props: {
          callback: this.endOfTest,
        },
        title: this.$trans.get('test.confirm'),
        size: 'sm',
        component: VModalConfirmExit,
      });
    },
  },
};
