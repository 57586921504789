<template>
  <div
    class="s-test-process__questions-map"
    :class="{'is-show': isShow}"
  >
    <!--    Просили убрать в 508 задаче, может позже вернем-->
    <!--    <div
      v-if="TEST.status !== 'preview'"
      class="s-test-process__button s-test-process__button&#45;&#45;logout"
      @click="confirmEndOfTest"
    >
      <span>{{ $trans.get('test.end_of_test') }}</span>
    </div>-->
    <div
      class="s-test-process__button s-test-process__button--map"
      style="top:2rem;"
      @click="toggleMap"
    >
      <span>{{ $trans.get('test.question_map') }}</span>
    </div>
    <div class="s-questions-map">
      <div
        v-for="item in questionsChunk[CURRENT_QMAP_NUM]"
        :key="item.id"
        class="s-questions-map__item"
        :data-var-num="item.idx + 1"
        :class="{
          'is-answer': checkAnswer(item.answers),
          'is-selected': item.id === CURRENT_QUESTION.id,
          'is-helper': item.id === questionHint.id,
        }"
        @click="selectQuestion(item.id, item.idx)"
      >
        <div
          v-for="(answer, idx) in item.answers"
          :key="answer.id"
          class="s-questions-map__item-point"
          :class="answerSelectors(answer)"
        >
          {{ toLetter(idx) }}
        </div>
      </div>
      <div
        v-if="questionsChunk.length > 1"
        class="s-questions-map__pagination-wrapper"
      >
        <div class="s-questions-map__pagination">
          <div
            class="s-questions-map__pagination-prev"
            @click="switchQuestionsMap(false)"
          />
          <div
            class="s-questions-map__pagination-next"
            @click="switchQuestionsMap(true)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import endOfTest from './mixins/endOfTest';
import toLetter from './helpers/toLetter';

export default {
  name: 'VTestQuestionMap',
  mixins: [endOfTest],
  data: () => ({
    isShow: true,
  }),
  computed: {
    ...mapGetters([
      'CURRENT_SUBJECT',
      'CURRENT_QUESTION',
      'CURRENT_QMAP_NUM',
      'TEST',
    ]),
    questionsChunk() {
      if (Array.isArray(this.CURRENT_SUBJECT.questions)) {
        return _.chunk(this.CURRENT_SUBJECT.questions, 20);
      }
      return [];
    },
    questionHint() {
      return this.$store.state.helpWithLastQuestion;
    },
  },

  updated() {
    if (this.questionHint.id) {
      this.isShow = true;
    }
  },

  mounted() {
    if (window.innerWidth <= 908) {
      this.isShow = false;
    }
  },

  methods: {
    toggleMap() {
      this.isShow = !this.isShow;
    },

    // Выбирает вопрос на карте и объявляет его в store
    selectQuestion(id, index) {
      if (this.CURRENT_QUESTION.id !== id) {
        this.$store.commit('SET_CURRENT_QUESTION', {
          id,
          idx: index,
        });
      }
    },

    // Проверяет массив ответов на наличие выбранного варианта
    checkAnswer(answers) {
      if (answers) {
        const result = answers.filter(o => o.checked === true);
        return (result.length > 0);
      }
      return false;
    },

    switchQuestionsMap(direction = true) {
      let number = this.CURRENT_QMAP_NUM;

      if (direction) {
        if (this.CURRENT_QMAP_NUM + 1 < this.questionsChunk.length) {
          number = this.CURRENT_QMAP_NUM + 1;
        }
      } else if (this.CURRENT_QMAP_NUM - 1 >= 0) {
        number = this.CURRENT_QMAP_NUM - 1;
      }

      this.$store.commit('SET_QMAP_NUM', number);
    },

    answerSelectors(answer) {
      const selectors = ['is-selected'];// 'is-bad' это не правильный ответ нам он не нужен
      let correct = false;
      const result = {
        [selectors[0]]: answer.checked,
      };

      if (this.TEST.status === 'preview') {
        if (answer.correct && answer.correct === 1) {
          selectors[0] = 'is-good';
          correct = true;
        }

        result[selectors[1]] = answer.checked && !correct;
      }

      return result;
    },

    toLetter,
  },
};
</script>
