export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: '',
    },
    grade: {
      type: Number,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
  },
};
