export default {
  computed: {
    fixedColumns() {
      return [
        {
          name: 'index',
          field: 'index',
          label: '№',
          fixed: true,
        }, {
          name: 'full_name',
          field: 'full_name',
          label: this.trans('attendance.time-sheet.full-name'),
          fixed: true,
        },
      ];
    },
    scrollColumns() {
      const { dayColumns } = this;
      const lastColumns = [
        {
          name: 'absentDays',
          field: 'absentDays',
          label: this.trans('attendance.time-sheet.absent-days'),
        },
        {
          name: 'respectfulAbsentDays',
          field: 'respectfulAbsentDays',
          label: this.trans('attendance.time-sheet.respectful-absent-days'),
        },
        {
          name: 'reasons',
          field: 'reasons',
          label: this.trans('attendance.time-sheet.absent-reasons'),
        },
      ];

      const daysColumns = typeof dayColumns !== 'undefined'
        ? Object.keys(dayColumns)
          .map(key => ({
            name: key,
            field: key,
            label: dayColumns[key],
          }))
        : [];
      return [...daysColumns, ...lastColumns];
    },
  },
};
