import {
  QCheckbox,
} from '@quasar/components';
import axios from 'axios';
import Offer from '@vjs/modals/Offer';
import { Helper } from '@common/src/helpers';

export default {
  name: 'VAgreementModal',
  components: {
    QCheckbox,
  },
  props: {
    transArr: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    dataUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      aggree: false,
    };
  },
  mounted() {
    const interval = setInterval(() => {
      if ($) {
        $('#_agreement_modal').modal('show');
        clearInterval(interval);
      }
    }, 100);
  },
  computed: {
    checkDisabled() {
      return this.aggree !== true;
    },
  },
  methods: {
    async getInfo(lang) {
      const res = await axios.get(this.dataUrl, {
        params: {
          lang,
        },
      });
      if (this.type === 'offer') {
        this.$sModal.open('offer', {
          size: '800',
          binds: {
            text: res.data,
          },
          component: Offer,
        });
      }
    },
    async updateAgreement() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.action,
        method: 'post',
        data: {
          agree: this.aggree,
          type: this.type,
        },
      });
      if (!res.error) {
        this.$emit('onClose');
        $('#_agreement_modal').modal('hide');
        if (res.redirect) {
          window.location.href = (res.redirect);
          return;
        }
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
