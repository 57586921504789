export default {
  props: {
    value: {},
    dateFormat: {},
    autocomplete: {
      type: String,
      default: 'on',
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  mounted() {
    const vm = this;
    $(this.$el).datepicker({
      // dateFormat: this.dateFormat,
      changeMonth: true,
      changeYear: true,
      onSelect(date) {
        vm.localValue = date;
        // vm.$emit('input', date);
      },
    });
  },
  watch: {
    value(value) {
      this.localValue = value;
      // $(this.$el).datepicker("setDate", value)
    },
    localValue(value) {
      this.$emit('input', value);
    },
  },
  destroyed() {
    $(this.$el).datepicker('hide').datepicker('destroy');
  },
  methods: {},
};
