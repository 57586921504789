import _ from 'lodash';

import setFirstAnswerCorrect from './setFirstAnswerCorrectHelper';
import setLastAnswerNotCorrect from './setLastAnswerNotCorrectHelper';
import atLeastOneCorrectAnswers from './atLeastOneCorrectAnswersHelper';

export default function (question) {
  let data = question;

  const res = data.answers.length > 2;
  _.forEach(data.answers, (answer, index) => {
    data.answers[index].disable = !res;
  });
  if (!atLeastOneCorrectAnswers(data, 0)) {
    data = setLastAnswerNotCorrect(data);
  } else
  if (!atLeastOneCorrectAnswers(data, 1)) {
    data = setFirstAnswerCorrect(data);
  }

  return data;
}
