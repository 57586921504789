import { eventBus } from '@common/mixins';

export default {
  computed: {
    canDeleteAnswer() {
      return this.dataQuestion.answers.length > 2;
    },
    canEditQuestion() {
      return this.dataQuestion && this.questionId && this.dataQuestion.id === this.questionId;
    },
  },
  methods: {
    newAnswer() {
      this.dataQuestion.answers.push({ answer: null });
    },
    deleteNewAnswer(key) {
      this.dataQuestion.answers.splice(key, 1);
    },
    deleteAnswer(key) {
      if (this.dataQuestion.answers[key].id) {
        this.confirmDeleteAnswer(key);
      } else {
        this.deleteNewAnswer(key);
      }
    },
    changeQuestion(id) {
      this.$store.commit('SET_QUESTION_ID', id);
      this.$emit('edit-question');
    },
    cancelQuestion() {
      this.$store.commit('SET_QUESTION_ID', null);
      if (!this.dataQuestion) {
        eventBus.$emit('close-question');
      }
    },
    async questionChangeVisibility(id) {
      await this.$store.dispatch('HIDE_QUESTIONNAIRE_QUESTION', id);
    },
    async questionChangeOption(id) {
      await this.$store.dispatch('CHANGE_QUESTION_OPTION', id);
    },
    async confirmDelete(id) {
      await this.$store.dispatch('DELETE_QUESTIONNAIRE_QUESTION', id);
    },
    async confirmDeleteAnswer(key) {
      const events = {
        onApprove: () => {
          this.deleteNewAnswer(key);
          eventBus.$emit('onCloseAll');
        },
      };
      this.$sModal.open('v-modal-confirm', {
        title: this.trans('notice.are_you_sure'),
        component: 'v-modal-confirm',
        events,
      });
    },
  },
};
