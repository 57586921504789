<template>
  <table
    class="tbl-cont table-curriculum"
  >
    <thead class="tbl-cont__head">
      <tr>
        <th
          rowspan="3"
          class="sticky-col first-col"
        >
          {{ $trans.get('curriculum.table_plan_head_index_credit') }}
        </th>
        <th
          rowspan="3"
          class="sticky-col second-col"
        >
          {{
            $trans.get('curriculum.table_plan_head_discipline_credit') }}
        </th>

        <th
          colspan="2"
          rowspan="2"
        >
          {{
            $trans.get('curriculum.table_plan_head_control') }}
        </th>
        <th rowspan="3">
          <span class="text_bottom_to_up">
            {{ $trans.get('curriculum.table_plan_head_credit_number') }}
          </span>
        </th>
        <th rowspan="3">
          <span class="text_bottom_to_up">
            {{ $trans.get('curriculum.table_plan_head_duration_total') }}
          </span>
        </th>
        <th colspan="5">
          {{ $trans.get('curriculum.table_plan_head_duration') }}, {{ $trans.get('curriculum.of_them_on') }}
        </th>
      </tr>
      <tr>
        <th colspan="4">
          {{ $trans.get('curriculum.table_plan_head_duration_theory_credit') }}
        </th>
        <th rowspan="2">
          <span class="text_bottom_to_up">
            {{ $trans.get('curriculum.table_plan_head_duration_practice_clinic') }}
          </span>
        </th>
      </tr>
      <tr>
        <th>
          <span class="text_bottom_to_up">
            {{ $trans.get('curriculum.table_plan_head_control_exam') }}
          </span>
        </th>
        <th>
          <span class="text_bottom_to_up">
            {{ $trans.get('curriculum.table_plan_head_control_test_credit') }}
          </span>
        </th>


        <th>
          <span class="text_bottom_to_up">
            {{ $trans.get('curriculum.table_plan_head_duration_self_regulating') }}
          </span>
        </th>
        <th>
          <span class="text_bottom_to_up">
            {{ $trans.get('curriculum.table_plan_head_duration_self_regulating_projection') }}
          </span>
        </th>
        <th>
          <span class="text_bottom_to_up">
            {{ $trans.get('curriculum.table_plan_head_duration_classroom') }}
          </span>
        </th>
        <th>
          <span class="text_bottom_to_up">
            {{ $trans.get('curriculum.table_plan_head_duration_simulation') }}
          </span>
        </th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(group, index) in groups">
        <tr
          v-if="checkSeason(index)"
          class="table-curriculum-body__title"
        >
          <td class="sticky-col first-col">
            {{ group.mc_data.season.number }} {{ $trans.get(group.mc_data.season.key) }}
          </td>
          <td v-for="i in 10" />
        </tr>
        <tr
          v-else-if="index == 0"
          class="table-curriculum-body__title"
        >
          <td class="sticky-col first-col">
            {{ group.mc_data.season.number }} {{ $trans.get(group.mc_data.season.key) }}
          </td>
          <td v-for="i in 10" />
        </tr>
        <tr class="table-curriculum-body__title">
          <td class="sticky-col first-col">
<!--            {{group.education_group_discipline.code }}-->
          </td>
          <td class="sticky-col second-col">
            <div>
              {{ group.education_group_discipline.name }}
            </div>
          </td>
          <td>
            <div class="table-curriculum-body__numeric">
              {{ countGroup(group,'controlForm.exam') }}
            </div>
          </td>
          <td>
            <div class="table-curriculum-body__numeric">
              {{ countGroup(group,'controlForm.pass') }}
            </div>
          </td>
          <td>
            <div class="table-curriculum-body__numeric">
              {{ groupTotal(group,'duration.credits') }}
            </div>
          </td>
          <td>
            <div class="table-curriculum-body__numeric">
              {{ groupTotal(group,
                            'duration.selfRegulating',
                            'duration.projection',
                            'duration.classroom',
                            'duration.simulation',
                            'duration.practice'
              )
              }}
            </div>
          </td>
          <td>
            <div class="table-curriculum-body__numeric">
              {{ groupTotal(group,'duration.selfRegulating') }}
            </div>
          </td>
          <td>
            <div class="table-curriculum-body__numeric">
              {{ groupTotal(group,'duration.projection') }}
            </div>
          </td>
          <td>
            <div class="table-curriculum-body__numeric">
              {{ groupTotal(group,'duration.classroom') }}
            </div>
          </td>
          <td>
            <div class="table-curriculum-body__numeric">
              {{ groupTotal(group,'duration.simulation') }}
            </div>
          </td>
          <td>
            <div class="table-curriculum-body__numeric">
              {{ groupTotal(group,'duration.practice') }}
            </div>
          </td>
        </tr>
        <template
          v-for="(curriculum_working_discipline, index) in group.curriculum_working_disciplines_parents"
        >
          <tr>
            <td class="sticky-col first-col">
              <div
                v-if="!checkEditValue( curriculum_working_discipline)"
              >
<!--                {{ group.education_group_discipline.code }} - {{ index+1 }}-->
              </div>
              <div v-else>
                <input
                  v-model="group.education_group_discipline.code"
                  type="text"
                  class="s-form__input"
                > - {{ index+1 }}
              </div>
            </td>
            <td
              class="sticky-col second-col"
            >
              <div
                v-if="!checkEditValue(curriculum_working_discipline)"
                class="table-curriculum-body__discipline_name"
              >
                {{
                  curriculum_working_discipline.education_discipline.name
                }}
              </div>
              <div
                v-else
                class="table-curriculum-body__discipline_name"
                style="padding-right: unset;"
              >
                <label class="s-form__label mb-10">
                  {{ $trans.get('fields.curriculum_discipline_id') }}:
                </label>
                <v-multiselect
                  v-model="disciplinesEdits[curriculum_working_discipline.id].education_discipline"
                  class="mb-10"
                  label="name"
                  value="id"
                  style="max-width: 300px;"
                  track-by="id"
                  :options="getRefDisciplines()"
                  :placeholder="$trans.get('placeholder.choose')"
                >
                  <template slot="noOptions">
                    {{ $trans.get('select.list_is_empty') }}
                  </template>
                  <template slot="noResult">
                    {{ $trans.get('select.list_is_empty') }}
                  </template>
                </v-multiselect>
              </div>
              <template
                v-if="!(curriculum_working_discipline.children&&curriculum_working_discipline.children.length>0)"
              >
                <div
                  v-if="checkEditValue( curriculum_working_discipline)"
                  class="table-curriculum-button-wrapper"
                >
                  <div
                    class="s-btn s-btn--thm-grey"
                    style="margin-bottom: 1rem; width:100%;"
                    @click="hideEditValue(curriculum_working_discipline)"
                  >
                    <div
                      class="s-str-ico s-str-ico--thm-angle-left s-str-ico--wider"
                    >
                      {{ $trans.get('curriculum.action_cancel') }}
                    </div>
                  </div>
                  <div
                    class="s-btn s-btn--thm-green"
                    style="width:100%;"
                    @click="saveEditValue(curriculum_working_discipline)"
                  >
                    <div
                      class="s-str-ico s-str-ico--thm-check s-str-ico--wider"
                    >
                      {{
                        $trans.get('curriculum.action_save')
                      }}
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="isEdit"
                  class="table-curriculum-control-wrapper"
                >
                  <div
                    class="s-btn-table s-btn--ico"
                    @click="showEditValue(curriculum_working_discipline)"
                  >
                    <div class="s-ico s-ico--thm-pencil" />
                  </div>
                  <div
                    class="s-btn-table s-btn--ico"
                    @click="showConfirmDelete(curriculum_working_discipline)"
                  >
                    <div class="s-ico s-ico--thm-trash" />
                  </div>
                </div>
              </template>
            </td>
            <td>
              <div
                v-if="checkEditValue(curriculum_working_discipline)"
                class="table-curriculum-body__numeric"
              >
                <input
                  v-model="disciplinesEdits[curriculum_working_discipline.id].controlForm.exam"
                  type="text"
                  class="s-form__input"
                >
              </div>
              <div
                v-else
                class="table-curriculum-body__numeric"
              >
                {{
                  getControlOfType(curriculum_working_discipline,'controlForm.exam') }}
              </div>
            </td>
            <td>
              <div
                v-if="checkEditValue(curriculum_working_discipline)"
                class="table-curriculum-body__numeric"
              >
                <input
                  v-model="disciplinesEdits[curriculum_working_discipline.id].controlForm.pass"
                  type="text"
                  class="s-form__input"
                >
              </div>
              <div
                v-else
                class="table-curriculum-body__numeric"
              >
                {{ getControlOfType(curriculum_working_discipline,'controlForm.pass')
                }}
              </div>
            </td>
            <td>
              <div
                v-if="checkEditValue(curriculum_working_discipline)"
                class="table-curriculum-body__numeric"
              >
                <input
                  v-model="disciplinesEdits[curriculum_working_discipline.id].duration.credits"
                  type="text"
                  class="s-form__input"
                >
              </div>
              <div
                v-else
                class="table-curriculum-body__numeric"
              >
                {{ getOfType(curriculum_working_discipline,'duration.credits')
                }}
              </div>
            </td>
            <td>
              <div class="table-curriculum-body__numeric">
                {{ getOfType(curriculum_working_discipline,
                             'duration.selfRegulating',
                             'duration.projection',
                             'duration.classroom',
                             'duration.simulation',
                             'duration.practice'
                ) }}
              </div>
            </td>
            <td>
              <div
                v-if="checkEditValue(curriculum_working_discipline)"
                class="table-curriculum-body__numeric"
              >
                <input
                  v-model="disciplinesEdits[curriculum_working_discipline.id].duration.selfRegulating"
                  type="text"
                  class="s-form__input"
                >
              </div>
              <div
                v-else
                class="table-curriculum-body__numeric"
              >
                {{ getOfType(curriculum_working_discipline,'duration.selfRegulating') }}
              </div>
            </td>
            <td>
              <div
                v-if="checkEditValue(curriculum_working_discipline)"
                class="table-curriculum-body__numeric"
              >
                <input
                  v-model="disciplinesEdits[curriculum_working_discipline.id].duration.projection"
                  type="text"
                  class="s-form__input"
                >
              </div>
              <div
                v-else
                class="table-curriculum-body__numeric"
              >
                {{
                  getOfType(curriculum_working_discipline,'duration.projection') }}
              </div>
            </td>
            <td>
              <div
                v-if="checkEditValue(curriculum_working_discipline)"
                class="table-curriculum-body__numeric"
              >
                <input
                  v-model="disciplinesEdits[curriculum_working_discipline.id].duration.classroom"
                  type="text"
                  class="s-form__input"
                >
              </div>
              <div
                v-else
                class="table-curriculum-body__numeric"
              >
                {{
                  getOfType(curriculum_working_discipline,'duration.classroom') }}
              </div>
            </td>
            <td>
              <div
                v-if="checkEditValue(curriculum_working_discipline)"
                class="table-curriculum-body__numeric"
              >
                <input
                  v-model="disciplinesEdits[curriculum_working_discipline.id].duration.simulation"
                  type="text"
                  class="s-form__input"
                >
              </div>
              <div
                v-else
                class="table-curriculum-body__numeric"
              >
                {{
                  getOfType(curriculum_working_discipline,'duration.simulation') }}
              </div>
            </td>
            <td>
              <div
                v-if="checkEditValue(curriculum_working_discipline)"
                class="table-curriculum-body__numeric"
              >
                <input
                  v-model="disciplinesEdits[curriculum_working_discipline.id].duration.practice"
                  type="text"
                  class="s-form__input"
                >
              </div>
              <div
                v-else
                class="table-curriculum-body__numeric"
              >
                {{
                  getOfType(curriculum_working_discipline,'duration.practice') }}
              </div>
            </td>
          </tr>
          <template
            v-if="curriculum_working_discipline.children&&curriculum_working_discipline.children.length"
          >
            <tr
              v-for="(curriculum_working_discipline_child, indexChild) in curriculum_working_discipline.children"
            >
              <td class="sticky-col first-col">
<!--                {{ curriculum_working_discipline_child.is_result-->
<!--                  ? $trans.get('curriculum.is_result')-->
<!--                  : group.education_group_discipline.code }} - -->
<!--                {{ index+1 }}.{{ indexChild+1 }}-->
              </td>
              <td
                class="sticky-col second-col"
              >
                <div
                  v-if="!checkEditValue( curriculum_working_discipline_child)"
                  class="table-curriculum-body__discipline_name"
                >
                  {{
                    curriculum_working_discipline_child.is_result
                      ? curriculum_working_discipline_child.education_result.name
                      : curriculum_working_discipline_child.education_discipline.name
                  }}
                </div>
                <div
                  v-else
                  class="table-curriculum-body__discipline_name"
                  style="padding-right: unset;"
                >
                  <label class="s-form__label mb-10">
                    {{ $trans.get('fields.curriculum_discipline_id') }}:
                  </label>
                  <v-multiselect
                    v-model="disciplinesEdits[curriculum_working_discipline_child.id].education_discipline"
                    class="mb-10"
                    style="max-width: 300px;"
                    label="name"
                    value="id"
                    track-by="id"
                    :options="curriculum_working_discipline_child.is_result
                      ? getRefResults()
                      : getRefDisciplines()"
                    :placeholder="$trans.get('placeholder.choose')"
                  >
                    <template slot="noOptions">
                      {{ $trans.get('select.list_is_empty') }}
                    </template>
                    <template slot="noResult">
                      {{ $trans.get('select.list_is_empty') }}
                    </template>
                  </v-multiselect>
                </div>
                <div
                  v-if="checkEditValue( curriculum_working_discipline_child)"
                  class="table-curriculum-button-wrapper"
                >
                  <div
                    class="s-btn s-btn--thm-grey"
                    style="margin-bottom: 1rem; width:100%;"
                    @click="hideEditValue(curriculum_working_discipline_child)"
                  >
                    <div
                      class="s-str-ico s-str-ico--thm-angle-left s-str-ico--wider"
                    >
                      {{ $trans.get('curriculum.action_cancel') }}
                    </div>
                  </div>
                  <div
                    class="s-btn s-btn--thm-green"
                    style="width:100%;"
                    @click="saveEditValue(curriculum_working_discipline_child)"
                  >
                    <div
                      class="s-str-ico s-str-ico--thm-check s-str-ico--wider"
                    >
                      {{ $trans.get('curriculum.action_save') }}
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="isEdit"
                  class="table-curriculum-control-wrapper"
                >
                  <div
                    class="s-btn-table s-btn--ico"
                    @click="showEditValue(curriculum_working_discipline_child)"
                  >
                    <div class="s-ico s-ico--thm-pencil" />
                  </div>
                  <div
                    class="s-btn-table s-btn--ico"
                    @click="showConfirmDelete(curriculum_working_discipline_child)"
                  >
                    <div class="s-ico s-ico--thm-trash" />
                  </div>
                </div>
              </td>
              <!--              <td>-->
              <!--                <div class="table-curriculum-body__numeric">-->
              <!--                  {{-->
              <!--                  curriculum_working_discipline_child.mc_data.times&&curriculum_working_discipline_child.mc_data.times.all-->
              <!--                  }}-->
              <!--                </div>-->
              <!--              </td>-->
              <td>
                <div
                  v-if="checkEditValue(curriculum_working_discipline_child)"
                  class="table-curriculum-body__numeric"
                >
                  <input
                    v-model="disciplinesEdits[curriculum_working_discipline_child.id].controlForm.exam"
                    type="text"
                    class="s-form__input"
                  >
                </div>
                <div
                  v-else
                  class="table-curriculum-body__numeric"
                >
                  {{
                    getControlOfType(curriculum_working_discipline_child,'controlForm.exam')
                  }}
                </div>
              </td>
              <td>
                <div
                  v-if="checkEditValue(curriculum_working_discipline_child)"
                  class="table-curriculum-body__numeric"
                >
                  <input
                    v-model="disciplinesEdits[curriculum_working_discipline_child.id].controlForm.pass"
                    type="text"
                    class="s-form__input"
                  >
                </div>
                <div
                  v-else
                  class="table-curriculum-body__numeric"
                >
                  {{
                    getControlOfType(curriculum_working_discipline_child,'controlForm.pass')
                  }}
                </div>
              </td>
              <td>
                <div
                  v-if="checkEditValue(curriculum_working_discipline_child)"
                  class="table-curriculum-body__numeric"
                >
                  <input
                    v-model="disciplinesEdits[curriculum_working_discipline_child.id].duration.credits"
                    type="text"
                    class="s-form__input"
                  >
                </div>
                <div
                  v-else
                  class="table-curriculum-body__numeric"
                >
                  {{
                    getOfType(curriculum_working_discipline_child,'duration.credits')
                  }}
                </div>
              </td>
              <td>
                <div class="table-curriculum-body__numeric">
                  {{ getOfType(curriculum_working_discipline_child,
                               'duration.selfRegulating',
                               'duration.projection',
                               'duration.classroom',
                               'duration.simulation',
                               'duration.practice'
                  ) }}
                </div>
              </td>
              <td>
                <div
                  v-if="checkEditValue(curriculum_working_discipline_child)"
                  class="table-curriculum-body__numeric"
                >
                  <input
                    v-model="disciplinesEdits[curriculum_working_discipline_child.id].duration.selfRegulating"
                    type="text"
                    class="s-form__input"
                  >
                </div>
                <div
                  v-else
                  class="table-curriculum-body__numeric"
                >
                  {{
                    getOfType(curriculum_working_discipline_child,'duration.selfRegulating')
                  }}
                </div>
              </td>
              <td>
                <div
                  v-if="checkEditValue(curriculum_working_discipline_child)"
                  class="table-curriculum-body__numeric"
                >
                  <input
                    v-model="disciplinesEdits[curriculum_working_discipline_child.id].duration.projection"
                    type="text"
                    class="s-form__input"
                  >
                </div>
                <div
                  v-else
                  class="table-curriculum-body__numeric"
                >
                  {{
                    getOfType(curriculum_working_discipline_child,'duration.projection')
                  }}
                </div>
              </td>
              <td>
                <div
                  v-if="checkEditValue(curriculum_working_discipline_child)"
                  class="table-curriculum-body__numeric"
                >
                  <input
                    v-model="disciplinesEdits[curriculum_working_discipline_child.id].duration.classroom"
                    type="text"
                    class="s-form__input"
                  >
                </div>
                <div
                  v-else
                  class="table-curriculum-body__numeric"
                >
                  {{
                    getOfType(curriculum_working_discipline_child,'duration.classroom')
                  }}
                </div>
              </td>
              <td>
                <div
                  v-if="checkEditValue(curriculum_working_discipline_child)"
                  class="table-curriculum-body__numeric"
                >
                  <input
                    v-model="disciplinesEdits[curriculum_working_discipline_child.id].duration.simulation"
                    type="text"
                    class="s-form__input"
                  >
                </div>
                <div
                  v-else
                  class="table-curriculum-body__numeric"
                >
                  {{
                    getOfType(curriculum_working_discipline_child,'duration.simulation')
                  }}
                </div>
              </td>
              <td>
                <div
                  v-if="checkEditValue(curriculum_working_discipline_child)"
                  class="table-curriculum-body__numeric"
                >
                  <input
                    v-model="disciplinesEdits[curriculum_working_discipline_child.id].duration.practice"
                    type="text"
                    class="s-form__input"
                  >
                </div>
                <div
                  v-else
                  class="table-curriculum-body__numeric"
                >
                  {{
                    getOfType(curriculum_working_discipline_child,'duration.practice')
                  }}
                </div>
              </td>
            </tr>
          </template>
        </template>

        <tr v-if="isEdit">
          <td class="sticky-col first-col" />
          <td class="sticky-col second-col">
            <div>
              <div
                class="s-btn s-btn--thm-blue"
                style="width: 100%;"
                @click="showForm('add_and_edit_discipline','store',{group:group})"
              >
                <div class="s-str-ico s-str-ico--thm-plus s-str-ico--wider">
                  {{
                    $trans.get('curriculum.action_add_new') }}
                </div>
              </div>
            </div>
          </td>
          <td :colspan="9" />
        </tr>
      </template>
      <tr class="table-curriculum-body__title table-curriculum-bottom">
        <td class="sticky-col first-col" />
        <td class="sticky-col second-col">
          {{ $trans.get('curriculum.general_total') }}
        </td>
        <td>
          <div class="table-curriculum-body__numeric">
            {{ total('controlForm.exam') }}
          </div>
        </td>
        <td>
          <div class="table-curriculum-body__numeric">
            {{ total('controlForm.pass') }}
          </div>
        </td>
        <td>
          <div class="table-curriculum-body__numeric">
            {{ total('duration.credits') }}
          </div>
        </td>
        <td>
          <div class="table-curriculum-body__numeric">
            {{ total(
              'duration.selfRegulating',
              'duration.projection',
              'duration.classroom',
              'duration.simulation',
              'duration.practice'
            ) }}
          </div>
        </td>
        <td>
          <div class="table-curriculum-body__numeric">
            {{ total('duration.selfRegulating') }}
          </div>
        </td>
        <td>
          <div class="table-curriculum-body__numeric">
            {{ total('duration.projection') }}
          </div>
        </td>
        <td>
          <div class="table-curriculum-body__numeric">
            {{ total('duration.classroom') }}
          </div>
        </td>
        <td>
          <div class="table-curriculum-body__numeric">
            {{ total('duration.simulation') }}
          </div>
        </td>
        <td>
          <div class="table-curriculum-body__numeric">
            {{ total('duration.practice') }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import checkEditValue from './mixins/checkEditValue';
import getRefs from './mixins/getRefs';
import actions from './mixins/actions';
import total from './mixins/total';

export default {
  name: 'VMedicalCreditCurriculum',
  mixins: [checkEditValue, getRefs, actions, total],
  props: {
    courseNumber: {
      type: Number,
      default: 0,
    },
    semesterNumbers: {
      type: Number,
      default: 0,
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    confirm: {
      type: Object,
      required: false,
    },
    groups: {
      type: [Object, Array],
    },
    forms: {
      type: [Object, Array],
    },
    disciplinesEdits: {
      type: [Object, Array],
    },
    totalAll: {
      type: Number,
      required: false,
      default: 0,
    },
    refs: {
      type: [Object, Array],
    },
    urlDisciplineStore: {
      type: String,
      required: true,
    },
    urlDisciplineUpdate: {
      type: String,
      required: true,
    },
    urlDisciplineDestroy: {
      type: String,
      required: true,
    },
    urlDisciplineValuesUpdate: {
      type: String,
      required: true,
    },
  },
  methods: {
    checkSeason(index) {
      return index !== 0
        && ((this.groups[index - 1].mc_data.season.number
          + this.groups[index - 1].mc_data.season.key)
        != (this.groups[index].mc_data.season.number
            + this.groups[index].mc_data.season.key));
    },
  },

};
</script>
