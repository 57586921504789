import VNoResults from '@vjs/components/VNoResults';
import VTraditionalCurriculum from './VTraditionalCurriculum.vue';
import VCreditCurriculum from './VCreditCurriculum.vue';
import VMedicalCreditCurriculum from './VMedicalCreditCurriculum.vue';
import VSecondMedicalCreditCurriculum from './VSecondMedicalCreditCurriculum.vue';
import getRefs from './mixins/getRefs';
import actions from './mixins/actions';

export default {
  components: {
    VNoResults,
    VTraditionalCurriculum,
    VCreditCurriculum,
    VMedicalCreditCurriculum,
    VSecondMedicalCreditCurriculum,
  },
  mixins: [getRefs, actions],
  props: {
    courseNumber: {
      type: Number,
      default: 0,
    },
    semesterNumbers: {
      type: Number,
      default: 0,
    },
    type: {
      type: Number,
      default: 1,
    },
    urlGroups: {
      type: String,
      required: true,
    },
    urlRefs: {
      type: String,
      required: true,
    },
    urlDisciplineStore: {
      type: String,
      required: true,
    },
    urlDisciplineUpdate: {
      type: String,
      required: true,
    },
    urlDisciplineDestroy: {
      type: String,
      required: true,
    },
    urlDisciplineValuesUpdate: {
      type: String,
      required: true,
    },
    totalAll: {
      type: Number,
      required: false,
      default: 0,
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    withResult: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const vm = this;
    return {
      window: {
        width: '',
        height: '',
      },
      groups: {},
      countForms: [],
      disciplinesEdits: {},
      refs: {
        disciplines: [],
        results: [],
      },
      confirm: {
        discipline: null,
      },
      forms: {
        add_and_edit_discipline: {
          modal: 'modal-curriculum-control-discipline',
          processSend: false,
          errors: {},
          currentAction: '',
          actionUpdate: vm.urlDisciplineUpdate,
          actionStore: vm.urlDisciplineStore,
          method: '',
          refs: {
            disciplines_parents: [],
          },
          onOpen() {
            const group = this.fields.group.value;
            if (group === null) {
              this.refs.disciplines_parents = [];
            } else {
              this.refs.disciplines_parents = _.map(group.curriculum_working_disciplines_parents, item => ({
                id: item.id,
                name: item.education_discipline.name,
              }));
            }
          },
          fields: {
            discipline_id: {
              attributeValue: 'id',
              value: null,
            },
            parent_id: {
              attributeValue: 'id',
              value: null,
            },
            is_result: {
              value: false,
            },
            name_ru: {
              value: '',
            },
            name_kz: {
              value: '',
            },
            group: {
              value: null,
              name: 'group_id',
              attributeValue: 'id',
            },
          },
          afterCallback(vm, response) {
            const { groups } = vm;
            const curriculum_working_discipline = response.data.data;
            const group = _.find(groups, ['id', curriculum_working_discipline.cw_group_id]);
            if (curriculum_working_discipline.parent_id === null) {
              group.curriculum_working_disciplines_parents.push(curriculum_working_discipline);
            } else {
              const curriculum_working_disciplines_parent = _.find(
                group.curriculum_working_disciplines_parents,
                ['id', curriculum_working_discipline.parent_id],
              );

              if (curriculum_working_disciplines_parent.hasOwnProperty('children')) {
                curriculum_working_disciplines_parent.children.push(curriculum_working_discipline);
              } else {
                vm.$set(
                  curriculum_working_disciplines_parent,
                  'children',
                  [curriculum_working_discipline],
                );
              }
            }
            vm.mapGroups();
            vm.$emit('change');
          },
        },
      },
    };
  },
  mounted() {
    const vm = this;
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    vm.LoadGroups();
    vm.LoadRefs();
  },
  async created() {
    this.$trans.add(['journal', 'button', 'fields', 'label', 'placeholder', 'select', 'warning_text', 'curriculum']);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    clearData() {
      this.forms.add_and_edit_discipline.fields.name_ru.value = '';
      this.forms.add_and_edit_discipline.fields.name_kz.value = '';
      this.forms.add_and_edit_discipline.fields.discipline_id.value = null;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    onModalClose() {
      this.forms.add_and_edit_discipline.errors = [];
    },
    destroyDiscipline(curriculum_working_discipline) {
      const vm = this;
      const data = {};
      data._token = window.core_project.csrfToken;
      $.ajax({
        url: vm.urlGenerate(vm.urlDisciplineDestroy, { id: curriculum_working_discipline.id }),
        method: 'DELETE',
        dataType: 'JSON',
        data,
        headers: {
          'Accept-Language': window.core_project.locale,
        },
        success(response) {
          $('#modal-discipline-delete').modal('hide');
        },
        error() {
          $('#modal-discipline-delete').modal('hide');
        },
      });
      const { groups } = vm;
      const group = _.find(groups, ['id', curriculum_working_discipline.cw_group_id]);
      if (curriculum_working_discipline.parent_id === null) {
        const disciplineIndex = _.findIndex(group.curriculum_working_disciplines_parents, o => o.id === curriculum_working_discipline.id);
        vm.$delete(group.curriculum_working_disciplines_parents, disciplineIndex);
      } else {
        const curriculum_working_disciplines_parent = _.find(
          group.curriculum_working_disciplines_parents,
          ['id', curriculum_working_discipline.parent_id],
        );
        const disciplineIndex = _.findIndex(curriculum_working_disciplines_parent.children, ['id', curriculum_working_discipline.id]);
        vm.$delete(curriculum_working_disciplines_parent.children, disciplineIndex);
      }
    },
    LoadRefs() {
      const vm = this;
      $.ajax({
        url: vm.urlRefs,
        method: 'GET',
        dataType: 'JSON',
        //        async: false,
        headers: {
          'Accept-Language': window.core_project.locale,
        },
        success(response) {
          vm.$set(vm, 'refs', response);
        },
      });
    },
    LoadGroups() {
      const vm = this;
      $.ajax({
        url: vm.urlGroups,
        method: 'GET',
        dataType: 'JSON',
        //        async: false,
        headers: {
          'Accept-Language': window.core_project.locale,
        },
        success(response) {
          const groups = response.data;
          _.forEach(groups, (o) => {
            o._tmp_data = {};
          });
          vm.$set(vm, 'groups', response.data);
          vm.mapGroups();
        },
      });
      //      return result
    },
    // TODO move to vue mix
    urlGenerate(url, params) {
      let result_url = url;
      _.forEach(params, (value, key) => {
        result_url = result_url.replace(`:${key}`, value);
      });
      return result_url;
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(form.fields, (el, key) => {
        let { value } = el;
        let keySave = key;
        if (el.hasOwnProperty('attributeValue')) {
          value = _.get(value, el.attributeValue, null);
        }
        if (el.hasOwnProperty('name')) {
          keySave = el.name;
        }
        _.set(data, keySave, value);
      });

      const action = form.currentAction;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
          vm.hideModal(form);
        },
        (response) => {
          vm.$set(form, 'processSend', false);
          vm.handleErrors(response, form);
        },
      );
    },
    handleErrors(response, form = null) {
      const vm = this;
      if (response.status === 422 || response.status === 423) {
        let errors = [];
        const formErrors = {};
        if (response.responseJSON && response.responseJSON.errors) {
          _.forEach(response.responseJSON.errors, (value, key) => {
            errors = errors.concat(value);
            _.set(formErrors, key, value);
          });
        } else {
          _.forEach(response.data.errors, (value, key) => {
            errors = errors.concat(value);
            _.set(formErrors, key, value);
          });
        }
        if (form) {
          _.set(form, 'errors', formErrors);
        }
        show_notice(errors, 'error');
      } else {
        console.log(response);
      }
    },
    hasError(form, field) {
      return this.forms[form].errors[field] !== undefined;
    },
    getError(form, field) {
      return _.first(this.forms[form].errors[field]);
    },
    mapGroups() {
      const vm = this;
      vm.groups.map(function (group) {
        if (group.curriculum_working_disciplines_parents === undefined) {
          return group;
        }
        return group.curriculum_working_disciplines_parents.map(function (parent) {
          let resultIndex = 0;
          let disciplineIndex = 0;
          if (parent.children === undefined) {
            return parent;
          }
          return parent.children.map(function (child) {
            if (child.is_result) {
              resultIndex += 1;
              child.resultIndex = resultIndex;
            } else {
              disciplineIndex += 1;
              child.disciplineIndex = disciplineIndex;
            }
            return child;
          });
        });
      });
    }
  },
};
