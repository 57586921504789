import Vue from 'vue';
import store from './VueJS/store';
import VStatementCreateView from './VueJS/views/VStatementCreateView/index';


if (document.getElementById('v-statement-create-view-app')) {
  window.vPassportPageViewApp = new Vue({
    el: '#v-statement-create-view-app',
    components: { VStatementCreateView },
    store,
  });
}
