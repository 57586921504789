import { eventBus, StoreTranslator } from '@common/mixins';
import { Helper } from '@common/src/helpers';


export default {
  mixins: [StoreTranslator],
  props: {
    urlRefs: {
      type: String,
      required: true,
    },
    urlCheckIin: {
      type: String,
      required: true,
    },
    urlMove: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    profile: {
      type: [Object, Array],
      required: false,
    },
    accessGrantEduLevels: {
      type: [Array],
      default() {
        return [];
      },
      required: false,
    },
  },
  data() {
    const vm = this;
    const fields = {
      iin: {
        value: '',
        name: 'user[iin]',
        component: 'search-iin-field',
        labelTransKey: 'label.iin',
        required: true,
        extraAttributes: {
          disabled: false,
        },
        events: {
          'on-search': vm.onClickSearch,
        },

      },
      citizenship_id: {
        value: '',
        name: 'user[citizenship_id]',
        component: 'select-field',
        labelTransKey: 'label.citizenship',
        values: [],
        required: true,
        extraAttributes: {
          disabled: false,
        },
        v_if(vm) {
          return vm.existsIIN();
        },
      },

      nationality_id: {
        value: '',
        name: 'user[nationality_id]',
        component: 'select-field',
        labelTransKey: 'label.nationality',
        values: [],
        required: true,
        extraAttributes: {
          disabled: false,
        },
        v_if(vm) {
          return vm.existsIIN();
        },
      },
      surname: {
        value: '',
        name: 'user[surname]',
        component: 'text-field',
        labelTransKey: 'label.surname',
        required: true,
        extraAttributes: {
          disabled: false,
        },
        v_if(vm) {
          return vm.existsIIN();
        },
      },
      firstname: {
        value: '',
        name: 'user[firstname]',
        component: 'text-field',
        labelTransKey: 'label.firstname',
        required: true,
        extraAttributes: {
          disabled: false,
        },
        v_if(vm) {
          return vm.existsIIN();
        },
      },
      lastname: {
        value: '',
        name: 'user[lastname]',
        component: 'text-field',
        labelTransKey: 'label.middle_name',
        extraAttributes: {
          disabled: false,
        },
        v_if(vm) {
          return vm.existsIIN();
        },
      },
      born_date: {
        value: '',
        name: 'user[born_date]',
        component: 'date-field',
        labelTransKey: 'label.born_date',
        required: true,
        extraAttributes: {
          disabled: false,
        },
        v_if(vm) {
          return vm.existsIIN();
        },

      },
      gender: {
        value: '',
        name: 'user[sex]',
        labelTransKey: 'label.gender',
        component: 'radio-field',
        extraAttributes: {
          disabled: false,
        },
        values: [
          {
            labelTransKey: 'label.male',
            value: 'm',
          },
          {
            labelTransKey: 'label.female',
            value: 'w',
          },
        ],
        required: true,
        v_if(vm) {
          return vm.existsIIN();
        },
      },
      phone: {
        value: '',
        name: 'user[phone]',
        component: 'phone-field',
        labelTransKey: 'label.phone',
        required: true,
        v_if(vm) {
          return vm.existsIIN();
        },
      },
      education: {
        value: '',
        name: 'statement[education]',
        component: 'select-field',
        labelTransKey: 'label.edu_level',
        values: [],
        required: true,
        v_if(vm) {
          return vm.existsIIN();
        },
      },

      educational_institution: {
        value: '',
        name: 'statement[educational_institution]',
        component: 'text-field',
        labelTransKey: 'label.educational_institution_name',
        required: true,
        v_if(vm) {
          return vm.existsIIN();
        },
      },
      is_grant: {
        value: 0,
        name: 'statement[is_grant]',
        labelTransKey: 'label.is_grant',
        component: 'radio-field',
        values: [
          {
            labelTransKey: 'main.yes',
            value: 1,
          },
          {
            labelTransKey: 'main.not',
            value: 0,
          },
        ],
        required: true,
        v_if(vm) {
          return vm.existsIIN() && vm.accessGrantEduLevels.includes(parseInt(vm.form.fields.education.value));
        },
      },
      profession_id: {
        value: '',
        name: 'statement[profession_id]',
        component: 'select-field',
        labelTransKey: 'label.profession_id',
        values: [],
        required: true,
        v_if(vm) {
          return vm.existsIIN();
        },
        events: {
          input: vm.onInputProfession,
        },
      },
      qualification_id: {
        value: '',
        name: 'statement[qualification_id]',
        component: 'v-select-field',
        labelTransKey: 'label.qualification_id',
        values: [],
        required: true,
        v_if(vm) {
          return vm.existsIIN() && vm.form.fields.profession_id.value > 0;
        },
        loadValues: {
          type: 'qualifications',
        },

      },
      type_of_training: {
        value: 1,
        name: 'statement[type_of_training]',
        labelTransKey: 'label.type_of_training',
        component: 'radio-field',
        values: [
          {
            labelTransKey: 'main.type_of_training.full_time',
            value: 1,
          },
          {
            labelTransKey: 'main.type_of_training.extramural',
            value: 3,
          },
        ],
        required: true,
        v_if(vm) {
          return vm.existsIIN();
        },
      },
      edu_lang: {
        value: 'kz',
        name: 'statement[edu_lang]',
        labelTransKey: 'label.edu_lang',
        component: 'radio-field',
        values: [
          {
            labelTransKey: 'label.lang.kz',
            value: 'kz',
          },
          {
            labelTransKey: 'label.lang.ru',
            value: 'ru',
          },
        ],
        required: true,
        v_if(vm) {
          return vm.existsIIN();
        },
      },
      soc_status_id: {
        value: 1,
        name: 'statement[soc_status_id]',
        component: 'select-field',
        labelTransKey: 'label.soc_status_id',
        values: [],
        required: true,
        v_if(vm) {
          return vm.existsIIN();
        },
      },
    };

    if (vm.profile !== undefined) {
      _.forEach(vm.profile, (value, key) => {
        if (fields.hasOwnProperty(key)) {
          fields[key].value = value;
        }
      });
      if (vm.profile.hasOwnProperty('iin') && vm.profile.iin) {
        fields.iin.extraAttributes.disabled = true;
      }
    }
    return {
      refs: {
        citizenships: [],
        nationalities: [],
      },
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
          if (data.redirect) {
            window.location = data.redirect;
          }
        },
      },
      lastSearchIIN: '',
      foundUserIIN: '',
      validIIN: (fields.iin.value !== null && fields.iin.value !== ''),
    };
  },
  beforeCreate() {
    this.$trans.add(['contingent', 'warning_text', 'placeholder', 'label', 'button', 'main', 'notice']);
  },
  mounted() {
    const vm = this;
    vm.loadRefs();
    vm.changeStatusValidIIN(vm.validIIN);
  },
  watch: {
    validIIN(newValue) {
      const vm = this;
      vm.changeStatusValidIIN(newValue);
    },
  },
  destroyed() {
  },
  methods: {
    onInputProfession(value) {
      const vm = this;
      vm.form.fields.qualification_id.value = '';
      vm.form.fields.qualification_id.loadValues.where = {
        profession_id: value,
      };
      vm.$set(vm.form.fields.qualification_id, 'value', null);
      eventBus.$emit(`reload-refs:${vm.form.fields.qualification_id.name}`);
    },
    changeStatusValidIIN(value) {
      const vm = this;

      if (value) {
        vm.form.fields.iin.component = 'iin-field';
        vm.form.fields.iin.extraAttributes.disabled = true;
        vm.form.fields.surname.extraAttributes.disabled = true;
        vm.form.fields.firstname.extraAttributes.disabled = true;
        vm.form.fields.lastname.extraAttributes.disabled = true;
        vm.form.fields.lastname.extraAttributes.disabled = true;
        vm.form.fields.born_date.extraAttributes.disabled = true;
      } else {
        vm.form.fields.iin.component = 'search-iin-field';
        vm.form.fields.iin.extraAttributes.disabled = false;
        vm.form.fields.surname.extraAttributes.disabled = false;
        vm.form.fields.firstname.extraAttributes.disabled = false;
        vm.form.fields.lastname.extraAttributes.disabled = false;
        vm.form.fields.lastname.extraAttributes.disabled = false;
        vm.form.fields.born_date.extraAttributes.disabled = false;
      }
    },
    existsIIN() {
      return this.validIIN;
    },
    onClickSearch(newVal) {
      this.validateIin(newVal);
    },
    loadRefs() {
      const vm = this;
      const config = {
        responseType: 'json',
        method: 'GET',
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      vm.$http.get(vm.urlRefs, config).then(
        (response) => {
          const { data } = response;
          vm.$set(vm, 'refs', data);
          vm.$set(vm.form.fields.citizenship_id, 'values', data.citizenships);
          vm.$set(vm.form.fields.nationality_id, 'values', data.nationalities);
          vm.$set(vm.form.fields.education, 'values', data.educationLevels);
          vm.$set(vm.form.fields.profession_id, 'values', data.professions);
          vm.$set(vm.form.fields.soc_status_id, 'values', data.socialStatuses);
        },
        (response) => {
          console.log(response);
        },
      );
    },
    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(form.fields, (el, key) => {
        _.set(data, vm.nameToDot(el.name), el.value);
      });
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
    validateIin(iin) {
      const vm = this;
      if (iin === vm.lastSearchIIN) {
        return;
      }
      vm.$set(vm, 'validIIN', false);
      const { form } = vm;
      vm.lastSearchIIN = iin;

      const config = {
        responseType: 'json',
        method: 'POST',
        headers: {
          'X-CSRF-TOKEN': window.core_project.csrfToken,
        },
      };
      const { fields } = form;

      vm.$http.post(
        vm.urlCheckIin,
        {
          iin,
        },
        config,
      )
        .then(
          (response) => {
            eventBus.$emit('all-change-loader', false);
            const { data } = response;
            if (data.status === 'exists') {
              vm.$set(vm, 'foundUserIIN', data.user.fullName);
              $('#modal-user-confirm-iin-rshep').modal('show');
            } else if (data.user !== undefined) {
              vm.$set(vm, 'validIIN', true);
              _.forEach(response.data.user, (value, key) => {
                if (fields.hasOwnProperty(key)) {
                  vm.$set(fields[key], 'value', value);
                }
              });
            }
          },
          (response) => {
            eventBus.$emit('all-change-loader', false);
            vm.lastSearchIIN = '';
            if (response.status === 422 || response.status === 423) {
              let errors = [];
              _.forEach(response.data.errors, (value, key) => {
                errors = errors.concat(value);
              });
              show_notice(errors, 'error');
              vm.$set(form, 'errors', response.data.errors);
            } else {
              console.log(response);
            }
          },
        );
    },
    cancelMove() {
      const vm = this;
      $('#modal-user-confirm-iin-rshep').modal('hide');
      vm.$set(vm, 'foundUserIIN', '');
      vm.$set(vm.form.fields.iin, 'value', '');
      vm.$set(vm, 'lastSearchIIN', '');
    },
    confirmMove(event) {
      const button = event.target;
      if (Helper.checkLoadingButton(button)) {
        return;
      }
      const vm = this;

      const { form } = vm;

      const config = {
        responseType: 'json',
        method: 'POST',
        headers: {
          'X-CSRF-TOKEN': window.core_project.csrfToken,
        },
      };
      Helper.buttonLoader(button);
      vm.$http.post(
        vm.urlMove,
        {
          iin: vm.lastSearchIIN,
        },
        config,
      )
        .then(
          (response) => {
            const { data } = response;
            Helper.buttonLoader(button);
            if (data.status === 'success') {
              window.location.reload();
            }
          },
          (response) => {
            Helper.buttonLoader(button);
            vm.cancelMove();
            if (response.status === 422 || response.status === 423) {
              let errors = [];
              _.forEach(response.data.errors, (value, key) => {
                errors = errors.concat(value);
              });
              show_notice(errors, 'error');
              vm.$set(form, 'errors', response.data.errors);
            } else {
              console.log(response);
            }
          },
        );
    },
  },
};
