<template>
  <div class="sc-curriculum-program__table">
    <template
      v-for="(info, key) in commonInfo"
    >
      <div
        :key="`${key}`"
        class="row no-gutters sc-curriculum-program__table--row"
        :class="{active: (key + 1) % 2 !== 0}"
      >
        <div class="col-lg-6 col-md-6 sc-curriculum-program__table--col sc-curriculum-program__table--label sn--text-right">
          {{ info.label }}
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 sc-curriculum-program__table--col">
          {{ info.value }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CommonInfoTable',
  props: {},
  computed: {
    commonInfo() {
      return this.$store.getters.commonInfo;
    },
  },
};
</script>
