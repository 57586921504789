<template>
  <div class="s-contest__conditions mt-30">
    <div v-if="contest">
      <h1 class="s-contest__description--heading">
        {{
          isPersonnelContest
            ? contest.title
            : contest.college_title
        }}
      </h1>
      <p class="s-contest__description--subheading">
        <b>{{ trans('contest.dates') }}:</b> {{ contest.date }}
      </p>
      <div
        v-if="isPersonnelContest"
        class="s-contest__prizes"
      >
        <span v-html="contest.prizes" />
      </div>
      <div class="s-contest__description">
        <span
          v-if="isPersonnelContest"
          v-html="contest.description"
        />
        <span
          v-else
          v-html="contest.college_description"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VContestConditionsView',
  props: {
    contest: Object,
    isPersonnelContest: {
      type: Boolean,
      default: true,
    },
  },

};
</script>
