import { requestWrapper } from '@vjs/helpers';

export default {
  name: 'SignButton',
  props: {
    signlink: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      signXml: null,
    };
  },
  async mounted() {
    await this.$trans.add(['button', 'notice']);
  },
  methods: {
    async sign() {
      this.$globalLoading.show();
      const webSocket = new WebSocket('wss://127.0.0.1:13579/');
      let callback = null;
      const xmlToSign = '<status>Sign</status>';
      if ([webSocket.CLOSED, webSocket.CLOSING].includes(webSocket.readyState)) {
        this.$notify({
          text: this.trans('notice.ncalayer_connection_error'),
          type: 'error',
        });
        return;
      }
      webSocket.onopen = (event) => {
        callback = async (response) => {
          const signXml = response.responseObject;
          const res = await requestWrapper.call(this, {
            url: this.signlink,
            method: 'post',
            data: { signXml },
          });
          if (res.error) {
            this.$globalLoading.hide();
            this.$notify({
              text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
              type: 'error',
            });
          }
        };
        webSocket.send(JSON.stringify(
          {
            id: 1,
            module: 'kz.gov.pki.knca.commonUtils',
            method: 'signXml',
            args: ['PKCS12', 'SIGNATURE', xmlToSign, '', ''],
          },
        ));
      };

      webSocket.onclose = (event) => {
        if (event.wasClean) {
          console.log('connection has been closed');
        } else {
          this.$notify({
            text: this.trans('notice.ncalayer_connection_closed'),
            type: 'error',
          });
          this.$globalLoading.hide();
        }
      };

      webSocket.onmessage = (event) => {
        const result = JSON.parse(event.data);
        if (result != null) {
          if (result?.code === '200') {
            if (typeof callback === 'function') {
              callback(result);
            }
          } else if (result?.code === '500') {
            this.$globalLoading.hide();
          }
        }
      };
    },
  },
};
