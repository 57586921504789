import VirtualList from 'vue-virtual-scroll-list';
import VChatMessage from './VChatMessage';

export default {
  components: { VirtualList },
  data() {
    return {
      localMessages: [],

      params: {
        loading: false,
        finished: false,
        perPage: 10,
        overflow: false,
        isFirstPageReady: false,
      },

      VChatMessage,
    };
  },

  async mounted() {
    await this.getMessages(this.params.perPage).then((messages) => {
      this.localMessages = this.localMessages.concat(messages);
    });
  },

  computed: {
    computedMessages() {
      const messages = _.cloneDeep(this.messages);
      return messages.filter(itm => !this.getMessageIds(this.localMessages).includes(itm.id));
    },
  },

  methods: {
    getMessageIds(messages) {
      return messages.map(itm => itm.id);
    },
    onItemRendered() {
      if (!this.$refs.vsl) {
        return;
      }
      if (!this.params.isFirstPageReady && this.$refs.vsl.getSizes() >= this.params.perPage) {
        this.params.isFirstPageReady = true;
        this.setVirtualListToBottom();
      }
      this.checkOverFlow();
    },
    setVirtualListToBottom() {
      if (this.$refs.vsl) {
        this.$refs.vsl.scrollToBottom();
      }
    },
    setVirtualListToOffset(offset) {
      if (this.$refs.vsl) {
        this.$refs.vsl.scrollToOffset(offset);
      }
    },
    checkOverFlow() {
      const { vsl } = this.$refs;
      if (vsl) {
        this.params.overflow = vsl.getScrollSize() > vsl.getClientSize();
      }
    },
    async onTotop() {
      if (this.params.loading) {
        return;
      }
      this.params.loading = true;
      // get next page
      await this.getMessages(this.params.perPage).then((messages) => {
        if (!messages.length) {
          this.params.finished = true;
          return;
        }
        const ids = this.getMessageIds(messages);
        this.localMessages = messages.concat(this.localMessages);
        this.$nextTick(() => {
          const { vsl } = this.$refs;
          const offset = ids.reduce((previousValue, currentSid) => {
            const previousSize = typeof previousValue === 'string' && previousValue !== 0 ? vsl.getSize(previousValue) : previousValue;
            return previousSize + this.$refs.vsl.getSize(currentSid);
          }, 0);
          this.setVirtualListToOffset(offset);
          this.params.loading = false;
        });
      });
    },
    getMessages(numbers) {
      return new Promise((resolve) => {
        if (this.localMessages.length >= this.messages.length) {
          resolve([]);
          return;
        }


        setTimeout(() => {
          const messages = [];
          while (numbers--) {
            const ids = this.getMessageIds(messages);
            const compMessages = this.computedMessages.filter(itm => !ids.includes(itm.id));
            const item = compMessages[compMessages.length - 1];
            if (item) {
              messages.unshift(item);
            }
          }

          resolve(messages);
        }, _.random(300, 800));
      });
    },
  },
};
