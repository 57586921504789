import {
  QForm,
  QBtn,
  QSelect,
  QIcon,
  QInput,
  QTable,
  QTooltip,
  QSlideTransition,
  QCheckbox,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import VNoResults from '@vjs/components/VNoResults';
import VTable from '@vjs/components/VTable/VTable.vue';

export default {
  name: 'VAdmissionResultList',
  components: {
    QForm,
    QBtn,
    QSelect,
    QInput,
    QTable,
    QIcon,
    QTooltip,
    VNoResults,
    VTable,
    QSlideTransition,
    QCheckbox,
  },
  props: {
    uriGetData: {
      type: String,
      required: true,
    },
    uriGetRefs: {
      type: String,
      required: true,
    },
    defaultFilters: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      table: {
        rows: [],
        columns: [],
      },
      pagination: {
        rowsPerPage: 20,
      },
      filter: '',
      loading: false,
      selectFilters: true,
      noResults: false,
      filters: {
        eduLevel: null,
        qualification: null,
        specialty: null,
        isPpz: 0,
      },
      options: {
        eduLevel: [],
        specialty: [],
        qualification: [],
        isPpz: [],
      },
    };
  },

  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'placeholder',
      'warning_text',
      'table',
      'fields',
      'statement',
    ]);

    const {
      eduLevel,
      specialty,
      qualification,
      filterField,
      isPpz,
    } = this.defaultFilters;
    this.filters.eduLevel = eduLevel;
    this.filters.specialty = specialty;
    this.filters.qualification = qualification;
    this.filter = filterField;
    this.isPpz = isPpz;

    await this.requestGetRefs();

    if (
      this.filters.eduLevel
      && this.filters.specialty
      && this.filters.qualification
    ) {
      await this.requestGetTable();
    }
  },

  computed: {
    validateFilterBtn() {
      return this.filters.eduLevel !== null
      && this.filters.qualification !== null
      && this.filters.specialty !== null;
    },
    isTableFlat() {
      if (this.filters.eduLevel === 3) {
        return this.$q.screen.width > 1024;
      }
      return this.$q.screen.width > 1140;
    },
    isTableGrid() {
      if (this.filters.eduLevel === 3) {
        return this.$q.screen.width <= 1024;
      }
      return this.$q.screen.width <= 1140;
    },
  },

  methods: {
    async requestGetTable() {
      this.$globalLoading.show();
      this.loading = true;
      this.filter = '';
      this.selectFilters = false;
      const res = await requestWrapper.call(this, {
        url: this.uriGetData,
        params: this.filters,
      });
      if (!res.error) {
        this.table = res;
        this.noResults = res.rows.length === 0;
      }
      this.loading = false;
      this.$globalLoading.hide();
    },
    async inputGetRefs(type) {
      switch (type) {
        case 'eduLevel':
          this.filters.specialty = null;
          this.filters.qualification = null;
          break;
        case 'specialty':
          this.filters.qualification = null;
          break;
      }

      await this.requestGetRefs();
    },
    async requestGetRefs() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.uriGetRefs,
        params: this.filters,
      });
      if (!res.error) {
        this.options = res;
      }
      this.$globalLoading.hide();
    },
    clearFilters(fromPpzSelect = false) {
      this.$globalLoading.show();
      this.table.rows = [];
      this.table.cols = [];
      this.$globalLoading.hide();
      this.noResults = false;
      this.selectFilters = true;
      this.filters.qualification = null;
      this.filters.specialty = null;
      this.filter = '';
      if (fromPpzSelect === false) {
        this.options.qualification = [];
        this.options.specialty = [];
        this.filters.eduLevel = null;
        this.filters.isPpz = 0;
      }
    },
    async handlePpzInput() {
      if (this.filters.eduLevel) {
        this.clearFilters(true);
        await this.requestGetRefs();
      }
    },
  },
};
