<template>
  <div class="sc-curriculum-program__info">
    <v-curriculum-program-heading :name="trans('label.general_information')" />
    <common-info-table />
    <v-curriculum-program-heading :name="trans('curriculum.semester_hour_distribution')" />
    <hours-distribution-table ref="hoursTable" />
    <v-curriculum-program-heading :name="trans('curriculum.working_program_content')" />
    <program-content-table ref="contentTable" />
  </div>
</template>

<script>
import VCurriculumProgramHeading from './VCurriculumProgramHeading';
import CommonInfoTable from './Tables/CommonInfoTable';
import HoursDistributionTable from './Tables/HoursDistributionTable';
import ProgramContentTable from './Tables/ProgramContentTable';

export default {
  name: 'VCurriculumProgramInfo',
  components: {
    VCurriculumProgramHeading,
    CommonInfoTable,
    HoursDistributionTable,
    ProgramContentTable,
  },
};
</script>
