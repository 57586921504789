import { Helper } from '@common/src/helpers';

export default {
  makeProgramUrl: (url, parentId) => Helper.urlGenerate(url, { parentId }),
  vm: null,
  curriculumProgramApiConfig: {},
  parentId: null,
  commonInfo: {},
  duplicatePrograms: [],
  semesterNumber: null,
  courseNumber: null,
  disciplines: [],
  programDisciplineId: null,
  isArchived: false,
  content: {
    meta: [],
    items: [],
  },
};
