import { FormField, StoreTranslator } from '@common/mixins';
import RecordRTC, { MediaStreamRecorder } from 'recordrtc';
import { Upload as TusUpload } from 'tus-js-client';
import ysFixWebmDuration from 'fix-webm-duration';
import { Helper } from '@common/src/helpers';
import VTimer from './VTimer';

export default {
  mixins: [StoreTranslator, FormField],
  components: { VTimer },
  name: 'voice-recorder',
  props: {
    config: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      recorder: null,
      currentStream: null,
      srcUrl: null,
      maxRecordingDuration: 6 * 1000 * 60,
      // FOR TESTING:
      // maxRecordingDuration: 0.05 * 1000 * 60,
      loading: false,
      isPlaying: false,
      currentAudioPlayTime: 0,
      currentAudioPlayTimeInterval: null,
      audioDuration: 0,
      audioDurationInterval: null,
      audioStartTime: 0,
    };
  },

  watch: {
    localValue(val) {
      if (val) {
        this.loading = false;
      }
    },
    audioDuration(val) {
      if (val >= this.maxRecordingDuration) {
        this.endRecording();
      }
    },
  },

  methods: {
    onAudioPlay() {
      this.currentAudioPlayTime = 0;
      this.$refs.audioRef.play();
      this.isPlaying = true;
      this.currentAudioPlayTimeInterval = setInterval(() => {
        this.currentAudioPlayTime = (this.$refs.audioRef?.currentTime ?? 0) * 1000; // текущая секунда аудио * 1000 мс
      }, 500);
      this.$refs.audioRef.onended = this.onAudioPause;
    },
    onAudioPause() {
      if (this.isPlaying) {
        clearInterval(this.currentAudioPlayTimeInterval);
        this.currentAudioPlayTime = 0;
        if (this.$refs.audioRef) {
          this.$refs.audioRef.pause();
          this.$refs.audioRef.currentTime = 0;
        }
        this.isPlaying = false;
      }
    },
    onClickStartRecord() {
      if (!this.isRecording && !this.field.value) {
        const vm = this;
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then((audioStream) => {
            vm.currentStream = audioStream;
            vm.recorder = RecordRTC(vm.currentStream, vm.computedConfig);
            vm.startRecording();
          }).catch(this.failRecording);
      }
    },
    onStopRecording() {
      const vm = this;
      this.loading = true;
      clearInterval(this.audioDurationInterval);
      this.audioDuration = Date.now() - this.audioStartTime;
      this.audioStartTime = null;
      const blob = this.recorder.getBlob();
      ysFixWebmDuration(blob, this.audioDuration, { logger: false }).then((fixedBlob) => {
        this.clearRecorder();
        this.srcUrl = URL.createObjectURL(fixedBlob);
        const fileName = (Math.random() * 1000).toString().replace('.', '');
        const extension = (navigator.mozGetUserMedia ? 'ogg' : 'wav');
        const metaData = {
          full_name: Helper.base64Encode(fileName),
          filename: `${fileName}.${extension}`,
          origin_name: fileName,
          filetype: 'audio',
        };

        const upload = new TusUpload(fixedBlob, {
          endpoint: '/tus/',
          retryDelays: [0, 3000, 5000, 10000, 20000],
          metadata: metaData,
          onError(error) {
            vm.loading = false;
            console.error(error);
          },
          onSuccess(xhr) {
            const uuid = xhr.getHeader('X-File-UUID');
            if (uuid) {
              vm.localValue = uuid;
              vm.$emit('input', uuid);
            }
          },
        });

        upload.start();
      });
    },
    onResetRecording() {
      this.onAudioPause();
      this.clearRecorder();
      clearInterval(this.audioDurationInterval);
      this.audioStartTime = null;
      this.localValue = null;
      this.audioDuration = 0;
      this.$emit('input', null);
    },
    startRecording() {
      this.recorder.setRecordingDuration(this.maxRecordingDuration)
        .onRecordingStopped(() => this.onStopRecording());
      this.recorder.startRecording();
      this.audioStartTime = Date.now();
      this.audioDurationInterval = setInterval(() => {
        this.audioDuration = Date.now() - this.audioStartTime;
      }, 1000);
      this.$emit('startRecording', this.recorder);
    },
    endRecording() {
      this.recorder.stopRecording(() => this.onStopRecording());
    },
    clearRecorder() {
      if (this.currentStream && this.currentStream.stop) {
        this.currentStream.stop();
        this.currentStream = null;
      }

      this.recorder = null;
      this.srcUrl = null;
    },
    failRecording(error) {
      show_notice(this.trans('notice.voice_recording_error'), 'error');
      this.$emit('errorRecording', error);
      this.clearRecorder();
    },
  },
  computed: {
    computedConfig() {
      const recorderType = MediaStreamRecorder;

      const defaultConfig = {
        type: 'audio',
        recorderType,
        mimeType: 'audio/mp4',
        disableLogs: true,
      };

      return { ...this.config, ...defaultConfig };
    },
    isRecording() {
      if (!this.recorder) {
        return false;
      }

      return this.recorder.getState() === 'recording';
    },
  },
};
