<template>
  <div class="s-test-process__status">
    <div class="s-test-process__status-label">
      {{ $trans.get('test.test') }}
    </div>
    <div
      class="s-test-process__status-value"
      :class="`is-${status}`"
    >
      {{ statusTitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'VTestStatus',
  computed: {
    status() {
      return this.$store.state.test.status;
    },
    statusTitle() {
      return this.$trans.get(`test.${this.status}`);
    },
  },
};
</script>
