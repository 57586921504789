import _ from 'lodash';
import { requestWrapper } from '@vjs/helpers';
import {
  QBtn,
  QCheckbox,
  QField,
  QForm,
  QIcon,
  QInput,
  QOptionGroup,
  QRadio,
  QSelect,
  QSlideTransition,
  QTooltip,
} from '@quasar/components';
import VFormInform from '@vjs/components/VFormInform';
import eventHub from '@vjs/config/eventHub';
import Validation from '../mixins/Validation';
import Files from '../mixins/Files';
import SelectUserFile from '../../SelectUserFile';

export default {
  name: 'VStepUserinfo',

  components: {
    VFormInform,
    QTooltip,
    QForm,
    QInput,
    QBtn,
    QSelect,
    QField,
    QRadio,
    QOptionGroup,
    QIcon,
    QCheckbox,
    QSlideTransition,
    SelectUserFile,
  },

  mixins: [
    Validation,
    Files,
  ],

  props: {
    uriGetProfile: {
      type: String,
      required: true,
    },
    uriSendProfileUpdate: {
      type: String,
      required: true,
    },
    uriProfileOptions: {
      type: String,
      required: true,
    },
    uriEducationOptions: {
      type: String,
      required: true,
    },
    profileExists: {
      type: Boolean,
      required: true,
    },
    forImport: false,
  },

  data() {
    return {
      access: false,
      loading: false,
      iinAfterRequest: null,
      form: {
        iin: '',
        lastname: '',
        firstname: '',
        surname: '',
        isFromCorrection: false,
        phone: '',
        address: '',
        citizenship_id: null,
        educational_institution: '',
        educational_institution_id: null,
        born_date: '',
        education: null,
        nationality_id: null,
        edu_lang: null,
        sex: null,
        doc_relation: [],
        // doc_for_privilege: [],
        isParent: false,
        forImport: false,
        soc_statuses: [],
        region: null,
        department: null,
        locality: null,
        hasInstitution: false,
        noInstitutionInList: false,
      },
      formBackup: {},
      options: {
        genders: [],
        nationality: [],
        citizenship: [],
        eduLevels: [],
        socialStatuses: [],
        eduLangs: [],
        regions: [],
      },
      educationOptions: {
        departments: [],
        localities: [],
        educationInstitutions: [],
      },
    };
  },

  created() {
    eventHub.$on('SET_NEXT_STEP', this.requestForm);
    eventHub.$on('addSocialStatus', (data) => {
      if (data.soc_status_id !== null) {
        if (!this.form.soc_statuses.filter(item => item.soc_status_id === data.soc_status_id).length) {
          this.form.soc_statuses.push(data);
        }
      } else {
        this.form.soc_statuses = [];
      }
    });
  },

  beforeDestroy() {
    eventHub.$off('SET_NEXT_STEP', this.requestForm);
  },

  async mounted() {
    this.$emit('loading', true);
    this.loading = true;
    if (this.forImport) {
      this.form.forImport = true;
    }
    await this.getOptions(); // получить все селекты
    if (this.profileExists === true && !this.forImport) {
      await this.requestGetData();
    }
    await this.getInstitution(null, false); // получить все селекты
    if (this.$store.state.vSteps.userInfo !== null) {
      if (this.forImport) {
        this.access = true;
      }
      this.form = this.$store.state.vSteps.userInfo;
    }
    if (this.$store.state.vSteps.userInfoSchool !== null) {
      this.options.regions = this.$store.state.vSteps.userInfoSchool[0];
      this.educationOptions.departments = this.$store.state.vSteps.userInfoSchool[1];
      this.educationOptions.localities = this.$store.state.vSteps.userInfoSchool[2];
      this.educationOptions.educationInstitutions = this.$store.state.vSteps.userInfoSchool[3];
    }
    this.loading = false;
    this.$emit('loading', false);
  },

  computed: {
    hasValidationFail() {
      const { validation } = this;
      return field => Array.isArray(validation[field]) && validation[field].length > 0;
    },
    checkIinDisable() {
      return !this.validateIin(this.form.iin);
    },
    checkIinBtnDisable() {
      return !this.checkIinDisable && !this.access;
    },
    validate() {
      let check = true;
      if (!this.form.noInstitutionInList) {
        check = check && this.form.educational_institution_id != null;
      } else {
        check = check && this.form.educational_institution !== '';
      }
      return check
        && this.form.education !== null
        && this.form.edu_lang !== null
        && this.form.nationality_id !== null
        && this.form.citizenship_id !== null
        && this.form.sex !== null
        && ((this.form.phone.length > 17 && this.form.address.length) || this.form.isFromCorrection)
        && (!this.form.isParent || this.form.doc_relation.length > 0);
    },
  },

  watch: {
    validate(val) {
      this.$store.commit('SET_ACCESS_STEP', {
        step: 3,
        access: val,
      });
    },
  },

  methods: {
    clearNextStep() {
      this.$store.commit('SET_QUALIFICATION_INFO', null);
      this.$store.commit('SET_QUALIFICATION_FORM_VALUES', null);
    },
    triggerSocialStatus() {
      this.$sModal.open('v-statement-social-status-modal', {
        component: 'v-statement-social-status-modal',
        notPersistent: true,
        binds: {
          uriUserFiles: this.uriUserFiles,
          socialStatuses: this.options.socialStatuses,
        },
      });
    },
    deleteStatus(socStatusId) {
      this.form.soc_statuses = this.form.soc_statuses.filter(item => item.soc_status_id !== socStatusId);
    },
    cleanInstitutions() {
      this.form.educational_institution = '';
      this.form.educational_institution_id = null;
      this.form.region = null;
      this.form.department = null;
      this.form.locality = null;
    },
    async getOptions() {
      const res = await requestWrapper.call(this, {
        url: this.uriProfileOptions,
      });
      if (!res.error) {
        this.options = res.data;
      }
    },
    async getInstitution(val, isUpdate = true) { // получить селекты для школ
      if (val === 'region') {
        this.form.department = null;
        this.form.locality = null;
        this.form.educational_institution = '';
        this.form.educational_institution_id = null;
      }
      const res = await requestWrapper.call(this, {
        url: this.uriEducationOptions,
        params: {
          iin: this.form.iin,
          department: this.form.department,
          region: this.form.region,
          locality: this.form.locality,
          isUpdate,
        },
      });
      if (!res.error) {
        this.educationOptions = res.data;
      }
    },
    async requestGetData() { // получить данные профиля
      const res = await requestWrapper.call(this, {
        url: this.uriGetProfile,
      });

      if (res.error) {
        if (res.data.errors) {
          this.$notify({
            text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
            type: 'error',
          });
        }
        return false;
      }

      if (!res.error) {
        this.access = true;
        this.form = res.data;
        this.$set(this.form, 'soc_statuses', []);
        // this.$set(this.form, 'doc_for_privilege', []);
        this.formBackup = this.form;
      }
    },
    async requestForCheckIin() { // законный представитель
      if (this.form.iin.length !== 12) {
        return;
      }
      if (!this.loading && this.checkIinBtnDisable) {
        this.loading = true;
        const res = await requestWrapper.call(this, {
          url: this.uriGetProfile,
          params: {
            iin: this.form.iin,
          },
        });
        if (res.error) {
          if (res.data.errors) {
            this.$notify({
              text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
              type: 'error',
            });
          }
          this.loading = false;
          return false;
        }
        if (!res.error) {
          this.access = true;
          this.form = res.data;
          this.$set(this.form, 'soc_statuses', []);
          // this.$set(this.form, 'doc_for_privilege', []);
        }
        await this.getInstitution(); // получить все селекты
        this.loading = false;
      }
    },
    handleIsParentCheckbox(val) { // обнулить все если законный представитель
      if (val) {
        this.formBackup = this.form;
        this.form = {
          iin: '',
          lastname: '',
          firstname: '',
          surname: '',
          phone: '',
          address: '',
          region: null,
          department: null,
          locality: null,
          citizenship_id: null,
          educational_institution: '',
          educational_institution_id: null,
          born_date: '',
          education: null,
          nationality_id: null,
          sex: null,
          doc_relation: [],
          // doc_for_privilege: [],
          user_id: null,
          isParent: true,
          soc_statuses: [],
          hasInstitution: false,
          edu_lang: null,
          noInstitutionInList: false,
        };
        this.access = false;
        return true;
      }
      this.form = this.formBackup;
      this.form.isParent = false;
      this.access = true;
    },
    async requestForm() { // обновить данные юзера
      this.$emit('loading', true);
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriSendProfileUpdate,
        data: this.form,
      });

      if (res.error) {
        if (res.data.errors) {
          this.$notify({
            text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
            type: 'error',
          });
        }
        this.$emit('loading', false);
        return false;
      }
      const optionsForSave = [
        this.options.regions,
        this.educationOptions.departments,
        this.educationOptions.localities,
        this.educationOptions.educationInstitutions,
      ];
      this.$store.commit('SET_USER_INFO', this.form);
      this.$store.commit('SET_USER_INFO_SCHOOL', optionsForSave);
      this.$store.commit('SET_NEXT_STEP');
      this.$emit('loading', false);
      return true;
    },
  },
};
