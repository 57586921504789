import {
  QForm,
  QBtn,
  QSelect,
  QIcon,
  QInput,
  QTable,
  QTooltip,
  QSlideTransition,
  QCheckbox,
  QTr,
  QTd,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import VNoResults from '@vjs/components/VNoResults';
import VTable from '@vjs/components/VTable/VTable.vue';

export default {
  name: 'VAdmissionResultList',
  components: {
    QForm,
    QBtn,
    QSelect,
    QInput,
    QTable,
    QIcon,
    QTooltip,
    VNoResults,
    VTable,
    QSlideTransition,
    QCheckbox,
    QTr,
    QTd,
  },
  props: {
    uriGetData: {
      type: String,
      required: true,
    },
    backLink: {
      type: String,
      required: true,
    },
    tableName: {
      type: String,
      required: false,
    },
    uriGetRefs: {
      type: String,
      required: true,
    },
    defaultFilters: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showFilter: false,
      table: {
        rows: [],
        columns: [],
      },
      pagination: {
        rowsPerPage: 20,
      },
      filter: '',
      loading: false,
      selectFilters: true,
      noResults: false,
      filters: {
        eduLevel: null,
        organization: null,
        qualification: null,
      },
      options: {
        eduLevel: [],
        organization: [],
        qualification: [],
      },
    };
  },

  async mounted() {
    await this.$trans.add([
      'main',
      'label',
      'button',
      'placeholder',
      'warning_text',
      'statement',
    ]);

    const {
      eduLevel,
      organization,
      qualification,
      filterField,
    } = this.defaultFilters;
    this.filters.eduLevel = eduLevel;
    this.filters.organization = organization;
    this.filters.qualification = qualification;
    this.filter = filterField;

    await this.requestGetRefs();
    await this.requestGetTable();
  },

  computed: {
    validateFilterBtn() {
      return this.filters.eduLevel !== null
      || this.filters.qualification !== null
      || this.filters.organization !== null;
    },
    isTableFlat() {
      return this.filters.eduLevel === 3 ? this.$q.screen.width > 1024 : this.$q.screen.width > 1140;
    },
    isTableGrid() {
      return this.filters.eduLevel === 3 ? this.$q.screen.width <= 1024 : this.$q.screen.width <= 1140;
    },
  },

  methods: {
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    searchByName(rows) {
      return rows.filter(row => row.fullName.toLowerCase().includes(this.filter.toLowerCase()));
    },
    async requestGetTable() {
      this.$globalLoading.show();
      this.loading = true;
      this.filter = '';
      this.selectFilters = false;
      const res = await requestWrapper.call(this, {
        url: this.uriGetData,
        params: this.filters,
      });
      if (!res.error) {
        this.table = res;
        this.noResults = res.rows.length === 0;
      }
      this.loading = false;
      this.$globalLoading.hide();
    },
    async requestGetRefs() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.uriGetRefs,
        params: this.filters,
      });
      if (!res.error) {
        this.options = res;
      }
      this.$globalLoading.hide();
    },
    async clearFilters() {
      this.$globalLoading.show();
      this.noResults = false;
      this.selectFilters = true;
      this.filters.qualification = null;
      this.filters.eduLevel = null;
      this.filters.organization = null;
      this.filter = '';
      await this.requestGetTable();
      this.$globalLoading.hide();
    },
  },
};
