import {
  QBtn,
  QInput,
  QTable,
  QTd,
  QTh,
  QTr,
} from '@quasar/components';

export default {
  components: {
    QTable,
    QTh,
    QTr,
    QTd,
    QBtn,
    QInput,
  },
  props: {
    action: {
      type: String,
      required: true,
    },
    getAchievements: {
      type: String,
      required: true,
    },
    getOptions: {
      type: String,
      required: true,
    },
    fullname: {
      type: String,
      required: true,
    },
    getExistingData: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: [],
      achievements: [],
    };
  },
  async mounted() {
    this.$trans.add(['table']);
    const getOptionsConfig = {
      url: this.getOptions,
      variableName: 'options',
      fullRes: true,
      hardSet: true,
    };
    await requestGetDataHelper(this, getOptionsConfig);
    await this.requestGetAchievements();
  },
  computed: {
    cols() {
      return [
        {
          label: this.trans('table.direction'),
          field: 'type_of_direction',
          name: 'type_of_direction',
          align: 'center',
          style: 'min-width: 75px;',
        },
        {
          label: this.trans('table.name'),
          field: 'name',
          name: 'name',
          align: 'center',
          style: 'min-width: 200px;',
        },
        {
          label: this.trans('table.level'),
          field: 'event_lvl',
          name: 'event_lvl',
          align: 'center',
          style: 'min-width: 75px;',
        },
        {
          label: this.trans('table.date'),
          field: 'date_participation',
          name: 'date_participation',
          align: 'center',
          style: 'min-width: 75px;',
        },
        {
          label: this.trans('table.for_participating'),
          field: 'point',
          name: 'point',
          align: 'center',
          style: 'min-width: 75px;',
        },
        {
          label: this.trans('table.prize_winning_place'),
          field: 'reward',
          name: 'reward',
          align: 'center',
          style: 'min-width: 75px;',
        },
        {
          label: this.trans('table.prize_winning_point'),
          field: 'participating_point',
          name: 'participating_point',
          align: 'center',
          style: 'width: 75px;',
        },
        {
          label: this.trans('table.operations'),
          field: 'operations',
          name: 'operations',
          align: 'center',
          style: 'min-width: 140px;',
        },
      ];
    },
  },
  methods: {
    openModalAdd(item = null) {
      const onApprove = async () => {
        await this.requestGetAchievements();
      };
      const events = { onApprove };
      this.$sModal.open('v-contingent-social-achievement-add', {
        id: 'v-contingent-social-achievement-add',
        title: this.trans('notice.are_you_sure'),
        component: 'v-contingent-social-achievement-add',
        notPersistent: true,
        size: '720',
        binds: {
          achievementId: item?.id,
          action: item?.operations.update ?? this.action,
          options: this.options,
          getExistingData: this.getExistingData,
        },
        events,
      });
    },
    async requestGetAchievements() {
      const getAchievementsConfig = {
        url: this.getAchievements,
        variableName: 'achievements',
        hardSet: true,
      };
      await requestGetDataHelper(this, getAchievementsConfig);
    },
    isCalculatingColumn(name) {
      const calculatingCols = [
        'point',
        'participating_point',
      ];
      return calculatingCols.includes(name);
    },
    bottomRow(attribute) {
      if (this.isCalculatingColumn(attribute)) {
        let res = 0;
        let count = 0;
        this.achievements.forEach((value) => {
          if (value[attribute]) {
            count += 1;
            res += Number(value[attribute]);
          }
        });
        if (attribute === 'participating_point') {
          return res === 0 ? '' : res.toFixed(2);
        }
        return res ? Math.round((res / count) * 100) / 100 : '';
      }
      if (attribute === 'type_of_direction') {
        return this.trans('table.total_points');
      }
      if (attribute === 'operations') {
        return (this.bottomRow('point') + Number(this.bottomRow('participating_point')));
      }
      return '';
    },
  },
};
