<template>
  <div>
    <div class="s-group mt-20 s-group--wider justify-content-end">
      <template v-if="isWaiting">
        <button
          v-if="signXml === null"
          class="s-btn s-btn--thm-blue s-btn--w-150"
          :class="signXml === null ? 's-btn--thm-blue' : 's-btn--thm-green'"
          @click="sign()"
        >
          {{
            signXml === null
              ? trans('button.add_sign_ncalayer')
              : trans('button.add_signed_ncalayer')
          }}
        </button>
        <label
          v-else
          class="s-btn"
        >
          {{ trans('button.add_signed_ncalayer') }}
        </label>
      </template>
      <button
        v-if="isWaiting && isAcceptable"
        class="s-btn s-btn--thm-red s-btn--w-150"
        data-toggle="modal"
        :disabled="signXml === null"
        :data-target="'#modal-decline-external-statement-'+statement.id"
      >
        {{ trans('button.decline') }}
      </button>
      <template v-if="statement.average_point !== null">
        <button
          v-if="isLinkable"
          class="s-btn s-btn--thm-green s-btn--w-150"
          data-toggle="modal"
          :data-target="'#modal-confirm-external-statement-'+statement.id"
        >
          {{ trans('statement.link') }}
        </button>
      </template>
      <button
        v-if="isSocStatusAcceptable"
        class="s-btn s-btn--thm-orange s-btn--w-150"
        data-toggle="modal"
        :disabled="statement.average_point === null || signXml === null"
        :data-target="'#modal-accept-external-statement-'+statement.id"
        @click="isSocStatusModal = true"
      >
        {{ trans('button.approve_with_soc_status') }}
      </button>
      <button
        v-if="isAcceptable"
        class="s-btn s-btn--thm-green s-btn--w-150"
        data-toggle="modal"
        :disabled="statement.average_point === null || signXml === null"
        :data-target="'#modal-accept-external-statement-'+statement.id"
        @click="isSocStatusModal = false"
      >
        {{ trans('button.approve') }}
      </button>
      <a
        :href="uriCreateGrades"
        class="s-btn s-btn--thm-blue s-btn--w-150"
      >
        {{ statement.average_point !== null
          ? trans('button.see_grades')
          : trans('button.add_grades')
        }}
      </a>
    </div>
    <v-external-statement-sign-modals
      :external-statements-link="externalStatementsLink"
      :accept-external-request-url="acceptExternalRequestUrl"
      :external-statement-decline-url="externalStatementDeclineUrl"
      :accept-with-soc-status-url="acceptWithSocStatusUrl"
      :is-soc-status-modal="isSocStatusModal"
      :refuses="refuses"
      :qualifications="qualifications"
      :is-linkable="isLinkable"
      :sign-xml="signXml"
      :statement="statement"
      :csrf-token="csrfToken"
    />
  </div>
</template>

<script>
import { StoreTranslator } from '@common/mixins';
import Button from '@app_college/components/Legacy/Common/Button/Button';
import VExternalStatementSignModals
from '@app_college/components/Legacy/Views/VExternalStatementSign/Modals/VStatementSignModals.vue';

export default {
  name: 'VExternalStatementSignButtons',
  components: { Button, VExternalStatementSignModals },
  mixins: [StoreTranslator],
  props: {
    isWaiting: {
      type: String,
      required: true,
    },
    isLinkable: {
      type: String,
      required: true,
    },
    isAcceptable: {
      type: String,
      required: true,
    },
    isSocStatusAcceptable: {
      type: String,
      required: true,
    },
    csrfToken: {
      type: String,
      default: '',
    },
    statement: {
      type: Object,
      required: true,
    },
    uriCreateGrades: {
      type: String,
      required: true,
    },
    refuses: {
      type: Object,
      required: false,
    },
    qualifications: {
      type: Array,
      required: false,
    },
    externalStatementsLink: {
      type: String,
      required: true,
    },
    externalStatementDeclineUrl: {
      type: String,
      required: true,
    },
    acceptWithSocStatusUrl: {
      type: String,
      required: true,
    },
    acceptExternalRequestUrl: {
      type: String,
      required: true,
    },
    uriCheckSign: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      signXml: null,
      loading: false,
      isSocStatusModal: false,
    };
  },
  mounted() {
    this.signXml = this.statement.sign_xml_accept;
  },
  beforeCreate() {
    this.$trans.add(['statement', 'label', 'button', 'main', 'notice']);
  },
  methods: {
    async sign() {
      this.$emit('loading', true);
      const webSocket = new WebSocket('wss://127.0.0.1:13579/');
      let callback = null;
      const xmlToSign = '<status>Sign</status>';
      webSocket.onopen = (event) => {
        callback = (response) => {
          const vm = this;
          $.ajax({
            url: this.uriCheckSign,
            method: 'POST',
            dataType: 'JSON',
            data: {
              signXml: response.responseObject,
            },
            success() {
              vm.signXml = response.responseObject;
            },
            error(res) {
              vm.signXml = null;
              if (res.status === 422 || res.status === 423) {
                let errors = [];
                $.each(res.responseJSON.errors, (i, val) => {
                  errors = errors.concat(val);
                });
                show_notice(errors, 'error');
              }
            },
          });
          // this.signXml = response.responseObject;
          this.$emit('loading', false);
        };
        webSocket.send(JSON.stringify(
          {
            id: 1,
            module: 'kz.gov.pki.knca.commonUtils',
            method: 'signXml',
            args: ['PKCS12', 'SIGNATURE', xmlToSign, '', ''],
          },
        ));
      };
      webSocket.onclose = (event) => {
        if (event.wasClean) {
          console.log('connection has been closed');
        } else {
          console.log('Connection error');
          // TODO: переделать на нормальные notice
          show_notice(this.trans('notice.ncalayer_not_run'), 'error');
          this.$emit('loading', false);
        }
        console.log(`Code: ${event.code} Reason: ${event.reason}`);
      };
      webSocket.onmessage = (event) => {
        const result = JSON.parse(event.data);
        console.log(event);
        console.log(result);
        if (result != null) {
          if (result?.code === '200') {
            if (typeof callback === 'function') {
              callback(result);
            }
          } else if (result?.code === '500') {
            this.$emit('loading', false);
          }
        }
      };
    },
  },
};
</script>
