import VChat from '@common/components/VChat';
import Centrifuge from 'centrifuge';

export default {
  components: { VChat },
  data() {
    return {
      userId: window.core_project.user_id,
      centrifuge: null,
      centrifugeSubscription: null,
      socketChannelResponse: [],
      chatMessages: [],
      chatLoaded: false,
    };
  },
  methods: {
    initCentrifuge() {
      this.centrifuge = new Centrifuge(this.centrifugeConfig.endpoint, {
        subscribeEndpoint: '/broadcasting/auth',
        subscribeHeaders: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
      });

      this.centrifuge.setToken(this.centrifugeConfig.token);
      this.centrifugeSubscription = this.centrifuge.subscribe(this.centrifugeConfig.channel,
        (message) => {
          const event = message?.data?.event ?? '';
          const data = message?.data?.data ?? {};
          switch (event) {
            case 'new_chat_message':
              this.$set(this.chatMessages, this.chatMessages.length, data);
              break;
              case 'room_will_finished_soon':
                this.$notify({
                  text: data.message,
                  type: 'warn',
                })
              break;
            default:
          }
        });
      const { centrifuge, centrifugeSubscription } = this;

      centrifugeSubscription.on('subscribe', (message) => {
        console.log('subscribe');
        console.log(message);
      });

      centrifugeSubscription.on('error', (message) => {
        console.log('error');
        console.log(message);
      });

      centrifugeSubscription.on('join', (context) => {
        this.handleChannelPresence();
      });

      centrifugeSubscription.on('leave', (context) => {
        this.handleChannelPresence();
      });

      centrifuge.connect();
      centrifuge.on('connect', (context) => {
        this.handleChannelPresence();
      });
    },
    async loadChatMessages() {
      if (this.config?.chatConfig.index) {
        await requestGetDataHelper(this, {
          url: this.config.chatConfig.index,
          variableName: 'chatMessages',
          hardSet: true,
          fullRes: true,
        });
        this.chatLoaded = true;
      }
    },
    handleChannelPresence() {
      this.centrifuge.presence(this.centrifugeConfig.channel).then((resp) => {
        if (resp?.presence) {
          this.socketChannelResponse = _.uniq(_.map(resp.presence, 'user')) ?? [];
        }
      }, (err) => {
        console.log('presence error', err);
      });
    },
    readMessages() {
      this.$set(this, 'chatMessages', this.chatMessages.map(itm => ({ ...itm, isRead: true })));
    },
  },
};
