<template>
  <div>
    <div class="sc-settings__text">
      {{ title }}
    </div>
    <div class="row justify-content-center">
      <div class="col col-sm sn--text-center mt-5 mb-5">
        <v-wrapper-multi-files-field
          v-model="file"
          required
          equal
          :config="{
            max: 1,
            size: 10*1024*1024,
            accepts,
          }"
        />
        <q-btn
          width="20"
          :label="trans('button.save')"
          :disable="!file"
          color="green"
          @click="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ClosePopup } from '@quasar/directives';

export default {
  name: 'VUploadFileModal',
  directives: { ClosePopup },
  props: {
    title: {
      type: String,
      default: '',
    },
    accepts: {
      type: Array,
      default() {
        return ['pdf'];
      },
    },
  },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    handleSubmit() {
      if (this.file) {
        this.$emit('modalSubmit', this.file);
        this.$emit('onClose');
      } else {
        this.$notify({
          text: this.trans('notice.file_empty'),
          type: 'error',
        });
      }
    },
  },
};
</script>
