export default {
  methods: {
    getAttribute(item, attribute) {
      let value = _.get(item, attribute, '');
      if (_.isArray(value)) {
        let tmpValue = '';
        _.forEach(value, (itm) => {
          tmpValue += itm;
        });
        value = tmpValue;
      }
      return value;
    },
    itemIndexNumber(itemIndex) {
      return (this.currentPage - 1) * 15 + itemIndex + 1;
    },
  },
};
