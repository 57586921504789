<template>
  <div class="s-test-process__result">
    <div class="s-test-process__result-title">
      {{ $trans.get('test.test_is_over') }}
    </div>
    <div class="s-test-process__result-desc mb-10">
      {{ $trans.get('test.test_results_preview') }}
    </div>
    <div
      class="s-test-process__center-username"
      style="width: unset;"
    >
      {{ userName }}
    </div>
    <div class="s-test-process__result-columns">
      <div class="s-test-process__result-column">
        <div :class="qrClass">
          <vue-qr
            :text="TEST.qrText"
            :size="qrSize"
          />
        </div>
        <table class="s-test-process__result-table">
          <tbody>
            <tr>
              <td>{{ $trans.get('label.quiz_id') }}</td>
              <td>{{ TEST.id }}</td>
            </tr>
            <tr
              v-for="subject in SUBJECTS"
              :key="subject.id"
            >
              <td>{{ subject.name }}</td>
              <td
                :class="{ 'with-result': !isContingent}"
              >
                {{ subject.correct_count }} / {{ subject.question_count }}
                <span
                  v-if="!isContingent"
                  class="s-test-process__result-point"
                >
                  {{ `${subject.result}%` }}
                </span>
              </td>
            </tr>
            <tr>
              <td>{{ $trans.get('label.score') }} </td>
              <td>{{ TEST.totalCorrectCount }} / {{ TEST.totalQuestionCount }} </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="s-test-process__result-column">
        <v-diagram-ring
          v-if="percent >= 0"
          selector="s-test-process__result-pie"
          :percent="percent"
        />
      </div>
    </div>
    <div class="s-test-process__result-time-wrapper">
      <div class="s-test-process__result-time-label">
        {{ $trans.get('test.test_time_all') }}:
      </div>
      <div class="s-test-process__result-time-value">
        <i v-if="timeSpent.hours>0">{{ timeSpent.hours }}</i><span v-if="timeSpent.hours>0">{{ $trans.get('test.time_hours') }}</span>
        <i v-if="timeSpent.minutes>0">{{ timeSpent.minutes }}</i><span v-if="timeSpent.minutes>0">{{ $trans.get('test.time_minutes') }}</span>
        <i>{{ timeSpent.seconds }}</i><span>{{ $trans.get('test.time_seconds') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr';
import { mapGetters } from 'vuex';
import timeConvert from './helpers/timeConvert';
import VDiagramRing from './VDiagramRing.vue';

export default {
  name: 'VTestResult',
  components: { VDiagramRing, VueQr },
  props: {
    userName: {
      type: String,
      required: true,
    },
    isContingent: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    diagram: null,
    window: {
      width: '',
      height: '',
    },
  }),
  computed: {
    ...mapGetters(['SUBJECTS', 'TEST']),
    timeSpent() {
      return timeConvert(this.TEST.timeSpent * 1000);
    },
    percent() {
      const { totalCorrectCount, totalQuestionCount } = this.TEST;
      const percent = (totalCorrectCount / totalQuestionCount * 100).toFixed(1);
      return parseFloat(percent);
    },
    qrSize() {
      return this.window.width >= 600 ? 150 : 200;
    },
    qrClass() {
      return this.window.width >= 600 ? 's-to-right' : 's-to-center';
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
};
</script>
<style lang="scss" scoped>
  .s-test-process__result-point {
    position: absolute;
    right: 0;
  }

  .s-test-process__result-table {
    td {
      &:last-child {
        position: relative;
      }
    }
  }

  .with-result {
    width: 18rem !important;
  }
</style>
