import {
  QField, QBtn, QIcon, QItemLabel, QCheckbox,
} from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import _ from 'lodash';

export default {
  name: 'VStudentTranscript',

  components: {
    QIcon, QField, QBtn, QItemLabel, QCheckbox,
  },

  props: {
    apiConfig: {
      type: Object,
      default: () => ({
        action: '',
        getData: '',
        subjects: '',
        typesOfEvaluation: '',
        back: '',
        getContingent: '',
        submitDuplicate: '',
        educationSemesters: '',
        getEducationResults: '',
        getParentSubjects: '',
        addModuleDiscipline: '',
        getModuleIndexes: '',
        transcriptExportAction: '',
      }),
    },
    isNotGraduated: {
      type: Boolean,
      default: false,
    },
    exportFileName: {
      type: String,
      default: '',
    },
    contingentEngName: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      transcripts: [],
      contingents: [],
      educationResults: [],
      options: {
        typesOfEvaluation: [],
        subjects: [],
        educationSemesters: [],
        parentOptions: [],
        indexesOptions: [],
        indexesOptionsFiltered: [],
      },
      subjectSelectConfig: {
        loading: false,
        disableVirtualScrolling: false,
        currentPage: 1,
        lastPage: 1,
        inputValue: '',
      },
      canDownload: false,
    };
  },


  async beforeMount() {
    await this.$trans.add(['student_transcript', 'button', 'fields', 'warning_text', 'document_flow']);
  },
  async mounted() {
    await this.requestGetTypesOfEvaluation();
    await this.requestGetSubjects();
    await this.requestGetData();
    await this.requestGetEducationSemesters();
    await this.requestGetParentSubjects();
    await this.requestGetModuleIndexes();
    const getContingentsConfig = {
      url: this.apiConfig.getContingent, variableName: 'contingents', hardSet: true, fullRes: true,
    };
    const getEducationResultConfig = {
      url: this.apiConfig.getEducationResults, variableName: 'educationResults', hardSet: true, fullRes: true,
    };
    await requestGetDataHelper(this, getContingentsConfig);
    await requestGetDataHelper(this, getEducationResultConfig);
  },

  methods: {
    confirmChangePosition({ sourceKey, targetKey }) {
      const transcripts = _.cloneDeep(this.transcripts);
      const tmpSourceKey = sourceKey;
      let tmpTargetKey = targetKey;
      let targetDiscipline = {};
      if (tmpTargetKey >= 0) {
        targetDiscipline = transcripts[tmpTargetKey];
      } else {
        targetDiscipline = transcripts[tmpTargetKey + 1];
      }
      const sourceTranscript = transcripts[tmpSourceKey];
      if (targetDiscipline.childCount > 0 && targetKey >= 0) {
        tmpTargetKey += targetDiscipline.childCount;
      }
      if (sourceTranscript.childCount > 0) {
        const movementSubjects = [];
        for (let i = sourceTranscript.childCount; i >= 0; i -= 1) {
          movementSubjects.push(transcripts.splice(tmpSourceKey + i, 1)[0]);
        }
        if (targetKey < tmpSourceKey) {
          tmpTargetKey += 1;
          if (targetDiscipline.childCount <= 0) {
            tmpTargetKey = targetKey + 1;
          }
          for (let j = 0; j < movementSubjects.length; j += 1) {
            transcripts.splice(tmpTargetKey, 0, movementSubjects[j]);
          }
        } else {
          for (let k = 0; k < movementSubjects.length; k += 1) {
            transcripts.splice(tmpTargetKey - sourceTranscript.childCount, 0, movementSubjects[k]);
          }
        }
      } else {
        if (tmpTargetKey < tmpSourceKey) {
          tmpTargetKey += 1;
        }
        transcripts.splice(tmpTargetKey, 0, transcripts.splice(tmpSourceKey, 1)[0]);
      }
      this.$set(this, 'transcripts', transcripts);
    },
    handleGetTranscripts(transcripts) {
      this.$set(this, 'transcripts', transcripts);
    },
    handleAddEduResult(result) {
      const childCount = this.transcripts.filter(el => el.parent_id === result.parent_id).length;
      const parentKey = this.transcripts.findIndex(itm => itm.id === result.parent_id);
      const targetKey = parentKey + childCount + 1;
      const eduResult = this.educationResults.find(el => el.id === result.education_result_id);
      const res = {
        subjectId: eduResult.id,
        name: eduResult.name,
        name_ru: eduResult.name_ru,
        name_kz: eduResult.name_kz,
        parent_id: result.parent_id,
        is_result: 1,
        typeOfEvaluation: 0,
        point: null,
        is_from_diploma: !this.isNotGraduated,
        childCount: 0,
      };

      this.transcripts.splice(targetKey, 0, res);

      if (!this.transcripts[parentKey].childCount) {
        this.transcripts[parentKey].hours = null;
        this.transcripts[parentKey].credits = null;
        this.transcripts[parentKey].typeOfEvaluation = null;
        this.transcripts[parentKey].point = null;
      }
      this.transcripts[parentKey].childCount = childCount + 1;
    },
    handleTranscriptNumberInput(type, parent) {
      const targetKey = this.transcripts.findIndex(itm => itm.id === parent);
      const childs = this.transcripts.filter(el => el.parent_id === parent);
      let tmpVal = 0;
      _.forEach(childs, (itm) => {
        tmpVal += Number(itm[type]);
      });
      if (targetKey >= 0) {
        this.transcripts[targetKey][type] = Number(tmpVal);
      }
    },
    async requestSubmit() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.action,
        method: 'post',
        data: {
          transcripts: this.transcripts,
        },
      });

      if (res.error) {
        this.$globalLoading.hide();
        Helper.handlerResponseErrorNew(this, res);
      }
    },
    async requestGetData() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.getData,
      });

      if (!res.error) {
        this.transcripts = res.data ?? [];
        this.canDownload = this.transcripts.length;
      }
      this.$globalLoading.hide();
    },
    async requestGetTypesOfEvaluation() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.typesOfEvaluation,
      });

      if (!res.error) {
        this.options.typesOfEvaluation = res;
      }
      this.$globalLoading.hide();
    },
    async requestGetEducationSemesters() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.educationSemesters,
      });

      if (!res.error) {
        this.options.educationSemesters = res;
      }
      this.$globalLoading.hide();
    },
    async requestGetParentSubjects() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.getParentSubjects,
      });

      if (!res.error) {
        this.options.parentOptions = res.data;
      }
      this.$globalLoading.hide();
    },
    handleSelectSubject(val) {
      setTimeout(async () => {
        this.subjectSelectConfig.inputValue = '';
        const {
          id, name_ru, name_kz, name,
        } = val[0];
        this.transcripts.push({
          subjectId: id,
          name,
          name_ru,
          name_kz,
          parent_id: null,
          is_result: 0,
          typeOfEvaluation: 0,
          point: null,
          is_from_diploma: !this.isNotGraduated,
          educationModule: null,
          isModule: true,
        });
        if (this.subjectsComputed.length < 25) {
          await this.onSubScroll({ index: 99999 });
        }
      });
    },
    async requestGetModuleIndexes() {
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.getModuleIndexes,
      });
      if (!res.error) {
        this.options.indexesOptions = Object.values(res.data);
        this.options.indexesOptionsFiltered = this.options.indexesOptions;
      }
    },
    removeTranscript(index) {
      const deletedItem = this.transcripts[index];
      const parentIndex = this.transcripts.findIndex(itm => itm.id === deletedItem.parent_id);
      if (parentIndex >= 0) {
        const parent = this.transcripts[parentIndex];
        parent.childCount -= 1;
        parent.hours -= deletedItem.hours ?? 0;
        parent.credits -= deletedItem.credits ?? 0;
      }
      this.$delete(this.transcripts, index);
    },
    async requestGetSubjects(loadingOff = false) {
      this.subjectSelectConfig.loading = true;
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.subjects,
        params: {
          page: this.subjectSelectConfig.currentPage,
          search: this.subjectSelectConfig.inputValue.toLowerCase(),
        },
      });

      if (!res.error) {
        this.options.subjects = [...this.options.subjects, ...res.items];
        this.subjectSelectConfig.lastPage = res.lastPage;
      }
      if (loadingOff) {
        this.subjectSelectConfig.loading = false;
      }
    },
    // Обработка виртуал-скроллинга для селекта предметов
    async onSubScroll({ index }) {
      if (!this.subjectSelectConfig.loading
        && this.subjectSelectConfig.currentPage < this.subjectSelectConfig.lastPage
        && index >= (this.options.subjects.length - 21)
      ) {
        this.subjectSelectConfig.currentPage += 1;
        await this.requestGetSubjects(false);
        this.$nextTick(() => {
          this.$refs.subjectSelect.$refs['q-select-wrapper-ref'].refresh();
          this.subjectSelectConfig.loading = false;
        });
      }
    },
    async requestGetSubjectsByFilter() {
      return new Promise(async (res, rej) => {
        const result = await requestWrapper.call(this, {
          url: this.apiConfig.subjects,
          params: {
            page: 1,
            search: this.subjectSelectConfig.inputValue.toLowerCase(),
          },
        });

        if (!result.error) {
          res(result);
        } else {
          rej(result);
        }
      });
    },
    async requestSearchSubjects(val, update) {
      this.subjectSelectConfig.loading = true;
      this.subjectSelectConfig.currentPage = 1;
      this.requestGetSubjectsByFilter().then((res) => {
        update(() => {
          this.options.subjects = res.items;
          this.subjectSelectConfig.loading = false;
        });
      });
    },
    handleInput(transcript) {
      const min = 0;
      const max = 100;
      const value = Number(transcript.point);

      if (value < min) {
        transcript.point = min;
      } else if (value > max) {
        transcript.point = max;
      } else if (transcript.point !== value) {
        transcript.point = value; // Дабы избежать значений по типу '000'
      }
    },
    openTranscriptModuleDisciplineModal(transcript, transcriptIndex) {
      const onApprove = async (res) => {
        const transcripts = _.cloneDeep(this.transcripts);
        transcripts[transcriptIndex] = res.data[0];
        this.$set(this, 'transcripts', transcripts);
        await this.requestGetParentSubjects();
      };
      const events = { onApprove };

      this.$sModal.open('v-modal-confirm-new', {
        id: 'v-modal-confirm-new',
        title: this.trans('notice.are_you_sure'),
        component: 'v-modal-confirm-new',
        notPersistent: true,
        binds: {
          type: 'request',
          action: this.apiConfig.addModuleDiscipline,
          method: 'post',
          message: this.trans('student_transcript.module_save'),
          requestData: { ...transcript },
        },
        events,
      });
    },
    defaultFilterFn(val, update, options, optionsFiltered) {
      if (val === '') {
        update(() => {
          this.options[options] = this.options[optionsFiltered];
        });
        return;
      }
      update(() => {
        const needle = val.toLowerCase();
        this.options[options] = this.options[optionsFiltered]
          .filter(item => _.lowerCase(item.label).indexOf(needle) > -1);
      });
    },
    exportTranscript(enFullName) {
      this.$globalLoading.show();
      const config = {
        responseType: 'blob',
        params: {
          en_full_name: enFullName,
        },
      };
      this.$http.get(this.apiConfig.transcriptExportAction, config)
        .then(
          (response) => {
            this.$globalLoading.hide();
            const result = document.createElement('a');
            result.href = window.URL.createObjectURL(new Blob([response.data]));
            result.download = this.exportFileName;
            result.click();
            this.$emit('onClose');
          },
          (response) => {
            this.$globalLoading.hide();
            Helper.handlerResponseError(response);
          },
        );
    },
  },
  computed: {
    computedPointsCount() {
      let result = 0;
      _.forEach(this.transcripts, (itm) => {
        if (itm.point) {
          result += 1;
        }
      });
      return result;
    },
    selectedSubjects() {
      const result = [];
      _.forEach(this.transcripts, (item) => {
        result.push({
          id: item.subjectId,
          name: item.name,
          name_ru: item.name_ru,
          name_kz: item.name_kz,
          is_from_diploma: !this.isNotGraduated,
          point: item.point,
          educationModule: item.education_module_id,
        });
      });
      return result;
    },
    subjectsComputed() {
      if (this.options.subjects && !_.isEmpty(this.options.subjects)) {
        const selected = this.selectedSubjects.filter(item => item.point === null).map(item => ({
          id: item.id, name: item.name, name_ru: item.name_ru, name_kz: item.name_kz,
        }));
        return _.differenceWith(this.options.subjects, selected, _.isEqual);
      }
      return [];
    },
    points() {
      return [
        { id: 2, name: this.trans('student_transcript.types_of_evaluation.not_acceptable') },
        { id: 3, name: this.trans('student_transcript.types_of_evaluation.acceptable') },
        { id: 4, name: this.trans('student_transcript.types_of_evaluation.good') },
        { id: 5, name: this.trans('student_transcript.types_of_evaluation.perfect') },
      ];
    },
    creditNotCredit() {
      return [
        { id: 100, name: this.trans('student_transcript.types_of_evaluation.credit') },
        { id: 0, name: this.trans('student_transcript.types_of_evaluation.not_credit') },
      ];
    },
  },
};
