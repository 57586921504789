<template>
  <div>
    <h3 class="s-title s-upper s-title--right-line mb-20">
      <span>{{ trans('label.journal_plan.theme') }}</span>
    </h3>
    <div class="sc-journal-plan__panel">
      <table>
        <tbody>
          <template v-for="(item, key) in table">
            <tr
              v-if="item.value"
              :key="key"
              class="sc-journal-plan__row"
            >
              <td class="sc-journal-plan__row--label">
                {{ item.label }}:
              </td>
              <td
                class="sc-journal-plan__row--value"
                v-html="item.value"
              />
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { requestWrapper } from '@vjs/helpers';

export default {
  name: 'VJournalPlanInfo',
  props: {
    uriPlanInfo: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      table: [],
    };
  },
  async mounted() {
    await this.getInfoTable();
  },
  methods: {
    async getInfoTable() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.uriPlanInfo,
      });

      if (!res.error) {
        this.table = res.data;
      }
      this.$globalLoading.hide();
    },
  },
};
</script>
