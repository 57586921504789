import { StoreTranslator } from '@common/mixins';


export default {
  mixins: [StoreTranslator],
  props: {
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    refs: {
      type: [Array, Object],
      required: true,
    },
    urlBack: {
      type: String,
      required: false,
      default: null,
    },
    resource: {
      type: [Array, Object],
      required: false,
    },
    hides: {
      type: [Array, Object],
      required: false,
    },
    contingentId: {
      type: Number,
      required: false,
    },
  },
  data() {
    const vm = this;
    const fields = {
      // СВЕДЕНИЯ ОБ УСПЕВАЕМОСТИ
      semester_performance: {
        beforeTitle: 'label.progress_information',
        beforeTitleWithoutMarginTop: true,
        value: '',
        name: 'semester_performance',
        component: 'v-multi-select-field',
        labelTransKey: 'label.semester_performance',
        values: _.get(vm.refs, 'semesterPerformance', []),
        required: true,
        events: {
          change: () => {
            if (this.form.fields.semester_performance.value === '05') {
              this.form.fields.reason.v_show = true;
            } else {
              this.form.fields.reason.v_show = false;
              this.form.fields.reason.value = '';
            }
          },
        },
      },
      reason: {
        v_if(vm) {
          return vm.form.fields.semester_performance.value === '05';
        },
        value: '',
        name: 'reason',
        component: 'v-multi-select-field',
        labelTransKey: 'label.reason',
        values: _.get(vm.refs, 'reason', []),
        required: true,
      },
      sdo: {
        value: '',
        name: 'sdo',
        component: 'v-multi-select-field',
        labelTransKey: 'label.sdo',
        values: _.get(vm.refs, 'yesOrNo', []),
        required: true,
        events: {
          change: () => {
            if (this.form.fields.sdo.value) {
              this.form.fields.sdo_hour_use_lecture.v_show = true;
              this.form.fields.sdo_hour_use_homework.v_show = true;
            } else {
              this.form.fields.sdo_hour_use_lecture.v_show = false;
              this.form.fields.sdo_hour_use_lecture.value = '';
              this.form.fields.sdo_hour_use_homework.v_show = false;
              this.form.fields.sdo_hour_use_homework.value = '';
            }
          },
        },
      },
      sdo_hour_use_lecture: {
        v_if(vm) {
          return vm.form.fields.sdo.value;
        },
        value: '',
        name: 'sdo_hour_use_lecture',
        component: 'v-multi-select-field',
        labelTransKey: 'label.sdo_hour_use_lecture',
        values: _.get(vm.refs, 'sdoHourUseLecture', []),
        required: true,
      },
      sdo_hour_use_homework: {
        v_if(vm) {
          return vm.form.fields.sdo.value;
        },
        value: '',
        name: 'sdo_hour_use_homework',
        component: 'v-multi-select-field',
        labelTransKey: 'label.sdo_hour_use_homework',
        values: _.get(vm.refs, 'sdoHourUseHomework', []),
        required: true,
      },
      // СВЕДЕНИЯ О ПРОГРАММАХ ОБУЧЕНИЯ
      is_dual_training: {
        beforeTitle: 'label.edu_program_information',
        value: '',
        name: 'is_dual_training',
        component: 'v-multi-select-field',
        labelTransKey: 'label.is_dual_training',
        values: _.get(vm.refs, 'yesOrNo', []),
        required: true,
      },
      is_serpin: {
        value: '',
        name: 'is_serpin',
        component: 'v-multi-select-field',
        labelTransKey: 'label.is_serpin',
        values: _.get(vm.refs, 'yesOrNo', []),
        required: true,
      },
      is_productive_employment: {
        value: '',
        name: 'is_productive_employment',
        component: 'v-multi-select-field',
        labelTransKey: 'label.is_productive_employment',
        values: _.get(vm.refs, 'yesOrNo', []),
        required: true,
      },
      is_completed_training_at_competence_center: {
        value: '',
        name: 'is_completed_training_at_competence_center',
        component: 'v-multi-select-field',
        labelTransKey: 'label.is_completed_training_at_competence_center',
        values: _.get(vm.refs, 'yesOrNo', []),
        required: true,
      },
      study_working_program: {
        value: '',
        name: 'study_working_program',
        component: 'v-multi-select-field',
        labelTransKey: 'label.study_working_program',
        values: _.get(vm.refs, 'yesOrNo', []),
        required: true,
      },
      // ДОПОЛНИТЕЛЬНЫЕ СВЕДЕНИЯ
      dormitory_info: {
        beforeTitle: 'label.additional_information_new',
        value: '',
        name: 'dormitory_info',
        component: 'v-tree-select-field',
        labelTransKey: 'label.dormitory_info',
        values: _.get(vm.refs, 'dormitoryInfo', []),
        required: true,
        events: {
          change: () => {
            if (this.form.fields.dormitory_info.value === '1') {
              this.form.fields.dormitory_living.v_show = true;
            } else {
              this.form.fields.dormitory_living.v_show = false;
              this.form.fields.dormitory_living.value = '';
            }
          },
        },
      },
      dormitory_living: {
        v_show(vm) {
          return vm.form.fields.dormitory_info.value === '1';
        },
        value: '',
        name: 'dormitory_living',
        component: 'v-multi-select-field',
        labelTransKey: 'label.dormitory_living',
        values: _.get(vm.refs, 'dormitoryLiving', []),
        required: true,
      },
      hot_food: {
        v_show(vm) {
          return !vm.form.fields.buffet_meal.length;
        },
        value: [],
        name: 'hot_food',
        component: 'v-multi-select-field',
        labelTransKey: 'label.hot_food',
        values: _.get(vm.refs, 'hotFood', []),
        required: false,
        extraAttributes: {
          multiple: true,
        },
        events: {
          input: () => {
            if (this.form.fields.hot_food.value.length) {
              const hotFoodExists = this.form.fields.hot_food.value.filter(item => item.id === '1');
              const hotFoodFreeExists = this.form.fields.hot_food.value.filter(item => item.id === '2');
              if (hotFoodFreeExists.length && !hotFoodExists.length) {
                this.form.fields.hot_food.value.push(this.form.fields.hot_food.values[0]);
              }
              this.form.fields.buffet_meal.v_show = false;
              this.form.fields.buffet_meal.value = [];
            } else {
              this.form.fields.buffet_meal.v_show = true;
            }
          },
        },
      },
      buffet_meal: {
        v_show(vm) {
          return !vm.form.fields.hot_food.value.length;
        },
        value: [],
        name: 'buffet_meal',
        component: 'v-multi-select-field',
        labelTransKey: 'label.buffet_meal',
        values: _.get(vm.refs, 'buffetMeal', []),
        required: false,
        extraAttributes: {
          multiple: true,
        },
        events: {
          input: () => {
            if (this.form.fields.buffet_meal.value.length) {
              const buffetMealExists = this.form.fields.buffet_meal.value.filter(item => item.id === '01');
              const buffetMealFreeExists = this.form.fields.buffet_meal.value.filter(item => item.id === '02');
              if (buffetMealFreeExists.length && !buffetMealExists.length) {
                this.form.fields.buffet_meal.value.push(this.form.fields.buffet_meal.values[0]);
              }
              this.form.fields.hot_food.v_show = false;
              this.form.fields.hot_food.value = [];
            } else {
              this.form.fields.hot_food.v_show = true;
            }
          },
        },
      },
      cost_of_education: {
        value: '',
        name: 'cost_of_education',
        component: 'number-field',
        labelTransKey: 'label.cost_of_education',
        required: true,
      },
      is_assigned_scholarship: {
        value: '',
        name: 'is_assigned_scholarship',
        component: 'v-multi-select-field',
        labelTransKey: 'label.is_assigned_scholarship',
        values: _.get(vm.refs, 'yesOrNo', []),
        required: true,
        events: {
          change: () => {
            if (this.form.fields.is_assigned_scholarship.value === 0) {
              this.form.fields.increased_scholarship.v_show = false;
              this.form.fields.increased_scholarship.value = '';
              this.form.fields.scholarship_info.v_show = false;
              this.form.fields.scholarship_info.value = '';
            } else if (this.form.fields.is_assigned_scholarship.value === 1) {
              this.form.fields.increased_scholarship.v_show = true;
              this.form.fields.scholarship_info.v_show = true;
            }
          },
        },
      },
      scholarship_info: {
        v_if(vm) {
          return vm.form.fields.is_assigned_scholarship.value;
        },
        value: '',
        name: 'scholarship_info',
        component: 'number-field',
        labelTransKey: 'label.scholarship_info',
        required: true,
      },
      increased_scholarship: {
        v_if(vm) {
          return vm.form.fields.is_assigned_scholarship.value;
        },
        value: '',
        name: 'increased_scholarship',
        component: 'v-multi-select-field',
        labelTransKey: 'label.increased_scholarship',
        values: _.get(vm.refs, 'increasedScholarship', []),
        required: true,
      },
      device_exists: {
        value: '',
        name: 'device_exists',
        component: 'v-multi-select-field',
        labelTransKey: 'label.device_exists',
        values: _.get(vm.refs, 'deviceExists', []),
        required: true,
        events: {
          change: () => {
            if (this.form.fields.device_exists.value === '-1') {
              this.form.fields.ethernet_belongs.v_show = false;
              this.form.fields.ethernet_belongs.value = '';
              this.form.fields.ethernet_access.v_show = false;
              this.form.fields.ethernet_access.value = '';
            } else {
              this.form.fields.ethernet_belongs.v_show = true;
              this.form.fields.ethernet_access.v_show = true;
            }
          },
        },
      },
      ethernet_belongs: {
        v_if(vm) {
          return vm.form.fields.device_exists.value !== '-1';
        },
        value: '',
        name: 'ethernet_belongs',
        component: 'v-multi-select-field',
        labelTransKey: 'label.ethernet_belongs',
        values: _.get(vm.refs, 'ethernetBelongs', []),
        required: true,
      },
      ethernet_access: {
        v_if(vm) {
          return vm.form.fields.device_exists.value !== '-1';
        },
        value: '',
        name: 'ethernet_access',
        component: 'v-multi-select-field',
        labelTransKey: 'label.ethernet_access',
        values: _.get(vm.refs, 'yesOrNo', []),
        required: true,
      },
      // СВЕДЕНИЯ ОБ АКТИВНОСТИ
      practice_completed: {
        beforeTitle: 'label.activity_information',
        value: '',
        name: 'practice_completed',
        component: 'v-multi-select-field',
        labelTransKey: 'label.practice_completed',
        values: _.get(vm.refs, 'practiceCompleted', []),
        required: true,
        extraAttributes: {
          multiple: true,
        },
        events: {
          input: () => {
            if (this.form.fields.practice_completed.value.length > 1) {
              const isFalse = this.form.fields.practice_completed.value.filter(item => item.id === '-1');
              if (isFalse.length === 1) {
                this.form.fields.practice_completed.value = isFalse;
              }
            }
          },
        },
      },
      is_internship_completed: {
        value: '',
        name: 'is_internship_completed',
        component: 'v-multi-select-field',
        labelTransKey: 'label.is_internship_completed',
        values: _.get(vm.refs, 'yesOrNo', []),
        required: true,
      },
      participation_in_prof_training: {
        value: '',
        name: 'participation_in_prof_training',
        component: 'v-multi-select-field',
        labelTransKey: 'label.participation_in_prof_training',
        values: _.get(vm.refs, 'participationInProfTraining', []),
        required: true,
      },
      is_certificate_qualification: {
        value: '',
        name: 'is_certificate_qualification',
        component: 'v-multi-select-field',
        labelTransKey: 'label.is_certificate_qualification',
        values: _.get(vm.refs, 'yesOrNo', []),
        required: true,
      },
      // attended_circles_and_sections: {
      //   value: '',
      //   name: 'attended_circles_and_sections',
      //   component: 'v-multi-select-field',
      //   labelTransKey: 'label.attended_circles_and_sections',
      //   values: _.get(vm.refs, 'attendedCirclesAndSections', []),
      //   required: true,
      //   extraAttributes: {
      //     multiple: true,
      //   },
      // },
      is_socially_useful_activity: {
        value: '',
        name: 'is_socially_useful_activity',
        component: 'v-multi-select-field',
        labelTransKey: 'label.is_socially_useful_activity',
        values: _.get(vm.refs, 'yesOrNo', []),
        required: true,
      },
      passed_practice: {
        value: '',
        name: 'passed_practice',
        component: 'v-multi-select-field',
        labelTransKey: 'label.passed_practice',
        values: _.get(vm.refs, 'yesOrNo', []),
        required: true,
      },
      involved_in_zhas_sarbaz: {
        value: '',
        name: 'involved_in_zhas_sarbaz',
        component: 'v-multi-select-field',
        labelTransKey: 'label.involved_in_zhas_sarbaz',
        values: _.get(vm.refs, 'yesOrNo', []),
        required: true,
      },
      passed_independent_certification: {
        value: '',
        name: 'passed_independent_certification',
        component: 'v-multi-select-field',
        labelTransKey: 'label.passed_independent_certification',
        values: _.get(vm.refs, 'yesOrNo', []),
        required: true,
      },
      // СВЕДЕНИЯ О СОЦИАЛЬНОМ СТАТУСЕ
      registration_in_law_enforcement: {
        beforeTitle: 'label.social_status_information',
        value: '',
        name: 'registration_in_law_enforcement',
        component: 'v-multi-select-field',
        labelTransKey: 'label.registration_in_law_enforcement',
        values: _.get(vm.refs, 'registrationInLawEnforcement', []),
        required: true,
        extraAttributes: {
          multiple: true,
        },
        events: {
          input: () => {
            if (this.form.fields.registration_in_law_enforcement.value.length > 1) {
              const isFalse = this.form.fields.registration_in_law_enforcement.value.filter(item => item.id === '-1');
              if (isFalse.length === 1) {
                this.form.fields.registration_in_law_enforcement.value = isFalse;
              }
            }
          },
        },
      },
      types_of_violations: {
        value: '',
        name: 'types_of_violations',
        component: 'v-tree-select-field',
        labelTransKey: 'label.types_of_violations',
        values: _.get(vm.refs, 'typesOfViolations', []),
        required: true,
        extraAttributes: {
          multiple: true,
        },
        events: {
          input: () => {
            if (this.form.fields.types_of_violations.value.length > 1) {
              const isFalse = this.form.fields.types_of_violations.value.filter(item => item.id === '-1');
              if (isFalse.length === 1) {
                this.form.fields.types_of_violations.value = isFalse;
                this.form.fields.conclusion_num.value = '';
                this.form.fields.conclusion_date.value = '';
              } else {
                this.form.fields.conclusion_num.v_show = true;
                this.form.fields.conclusion_date.v_show = true;
              }
            }
          },
        },
      },
      is_orphan: {
        value: '',
        name: 'is_orphan',
        component: 'v-multi-select-field',
        labelTransKey: 'label.is_orphan',
        values: _.get(vm.refs, 'yesOrNo', []),
        required: true,
        events: {
          change: () => {
            if (!this.form.fields.is_orphan.value) {
              this.form.fields.without_parental_care.v_show = true;
            } else {
              this.form.fields.without_parental_care.v_show = false;
              this.form.fields.without_parental_care.value = '';
              this.form.fields.assistance_financial_and_material.value = '';
              this.form.fields.assistance_financial_and_material.values = _.get(this.refs, 'assistanceFinancialAndMaterial', []).filter(item => ['03', '08'].includes(item.id));
            }
          },
        },
      },
      without_parental_care: {
        v_show(vm) {
          return vm.form.fields.without_parental_care.value;
        },
        value: '',
        name: 'without_parental_care',
        component: 'v-multi-select-field',
        labelTransKey: 'label.without_parental_care',
        values: _.get(vm.refs, 'yesOrNo', []),
        required: false,
        events: {
          change: () => {
            if (!this.form.fields.is_orphan.value && !this.form.fields.without_parental_care.value) {
              this.form.fields.assistance_financial_and_material.value = '';
              this.form.fields.assistance_financial_and_material.values = _.get(this.refs, 'assistanceFinancialAndMaterial', []).filter(item => item.id !== '03');
            } else {
              this.form.fields.assistance_financial_and_material.value = '';
              this.form.fields.assistance_financial_and_material.values = _.get(this.refs, 'assistanceFinancialAndMaterial', []).filter(item => ['01', '03'].includes(item.id));
            }
          },
        },
      },
      disability: {
        value: '',
        name: 'disability',
        component: 'v-multi-select-field',
        labelTransKey: 'label.disability',
        values: _.get(vm.refs, 'yesOrNo', []),
        required: true,
        events: {
          change: () => {
            if (!this.form.fields.disability.value) {
              this.form.fields.disability_group.value = '';
              this.form.fields.disability_reason.value = '';
              this.form.fields.disability_term.value = '';
              this.form.fields.disability_date.value = '';
              this.form.fields.disability_term_before.value = '';
            }
          },
        },
      },
      disability_group: {
        v_if(vm) {
          return vm.form.fields.disability.value;
        },
        value: '',
        name: 'disability_group',
        component: 'v-multi-select-field',
        labelTransKey: 'label.disability_group',
        values: _.get(vm.refs, 'disabilityGroup', []),
        required: true,
      },
      disability_reason: {
        v_if(vm) {
          return vm.form.fields.disability.value;
        },
        value: '',
        name: 'disability_reason',
        component: 'v-multi-select-field',
        labelTransKey: 'label.disability_reason',
        values: _.get(vm.refs, 'disabilityReason', []),
        required: true,
      },
      disability_term: {
        v_if(vm) {
          return vm.form.fields.disability.value;
        },
        value: '',
        name: 'disability_term',
        component: 'v-multi-select-field',
        labelTransKey: 'label.disability_term',
        values: _.get(vm.refs, 'disabilityTerm', []),
        required: true,
      },
      disability_date: {
        v_if(vm) {
          return vm.form.fields.disability.value;
        },
        value: '',
        name: 'disability_date',
        component: 'date-field',
        labelTransKey: 'label.disability_date',
        required: true,
      },
      disability_term_before: {
        v_if(vm) {
          return vm.form.fields.disability.value;
        },
        value: '',
        name: 'disability_term_before',
        component: 'date-field',
        labelTransKey: 'label.disability_term_before',
        required: true,
      },
      conclusion_num: {
        v_if(vm) {
          return vm.form.fields.types_of_violations.value.length
              && vm.form.fields.types_of_violations.value.filter(item => item.id === '-1').length === 0;
        },
        value: '',
        name: 'conclusion_num',
        component: 'number-field',
        labelTransKey: 'label.conclusion_num',
        required: true,
      },
      conclusion_date: {
        v_if(vm) {
          return vm.form.fields.types_of_violations.value.length
              && vm.form.fields.types_of_violations.value.filter(item => item.id === '-1').length === 0;
        },
        value: '',
        name: 'conclusion_date',
        component: 'date-field',
        labelTransKey: 'label.conclusion_date',
        required: true,
      },
      assistance_targeted_social: {
        value: '',
        name: 'assistance_targeted_social',
        component: 'v-multi-select-field',
        labelTransKey: 'label.assistance_targeted_social',
        values: _.get(vm.refs, 'yesOrNo', []),
        required: true,
        events: {
          change: () => {
            this.form.fields.living_wage.value = '';
            this.form.fields.assistance_financial_and_material.value = '';
            if (this.form.fields.assistance_targeted_social.value) {
              this.form.fields.living_wage.values = _.get(this.refs, 'aspTypes', []);
            } else {
              this.form.fields.living_wage.values = _.get(this.refs, 'aspTypes', []).filter(item => item.id !== 'loPL');
            }
          },
        },
      },
      living_wage: {
        value: '',
        name: 'living_wage',
        component: 'v-multi-select-field',
        labelTransKey: 'label.living_wage',
        values: _.get(vm.refs, 'aspTypes', []),
        required: true,
        events: {
          change: () => {
            if (!this.form.fields.is_orphan.value && !this.form.fields.without_parental_care.value) {
              if (this.form.fields.living_wage.value === 'upLW') {
                this.form.fields.assistance_financial_and_material.value = '';
                this.form.fields.assistance_financial_and_material.values = _.get(this.refs, 'assistanceFinancialAndMaterial', []).filter(item => !['01', '02', '03'].includes(item.id));
              } else {
                if (this.form.fields.assistance_targeted_social.value) {
                  if (this.form.fields.living_wage.value === 'loLW') {
                    this.form.fields.assistance_financial_and_material.value = '';
                    this.form.fields.assistance_financial_and_material.values = _.get(this.refs, 'assistanceFinancialAndMaterial', []).filter(item => item.id === '02');
                  } else {
                    this.form.fields.assistance_financial_and_material.value = '';
                    this.form.fields.assistance_financial_and_material.values = _.get(this.refs, 'assistanceFinancialAndMaterial', []).filter(item => item.id === '01');
                  }
                } else {
                  if (this.form.fields.living_wage.value === 'loLW') {
                    this.form.fields.assistance_financial_and_material.value = '';
                    this.form.fields.assistance_financial_and_material.values = _.get(this.refs, 'assistanceFinancialAndMaterial', []).filter(item => item.id === '01');
                  } else {
                    this.form.fields.assistance_financial_and_material.values = _.get(this.refs, 'assistanceFinancialAndMaterial', []).filter(item => item.id !== '03');
                  }
                }
              }
            }
          },
        },
      },
      assistance_financial_and_material: {
        v_if(vm) {
          return vm.form.fields.assistance_targeted_social.value !== '';
        },
        value: '',
        name: 'assistance_financial_and_material',
        component: 'v-multi-select-field',
        labelTransKey: 'label.assistance_financial_and_material',
        values: [],
        required: true,
      },
      availability_quota: {
        value: '',
        name: 'availability_quota',
        component: 'v-multi-select-field',
        labelTransKey: 'label.availability_quota',
        values: _.get(vm.refs, 'quotaTypes', []),
        required: true,
      },
    };
    let is_orphan = false;
    let without_parental_care = false;
    let assistance_targeted_social = false;
    if (vm.resource !== undefined) {
      if (vm.resource.values) {
        _.forEach(vm.resource.values, (value, key) => {
          if (fields.hasOwnProperty(key)) {
            fields[key].value = value;
            if (key === 'assistance_targeted_social') {
              assistance_targeted_social = value;
              if (value === 1) {
                fields.living_wage.values = _.get(this.refs, 'aspTypes', []);
              } else {
                fields.living_wage.values = _.get(this.refs, 'aspTypes', []).filter(item => item.id !== 'loPL');
              }
            }
            if (key === 'is_orphan') {
              is_orphan = value;
              if (value === 1) {
                fields.assistance_financial_and_material.values = _.get(this.refs, 'assistanceFinancialAndMaterial', []).filter(item => ['03', '08'].includes(item.id));
              }
            }
            if (key === 'without_parental_care') {
              without_parental_care = value;
              if (!is_orphan && !value) {
                fields.assistance_financial_and_material.values = _.get(this.refs, 'assistanceFinancialAndMaterial', []).filter(item => item.id !== '03');
              } else {
                fields.assistance_financial_and_material.values = _.get(this.refs, 'assistanceFinancialAndMaterial', []).filter(item => ['01', '03'].includes(item.id));
              }
            }
            if (key === 'living_wage') {
              if (!is_orphan && !without_parental_care) {
                if (value === 'upLW') {
                  fields.assistance_financial_and_material.values = _.get(this.refs, 'assistanceFinancialAndMaterial', []).filter(item => !['01', '02', '03'].includes(item.id));
                } else {
                  if (assistance_targeted_social  ) {
                    if (value === 'loLW') {
                      fields.assistance_financial_and_material.values = _.get(this.refs, 'assistanceFinancialAndMaterial', []).filter(item => item.id === '02');
                    } else {
                      fields.assistance_financial_and_material.values = _.get(this.refs, 'assistanceFinancialAndMaterial', []).filter(item => item.id === '01');
                    }
                  } else {
                    if (value === 'loLW') {
                      fields.assistance_financial_and_material.values = _.get(this.refs, 'assistanceFinancialAndMaterial', []).filter(item => item.id === '01');
                    } else {
                      fields.assistance_financial_and_material.values = _.get(this.refs, 'assistanceFinancialAndMaterial', []).filter(item => item.id !== '03');
                    }
                  }
                }
              }
            }
          }
        });
      }
      if (vm.resource.disable) {
        _.forEach(vm.resource.disable, (attribute) => {
          if (fields.hasOwnProperty(attribute)) {
            _.set(fields, `${attribute}.extraAttributes.disabled`, true);
          }
        });
      }
    }
    if (vm.hides !== undefined) {
      _.forEach(vm.hides, (attribute) => {
        if (fields.hasOwnProperty(attribute)) {
          _.set(fields, `${attribute}.v_show`, false);
        }
      });
    }

    return {
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
          // TODO привести к одному варианту
          if (data.redirect) {
            window.location = data.redirect;
          }
        },
      },
    };
  },
  beforeCreate() {
    this.$trans.add(['fields', 'warning_text', 'placeholder', 'label', 'button', 'main', 'notice', 'cabinet', 'curriculum', 'table']);
  },
  methods: {
    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleVShow(field) {
      const vm = this;
      if (field.v_show !== undefined) {
        if (_.isFunction(field.v_show)) {
          return field.v_show(vm);
        }
        return field.v_show;
      }

      return true;
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(form.fields, (el, key) => {
        if (
          (!el.hasOwnProperty('v_show') || el.v_show !== false)
            && vm.handleVif(el) !== false
        ) {
          if ((typeof el.value === 'object') && el.value !== null) {
            const arrField = [];
            _.forEach(el.value, (arrEl) => {
              arrField.push(arrEl.id);
            });
            _.set(data, key, arrField);
          } else {
            _.set(data, vm.nameToDot(el.name), el.value);
          }
        }
      });
      _.set(data, 'contingentId', vm.contingentId);
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      vm.$globalLoading.show();

      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            if (errors.length > 5) {
              show_notice(vm.trans('notice.has_not_filled_fields'), 'error');
            } else {
              show_notice(errors, 'error');
            }
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
};
