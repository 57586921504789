import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import Validation from '@vjs/mixins/Validation';
import VAdmissionMembersSelect from './components/VAdmissionMembersSelect';

export default {
  name: 'VAdmissionStructure',
  mixins: [Validation],
  components: {
    VAdmissionMembersSelect,
  },

  props: {
    config: {
      type: Object,
      default: () => ({
        action: '',
        checkIin: '',
        back: '',
        getData: '',
      }),
    },
  },
  data() {
    const defaultCard = {
      members: [],
      date_from: null,
      date_to: null,
      time_from: null,
      time_to: null,
    };
    return {
      form: {
        visiting: _.cloneDeep(defaultCard),
        exam: _.cloneDeep(defaultCard),
        appeal: _.cloneDeep(defaultCard),
        housing: _.cloneDeep(defaultCard),
        transfer: _.cloneDeep(defaultCard),
      },
    };
  },

  async mounted() {
    await this.$trans.add(['label', 'button', 'placeholder', 'admission']);
    await requestGetDataHelper(this, {
      variableName: 'form',
      url: this.config.getData,
    });
  },

  computed: {
    timeOptions() {
      const vals = [
        '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30',
        '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30',
        '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30',
        '20:00', '20:30',
      ];

      return vals.map(itm => ({
        id: itm,
        name: itm,
      }));
    },
  },

  methods: {
    async submit() {
      this.validation = {};
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.config.action,
        method: 'post',
        data: this.form,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
  },
};
