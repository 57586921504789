import { Helper } from '@common/src/helpers';

export default {
  props: {
    action: {
      type: String,
      default: '',
    },
    method: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    contingent: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      form: {
        doneWork: null,
        date: {
          from: null,
          to: null,
        },
        note: null,
        contingent: this.contingent,
        type: this.type,
      },
    };
  },
  computed: {
    validate() {
      return this.form.doneWork && this.form.date.from && this.form.date.to;
    },
  },
  methods: {
    async handleAjaxFormSubmit() {
      this.$globalLoading.show();
      const data = this.form;
      const res = await requestWrapper.call(this, {
        url: this.action,
        method: this.method,
        data,
      });

      if (!res.error) {
        this.$emit('onClose');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
