<template>
  <q-form
    autocorrect="off"
    autocapitalize="off"
    autocomplete="off"
    spellcheck="false"
  >
    <v-wrapper-q-number-input
      v-model.number="grade"
      :extra-binds="{
        label: trans('practice.labels.grade'),
        config: {
          min: 0,
          max: 100,
        }
      }"
    />
    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :disabled="!grade"
        :label="trans('button.approve')"
        @click="approve"
      />
    </div>
  </q-form>
</template>

<script>
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';

export default {
  props: {
    action: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      grade: null,
    };
  },
  methods: {
    async approve() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.action,
        method: 'post',
        data: {
          grade: this.grade,
        },
      });

      if (!res.error) {
        this.$emit('onClose');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
    },
  },
};
</script>
