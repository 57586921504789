import { Form, StoreTranslator } from '@common/mixins';

export default {
  mixins: [StoreTranslator, Form],
  props: {
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      default: 'POST',
    },
  },
  data() {
    return {
      processSend: false,
      fields: {
        name: {
          value: null,
          name: 'name',
          component: 'text-field',
          labelTransKey: 'label.firstname',
          values: [],
          required: true,
        },
      },
    };
  },
  beforeCreate() {
    this.$trans.add([
      'label',
      'text',
      'button',
      'placeholder',
    ]);
  },
  mounted() {

  },

  destroyed() {
  },
  methods: {

  },
};
