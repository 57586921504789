import {
  getYoutubeID,
  youtube,
} from './youtube';

const REG_YOUTUBE = new RegExp('youtu');

const oembed = {
  youtube: [],
};

async function switcher() {
  Object.keys(oembed).forEach((item) => {
    if (item.length > 0) {
      switch (item) {
        case 'youtube':
          youtube(oembed[item]);
          break;
        default:
      }
    }
  });
}

const oembedTransform = () => {
  document.querySelectorAll('oembed[url]').forEach((item, itemIdx) => {
    const url = item.getAttribute('url');
    item.parentElement.setAttribute('id', `oembed-${itemIdx}`);
    if (REG_YOUTUBE.test(url)) {
      oembed.youtube.push({
        yID: getYoutubeID(url),
        domID: `oembed-${itemIdx}`,
      });
    }
  });

  switcher();
};

export default oembedTransform;
