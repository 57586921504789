import _ from 'lodash';

function checkVisibleStep(state, step) {
  let check = null;

  if (step > 0 && step <= Object.keys(state.steps).length) {
    check = _.find(state.steps, { step });
  }

  return check && check.visible;
}

export default {
  SET_CURRENT_STEP: (state, payload) => {
    state.currentStep = payload;
  },

  SET_PREV_STEP: (state) => {
    for (let idx = state.currentStep; idx > 0; idx -= 1) {
      if (checkVisibleStep(state, idx - 1)) {
        state.currentStep = idx - 1;
        return;
      }
    }
  },

  SET_NEXT_STEP: (state) => {
    for (let idx = state.currentStep; idx <= Object.keys(state.steps).length; idx += 1) {
      if (checkVisibleStep(state, idx + 1)) {
        state.currentStep = idx + 1;
        return;
      }
    }
  },
  SET_EDU_NAVIGATOR: (state, payload) => {
    state.eduNavigator = payload;
  },
  SET_USER_INFO: (state, payload) => {
    state.userInfo = payload;
  },
  SET_SIGN_XML: (state, payload) => {
    state.signXml = payload;
  },
  SET_USER_INFO_SCHOOL: (state, payload) => {
    state.userInfoSchool = payload;
  },
  SET_QUALIFICATION_INFO: (state, payload) => {
    state.qualificationInfo = payload;
  },
  SET_QUALIFICATION_FORM_VALUES: (state, payload) => {
    state.qualificationFormValues = payload;
  },
  SET_QUALIFICATION_OPTIONS: (state, payload) => {
    state.qualificationOptions = payload;
  },
  SET_SUBJECTS_FORM_VALUES: (state, payload) => {
    state.subjectsInfo = payload;
  },
  SET_VISIBLE_STEP: (state, payload = { step: 1, visible: true }) => {
    const step = _.find(state.steps, { step: payload.step });
    step.visible = payload.visible;
  },

  SET_HIDDEN_STEP: (state, payload = { step: 1, hidden: true }) => {
    const step = _.find(state.steps, { step: payload.step });
    step.hidden = payload.hidden;
  },

  SET_ACCESS_STEP: (state, payload = { step: 1, access: true }) => {
    const step = _.find(state.steps, { step: payload.step });
    if (step) {
      step.access = payload.access;
    }
  },

  SET_BACKWARD_STEP: (state, payload = { step: 1, backward: true }) => {
    const step = _.find(state.steps, { step: payload.step });
    if (step) {
      step.backward = payload.backward;
    }
  },
};
