<template>
  <div>
    <v-form-inform :message="trans('label.diploma_edit_margins_warn')" />


    <v-wrapper-q-number-input
      v-model.number="form.top"
      :extra-binds="{
        label: trans('label.diploma_margin_top'),
        counter: true,
        config: {
          withoutMask: true,
          min: -100,
          max: 100,
        }
      }"
    />

    <v-wrapper-q-number-input
      v-model.number="form.left"
      :extra-binds="{
        label: trans('label.diploma_margin_left'),
        counter: true,
        config: {
          withoutMask: true,
          min: -100,
          max: 100,
        }
      }"
    />


    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :label="trans('button.approve')"
        @click="approve"
      />
    </div>
  </div>
</template>

<script>
import { Helper } from '@common/src/helpers';

export default {
  props: {
    action: {
      type: String,
      default: '',
    },
    top: {
      type: Number,
      default: 0,
    },
    left: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: {
        top: this.top,
        left: this.left,
      },
    };
  },
  methods: {
    async approve() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.action,
        method: 'post',
        data: this.form,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
  },
};
</script>
