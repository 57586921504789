import {
  QForm,
  QInput,
  QBtn,
  QCheckbox,
  QSelect,
  QDialog,
} from '@quasar/components';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { required } from '@vjs/validations';
import eventHub from '@vjs/config/eventHub';
import VModal from '@vjs/components/VModal';
import VFormInform from '@vjs/components/VFormInform';

export default {
  components: {
    QForm,
    QInput,
    QBtn,
    QCheckbox,
    QSelect,
    QDialog,
    VModal,
    VFormInform,
  },
  props: {
    showWarning: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        initialData: '',
        language: 'ru',
        toolbar: {
          items: [
            'bold',
            'italic',
            'numberedList',
            'undo',
            'redo',
          ],
        },

      },
      modal: false,
      modalData: {},
      loading: false,
      bookLoaded: false,
      coverLoaded: false,
    };
  },
  watch: {
    modal(newValue) {
      if (newValue) {
        this.editorConfig.initialData = '';
        if (this.modalType === 'edit') {
          if (this.modalData.book_path) {
            this.bookLoaded = true;
            fetch(this.modalData.book_path)
              .then(res => res.blob())
              .then((blob) => {
                let extension = this.modalData.book_path.substring(this.modalData.book_path.lastIndexOf('.'));
                extension = extension.substr(0, extension.indexOf('?'));
                const file = new File([blob], `myblob${extension}`, { type: blob.type });
                this.$set(this.modalData, 'book', file);
                this.$store.commit('setBook', file);
              });
          }
          if (this.modalData.cover_path) {
            this.coverLoaded = true;
            fetch(this.modalData.cover_path)
              .then(res => res.blob())
              .then((blob) => {
                let extension = this.modalData.cover_path.substring(this.modalData.cover_path.lastIndexOf('.'));
                extension = extension.substr(0, extension.indexOf('?'));
                const file = new File([blob], `myblob${extension}`, { type: blob.type });
                this.$set(this.modalData, 'cover', file);
                this.$store.commit('setCover', file);
              });
          }
        }
      }
    },
    'modalData.year': function () {
      if (this.modalData.year > new Date().getFullYear()) {
        this.$set(this.modalData, 'year', new Date().getFullYear());
      }
    },
  },
  mounted() {
    eventHub.$on('stopLoading', (evt) => { this.loading = false; });
    eventHub.$on('startLoading', (evt) => { this.loading = true; });

    eventHub.$on('libraryOpenModal', (evt) => {
      if (!this.qualification) {
        this.$store.commit('setQualification', [{
          label: this.trans('placeholder.choose'),
          value: 0,
        }]);
      }
      this.$set(this, 'modalData', this.create_book);
      this.modal = evt;
      this.$nextTick(() => {
        if (this.$refs.myForm) {
          this.$refs.myForm.resetValidation();
        }
      });
    });
  },
  computed: {
    create_book() {
      return this.$store.state.library.create_book;
    },
    types() {
      return this.$store.state.library.types;
    },
    modalType() {
      return this.$store.state.library.modalType;
    },
    qualification() {
      return this.$store.state.library.qualification;
    },
    editBook() {
      return this.$store.state.library.book;
    },
    editCover() {
      return this.$store.state.library.cover;
    },
  },
  methods: {
    required: message => val => required(message)(val),
    validateYear(val) {
      if (val > new Date().getFullYear()) {
        return new Date().getFullYear();
      }
      return val;
    },
    handleLinkVisibleCheck() {
      this.modalData.bookLoaded = false;
      this.modalData.book_link = '';
      this.modalData.book = '';
    },
    getModalName() {
      let title = '';
      if (this.modalType === 'create') {
        title = this.trans('title.download_book');
        if (this.showWarning) {
          title = this.trans('title.college_size');
        }
      } else if (this.modalType === 'edit') {
        title = this.trans('title.edit_book');
      }

      return title;
    },
    sendForm() {
      this.$store.commit('setCreateBook', this.modalData);
      if (this.$store.state.library.modalType === 'create') {
        eventHub.$emit('libraryStoreBook');
      } else if (this.$store.state.library.modalType === 'edit') {
        eventHub.$emit('libraryUpdateBook');
      }
    },
    filterFn(val, update) {
      eventHub.$emit('libraryFilterFn', val, update);
    },
    abortFilterFn() {
      eventHub.$emit('libraryFilterAbortFn');
    },
    bookUpload() {
      if (this.modalType === 'edit') {
        this.bookLoaded = false;
      }
      if (this.$refs.file_book.files[0].size < 1024 * 1024 * 50) {
        this.$set(this.modalData, 'book', this.$refs.file_book.files[0]);
        this.$store.commit('setBook', this.$refs.file_book.files[0]);
      } else {
        this.$notify({
          text: this.trans('validation.max.file_mb', { max: 50 }),
          type: 'error',
        });
        this.$refs.file_book.value = '';
      }
    },
    coverUpload() {
      if (this.modalType === 'edit') {
        this.coverLoaded = false;
      }
      this.$set(this.modalData, 'cover', this.$refs.file_cover.files[0]);
      this.$store.commit('setCover', this.$refs.file_cover.files[0]);
    },
    getBookName() {
      if (this.modalType === 'edit' && this.bookLoaded) {
        return this.trans('button.change_book');
      }
      return this.modalData.book ? this.modalData.book.name : this.trans('button.download_book');
    },
    getCoverName() {
      if (this.modalType === 'edit' && this.coverLoaded) {
        return this.trans('button.change_cover');
      }
      return this.modalData.cover ? this.modalData.cover.name : this.trans('button.download_cover');
    },
  },
};
