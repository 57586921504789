import {
  QInput,
  QTable,
  QTr,
  QTh,
  QTd,
} from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import VConfirmDialogNew from '@vjs/modals/VConfirmDialogNew.vue';
import VNoResults from '@vjs/components/VNoResults';

export default {
  name: 'VGrantRefusalsList',
  components: {
    QInput,
    QTable,
    QTr,
    QTh,
    QTd,
    VConfirmDialogNew,
    VNoResults,
  },
  props: {
    uriLoadData: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      table: {
        rows: [],
        columns: [],
      },
      form: {
        message: '',
      },
      loading: false,
      pagination: {
        rowsPerPage: 20,
      },
      openModal: false,
      modalMethod: 'post',
      uriConfirm: '',
      withInput: false,
      loadingModal: false,
    };
  },
  async mounted() {
    await this.$trans.add([
      'label',
      'warning_text',
    ]);
    await this.loadData();
  },
  methods: {
    openConfirmModal(action) {
      this.openModal = true;
      this.uriConfirm = action.to;
      this.withInput = action.withInput;
    },
    async requestConfirmModal() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: this.modalMethod,
        url: this.uriConfirm,
        params: this.form,
      });
      if (!res.error) {
        this.openModal = false;
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      await this.loadData();
      this.$globalLoading.hide();
    },
    async loadData() {
      this.$globalLoading.show();
      this.loading = true;
      const res = await requestWrapper.call(this, {
        url: this.uriLoadData,
      });
      if (!res.error) {
        this.table = res;
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.loading = false;
      this.$globalLoading.hide();
    },
  },
};
