export default {
  methods: {
    checkEditValue(curriculum_working_discipline) {
      const vm = this;
      return vm.disciplinesEdits[curriculum_working_discipline.id] !== undefined;
    },
    hideEditValue(curriculum_working_discipline) {
      const vm = this;
      vm.$delete(vm.disciplinesEdits, curriculum_working_discipline.id);
    },
    urlGenerate(url, params) {
      let result_url = url;
      _.forEach(params, (value, key) => {
        result_url = result_url.replace(`:${key}`, value);
      });
      return result_url;
    },
    saveEditValue(curriculum_working_discipline, indexCode = null) {
      const vm = this;
      const disciplinesEdit = vm.disciplinesEdits[curriculum_working_discipline.id];
      if (!disciplinesEdit.education_discipline) {
        show_notice(this.$trans('warning_text.curriculum_discipline_required'), 'error');
        return;
      }
      vm.$set(curriculum_working_discipline, 'education_discipline', _.cloneDeep(disciplinesEdit.education_discipline));
      vm.$set(curriculum_working_discipline, 'education_result', _.cloneDeep(disciplinesEdit.education_discipline));
      vm.$set(curriculum_working_discipline, 'controlForm', _.cloneDeep(disciplinesEdit.controlForm));
      vm.$set(curriculum_working_discipline, 'duration', _.cloneDeep(disciplinesEdit.duration));
      vm.$set(curriculum_working_discipline, 'semester', _.cloneDeep(disciplinesEdit.semester));
      const data = {};
      data._token = window.core_project.csrfToken;
      data.education_discipline = _.cloneDeep(disciplinesEdit.education_discipline);
      data.controlForm = _.cloneDeep(disciplinesEdit.controlForm);
      data.duration = _.cloneDeep(disciplinesEdit.duration);
      data.semester = _.cloneDeep(disciplinesEdit.semester);
      if (indexCode) {
        data.indexCode = indexCode;
      }
      $.ajax({
        url: vm.urlGenerate(vm.urlDisciplineValuesUpdate, { id: curriculum_working_discipline.id }),
        method: 'PATCH',
        dataType: 'JSON',
        data,
        headers: {
          'Accept-Language': window.core_project.locale,
        },
        success(response) {
          vm.$delete(vm.disciplinesEdits, curriculum_working_discipline.id);
        },
        error(response) {
          vm.handleErrors(response);
        },
      });
    },
    showEditValue(curriculum_working_discipline) {
      const vm = this;
      const discipline = curriculum_working_discipline.is_result == 0
        ? curriculum_working_discipline.education_discipline
        : curriculum_working_discipline.education_result;
      vm.$set(vm.disciplinesEdits, curriculum_working_discipline.id, {
        controlForm: _.cloneDeep(curriculum_working_discipline.controlForm),
        duration: _.cloneDeep(curriculum_working_discipline.duration),
        semester: _.cloneDeep(curriculum_working_discipline.semester),
        education_discipline: discipline,
      });
    },

  },
};
