import {
  QForm,
  QInput,
  QBtn,
  QIcon,
  QToggle,
  QCheckbox,
  QSelect,
  QBtnToggle,
  QDialog,
  QPagination,
  QTooltip,
} from '@quasar/components';
import {
  genFormData,
} from '@vjs/helpers';
import eventHub from '@vjs/config/eventHub';
import VModal from '@vjs/components/VModal';
import _ from 'lodash';
import VLibraryDescription from './VLibraryDescription.vue';
import VLibraryModal from './VLibraryModal.vue';

export default {
  components: {
    VModal,
    QForm,
    QInput,
    QBtn,
    QIcon,
    QToggle,
    QCheckbox,
    QSelect,
    QBtnToggle,
    QDialog,
    QPagination,
    VLibraryDescription,
    VLibraryModal,
    QTooltip,
  },
  created() {
    this.$trans.add([
      'cabinet',
      'button',
      'placeholder',
      'label',
      'title',
      'validation',
      'notice',
      'warning_text',
      'cabinet',
    ]);
    this.$store.commit('setTypes', this.types);

    eventHub.$on('libraryFilterFn', (val, update) => {
      this.filterFn(val, update);
    });

    eventHub.$on('libraryFilterAbortFn', () => {
      this.abortFilterFn();
    });

    eventHub.$on('libraryStoreBook', () => {
      this.storeBook();
    });

    eventHub.$on('libraryUpdateBook', async () => {
      await this.updateBook();
      const { id } = this.$store.state.library.description;
      this.$store.commit('setDescription', this.books.filter(v => v.id === id)[0]);
    });

    eventHub.$on('openEditFromDesc', () => {
      this.setEditPopup(this.$store.state.library.description.id);
    });

    eventHub.$on('openDestroyFromDesc', () => {
      this.openSuccessModal(this.$store.state.library.description.id);
    });
  },
  data() {
    return {
      books: [],
      booksFilter: [],
      create_book: {},
      qualification: null,
      qualificationFilter: null,
      qualificationExist: false,
      filter: {
        profession_id: 0,
        type: '',
        textFilter: null,
      },
      modalType: 'create',
      pageCount: 1,
      currentPage: 1,
      elementsOnPage: 10,
      page: [],
      description: false,
      successModal: false,
      successDeleteId: '',
      noBooks: false,
    };
  },
  props: {
    postPath: {
      type: String,
      default: '/',
    },
    getLibraryPath: {
      type: String,
      default: '/',
    },
    getProfessionsPath: {
      type: String,
      default: '/',
    },
    getBook: {
      type: String,
      default: '/',
    },
    deleteBookPath: {
      type: String,
      default: '/',
    },
    updateBookPath: {
      type: String,
      default: '/',
    },
    types: {
      type: Object,
      default: {},
    },
    itsLibrarian: {
      type: Boolean,
      default: false,
    },
    canDeleteBook: {
      type: Boolean,
      default: false,
    },
    showWarning: {
      type: Boolean,
      default: false,
    },
  },
  name: 'VLibrary',
  mounted() {
    this.commitCreateBook();
    this.getLibrary();
    this.$store.commit('setQualification', this.qualification);
  },
  computed: {
    editBook() {
      return this.edit.book;
    },
  },
  watch: {
    books() {
      this.initPagination();
    },
    currentPage() {
      this.initPagination();
    },
    'filter.profession_id': function () {
      this.filterBooks();
      this.currentPage = 1;
    },
    'filter.textFilter': function () {
      this.filterBooks();
      this.currentPage = 1;
    },
    'filter.type': function () {
      this.filterBooks();
      this.currentPage = 1;
      if (this.filter.type === 2 && !this.qualification) {
        this.qualification = [{
          label: this.trans('placeholder.choose'),
          value: 0,
        }];
        this.qualificationFilter = this.qualification;
      }
    },
  },
  methods: {
    filterBooks() {
      this.books = this.booksFilter;
      if (this.filter.type) {
        this.books = this.books
          .filter(v => v.type === this.filter.type.toString());
      }
      if (this.filter.textFilter) {
        const textFilter = this.filter.textFilter.toLowerCase();
        this.books = this.books
          .filter(v => v.book_name.toLowerCase().includes(textFilter)
            || v.author.toLowerCase().includes(textFilter)
            || v.year.toString().includes(textFilter));
      }
      if (this.filter.profession_id) {
        this.books = this.books
          .filter(v => v.profession_id === this.filter.profession_id);
      }
    },
    openSuccessModal(path) {
      this.successModal = true;
      this.successDeleteId = path;
    },
    async doDelete() {
      await this.deleteBook(this.successDeleteId);
      this.successModal = false;
      this.description = false;
      this.$store.state.library.description = {};
    },
    commitCreateBook() {
      this.$store.commit('setCreateBook', this.setCreatedBook());
    },
    openCreateModal() {
      this.commitCreateBook();
      eventHub.$emit('libraryOpenModal', true);
      this.$store.commit('setModalType', 'create');
    },
    initPagination() {
      this.page = this.books.slice(
        this.elementsOnPage * (this.currentPage - 1),
        this.elementsOnPage * this.currentPage,
      );
      this.pageCount = Math.ceil(this.books.length / this.elementsOnPage);
    },
    setCreatedBook() {
      if (this.$refs.file_book && this.$refs.file_cover) {
        this.$refs.file_book.value = null;
        this.$refs.file_cover.value = null;
      }
      return {
        book_name: '',
        author: '',
        year: '',
        type: 1,
        profession_id: 0,
        visible_to_students: 0,
        link_visible: 0,
        book_link: '',
        book: '',
        cover: '',
        description: '',
      };
    },

    async getLibrary(afterStore = false) {
      try {
        const { data } = await this.$axios.get(this.getLibraryPath);
        this.books = data;
        this.booksFilter = data;
        this.filter.type = '';
      } catch (error) {
        const { errors } = error.response.data;
        this.$notify({
          text: errors[Object.keys(errors)[0]][0],
          type: 'error',
        });
      }
      if (afterStore) {
        this.getFilteredQualifications();
      }
      if (!this.books.length) {
        this.noBooks = true;
      }
    },

    filterFn(val, update) {
      if (this.qualification !== null && this.qualificationExist) {
        // already loaded
        update();
        return;
      }
      this.getProfessions(update);
    },

    abortFilterFn() {
      this.qualificationExist = false;
    },

    async getProfessions(update = null) {
      this.qualification = [{
        label: this.trans('placeholder.choose'),
        value: 0,
      }];
      try {
        const { data } = await this.$axios.get(this.getProfessionsPath);
        if (update) {
          update(() => {
            this.qualification = [...this.qualification, ...data];
            this.$store.commit('setQualification', this.qualification);
          });
        } else {
          this.qualification = [...this.qualification, ...data];
          this.$store.commit('setQualification', this.qualification);
        }
        this.qualificationExist = true;
        this.qualificationFilter = this.qualification;
        this.getFilteredQualifications();
      } catch (error) {
        const { errors } = error.response.data;
        this.$notify({
          text: errors[Object.keys(errors)[0]][0],
          type: 'error',
        });
      }
    },

    async deleteBook(id) {
      try {
        await this.$axios.get(`${this.deleteBookPath}/${id}`);
        this.getLibrary();
        this.$notify({
          text: this.trans('notice.book_deleted'),
          type: 'success',
        });
      } catch (error) {
        const errorNumber = error.message.replace(/\D+/g, '');
        if (errorNumber === '403') {
          this.$notify({
            text: this.trans('notice.error_of_access_delete'),
            type: 'error',
          });
        } else {
          this.$notify({
            text: this.trans('notice.error_on_server'),
            type: 'error',
          });
        }
      }
    },

    async storeBook() {
      try {
        eventHub.$emit('startLoading', true);
        this.$axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        await this.$axios.post(this.postPath, genFormData(this.$store.state.library.create_book));
        this.commitCreateBook();
        this.$notify({
          text: this.trans('notice.book_created'),
          type: 'success',
        });
        eventHub.$emit('libraryOpenModal', false);
        eventHub.$emit('stopLoading', true);
        this.noBooks = false;
      } catch (error) {
        const { errors } = error.response.data;
        eventHub.$emit('stopLoading', true);
        this.$notify({
          text: errors[Object.keys(errors)[0]][0],
          type: 'error',
        });
      }
      this.getLibrary(true);
    },

    async updateBook() {
      try {
        eventHub.$emit('startLoading', true);
        this.$axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        const patch = genFormData(this.$store.state.library.create_book);
        patch.append('_method', 'PATCH');
        await this.$axios.post(
          `${this.updateBookPath}/${this.$store.state.library.create_book.id}`, patch,
        );
        this.commitCreateBook();
        await this.getLibrary();
        this.$notify({
          text: this.trans('notice.book_saved'),
          type: 'success',
        });
        eventHub.$emit('libraryOpenModal', false);
        eventHub.$emit('stopLoading', true);
      } catch (error) {
        const { errors } = error.response.data;
        this.$notify({
          text: errors[Object.keys(errors)[0]][0],
          type: 'error',
        });
        eventHub.$emit('stopLoading', true);
      }
    },

    getFilteredQualifications() {
      const qual = this.books.filter(v => v.type === '2').map(q => q.profession_id);
      this.$set(
        this,
        'qualificationFilter',
        this.qualification.filter(v => _.includes(qual, v.value) || v.value === 0),
      );
    },

    setEditPopup(id) {
      this.modal = true;
      this.modalType = 'edit';
      const editedBook = _.cloneDeep(this.books.find(v => v.id === id));
      editedBook.book_link = editedBook.book_link || '';
      editedBook.type = parseInt(editedBook.type, 10);
      editedBook.year = editedBook.year.toString();
      editedBook.link_visible = editedBook.book_link ? 1 : 0;
      this.$set(this, 'create_book', editedBook);
      this.$store.commit('setModalType', this.modalType);
      this.$store.commit('setCreateBook', this.create_book);
      eventHub.$emit('libraryOpenModal', true);
      this.getProfessions();
    },
    getDescription(id) {
      this.description = true;
      this.$store.commit('setDescription', this.books.filter(v => v.id === id)[0]);
    },
  },
};
