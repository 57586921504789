import {
  QForm,
  QBtn,
  QSelect,
  QIcon,
  QInput,
  QTable,
  QTh,
  QTr,
  QTd,
  QTooltip,
  QSlideTransition,
  QCheckbox,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import VConfirmDialog from '@vjs/modals/VConfirmDialog.vue';
import VNoResults from '@vjs/components/VNoResults';
import VTable from '@vjs/components/VTable/VTable.vue';
import _ from 'lodash';

export default {
  name: 'VScheduleView',
  components: {
    QForm,
    QBtn,
    QSelect,
    QInput,
    QTable,
    QTh,
    QTr,
    QTd,
    QIcon,
    QTooltip,
    VNoResults,
    VTable,
    QSlideTransition,
    QCheckbox,
    VConfirmDialog,
  },
  props: {
    uriGetData: {
      type: String,
      required: true,
    },
    uriGetRefs: {
      type: String,
      required: true,
    },
    defaultFilters: {
      type: Object,
      required: false,
    },
    exportUrl: {
      required: false,
    },
  },
  data() {
    return {
      loader: null,
      loading: false,
      isDaysExists: true,
      days: [],
      filtersSelected: false,
      currentDate: null,
      filters: {
        teacher: null,
        currentDate: null,
      },
      filter: '',
      options: {
        teachers: [],
        teachersFiltered: [],
        dateRanges: [],
        dateRangesFiltered: [],
      },
      refs: {
        times: [],
        types: [],
      },

    };
  },

  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'placeholder',
      'warning_text',
      'table',
      'fields',
      'statement',
      'training_schedule',
    ]);

    const {
      teacher,
    } = this.defaultFilters;
    this.filters.teacher = teacher;
    await this.requestGetRefs();
    if (
      this.filters.teacher
    ) {
      await this.requestGetTable();
    }
  },

  computed: {
    validateFilterBtn() {
      return this.filters.teacher !== null;
    },
  },

  methods: {
    async requestGetTable() {
      this.$globalLoading.show();
      this.loading = true;
      const res = await requestWrapper.call(this, {
        url: this.uriGetData,
        params: this.filters,
      });
      if (!res.error) {
        this.filtersSelected = true;
        this.$set(this, 'days', res.days);
        this.$set(this.options, 'dateRanges', res.dateRanges);
        this.$set(this, 'currentDate', res.currentDate);
        this.$set(this.options, 'dateRangesFiltered', res.dateRanges);
        this.$set(this.refs, 'times', res.times);
        this.$set(this, 'isDaysExists', this.refs.times.length === 0);
      }
      this.loading = false;
      this.$globalLoading.hide();
    },
    onValueChange() {
      this.$set(this.refs, 'times', []);
      this.$set(this, 'days', []);
      this.filtersSelected = false;
      this.$set(this, 'currentDate', null);
      this.$set(this.filters, 'currentDate', null);
      this.$set(this, 'isDaysExists', this.refs.times.length === 0);
    },
    async requestGetRefs() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.uriGetRefs,
        params: this.filters,
      });
      if (!res.error) {
        this.options.teachers = res.teachers;
        this.options.teachersFiltered = res.teachers;
      }
      this.$globalLoading.hide();
    },
    defaultFilterFn(val, update, options, optionsFiltered) {
      if (val === '') {
        update(() => {
          this.options[options] = this.options[optionsFiltered];
        });
        return;
      }
      update(() => {
        const needle = val.toLowerCase();
        this.options[options] = this.options[optionsFiltered].filter(item => _.lowerCase(item.label).indexOf(needle) > -1);
      });
    },
    async loadData() {
      this.$set(this.filters, 'currentDate', this.currentDate);
      this.requestGetTable();
    },
    async exportDownload() {
      this.$globalLoading.show();
      const config = {
        responseType: 'blob',
        params: this.filters,
        method: 'GET',
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };

      this.$http.get(Helper.urlGenerate(this.exportUrl, { training_schedule: this.training_schedule }), config)
        .then(
          (response) => {
            this.$globalLoading.hide();
            const orig_filename = (response.headers.get('Content-Disposition') || '').split('filename=')[1];
            let filename = '';
            try {
              filename = decodeURIComponent(escape(orig_filename));
            } catch (e) {
              filename = orig_filename;
            }
            const result = document.createElement('a');
            result.href = window.URL.createObjectURL(response.body);
            result.download = filename;
            result.click();
          },
          (response) => {
            this.$globalLoading.hide();
            console.warn(response);
          },
        );
    },

  },
};
