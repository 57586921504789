import { Helper } from '@common/src/helpers';

export default {
  methods: {
    setModal(component, title = null, events = {}, binds = {}, preClose) {
      this.$sModal.open(component, {
        id: component,
        title,
        component,
        notPersistent: true,
        binds,
        events,
        preClose,
      });
    },
    async makeRequest(url, method, data) {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, { url, method, data });
      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      } else {
        await this.loadData(this.getData);
        eventBus.$emit('onCloseAll');
      }
      this.$globalLoading.hide();
    },
    async loadData(url, fullRes = false, variableName = 'items') {
      await requestGetDataHelper(this, {
        url,
        fullRes,
        variableName,
      });
    },
    async updateModuleAccess(module, url = null, method = 'post', updateData = null) {
      let data = {};
      if (updateData) {
        data = updateData;
      } else {
        data = {
          key: module.key,
          value: module.value,
          children: module.children,
        };
      }
      await this.makeRequest(url, method, data);
    },
  },
};
