<template>
  <div class="exam-questions__line">
    <h5 class="s-title">
      {{ title }}
    </h5>
    <hr class="s-w-100 s-hr">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VHeadings',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
