<template>
  <table
    class="s-table-cabinet"
  >
    <thead class="s-table__head">
      <tr>
        <th class="s-to-center s-table__col-1">
          №
        </th>
        <th
          v-for="column in columns"
          :class="[column.class ? column.class : '' ]"
        >
          {{ column.label }}
        </th>
        <th
          v-show="hideActions"
          class="s-to-center"
          :class="actionsClass"
        >
          {{ trans('table.operations') }}
        </th>
      </tr>
    </thead>
    <tbody class="s-table__body">
      <tr v-for="(item, index) in items">
        <td class="s-to-center">
          {{ index !== items.length - 1 ? itemIndexNumber(index) : '' }}
        </td>
        <td
          v-for="column in columns"
          :data-table-head="column.label"
          :class="column.cellClass"
        >
          {{ getAttribute(item,column.attribute) }}
        </td>
        <td
          v-show="hideActions"
          class="s-to-center s-table__col-2"
          :data-table-head=" trans('table.operations')"
        >
          <div
            class="s-group s-group--to-center"
            :class="operationsWrapperClass"
          >
            <template
              v-for="action in item.actions"
              v-if="item.actions"
            >
              <component
                :is="action.component"
                v-if="action.component"
                v-bind="action.binds"
              />
              <a
                v-else
                class="s-btn-table s-btn--ico"
                :href="action.link"
                :title="action.title"
              >
                <span
                  v-if="action.icon"
                  class="s-ico"
                  :class="action.icon.classes"
                />
                {{ action.label?action.label:'' }}
              </a>
            </template>
          </div>
        </td>
      </tr>
      <tr
        v-if="rowTotal"
      >
        <td />
        <td
          v-for="column in columns"
          :data-table-head="column.label"
          :class="column.cellClass"
        >
          {{ getAttribute(rowTotal,column.attribute) }}
        </td>
        <td
          v-show="hideActions"
          class="s-to-center"
          :data-table-head="trans('table.operations')"
        />
      </tr>
    </tbody>
  </table>
</template>
<script>
import table from './mixins/table';

export default {
  name: 'VColumnTable',
  mixins: [table],
  props: {
    columns: {
      type: [Array, Object],
      default: () => [],
    },
    items: {
      type: [Array, Object],
      default: () => [],
    },
    hideActions: {
      type: Boolean,
      required: false,
    },
    actionsClass: {
      type: String,
      default: '',
    },
    operationsWrapperClass: {
      type: String,
      default: '',
    },
    currentPage: {
      type: Number,
    },
    rowTotal: {
      type: Number,
    },
  },
};
</script>
