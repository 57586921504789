import arrayHelper from '@app_college/components/Views/VDormitory/mixins/arrayHelper';

export default {
  name: 'DormitoryRoomModal',
  mixins: [arrayHelper],
  props: {
    floor: {
      type: Number,
      required: true,
    },
    resource: {
      type: Object,
      default: null,
    },
  },
  computed: {
    validate() {
      return this.roomsInfo.filter(item => item.number == null || !item.places_count).length === 0;
    },
    gender() {
      return [
        {
          id: 'w',
          name: this.trans('filter_fields.gender_female'),
        },
        {
          id: 'm',
          name: this.trans('filter_fields.gender_male'),
        },
      ];
    },
  },
  data() {
    return {
      roomsInfo: [],
    };
  },
  mounted() {
    this.roomsInfo = this.makeArray(this.resource.rooms_count).map((item) => {
      const index = item + 1;
      const filteredData = this.resource.rooms.find(
        el => el.value === index
          && (el.number !== null || !el.places_count)
      );
      return {
        value: index,
        label: index,
        number: filteredData?.number,
        sex: filteredData?.sex ?? 'w',
        places_count: filteredData?.places_count,
      };
    });
  },
  methods: {
    handleAjaxFormSubmit() {
      eventBus.$emit('update-floor', {
        floor: this.floor,
        rooms: this.roomsInfo,
      });
      this.$emit('onClose');
    },
  },
};
