<template>
  <div class="s-group mt-20 s-group--wider justify-content-end">
    <button
      v-if="signXml === null"
      class="s-btn s-btn--thm-blue s-btn--w-150"
      :class="signXml === null ? 's-btn--thm-blue' : 's-btn--thm-green'"
      @click="sign()"
    >
      {{
        signXml === null
          ? trans('button.add_sign_ncalayer')
          : trans('button.add_signed_ncalayer')
      }}
    </button>
    <label
      v-else
      class="s-btn"
    >
      {{ trans('button.add_signed_ncalayer') }}
    </label>
    <template v-if="signXml === null">
      <button
        class="s-btn s-btn--thm-green s-btn--w-150"
        disabled="true"
      >
        {{ trans('button.approve') }}
      </button>
      <button
        class="s-btn s-btn--thm-red s-btn--w-150"
        disabled="true"
      >
        {{ trans('button.decline') }}
      </button>
    </template>
    <template v-else>
      <div
        class="s-btn s-btn--thm-green s-btn--w-150"
        data-toggle="modal"
        :data-target="'#modal-accept-external-statement-'+statement.id"
      >
        {{ trans('button.approve') }}
      </div>
      <div
        class="s-btn s-btn--thm-red s-btn--w-150"
        data-toggle="modal"
        :data-target="'#modal-decline-external-statement-'+statement.id"
      >
        {{ trans('button.decline') }}
      </div>
    </template>
    <v-statement-service-sign-modals
      :accept-external-request-url="acceptExternalRequestUrl"
      :external-statement-decline-url="externalStatementDeclineUrl"
      :negative-responses="negativeResponses"
      :professions="professions"
      :education-forms="educationForms"
      :year-food-maps="yearFoodMaps"
      :is-additional-data="isAdditionalData"
      :is-dormitory="isDormitory"
      :is-free-food="isFreeFood"
      :is-duplicate="isDuplicate"
      :is-restore="isRestore"
      :sign-xml="signXml"
      :statement="statement"
      :csrf-token="csrfToken"
      :type="type"
    />
  </div>
</template>

<script>
import { StoreTranslator } from '@common/mixins';
import Button from '@app_college/components/Legacy/Common/Button/Button';
import VStatementServiceSignModals
from '@app_college/components/Legacy/Views/VStatementServiceSign/Modals/VStatementSignModals.vue';

export default {
  name: 'VStatementServiceSignButtons',
  components: { Button, VStatementServiceSignModals },
  mixins: [StoreTranslator],
  props: {

    type: {
      type: String,
      required: true,
    },
    isRestore: {
      type: String,
      required: true,
    },
    isAdditionalData: {
      type: String,
    },
    isDormitory: {
      type: String,
      required: true,
    },
    isFreeFood: {
      type: String,
      required: true,
    },
    isDuplicate: {
      type: String,
      required: true,
    },
    csrfToken: {
      type: String,
      default: '',
    },
    statement: {
      type: Object,
      required: true,
    },
    negativeResponses: {
      type: Object,
      required: false,
    },
    professions: {
      type: Array,
      required: false,
    },
    educationForms: {
      type: Object,
      required: false,
    },
    yearFoodMaps: {
      type: Object,
      required: false,
    },
    externalStatementDeclineUrl: {
      type: String,
      required: true,
    },
    acceptExternalRequestUrl: {
      type: String,
      required: true,
    },
    uriCheckSign: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      signXml: null,
      loading: false,
    };
  },
  beforeCreate() {
    this.$trans.add(['statement', 'label', 'button', 'main', 'notice']);
  },
  mounted() {
    this.signXml = this.statement.sign_xml_accept;
  },
  methods: {
    async sign() {
      this.$emit('loading', true);
      const webSocket = new WebSocket('wss://127.0.0.1:13579/');
      let callback = null;
      const xmlToSign = '<status>Sign</status>';
      webSocket.onopen = (event) => {
        callback = (response) => {
          const vm = this;
          $.ajax({
            url: this.uriCheckSign,
            method: 'POST',
            dataType: 'JSON',
            data: {
              signXml: response.responseObject,
            },
            success() {
              vm.signXml = response.responseObject;
            },
            error(res) {
              vm.signXml = null;
              if (res.status === 422 || res.status === 423) {
                let errors = [];
                $.each(res.responseJSON.errors, (i, val) => {
                  errors = errors.concat(val);
                });
                show_notice(errors);
              }
            },
          });
          // this.signXml = response.responseObject;
          this.$emit('loading', false);
        };
        webSocket.send(JSON.stringify(
          {
            id: 1,
            module: 'kz.gov.pki.knca.commonUtils',
            method: 'signXml',
            args: ['PKCS12', 'SIGNATURE', xmlToSign, '', ''],
          },
        ));
      };
      webSocket.onclose = (event) => {
        if (event.wasClean) {
          console.log('connection has been closed');
        } else {
          console.log('Connection error');
          // TODO: переделать на нормальные notice
          show_notice(this.trans('notice.ncalayer_not_run'));
          this.$emit('loading', false);
        }
        console.log(`Code: ${event.code} Reason: ${event.reason}`);
      };
      webSocket.onmessage = (event) => {
        const result = JSON.parse(event.data);
        console.log(event);
        console.log(result);
        if (result != null) {
          if (result?.code === '200') {
            if (typeof callback === 'function') {
              callback(result);
            }
          } else if (result?.code === '500') {
            this.$emit('loading', false);
          }
        }
      };
    },
  },
};
</script>
