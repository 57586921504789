export default {
  name: 'VDormitoryStatements',
  props: {
    uriGetData: {
      type: String,
      required: true,
    },
    noResultTitle: {
      type: String,
      default: '',
    },
    noResultText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      statements: [],
    };
  },
  async mounted() {
    await this.$trans.add(['dormitory', 'table', 'warning_text']);
    await requestGetDataHelper(this, { url: this.uriGetData, variableName: 'statements' });
  },
  computed: {
    isItemsEmpty() {
      return Object.keys(this.statements).length === 0;
    },
  },
  methods: {
    handleClick(btn) {
      if (!btn.onClick) {
        return;
      }
      const modal = btn.onClick.openModal;
      const binds = modal.binds ?? {};
      const events = {};
      this.$sModal.open(modal.id, {
        component: modal.id,
        notPersistent: true,
        title: modal.title,
        binds,
        events,
      });
    },
  },
};
