export default {
  methods: {
    isFloat(result) {
      return Number(result) === result && result % 1 !== 0;
    },
    toFixedFloat(result) {
      return this.isFloat(result) ? result.toFixed(1) : result;
    },
    total(...types) {
      const vm = this;
      const key = types.join('-');
      let result = 0;
      _.forEach(vm.groups, (group) => {
        let val = group._tmp_data[key];
        if (_.isNaN(val)) {
          val = 0;
        }
        result += val;
      });
      return this.toFixedFloat(result);
    },
    countGroup(group, ...types) {
      const vm = this;

      let result = 0;
      _.forEach(group.curriculum_working_disciplines_parents, (curriculum_working_discipline) => {
        if (vm.getOfType(curriculum_working_discipline, ...types) !== 0) {
          const val = vm.getControlOfType(curriculum_working_discipline, ...types);
          if (val && typeof val === 'string' && val.includes(',')) {
            result += val.split(',').length;
          } else if (this.isFloat(val)) {
            result += val.toString().split('.').length;
          } else if (curriculum_working_discipline.children.length > 0) {
            result += val;
          } else {
            result += 1;
          }
        }
      });
      vm.$set(group._tmp_data, types.join('-'), result);
      return result;
    },
    groupTotal(group, ...types) {
      const vm = this;

      let result = 0;
      _.forEach(group.curriculum_working_disciplines_parents, (curriculum_working_discipline) => {
        result += vm.getOfType(curriculum_working_discipline, ...types);
      });
      vm.$set(group._tmp_data, types.join('-'), result);
      return this.toFixedFloat(result);
    },
  },
};
