import RegistrationPhoneForm from '@common/views/FormRegistrationSms';
import VOrganizationProfile from '@common/views/VOrganizationProfile';
import VOrganizationEditForm from '@common/views/VOrganizationEditForm';
import VOrganizationInfo from '@common/views/VOrganizationInfo';
import NewTableWithFilters from '@common/views/NewTableWithFilters';
import VFeedbackForm from '@vjs/views/VFeedbackForm';
import VContestView from '@app_college/views/VContestView';
import VNameBookView from '@app_college/views/VNameBookView';
import VAdmissionResultProfessionsList from '@app_college/views/VAdmissionResult/ProfessionsList';
import VAdmissionResultList from '@app_college/views/VAdmissionResult/ContestResultList';
import VAdmissionMonitoringView from '@app_college/views/VAdmissionMonitoringView';
import VJournalFillingActivity from '@app_college/views/VJournalFillingActivity';
import JitsiConference from './JitsiConference';
import TrainingScheduleShow from './TrainingSchedule/Show';
import VExternalStatementsGradesView from './VExternalStatementsGradesView';
import VHomeworkQuizResult from './VHomeworkQuizResult';
import VContingentPerformanceBook from './VContingentPerformanceBook';
import VOrganizationDisciplinesTable from './VOrganizationDisciplinesTable';
import VOrganizationDisciplinesAddSubjectsForm from './VOrganizationDisciplinesAddSubjectsForm';
import VJournal from './VJournal';
import VStatementSignButtons from './VStatementSignButtons';
import VGrantRefusalsList from './VGrantRefusalsList';
import VStudentTranscript from './VStudentTranscript';
import VEducationModuleForm from './VEducationModuleForm';
import VJournalPlanForm from './VJournalPlanForm';
import VTimeSheetView from './VTimeSheetView';
import VCurriculumWorkingProgramForm from './VCurriculumWorkingProgram/Form';
import VCurriculumWorkingProgramView from './VCurriculumWorkingProgram/View';
import VDocumentFlowStatement from './DocumentFlow/Statement';
import SignButton from './DocumentFlow/SignButtons';
import VQuestionnaireForm from './VQuestionnaire/Form';
import VQuestionsList from './VQuestionnaire/VQuestions/VQuestionsList.vue';
import VQuestionnaire from './VQuestionnaire/Questionnaire';
import VQuestionnaireProcess from './VQuestionnaire/Process';
import VDormitoryForm from './VDormitory/Form';
import VAdmissionStructure from './VAdmissionStructure';
import VDormitoryList from './VDormitory/List';
import VDormitoryRecords from './VDormitory/Records';
import VDormitoryStatements from './VDormitory/Statements';
import VDormitoryButtons from './VDormitory/Buttons';
import VContingentPersonalInfo from './VContingentPersonalInfo';
import VStatementAppeal from './VStatementAppeal';
import VEducationResultsTable from './VEducationResultsTable';
import VSetting from './VSetting';
import VEnterprise from './VEnterprise';
import VEnterpriseForm from './VEnterprise/Form';
import VEnterprisePersonnel from './VEnterprise/Personnel';
import VEnterprisePersonnelForm from './VEnterprise/Personnel/Form';
import VPracticeForm from './VPractice/Form';
import VPractice from './VPractice';
import VAdmissionStatementSearch from './VAdmissionStatementSearch';
import VExamQuizResult from './VExamQuizResult';
import VStudentCard from './VStudentCard';
import VContingentSocialAchievement from './VContingentSocialAchievement';
import VLiveKitView from './VLiveKitView';

export default function (Vue) {
  Vue.component('vue-registration-phone-form', RegistrationPhoneForm);
  Vue.component('v-feedback-form', VFeedbackForm);
  Vue.component('vue-jitsi-conference', JitsiConference);
  Vue.component('vue-external-statements-grades', VExternalStatementsGradesView);
  Vue.component('vue-training-schedule-show', TrainingScheduleShow);

  Vue.component('v-organization-profile', VOrganizationProfile);
  Vue.component('v-organization-info', VOrganizationInfo);
  Vue.component('v-organization-edit-form', VOrganizationEditForm);
  Vue.component('v-homework-quiz-result', VHomeworkQuizResult);
  Vue.component('v-contest-view', VContestView);
  Vue.component('v-name-book-view', VNameBookView);
  Vue.component('v-contingent-performance-book', VContingentPerformanceBook);
  Vue.component('v-organization-disciplines-table', VOrganizationDisciplinesTable);
  Vue.component('v-organization-disciplines-add-subjects-form', VOrganizationDisciplinesAddSubjectsForm);
  Vue.component('v-statement-sign-buttons', VStatementSignButtons);
  Vue.component('v-admission-monitoring-view', VAdmissionMonitoringView);
  Vue.component('v-journal', VJournal);
  Vue.component('vue-new-table-with-filters', NewTableWithFilters);
  Vue.component('v-grant-refusals-list', VGrantRefusalsList);
  Vue.component('v-admission-result-professions-list', VAdmissionResultProfessionsList);
  Vue.component('v-admission-contest-result-list', VAdmissionResultList);
  Vue.component('v-student-transcript', VStudentTranscript);
  Vue.component('v-education-module-form', VEducationModuleForm);
  Vue.component('v-journal-plan-form', VJournalPlanForm);
  Vue.component('v-time-sheet-view', VTimeSheetView);
  Vue.component('v-curriculum-working-program', VCurriculumWorkingProgramForm);
  Vue.component('v-curriculum-working-program-view', VCurriculumWorkingProgramView);
  Vue.component('v-document-flow-statement', VDocumentFlowStatement);
  Vue.component('v-sign-button', SignButton);
  Vue.component('v-questionnaire-form', VQuestionnaireForm);
  Vue.component('v-question-list', VQuestionsList);
  Vue.component('v-questionnaire', VQuestionnaire);
  Vue.component('v-questionnaire-process', VQuestionnaireProcess);
  Vue.component('v-dormitory-form', VDormitoryForm);
  Vue.component('v-admission-structure', VAdmissionStructure);
  Vue.component('v-dormitory-list', VDormitoryList);
  Vue.component('v-dormitory-buttons', VDormitoryButtons);
  Vue.component('v-contingent-personal-info', VContingentPersonalInfo);
  Vue.component('v-statement-appeal-view', VStatementAppeal);
  Vue.component('v-dormitory-records', VDormitoryRecords);
  Vue.component('v-dormitory-statement', VDormitoryStatements);
  Vue.component('v-education-results-table', VEducationResultsTable);
  Vue.component('v-setting', VSetting);
  Vue.component('v-journal-filling-activity', VJournalFillingActivity);
  Vue.component('v-enterprise-list', VEnterprise);
  Vue.component('v-enterprise-form', VEnterpriseForm);
  Vue.component('v-enterprise-personnel-list', VEnterprisePersonnel);
  Vue.component('v-enterprise-personnel-form', VEnterprisePersonnelForm);
  Vue.component('v-practice-form', VPracticeForm);
  Vue.component('v-practice-view', VPractice);
  Vue.component('v-admission-statement-search', VAdmissionStatementSearch);
  Vue.component('v-student-card', VStudentCard);
  Vue.component('v-exam-quiz-result', VExamQuizResult);
  Vue.component('v-contingent-social-achievement', VContingentSocialAchievement);
  Vue.component('v-live-kit-view', VLiveKitView);
}
