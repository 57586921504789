import { QPopupProxy, QSpinner } from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import VirtualScrollingMixin from './VirtualScrollingMixin';

export default {
  name: 'VChat',
  mixins: [VirtualScrollingMixin],
  components: {
    QPopupProxy,
    QSpinner,
  },
  props: {
    action: String,
    readAction: String,
    messages: Array,
    userId: Number,
  },
  data() {
    return {
      popupOpened: false,
      message: null,
    };
  },

  computed: {
    screenHeight() {
      return this.$q.screen.height;
    },
    unreadMessages() {
      return this.messages.filter(itm => !itm.isRead && itm.user_id !== this.userId);
    },
    unreadMessagesCount() {
      return this.unreadMessages.length;
    },
  },

  methods: {
    async sendMessage() {
      if (this.message) {
        const res = requestWrapper.call(this, {
          method: 'post',
          url: this.action,
          data: { message: this.message },
        });

        if (!res.error) {
          this.message = null;
          this.focusInput();
          this.setVirtualListToBottom();
        }
      }
    },
    focusInput() {
      this.$refs.messageInput.focus();
    },
    handleOpenPopup() {
      this.readAllMessages();
      this.popupOpened = true;
      this.setVirtualListToBottom();
      this.focusInput();
    },
    readAllMessages() {
      if (this.unreadMessagesCount) {
        this.$emit('readMessages');
        requestWrapper.call(this, {
          method: 'post',
          url: this.readAction,
          data: { ids: this.unreadMessages.map(itm => itm.id) },
        });
      }
    },
  },
  watch: {
    screenHeight() {
      if (this.popupOpened) {
        // eslint-disable-next-line no-unused-expressions
        this.$refs.popupProxy?.$refs?.popup?.updatePosition();
      }
    },
    messages: {
      handler(val) {
        const lastLocalMessage = this.localMessages[this.localMessages.length - 1];
        const lastMessage = val[val.length - 1];
        if (
          (lastLocalMessage && lastLocalMessage?.id !== lastMessage.id)
          || this.localMessages.length === 0
        ) {
          this.$set(this.localMessages, this.localMessages.length, lastMessage);
          this.setVirtualListToBottom();
        }
        if (this.popupOpened) { this.readAllMessages(); }
      },
      deep: true,
    },
  },
};
