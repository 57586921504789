<template>
  <div
    class="s-timer"
  >
    <div class="d-flex">
      <span>{{ minutes }}</span>
      <span>{{ seconds }}</span>
    </div>
  </div>
</template>

<script>
import timeConvert from '@app_college/views/VTicketProcess/helpers/timeConvert';

export default {
  name: 'VTimer',

  props: {
    value: { type: Number, default: 0 },
  },

  computed: {
    minutes() {
      return this.timeObj.minutes;
    },
    seconds() {
      return this.timeObj.seconds;
    },
    timeObj() {
      return timeConvert(this.value);
    },
  },
};
</script>
<style scoped>
.s-timer span:not( :last-child )::after {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
</style>
