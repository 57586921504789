<template>
  <div>
    <q-input
      v-model="form.en_full_name"
      :label="trans('document_flow.eng_full_name_check')"
      :placeholder="trans('placeholder.name')"
    />

    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :label="trans('button.approve')"
        @click="approve"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contingentEngName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {
        en_full_name: this.contingentEngName,
      },
    };
  },
  methods: {
    async approve() {
      this.$emit('exportTranscript', this.form.en_full_name);
      this.$emit('onClose');
    },
  },
};
</script>
