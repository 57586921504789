import { Helper } from '@common/src/helpers';

export default {
  props: {
    action: {
      type: String,
      default: '',
    },
    searchUri: {
      type: String,
      default: '',
    },
    urlBack: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      langs: ['kz', 'ru'],
      existingEnterprise: false,
      form: {
        bin: null,
        name: {
          kz: null,
          ru: null,
        },
        address: {
          kz: null,
          ru: null,
        },
        logo: null,
      },
    };
  },
  computed: {
    validate() {
      return this.form.bin && this.form.name.kz && this.form.name.ru
        && this.form.address.kz && this.form.address.ru
        && this.form.logo && this.form.logo[0]?.name;
    },
  },
  async mounted() {
    await this.$trans.add([
      'warning_text',
      'practice',
      'button',
    ]);
  },
  methods: {
    clearForm() {
      this.form.name.kz = null;
      this.form.name.ru = null;
      this.form.address.kz = null;
      this.form.address.ru = null;
      this.form.logo = null;
    },
    async searchEnterprise() {
      this.clearForm();
      if (!this.form.bin || this.form.bin.length !== 12) {
        return;
      }
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'POST',
        url: this.searchUri,
        data: this.form,
      });
      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      }
      if (Object.keys(res).length > 0) {
        this.form = res;
        this.existingEnterprise = true;
      }
      this.$globalLoading.hide();
    },
    triggerModal() {
      const events = {};
      events.modalSubmit = async (file) => {
        this.form.logo = file;
      };
      this.$sModal.open('v-modal-upload-file', {
        id: 'v-modal-upload-file',
        component: 'v-modal-upload-file',
        notPersistent: true,
        binds: {
          accepts: ['png', 'jpeg', 'jpg'],
        },
        events,
      });
    },
    async handleSubmit() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'POST',
        url: this.action,
        data: this.form,
      });
      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
