function appendArray(formData, values, name) {
  if (!values && name) {
    formData.append(name, '');
  } else if (typeof values === 'object') {
    _.forEach(values, (value, key) => {
      if (typeof value === 'object') {
        appendArray(formData, value, `${name}[${key}]`);
      } else if (value !== null) {
        formData.append(`${name}[${key}]`, value);
      }
    });
  } else {
    formData.append(name, values);
  }
}

export default function (obj) {
  const formData = new FormData();
  Object.keys(obj).forEach((item) => {
    appendArray(formData, obj[item], item);
  });
  return formData;
}
