import { StoreTranslator } from '@common/mixins';


export default {
  mixins: [StoreTranslator],
  props: {
    resource: {
      type: [Array, Object],
      required: false,
    },
    hides: {
      type: [Array, Object],
      required: false,
      default: () => ([]),
    },
    extraConfigsFields: {
      type: [Array, Object],
      required: false,
    },
    apiConfig: {
      type: Object,
      default: () => ({
        action: null,
        method: 'post',
        urlGetDateOfPoints: null,
        urlBack: null,
        additionalButton: null,
      }),
    },
  },
  data() {
    const vm = this;
    const fields = {
      name: {
        value: null,
        name: 'name',
        component: 'text-field',
        labelTransKey: 'fields.name',
        values: [],
        required: true,
      },
      journal_id: {
        value: null,
        name: 'journal_id',
        component: 'v-multi-select-field',
        labelTransKey: 'menu.journal',
        values: [],
        required: true,
        v_show: true,
        loadValues: {
          autoLoad: true,
          type: 'journals',
          headers: {
            'X-Permission': 'video-conferences-create',
          },
          where: {
            personnelVcs: true
          },
        },
        events: {
          change: () => {
            vm.$set(this.form.fields.journal_day_id, 'values', []);
            vm.$set(this.form.fields.journal_day_id, 'value', null);
            if (this.form.fields.journal_id.value) {
              vm.loadDateOfPoints(this.form.fields.journal_id.value);
            } else {
              this.dateOfPointsOptions = [];
              this.form.fields.journal_day_id.values = [];
            }
          },
        },
      },
      journal_day_id: {
        value: '',
        name: 'journal_day_id',
        component: 'v-multi-select-field',
        labelTransKey: 'fields.date_point',
        values: [],
        required: true,
        v_show: true,
        extraAttributes: {
          disabled: vm.apiConfig.method === 'patch',
          customLabel(option, label) {
            return null;
          },
        },
      },
      time: {
        value: '',
        name: 'time',
        component: 'number-field',
        labelTransKey: 'fields.time',
        required: true,
        config: {
          alias: 'datetime',
          inputFormat: 'HH:MM',
          hourFormat: 24,
          autocomplete: false
        },
      },
    };

    if (vm.resource !== undefined) {
      if (vm.resource.values) {
        _.forEach(vm.resource.values, (value, key) => {
          if (fields.hasOwnProperty(key)) {
            fields[key].value = value;
          }
        });
      }
      if (vm.resource.hides) {
        _.forEach(vm.resource.hides, (attribute) => {
          if (fields.hasOwnProperty(attribute)) {
            _.set(fields, `${attribute}.extraAttributes.disabled`, true);
          }
        });
      }
    }
    if (vm.extraConfigsFields) {
      this.setExtraConfigFields(fields);
    }
    return {
      form: {
        processSend: false,
        action: vm.apiConfig.action,
        method: vm.apiConfig.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
        },
      },
    };
  },
  beforeCreate() {
    this.$trans.add([
      'fields', 'warning_text', 'placeholder', 'label', 'button', 'main', 'notice', 'cabinet', 'curriculum',
      'personnel_dicts',
      'training_schedule',
      'menu',
    ]);
  },
  mounted() {
    const vm = this;
  },
  destroyed() {
  },
  methods: {
    setExtraConfigFields(fields){
      const vm = this;
      console.log(vm.extraConfigsFields);
      console.log(fields);
      _.forEach(vm.extraConfigsFields, (configs, attribute) => {
        if (fields.hasOwnProperty(attribute)) {
          const field = fields[attribute];
          _.set(fields, attribute, _.assignIn(field, configs));
        }
      });
    },
    loadDateOfPoints(journalId) {
      const vm = this;
      if (!journalId) {
        vm.$set(vm.form.fields.journal_day_id, 'value', null);
        return;
      }
      const config = {
        responseType: 'json',
        method: 'GET',
        params: {
          journalId,
        },
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      vm.$http.get(vm.apiConfig.urlGetDateOfPoints, config).then(
        (response) => {
          console.log(response.body);
          vm.$set(vm.form.fields.journal_day_id, 'values', response.body);
          vm.setExtraConfigFields(vm.form.fields);
        },
        (response) => {
          vm.$set(vm.form.fields.journal_day_id, 'options', []);
          vm.$set(vm.form.fields.journal_day_id, 'values', []);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
          } else {
            console.log(response);
          }
        },
      );
    },
    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleVShow(field) {
      const vm = this;
      if (field.v_show !== undefined) {
        if (_.isFunction(field.v_show)) {
          return field.v_show(vm);
        }
        return field.v_show;
      }

      return true;
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(form.fields, (el, key) => {
        _.set(data, vm.nameToDot(el.name), el.value);
      });
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      vm.$globalLoading.show();

      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
};
