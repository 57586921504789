import { StoreTranslator } from '@common/mixins';

export default {
  mixins: [StoreTranslator],
  props: {
    actionUrls: {
      type: Object,
      required: true,
      default() {
        return {
          data: '',
          move: '',
          delete: '',
          subgroupsRefs: '',
        };
      },
    },
  },
  data() {
    return {
      items: [],
      groupItems: [],
      errors: [],
      inputData: {
        subgroups: {
          value: '',
        },
      },
      existingData: {
        subgroups_from: {},
      },
      currentAction: 'move',
    };
  },
  beforeCreate() {
    this.$trans.add(['fields', 'warning_text', 'placeholder', 'label', 'button', 'main', 'notice', 'table']);
  },
  mounted() {
    this.loadData(this.actionUrls.data);
  },
  methods: {
    loadData(url, params = {}, method = 'GET') {
      const vm = this;
      vm.$globalLoading.show();
      const config = {
        responseType: 'json',
        method,
        params,
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };

      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      vm.$http.get(url, config)
        .then((response) => {
          vm.$globalLoading.hide();
          const { data } = response.data;

          vm.$set(vm, 'inputData', data.inputData);
          vm.$set(vm, 'items', data.items);
          vm.$set(vm, 'groupItems', data.groupItems);
          vm.$set(vm, 'existingData', data.existingData);
        })
        .catch((response) => {
          vm.$globalLoading.hide();
          vm.handleError(response);
        });
    },
    getSelected($groupContingent = false) {
      let contingent = this.items;
      if ($groupContingent) {
        contingent = this.groupItems;
      }
      return contingent
        .filter(item => item.status === '1')
        .map(item => item.id);
    },
    performAction() {
      const vm = this;

      switch (vm.currentAction) {
        case 'move':
          vm.moveContingent('PATCH');
          break;
        case 'delete':
          vm.deleteContingent('DELETE');
          break;
        case 'add':
          vm.addContingent('POST');
          break;
        default: return;
      }
      vm.modalHide();
    },
    moveContingent(method = 'GET') {
      const vm = this;
      vm.$globalLoading.show();
      if (!vm.inputData.subgroups) {
        return;
      }

      const url = vm.actionUrls.move;
      const config = {
        responseType: 'json',
        method,
        params: {
          subgroup: vm.inputData.subgroups.value,
          selected: vm.getSelected(),
        },
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };

      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      vm.$http.patch(url, config)
        .then(() => {
          vm.$globalLoading.hide();
          show_notice(vm.trans('notice.moved'), 'notice');
          vm.inputData.subgroups.loadValues.setEmpty();
          vm.loadData(vm.actionUrls.data);
        })
        .catch((response) => {
          vm.$globalLoading.hide();
          vm.handleError(response);
        });
    },
    deleteContingent(method = 'GET') {
      const vm = this;
      vm.$globalLoading.show();
      const url = vm.actionUrls.delete;
      const config = {
        responseType: 'json',
        method,
        params: {
          subgroup: vm.inputData.subgroups.value,
          selected: vm.getSelected(),
        },
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };

      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      vm.$http.get(url, config)
        .then(() => {
          vm.$globalLoading.hide();
          show_notice(vm.trans('notice.deleted'), 'notice');
          vm.loadData(vm.actionUrls.data);
        })
        .catch((response) => {
          vm.$globalLoading.hide();
          vm.handleError(response);
        });
    },
    addContingent(method = 'GET') {
      const vm = this;
      vm.$globalLoading.show();
      const url = vm.actionUrls.add;
      const config = {
        responseType: 'json',
        method,
        params: {
          subgroup: vm.inputData.subgroups.value,
          selected: vm.getSelected(true),
        },
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };

      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      vm.$http.get(url, config)
        .then(() => {
          vm.$globalLoading.hide();
          show_notice(vm.trans('notice.saved'), 'notice');
          vm.loadData(vm.actionUrls.data);
          vm.currentAction = 'move';
        })
        .catch((response) => {
          vm.$globalLoading.hide();
          vm.handleError(response);
        });
    },
    handleError(response) {
      const vm = this;
      if (response.status === 422 || response.status === 423) {
        let errors = [];
        _.forEach(response.data.errors, (value) => {
          errors = errors.concat(value);
        });
        if (!errors.length && response.data.message) {
          show_notice(response.data.message, 'error');
          return false;
        }
        show_notice(errors, 'error');
        vm.$set('errors', response.data.errors);
      } else {
        console.log(response);
      }
    },
    showModal() {
      if (!this.buttonDisabled) {
        $('#modal-confirm-destroy').modal('show');
      }
    },
    modalHide() {
      $('#modal-confirm-destroy').modal('hide');
    },
    dataClear() {
      const vm = this;
      vm.$delete(vm.inputData, 'subgroups');
    },
  },
  computed: {
    buttonDisabled() {
      const vm = this;
      return (vm.currentAction === 'move' && !(vm.inputData.subgroups && vm.inputData.subgroups.value));
    },
  },
};
