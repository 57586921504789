<template>
  <div class="s-test-process__question">
    <div class="s-test-process__center-row">
      <div class="s-test-process__center-label">
        {{ $trans.get('test.question') }}:
      </div>
      <div
        class="s-test-process__center-value sn-ck-editor-data"
        v-html="CURRENT_QUESTION.question"
      />
    </div>
    <div class="s-test-process__center-row">
      <div class="s-test-process__center-label">
        {{ $trans.get('test.answers') }}:
      </div>
      <div class="s-test-process__center-value">
        <ul class="s-list-of-answers">
          <li
            v-for="(item, index) in CURRENT_QUESTION.answers"
            :key="item.id"
            class="s-list-of-answers__item sn-ck-editor-data"
            :class="{
              'is-selected': item.checked,
              'is-loading': LOADING.answer && selectedID === item.id,
            }"
            :data-var-answer="toLetter(index)"
            @click="selectAnswer(item.id)"
            v-html="item.text"
          />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import activateMathJax from './helpers/activateMathJax';
import toLetter from './helpers/toLetter';

export default {
  name: 'VTestQuestion',
  data: () => ({
    selectedID: null,
  }),

  computed: {
    ...mapGetters(['CURRENT_QUESTION', 'CURRENT_SUBJECT', 'LOADING', 'TEST', 'QUIZ_ID']),
  },

  mounted() {
    this.activateMathJax();
  },

  updated() {
    this.activateMathJax();
  },

  methods: {
    selectAnswer(id) {
      const { status } = this.TEST;
      if (status === 'end' || status === 'preview') {
        return;
      }
      // Для предотвращения повторной отправки уже выбранного ответа
      const filterAnswer = this.CURRENT_QUESTION.answers.filter(o => o.checked);
      if (filterAnswer[0]) {
        this.selectedID = filterAnswer[0].id;
      }

      if (this.selectedID !== id) {
        this.selectedID = id;
        this.$store.dispatch('SEND_ANSWER', {
          subjectId: this.CURRENT_QUESTION.subjectID,
          questionId: this.CURRENT_QUESTION.id,
          questionIdx: this.CURRENT_SUBJECT.curQuestionIdx,
          answerId: id,
        });
      }
    },

    toLetter,
    activateMathJax,
  },
};
</script>
