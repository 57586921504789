import _ from 'lodash';
import { StoreTranslator } from '@common/mixins';

export default {
  name: 'CalendarThematicPlanModalDestroyTheme',
  mixins: [StoreTranslator],
  props: {
    action: {
      type: String,
      required: true,
    },
    resource: {
      type: [Array, Object],
      required: true,
    },
  },
  data() {
    return {
      processSend: false,
    };
  },
  beforeCreate() {
    this.$trans.add([
      'fields',
      'calendar-thematic-plan',
      'label',
      'notice',
    ]);
  },
  methods: {
    closeModal() {
      this.$emit('modalClose');
    },
    submitDelete() {
      const vm = this;
      const { action } = vm;
      if (vm.processSend === true) {
        return false;
      }
      vm.$set(vm, 'processSend', true);
      vm.$globalLoading.show();

      vm.errors = [];

      const config = {
        responseType: 'json',
        headers: {},
        body: {
          id: vm.resource.id,
        },
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      vm.$http.delete(action, config).then(
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(vm, 'processSend', false);
          vm.$emit('success');
          vm.errors = [];
          show_notice(response.data.message, 'notice');
        },
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(vm, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
            });
            show_notice(errors, 'error');
          } else {
            console.log(response);
          }
        },
      );
    },
  },
};
