import _ from 'lodash';

export default {
  SET_VM: (state, payload) => {
    state.vm = payload;
  },
  SET_PROGRAM_API_CONFIG: (state, payload) => {
    state.curriculumProgramApiConfig = payload;
  },
  SET_PARENT_ID: (state, payload) => {
    state.parentId = payload;
  },
  SET_SEMESTER_NUMBER: (state, payload) => {
    state.semesterNumber = payload;
  },
  SET_COURSE_NUMBER: (state, payload) => {
    state.courseNumber = payload;
  },
  SET_IS_ARCHIVED: (state, payload) => {
    state.isArchived = payload;
  },
  SET_DISCIPLINE_ID: (state, payload) => {
    state.programDisciplineId = payload;
  },
};
