import Vue from 'vue';
import store from './VueJS/store';
import VTicketProcess from './VueJS/views/VTicketProcess/index';


if (document.getElementById('v-ticket-process-app')) {
  window.vTicketProcessApp = new Vue({
    el: '#v-ticket-process-app',
    components: { VTicketProcess },
    store,
  });
}
