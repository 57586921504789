<template>
  <q-dialog
    :value="modal"
    @input="handleModal"
  >
    <v-modal
      :title="trans('label.doc_extend_direction')"
      size="medium"
    >
      <q-form
        class="mb-30"
      >
        <div
          v-if="select && select.items"
        >
          <q-select
            v-model="form.selectValue"
            :options="select.items"
            :label="select.label"
            hide-selected
            bottom-slots
            filled
            use-input
            emit-value
            map-options
            fill-input
            :placeholder="trans('select.placeholder')"
          />
        </div>
        <div>
          <div class="sn-field__label">
            {{ getFileLabel(type) }}: <i>*</i>
          </div>
          <div class="row align-items-end mb-20">
            <div
              class="col-sm-6 col-md-5 col-12"
              :class="$q.screen.width < 576 ? 'mb-10' : ''"
            >
              <q-btn
                class="s-w-100"
                :label="form.file.length === 0 ? trans('button.add') : trans('label.attached')"
                :color="form.file.length === 0 ? 'blue' : 'green'"
                :icon-right="form.file.length === 0 ? 'plus' : 'clip'"
                label-wider
                ellipsis
                no-caps
                @click="triggerFile('file')"
              />
            </div>
            <div class="col-sm-6 col-md-7 col-12">
              <q-btn
                outline
                :label="form.file.length === 0 ? trans('label.file_not_chosen') : trans('label.files_downloaded', {count: form.file.length})"
                class="s-w-100"
                label-wider
                color="grey"
                no-caps
                ellipsis
                :icon-right="form.file.length === 0 ? undefined : 'close'"
                @click="clearFile('file')"
              />
            </div>
          </div>
        </div>
      </q-form>
      <select-user-file
        :open-modal="modalFiles"
        :url-files="uriUserFiles"
        :url-file-store="uriUserFiles"
        :multiple="type !== 'doc_for_privilege'"
        @input="selectFile"
        @select-close="fileModalClose"
      />
      <div class="row justify-content-between">
        <div class="col-12 col-sm-auto mb-10">
          <q-btn
            v-close-popup
            class="s-w-100"
            width="20"
            :label="trans('button.cancel')"
            label-wider
            color="grey"
          />
        </div>
        <div class="col-12 col-sm-auto">
          <q-btn
            class="s-w-100"
            width="20"
            :label="trans('button.send')"
            color="green"
            :disable="!validate"
            @click="send"
          />
        </div>
      </div>
    </v-modal>
  </q-dialog>
</template>

<script>
import { QBtn, QDialog, QForm } from '@quasar/components';
import { Helper } from '@common/src/helpers';
import { requestWrapper } from '@vjs/helpers';
import { ClosePopup } from '@quasar/directives';
import VModal from '@vjs/components/VModal';
import eventHub from '@vjs/config/eventHub';
import SelectUserFile from '../../SelectUserFile';
import StatementFiles from '../../VStatementCreateView/mixins/StatementFiles';
import Files from '../../VStatementCreateView/mixins/Files';

export default {
  name: 'VApplicationDocsModal',
  components: {
    QDialog,
    QBtn,
    QForm,
    VModal,
    SelectUserFile,
  },

  directives: { ClosePopup },

  mixins: [Files, StatementFiles],

  props: {
    modal: Boolean,
    select: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      required: true,
    },
    uriUpdateFiles: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        file: [],
        selectValue: null,
      },
      activeFile: null,
    };
  },

  computed: {
    validate() {
      if (this.select) {
        return this.form.selectValue != null && this.form.file.length !== 0;
      }
      return this.form.file.length !== 0;
    },
  },

  methods: {
    handleModal() {
      this.form.file = [];
      this.$emit('modal-close', false);
    },
    async send() {
      this.$globalLoading.show();
      const data = { files: {} };
      data.files[this.type] = this.form.file;
      if (this.select) {
        data[this.select.key] = this.form.selectValue;
      }
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriUpdateFiles,
        data,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      } else {
        this.handleModal();
        eventHub.$emit('updatedDocs', true);
      }
      this.$globalLoading.hide();
    },
  },
};
</script>
