import { requestWrapper } from '@vjs/helpers';

export default {
  GET_TIMESHEET: async ({ state }, params = {}) => {
    const { vm } = state;
    vm.$globalLoading.show();
    const res = await requestWrapper.call(vm, {
      url: state.makeTimeSheetUrl(state.attendanceApiConfig.timeSheetGet.apiUrl, state.groupId),
      params,
    });

    if (!res.error) {
      const { data } = res;
      state.attendance = data.attendance;
      state.overallData = data.overallData;
      state.availableDates = data.availableDates;
      state.currentMonth = data.currentMonth;
      state.currentYear = data.currentYear;
      state.dateStrings = data.dateStrings;
      state.dayColumns = data.dayColumns;
      state.dayCount = data.dayCount;
      state.editMonth = data.editMonth;
      state.editYear = data.editYear;
      state.isGroupTrashed = data.isGroupTrashed;
      state.holidays = data.holidays;
      state.timeSheet = data;
    }
    vm.$globalLoading.hide();
  },
};
