import { color } from '@amcharts/amcharts4/core';
import charts from '../../mixins/charts';

export default {
  name: 'VRelatedBarPieChart',
  mixins: [charts],
  props: {
    templateTooltip: {
      type: String,
      default: '',
    },
    langs: {
      type: Object,
      default: () => ({
        men: '',
        women: '',
      }),
    },
  },

  data() {
    return {
      curLang: window.core_project.locale,
      barChart: null,
      pieChart: null,
      colors: [],
    };
  },

  created() {
    for (let i = 0; i < 50; i += 1) {
      this.colors.push(color(this.getRandomColor()));
    }
  },

  computed: {
    barChartLegend() {
      return this.getBarLegend(this.barChart);
    },
    pieChartLegend() {
      return this.getPieLegend(this.pieChart);
    },
  },

  watch: {
    barChartLegend() {},
  },

  async mounted() {
    if (this.$q.screen.xs) {
      this.show = false;
    }

    const data = await this.request(this.uri);

    this.noData = !this.checkData(data);

    if (!this.noData && this.show) {
      const refacData = data.map(item => ({
        name: item.name,
        count: item.gender.w + item.gender.m,
        m: item.gender.m,
        w: item.gender.w,
      }));
      this.chart = this.createBarChart(this.$refs.barChart, data, this.colors, this.templateTooltip);
      this.barChart = this.createBarChart(
        this.$refs.barChart,
        refacData,
        this.colors,
        this.templateTooltip,
      );
      this.barChart.series.columns.template.events.on('hit', this.handleBarChart);

      this.pieChart = this.createPieChart(
        this.$refs.pieChart,
        this.calcDataForPie(refacData),
      );
    }
  },

  methods: {
    handleBarChart(ev) {
      if (this.barChart) {
        this.barChart.series.columns.values.forEach((item) => {
          if (ev.target.dataItem.dataContext.name === item.dataItem.dataContext.name) {
            item.isActive = !item.isActive;

            if (item.isActive) {
              this.pieChart.chart.data = this.getDataArrayForPieChart(
                ev.target.dataItem.dataContext.m,
                ev.target.dataItem.dataContext.w,
              );
            } else {
              this.pieChart.chart.data = this.pieChart.data;
            }
          } else {
            item.isActive = false;
          }
        });
      }
    },
    getDataArrayForPieChart(man, woman) {
      return [
        {
          name: this.langs.men,
          count: man,
          color: color('#6750a1'),
        },
        {
          name: this.langs.women,
          count: woman,
          color: color('#6ad137'),
        },
      ];
    },
    calcDataForPie(data) {
      const man = data.reduce((acc, item) => acc + item.m, 0);
      const woman = data.reduce((acc, item) => acc + item.w, 0);
      return this.getDataArrayForPieChart(man, woman);
    },
    handleLegendClick(idx) {
      if (this.barChart) {
        this.barChart.series.columns.values.forEach((item, index) => {
          if (index === idx) {
            item.dispatchImmediately('hit');
          } else {
            item.isActive = false;
          }
        });
      }
    },
  },
};
