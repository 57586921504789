import { color } from '@amcharts/amcharts4/core';
import charts from '../../mixins/charts';

export default {
  name: 'VBarChart',
  mixins: [charts],
  props: {
    templateTooltip: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      chart: null,
      colors: [],
    };
  },

  computed: {
    barChartLegend() {
      return this.getBarLegend(this.chart);
    },
  },

  created() {
    for (let i = 0; i < 50; i += 1) {
      this.colors.push(color(this.getRandomColor()));
    }
  },

  watch: {
    barChartLegend() {},
  },

  async mounted() {
    if (this.$q.screen.xs) {
      this.show = false;
    }

    const data = await this.request(this.uri);

    this.noData = !this.checkData(data);

    if (!this.noData && this.show) {
      this.chart = this.createBarChart(this.$refs.barChart, data, this.colors, this.templateTooltip);
    }
  },

  methods: {
    handleLegendClick(legend) {
      if (this.chart) {
        this.chart.series.columns.values.forEach((item) => {
          if (item.dataItem.dataContext.id === legend.id) {
            item.isActive = !item.isActive;
          } else {
            item.isActive = false;
          }
        });
      }
    },
  },
};
