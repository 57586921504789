import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Helper } from '@common/src/helpers';

export default {
  props: {
    action: {
      type: String,
      default: '',
    },
    uploadUrl: {
      type: String,
      default: '',
    },
    method: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: null,
    },
    cans: {
      type: Object,
      default: {},
    },
    status: {
      type: String,
      default: '',
    },
    contingent: {
      type: Number,
      default: null,
    },
    changeStatus: {
      type: String,
      default: '',
    },
    grade: {
      type: Number,
      default: null,
    },
    addGrade: {
      type: String,
      default: '',
    },
  },
  data() {
    const vm = this;
    return {
      form: {
        body: this.body,
        type: this.type,
        contingent: this.contingent,
      },
      editor: ClassicEditor,
      loading: true,
      editorConfig: {
        language: 'ru',
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'imageUpload',
            'blockQuote',
            'undo',
            'redo',
          ],
        },
      },
      UploadAdapter: function UploadAdapter(loader) {
        this.upload = () => loader.file
          .then(file => new Promise(async (resolve) => {
            const formData = new FormData();
            _.each(loader, (item, index) => {
              formData.append(index, item);
            });
            await vm.uploadFile(formData, file, resolve);
          }));
        this.abort = () => {
          console.log('Abort upload.');
        };
      },
    };
  },
  methods: {
    async uploadFile(formData, file, resolve) {
      try {
        this.$globalLoading.show();
        formData.append('file', file);
        formData.append('responseType', 'json');
        const res = await requestWrapper.call(this, {
          url: this.uploadUrl,
          method: this.method,
          data: formData,
        });
        resolve(res);
        this.$globalLoading.hide();
      } catch (error) {
        //  Костыль с сообщением, если файл не прошел валидацию по размеру
        this.$notify({
          text: this.trans('validation.img_max_size_teacher'),
          type: 'error',
        });
        this.$globalLoading.hide();
      }
    },
    async handleAjaxFormSubmit() {
      if (!this.form.body || this.loading) {
        this.loading = !this.loading;
        return;
      }
      this.$globalLoading.show();
      const data = this.form;
      const res = await requestWrapper.call(this, {
        url: this.action,
        method: this.method,
        data,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.loading = !this.loading;
      this.$globalLoading.hide();
    },
    onEditorReady(instance) {
      const fileRepository = instance.plugins.get('FileRepository');

      if (fileRepository != null) {
        fileRepository.createUploadAdapter = loader => new this.UploadAdapter(
          loader, this.storeData,
        );
      }
    },
  },
};
