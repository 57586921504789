import Vue from 'vue';

import WrapperMultiSelect from '@common/components/Form/WrapperMultiSelect/WrapperMultiSelect';
import Notify from './Notify';
import Table from './Table';
import Button from './Button';
import DatePicker from './DatePicker';
import SelectField from './SelectField';
import Select2 from './Select2';
import VSelectField from './VSelectField';

Vue.component('vue-date-picker', DatePicker);
Vue.component('notify', Notify);
Vue.component('vue-table', Table);
Vue.component('vue-button', Button);
Vue.component('select-field', SelectField);
Vue.component('vue-select2', Select2);
Vue.component('v-select-field', VSelectField);
Vue.component('v-wrapper-multi-select', WrapperMultiSelect);
