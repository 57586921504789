import { StoreTranslator } from '@common/mixins';

export default {
  mixins: [StoreTranslator],
  props: {
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    urlBack: {
      type: String,
      required: false,
      default: null,
    },
    resource: {
      type: [Array, Object],
      required: false,
    },
    hides: {
      type: [Array, Object],
      required: false,
      default: () => ([]),
    },
    extraConfigsFields: {
      type: [Array, Object],
      required: false,
    },
  },
  data() {
    const vm = this;
    const fields = {
      task: {
        value: '',
        component: 'text-show-field',
        labelTransKey: 'fields.task',
        class: 'sn-ck-editor-data',
      },
    };
    if (vm.type === 'text_files') {
      fields.body = {
        value: '',
        name: 'body',
        component: 'ckeditor-field',
        labelTransKey: 'main.answer',
        required: false,
        extraAttributes: {
          disabled: false,
        },
      };
      fields.files = {
        value: '',
        name: 'files',
        component: 'multiple-files-field',
        labelTransKey: 'fields.file',
        required: false,
        extraAttributes: {
          disabled: false,
          withPreview: true,
        },
        config: {
          accepts: ['pdf', 'png', 'bmp', 'jpeg', 'jpg', 'zip', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'rar'],
          size: 20 * 1048576,
        },
      };
      fields.voice = {
        value: null,
        component: 'voice-recorder',
        name: 'voice',
        labelTransKey: 'fields.voice_message',
      };
    }
    if (vm.resource !== undefined) {
      _.forEach(vm.resource, (value, key) => {
        if (fields.hasOwnProperty(key)) {
          fields[key].value = value;
        }
      });
    }
    if (vm.hides) {
      _.forEach(vm.hides, (attribute) => {
        if (fields.hasOwnProperty(attribute)) {
          _.set(fields, `${attribute}.extraAttributes.disabled`, true);
        }
      });
    }
    if (vm.extraConfigsFields) {
      _.forEach(vm.extraConfigsFields, (configs, attribute) => {
        if (fields.hasOwnProperty(attribute)) {
          const field = fields[attribute];
          _.set(fields, attribute, _.assignIn(field, configs));
        }
      });
    }
    return {
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
          // TODO привести к одному варианту
          if (data.redirect) {
            window.location = data.redirect;
          }
        },
      },

    };
  },
  beforeCreate() {
    this.$trans.add([
      'fields', 'warning_text', 'placeholder', 'label', 'button', 'main', 'notice', 'cabinet', 'curriculum',
      'personnel_dicts',
      'training_schedule',
      'menu',
    ]);
  },
  mounted() {
    const vm = this;
  },

  destroyed() {
  },
  methods: {

    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleVShow(field) {
      const vm = this;
      if (field.v_show !== undefined) {
        if (_.isFunction(field.v_show)) {
          return field.v_show(vm);
        }
        return field.v_show;
      }

      return true;
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(form.fields, (el, key) => {
        if (!el.name) {
          return;
        }
        if (el.hasOwnProperty('fill')) {
          el.fill(data);
        } else {
          _.set(data, vm.nameToDot(el.name), el.value);
        }
      });
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      vm.$globalLoading.show();

      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      if (!fieldName) {
        return [];
      }
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
};
