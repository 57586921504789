import {
  Player,
  Audio,
  Ui,
  Controls,
  PlaybackControl,
  TimeProgress,
  ScrubberControl,
  Slider,
  MuteControl,
  Control,
} from '@vime/vue';

export default {
  components: {
    Player,
    Audio,
    Ui,
    Controls,
    PlaybackControl,
    TimeProgress,
    ScrubberControl,
    Slider,
    MuteControl,
    Control,
  },

  props: {
    type: { type: String, required: true },
    src: { type: String, required: true },
  },

  data() {
    return {
      volume: 50,
      audioControlElement: null,
    };
  },

  watch: {
    audioControlElement(value) {
      if (value) {
        const style = document.createElement('style');
        style.innerHTML = ''
          + '.controls {'
          + ' border: 0.5px solid #C3CAE8; '
          + '}';
        value.shadowRoot.appendChild(style);
      }
    },
  },

  mounted() {
    this.audioControlElement = this.$refs.audioControls.$el;
  },
  methods: {
    onVolumeChange(value) {
      this.volume = value;
    },
  },
};
