<template>
  <q-form
    autocorrect="off"
    autocapitalize="off"
    autocomplete="off"
    spellcheck="false"
    @submit="approve"
  >
    <v-form-inform v-if="inform" :message="inform" />
    <q-input
      v-model="message"
      :label="trans(`label.type_message_new`)"
      filled
      class="mb-30"
      type="textarea"
      required
      counter
      :maxlength="1000"
    />

    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :disable="!message.length"
        type="submit"
        :label="trans('button.approve')"
      />
    </div>
  </q-form>
</template>

<script>
import { QInput } from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';

export default {

  components: { QInput },

  props: {
    inform: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: 'event',
    },
    action: {
      type: String,
      default: '',
    },
    method: {
      type: String,
      default: 'post',
    },
  },
  data() {
    return {
      message: '',
    };
  },
  methods: {
    async approve() {
      switch (this.type) {
        case 'request':
          await this.request();
          break;
        case 'event':
        default:
          this.$emit('onApprove', this.message);
          break;
      }
    },
    async request() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.action,
        method: this.method,
        [this.method === 'get' ? 'params' : 'data']: { message: this.message },
      });

      if (!res.error) {
        this.$emit('onClose');
        this.$emit('onApprove');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
</script>
