function activateMathJax() {
  // Активация MathJax, если MathJax найден
  if (window.MathJax) {
    window.MathJax.Hub.Config({
      tex2jax: {
        inlineMath: [['$', '$'], ['\\(', '\\)']],
        processEscapes: true,
      },
    });
    window.MathJax.Hub.Queue([
      'Typeset',
      window.MathJax.Hub,
    ],
    () => {
      // Фикс от скачков формул во время отрисовки
      const mathTex = document.querySelectorAll('.math-tex');
      Array.from(mathTex).forEach((item) => {
        item.style.opacity = '1';
      });
    });
  }
}

export default activateMathJax;
