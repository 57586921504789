export default {
  SET_QUESTIONNAIRE_VM: (state, payload) => {
    state.vm = payload;
  },
  SET_QUESTION_ID: (state, payload) => {
    state.questionId = payload;
  },
  SET_METHOD: (state, payload) => {
    state.method = payload;
  },
  SET_QUESTION_API_CONFIG: (state, payload) => {
    state.questionApiConfig = payload;
  },
  SET_PAGINATE_PAGE: (state, payload) => {
    state.paginatePage = payload;
  },
  SET_PROCESS_API_CONFIG: (state, payload) => {
    state.processApiConfig = payload;
  },
  SET_QUESTION_GROUP: (state, payload) => {
    let leftAnswers = payload.questions.length;
    const curQuestionID = parseInt(localStorage.getItem('curQuestionID') ?? payload.questions[0].id, 10);
    const curQuestionIdx = parseInt(localStorage.getItem('curQuestionIdx') ?? '0', 10);
    localStorage.setItem('curQuestionID', curQuestionID);
    localStorage.setItem('curQuestionIdx', curQuestionIdx);
    state.questionGroup = {
      payload,
      curQuestionID,
      curQuestionIdx,
      questions: payload.questions.map((question, index) => ({
        id: question.id,
        idx: index,
        questionGroupId: payload.id,
        question: question.text,
        with_option: question.with_option,
        answers: [
          ...question.answers.map((answer) => {
            if (answer.checked) {
              leftAnswers -= 1;
            }
            return answer;
          }),
        ],
      })),
      leftAnswers,
    };
    state.currentQuestion = state.questionGroup.questions[state.questionGroup.curQuestionIdx];
  },
  SET_CURRENT_PROCESS_QUESTION: (state, payload = { id: 0, idx: 0 }) => {
    state.questionGroup.curQuestionID = payload.id;
    state.questionGroup.curQuestionIdx = payload.idx;
    localStorage.setItem('curQuestionID', payload.id);
    localStorage.setItem('curQuestionIdx', payload.idx);
    state.currentQuestion = state.questionGroup.questions[payload.idx];
  },
  // Переключает на предыдущий вопрос в текущем предмете
  PREV_QUESTION: (state) => {
    if (state.questionGroup.curQuestionIdx - 1 >= 0) {
      state.questionGroup.curQuestionIdx -= 1;
    } else {
      state.questionGroup.curQuestionIdx = state.questionGroup.questions.length - 1;
    }
    state.vm.$store.commit('SET_CURRENT_PROCESS_QUESTION', { id: state.questionGroup.questions[state.questionGroup.curQuestionIdx].id, idx: state.questionGroup.curQuestionIdx });
  },
  // Переключает на следующий вопрос в текущем предмете
  NEXT_QUESTION: (state) => {
    if (state.questionGroup.curQuestionIdx + 1 < state.questionGroup.questions.length) {
      state.questionGroup.curQuestionIdx += 1;
    } else {
      state.questionGroup.curQuestionIdx = 0;
    }
    state.vm.$store.commit('SET_CURRENT_PROCESS_QUESTION', { id: state.questionGroup.questions[state.questionGroup.curQuestionIdx].id, idx: state.questionGroup.curQuestionIdx });
  },
  SET_LOADING: (state, payload = { name: 'common', status: false }) => {
    state.loading[payload.name] = payload.status;
  },
};
