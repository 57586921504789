export default {
  methods: {
    chapterName(key, chapter) {
      return this.trans('curriculum.program.chapter_name', {
        index: key + 1,
        chapter_name: this.getAttribute(chapter, 'name'),
        results: this.getAttribute(chapter, 'results'),
      });
    },
    getAttribute(item, attribute) {
      if (attribute === 'total_hours') {
        return this.calculateTotal(item);
      }
      const el = _.get(item, attribute, '');
      if (el !== null && !Array.isArray(el) && typeof el === 'object') {
        return el?.value;
      }
      return el !== null ? el : '';
    },
    calculateTotal(item, attribute = null, semester = null) {
      let result = 0;
      if (item.lessons && item.lessons.length > 0) {
        _.forEach(item.lessons, (el) => {
          result += this.calculateTotal(el, attribute);
        });
      } else {
        _.forEach(item, (el, key) => {
          let condition;
          if (attribute !== null) {
            condition = key === attribute;
          } else {
            condition = key !== 'total_hours' && el?.type === 'number' && !el?.isNotCalculated;
          }
          if (condition) {
            let val;
            if (item[attribute] && semester !== null) {
              val = item[attribute][semester];
            } else {
              val = (typeof el === 'object' && !Array.isArray(el) && el !== null) ? el?.value : el;
            }
            if (val && typeof val === 'string' && val.includes(',')) {
              val += val.replace(',', '.');
            }
            val = parseFloat(val);
            if (_.isNaN(val)) {
              val = 0;
            }
            result += val;
          }
        });
      }
      return result;
    },
    calculateTotals(attribute, semester = null) {
      let result = 0;
      _.forEach(this.items, (item) => {
        if (!item.is_module) {
          result += this.calculateTotal(item, attribute, semester);
        }
      });
      return result ?? null;
    },
    total() {
      let result = 0;
      _.forEach(this.items, (item) => {
        result += this.getAttribute(item, 'total_hours');
      });
      return new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }).format(result).replace(',', '.');
    },
  },
};
