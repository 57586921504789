import {
  QToggle,
  QIcon,
  QCheckbox,
} from '@quasar/components';
import { Helper } from '@common/src/helpers';

export default {
  name: 'VQuestionnaireForm',
  components: {
    QToggle,
    QIcon,
    QCheckbox,
  },
  props: {
    resource: {
      type: Object,
      default: null,
    },
    uriAction: {
      type: String,
      default: null,
    },
    backLink: {
      type: String,
      default: null,
    },
    uriGetOptions: {
      type: String,
      default: null,
    },
    method: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      fields: {
        name: null,
        questions_group_id: null,
        date: null,
        time: null,
        type: null,
        groups: [],
        personnel_type: null,
        is_anonymous: false,
      },
      options: {
        questionGroups: [],
        personnelTypes: [],
        groups: [],
        types: [],
      },
    };
  },
  async mounted() {
    await this.$trans.add(['questionnaire', 'label']);
    await this.loadOptions();
    _.forEach(this.resource, (attribute, key) => {
      if (this.fields.hasOwnProperty(key)) {
        this.fields[key] = attribute;
      }
    });
  },
  computed: {
    validate() {
      return this.fields.name != null && this.fields.questions_group_id != null
        && this.fields.date != null && this.fields.time != null && this.fields.type != null
        && moment(this.fields.time, 'H:m').isValid()
        && (this.fields.type === 1 || this.fields.personnel_type != null);
    },
  },
  methods: {
    clearSelects() {
      this.fields.personnel_type = null;
      this.fields.groups = [];
    },
    async loadOptions() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.uriGetOptions,
      });
      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      } else {
        this.options = res.data;
      }
      this.$globalLoading.hide();
    },
    async handleAjaxFormSubmit() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.uriAction,
        method: this.method,
        data: this.fields,
      });
      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
    validateTime(val) {
      return moment(val, 'H:m').isValid();
    },
  },
};
