import _ from 'lodash';

export default function (question, $value) {
  let res = false;
  _.forEach(question.answers, (answer, index) => {
    if (question.answers[index].correct === $value) {
      res = true;
    }
  });

  return res;
}
