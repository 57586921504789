export default {
  computed: {
    timeSheetData() {
      const { attendance, overallData } = this;
      const absentArr = [0, 2];
      if (typeof attendance === 'undefined') {
        return [];
      }
      const daysTotalAbsent = {
        fixed: {
          index: ' ',
          full_name: this.trans('attendance.time-sheet.absent-total'),
        },
        scroll: {
          absentDays: 0,
          reasons: '',
          respectfulAbsentDays: 0,
          days: [],
        },
        is_total: true,
      };
      const daysTotalAttend = {
        fixed: {
          index: ' ',
          full_name: this.trans('attendance.time-sheet.attend-total'),
        },
        scroll: {
          absentDays: 0,
          reasons: '',
          respectfulAbsentDays: 0,
          days: [],
        },
        is_total: true,
      };

      attendance.forEach((item) => {
        Object.keys(item.days)
          .forEach((key) => {
            const day = item.days[key];
            if (typeof daysTotalAbsent.scroll.days[key] === 'undefined') {
              daysTotalAbsent.scroll.days[key] = {
                attendance: {
                  attendance: overallData[day.day]?.absent ?? 0,
                },
                is_work: day.is_work,
                day: day.day,
              };
              daysTotalAttend.scroll.days[key] = {
                attendance: {
                  attendance: overallData[day.day]?.attend ?? 0,
                },
                is_work: day.is_work,
                day: day.day,
              };
            }
          });
      });
      daysTotalAbsent.scroll.absentDays = overallData?.totalAbsent;
      daysTotalAbsent.scroll.respectfulAbsentDays = overallData?.totalAbsentRespectful;

      daysTotalAbsent.scroll.days = Object.keys(daysTotalAbsent.scroll.days)
        .map(key => daysTotalAbsent.scroll.days[key]);

      daysTotalAttend.scroll.days = Object.keys(daysTotalAttend.scroll.days)
        .map(key => daysTotalAttend.scroll.days[key]);

      const rows = attendance.map((item, index) => ({
        fixed: {
          index: index + 1,
          full_name: item.full_name,
          contingentId: item.contingentId,
        },
        scroll: {
          days: Object.keys(item.days)
            .map(key => ({
              ...item.days[key],
              date: key,
            })),
          absentDays: item.absentDays,
          reasons: item.reasons,
          respectfulAbsentDays: item.respectfulAbsentDays,
        },
      }));
      return this.isChildrenTimeSheet ? rows
        : [...rows, daysTotalAbsent, daysTotalAttend];
    },
  },
};
