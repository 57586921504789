import { StoreTranslator } from '@common/mixins';

export default {
  mixins: [StoreTranslator],
  props: {
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    postId: {
      type: [String, Number],
      required: true,
    },
    commentId: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },
  data() {
    const vm = this;
    return {
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields: {
          body: {
            value: '',
          },
          post_id: {
            value: vm.postId,
          },
          comment_id: {
            value: vm.commentId,
          },
        },
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
        },
      },
    };
  },
  mounted() {
    const vm = this;
  },

  destroyed() {
  },
  methods: {
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(form.fields, (el, key) => {
        data[key] = el.value;
      });
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      form.errors = [];
      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      if (method.toLowerCase() === 'post' || method.toLowerCase() === 'delete' || method.toLowerCase() === 'patch') {
        config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;
      }
      vm.$http.post(action, data, config).then(
        (response) => {
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
            });
            show_notice(errors, 'error');
            form.errors = response.data.errors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return form.errors[field] !== undefined;
    },
    getError(form, field) {
      return _.first(form.errors[field]);
    },

  },
};
