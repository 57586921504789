import { genArray, scrollTop, requestWrapper } from '@vjs/helpers';
import {
  QField,
  QPagination,
  QTooltip,
  QBtn,
} from '@quasar/components';
import Modal from '@vjs/components/VModal';
import VNoResults from '@vjs/components/VNoResults';
import VFormInform from '@vjs/components/VFormInform';
import eventHub from '@vjs/config/eventHub';
import VExamTheme from './VExamTheme.vue';
import VExamThemeAdd from './VExamThemeAdd.vue';


export default {
  name: 'VExamThemes',
  components: {
    QField,
    QPagination,
    QTooltip,
    QBtn,
    Modal,
    VExamTheme,
    VExamThemeAdd,
    VNoResults,
    VFormInform,
  },
  props: {
    uriGetThemes: {
      type: String,
      required: true,
    },
    uriAddTheme: {
      type: String,
      required: true,
    },
    uriEditTheme: {
      type: String,
      required: true,
    },
    uriDestroyTheme: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      addTheme: false,
      currentPage: 1,
      countByPage: 5,
      themeItems: [],
      loading: false,
    };
  },

  async mounted() {
    await this.$trans.add([
      'button',
      'placeholder',
      'label',
      'title',
      'validation',
      'notice',
      'curriculum',
      'test',
      'main',
      'table',
      'warning_text',
      'cabinet',
    ]);
    await this.$store.commit('setUrlThemeGroup', this.uriGetThemes);
    await this.$store.commit('setImgErrorMsg', this.trans('validation.img_max_size_teacher'));
    await this.requestGetThemes();
    eventHub.$on('addedTheme', async (evt) => {
      this.addTheme = false;
      await this.requestGetThemes();
    });
    eventHub.$on('loadFile', (evt) => { this.handleQLoader(evt); });
    eventHub.$on('deletedTheme', async (evt) => { await this.requestGetThemes(); });
    eventHub.$on('editedTheme', async (evt) => { await this.requestGetThemes(); });
    eventHub.$on('cancelAdding', (evt) => { this.addTheme = false; });
  },
  computed: {
    paginate() {
      if (this.loading) {
        return genArray({}, 5);
      }
      const current = (this.currentPage - 1) * this.countByPage;
      return this.themeItems.slice(current, current + this.countByPage);
    },
    countOfPages() {
      let count = parseInt(this.themeItems.length / this.countByPage, 10);
      const remains = this.themeItems.length % this.countByPage;
      count += remains !== 0 ? 1 : 0;
      this.currentPage = 1;
      return count <= 1 ? 0 : count;
    },
  },
  methods: {
    handleQLoader(loading) {
      if (loading) {
        this.$globalLoading.show();
      } else {
        this.$globalLoading.hide();
      }
    },
    openAdding() {
      this.addTheme = true;
      eventHub.$emit('closeEdit', true);
    },
    async requestGetThemes() {
      let pageTemp = this.currentPage;
      this.loading = true;
      const res = await requestWrapper.call(this, {
        url: this.uriGetThemes,
      }, true);

      if (!res.error) {
        this.themeItems = res.examThemes;
      }
      this.loading = false;
      if (this.countOfPages !== 0 && this.countOfPages < pageTemp) {
        pageTemp -= 1;
      }
      if (this.countOfPages === 0) {
        pageTemp = 1;
      }
      this.currentPage = pageTemp;
    },
    // Запускается на событие @input в q-pagination
    async changePagination() {
      // Перевод страницы к началу при выборе страницы
      scrollTop(
        window,
        window.scrollY,
        window.scrollY - Math.abs(this.$refs.themes.getBoundingClientRect().top),
        500,
      );
    },
  },
};
