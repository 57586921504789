export default {
  methods: {
    openAttendanceModal(col, fullName, isTotal) {
      if (!this.checkIsEditable(col, isTotal)) {
        return null;
      }
      return this.$sModal.open('v-modal-attendance', {
        component: 'v-modal-attendance',
        notPersistent: true,
        title: fullName,
        binds: {
          resource: {
            contingentId: col.contingentId,
            date: col.date,
            attendance: col.attendance.attendance,
          },
          reasons: this.reasonsList,
          uriUserFiles: this.uriUserFiles,
        },
      });
    },
    openContingentAttendanceModal() {
      return this.$sModal.open('v-modal-contingent-attendance', {
        component: 'v-modal-contingent-attendance',
        notPersistent: true,
        title: this.trans('attendance.days_attendance_modal'),
        binds: {
          reasons: this.reasonsList,
          uriUserFiles: this.uriUserFiles,
        },
      });
    },
    openHolidaysModal() {
      return this.$sModal.open('v-holidays-modal', {
        component: 'v-holidays-modal',
        notPersistent: true,
        binds: {
          resource: this.holidays,
        },
      });
    },
  },
};
