<template>
  <div class="s-test-process__subjects-list">
    <div
      v-for="subject in SUBJECTS"
      :key="subject.id"
      class="s-test-process__subject"
      :class="{'is-selected': subject.id === CURRENT_SUBJECT.id}"
      @click="selectSubject(subject.id)"
    >
      <span>{{ subject.name }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import checkQuestionMapMixin from './mixins/checkQuestionMap';

export default {
  name: 'VSubjectList',
  mixins: [checkQuestionMapMixin],

  computed: {
    ...mapGetters(['SUBJECTS', 'CURRENT_SUBJECT', 'CURRENT_QMAP_NUM']),
  },

  methods: {
    selectSubject(id) {
      this.$store.commit('SET_CURRENT_SUBJECT', id);

      this.checkQuestionMap();
    },
  },
};
</script>
