<template>
  <table
    class="s-table-cabinet"
  >
    <thead class="s-table__head">
      <tr
        v-for="(row, index) in rows"
        :key="index"
      >
        <th
          v-if="index === 0"
          class="s-to-center s-table__col-1"
          rowspan="3"
        >
          №
        </th>
        <th
          v-for="(column, columnIndex) in row"
          :key="columnIndex"
          :class="[column.class ? column.class : '' ]"
          :rowspan="column.rowspan"
          :colspan="column.colspan"
        >
          {{ column.label }}
        </th>
        <th
          v-if="index === 0"
          v-show="hideActions"
          class="s-to-center"
          :class="actionsClass"
          :rowspan="3"
        >
          {{ trans('table.operations') }}
        </th>
      </tr>
    </thead>
    <tbody class="s-table__body">
      <tr
        v-for="(item, index) in items"
        :key="index"
      >
        <td class="s-to-center">
          {{ index !== items.length - 1 ? itemIndexNumber(index) : '' }}
        </td>
        <td
          v-for="(column, columnIndex) in columns"
          :key="columnIndex"
          :data-table-head="column.label"
          :class="column.cellClass"
        >
          {{ getAttribute(item, column.attribute) }}
        </td>
        <td
          v-show="hideActions"
          class="s-to-center"
          :data-table-head=" trans('table.operations')"
        >
          <div
            v-if="item.actions"
            class="s-group s-group--to-center"
            :class="operationsWrapperClass"
          >
            <template
              v-for="(action, actionIndex) in item.actions"
            >
              <component
                :is="action.component"
                v-if="action.component"
                :key="actionIndex"
                v-bind="action.binds"
              />
              <a
                v-else
                :key="actionIndex"
                class="s-btn-table s-btn--ico"
                :href="action.link"
                :title="action.title"
              >
                <span
                  v-if="action.icon"
                  class="s-ico"
                  :class="action.icon.classes"
                />
                {{ action.label?action.label:'' }}
              </a>
            </template>
          </div>
        </td>
      </tr>
      <tr
        v-if="rowTotal"
      >
        <td />
        <td
          v-for="column in columns"
          :data-table-head="column.label"
          :class="column.cellClass"
        >
          {{ getAttribute(rowTotal,column.attribute) }}
        </td>
        <td
          v-show="hideActions"
          class="s-to-center"
          :data-table-head="trans('table.operations')"
        />
      </tr>
    </tbody>
  </table>
</template>

<script>
import table from './mixins/table';

export default {
  name: 'VRowTable',
  mixins: [table],
  props: {
    rows: {
      type: [Array, Object],
      default: () => [],
    },
    items: {
      type: [Array, Object],
      default: () => [],
    },
    hideActions: {
      type: Boolean,
      required: false,
    },
    actionsClass: {
      type: String,
      default: '',
    },
    operationsWrapperClass: {
      type: String,
      default: '',
    },
    currentPage: {
      type: Number,
    },
    rowTotal: {
      type: Number,
    },
  },
  data() {
    return {
      columns: [],
    };
  },
  mounted() {
    this.setColumns();
  },
  methods: {
    setColumns() {
      for (const row in this.rows) {
        for (const column in this.rows[row]) {
          if (this.rows[row][column].hasOwnProperty('attribute') && !this.columns.includes(this.rows[row][column])) {
            this.$set(this, 'columns', this.columns.concat(this.rows[row][column]));
          }
        }
      }
      this.$set(this, 'columns', this.columns.sort((a, b) => a.order - b.order));
    },
  },
};
</script>
