import { StoreTranslator } from '@common/mixins';


export default {
  mixins: [StoreTranslator],
  props: {
    urlBack: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    distributionContingent: {
      type: Object,
      required: false,
    },
  },
  data() {
    const vm = this;
    const fields = {
      quote_count: {
        value: '',
        name: 'quote_count',
        component: 'select-field',
        labelTransKey: 'label.count_contingent_in_group',
        values: [
          {
            id: 5,
            name: 5,
          },
          {
            id: 10,
            name: 10,
          },
          {
            id: 15,
            name: 15,
          },
          {
            id: 20,
            name: 20,
          },
          {
            id: 23,
            name: 23,
          },
          {
            id: 25,
            name: 25,
          },
          {
            id: 30,
            name: 30,
          },
          {
            id: 35,
            name: 35,
          },
        ],
        required: true,
      },
      filter_gender: {
        value: false,
        name: 'filter_gender',
        component: 'boolean-field',
        checkboxLabelTransKey: 'label.filter_gender',
        required: false,
      },
      filter_social_status: {
        value: false,
        name: 'filter_social_status',
        component: 'boolean-field',
        checkboxLabelTransKey: 'label.filter_social_status',
        required: false,
      },
    };

    return {
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
          // TODO привести к одному варианту
          if (data.redirect) {
            window.location = data.redirect;
          }
        },
      },
    };
  },
  mounted() {
    const vm = this;
  },

  destroyed() {
  },
  methods: {
    onInputEduLevel(value) {
      const vm = this;
      const config = {
        responseType: 'json',
        method: 'GET',
        params: {
          edu_level: value,
        },
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      vm.$http.get(vm.urlListStatements, config).then(
        (response) => {
          const { data } = response;
          vm.$set(vm.form.fields.statements, 'value', data);
        },
        (response) => {
          console.log(response);
        },
      );
    },

    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(form.fields, (el, key) => {
        _.set(data, vm.nameToDot(el.name), el.value);
      });
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
};
