import _ from 'lodash';
import {
  QSpinnerGears,
  QTooltip,
  QInput,
  QForm,
  QBtn,
} from '@quasar/components';
import { ClosePopup } from '@quasar/directives';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { requestWrapper } from '@vjs/helpers';
import eventHub from '@vjs/config/eventHub';
import VConfirmDialog from '@vjs/modals/VConfirmDialog.vue';

export default {
  name: 'VExamTicket',
  components: {
    QSpinnerGears,
    QTooltip,
    QInput,
    QForm,
    QBtn,
    VConfirmDialog,
  },
  directives: {
    ClosePopup,
  },
  props: {
    ticketProp: {
      type: Object,
      required: true,
    },
    uriEditTicket: {
      type: String,
      required: true,
    },
    uriDestroyTicket: {
      type: String,
      required: true,
    },
    loading: Boolean,
  },
  data() {
    return {
      storeData: this.$store,
      ticketLoading: false,
      ticketEditId: 0,
      ticketDestroyId: 0,
      dataTicket: {},
      dataTicketEdit: {},
      modalConfirmTicketRemove: false,
      editor: ClassicEditor,
      editorConfig: {
        language: 'ru',
        toolbar: {
          items: [
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'imageUpload',
            'blockQuote',
            'insertTable',
            'undo',
            'redo',
          ],
        },
      },
      UploadAdapter: function UploadAdapter(loader, store) {
        this.upload = () => loader.file
          .then(file => new Promise((resolve) => {
            const formData = new FormData();
            _.each(loader, (item, index) => {
              formData.append(index, item);
            });
            store.dispatch('FILE_TICKET', { payload: formData, file, resolve });
          }));
        this.abort = () => {
          console.log('Abort upload.');
        };
      },
    };
  },
  async mounted() {
    await this.setData();
    eventHub.$on('closeEdit', (evt) => {
      if (evt !== this.ticketEditId) {
        this.cancelEdit();
      }
    });
  },
  methods: {
    onEditorReady(instance) {
      const fileRepository = instance.plugins.get('FileRepository');

      if (fileRepository != null) {
        fileRepository.createUploadAdapter = loader => new this.UploadAdapter(
          loader, this.storeData,
        );
      }
    },
    setData() {
      this.dataTicket = _.cloneDeep(this.ticketProp);
    },
    openDestroyModal(id) {
      eventHub.$emit('cancelAdding', true);
      this.modalConfirmTicketRemove = true;
      this.ticketDestroyId = id;
    },
    changeTicket(id) {
      eventHub.$emit('cancelAdding', true);
      eventHub.$emit('closeEdit', id);
      this.ticketEditId = 0;
      this.dataTicketEdit = _.cloneDeep(this.dataTicket);
      this.ticketEditId = id;
    },
    cancelEdit() {
      this.ticketEditId = 0;
      this.ticketDestroyId = 0;
      this.modalConfirmTicketRemove = false;
      this.dataTicketEdit = {};
      this.setData();
    },
    async requestDestroyTicket() {
      this.ticketLoading = true;
      const res = await requestWrapper.call(this, {
        url: this.uriDestroyTicket,
        method: 'delete',
        data: {
          exam_ticket_id: this.ticketDestroyId,
        },
      }, true);

      if (!res.error) {
        if (res.data && res.data.message) {
          this.$notify({
            text: res.data.message,
            type: 'success',
          });
        }
        eventHub.$emit('deletedTicket', true);
        this.cancelEdit();
      }
      if (res.error && res.data.errors) {
        this.$notify({
          text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
          type: 'error',
        });
      }
      this.ticketLoading = false;
    },
    async requestEditTicket() {
      this.ticketLoading = true;
      const res = await requestWrapper.call(this, {
        url: this.uriEditTicket,
        method: 'patch',
        data: {
          exam_ticket_id: this.dataTicket.id,
          name: this.dataTicket.name,
          body: this.dataTicket.body,
        },
      }, true);

      if (!res.error) {
        if (res.data && res.data.message) {
          this.$notify({
            text: res.data.message,
            type: 'success',
          });
        }
        eventHub.$emit('editedTicket', true);
        this.cancelEdit();
      }
      if (res.error && res.data.errors) {
        this.$notify({
          text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
          type: 'error',
        });
      }
      this.ticketLoading = false;
    },
  },
};
