<template>
  <div class="s-test-process__subjects-list">
    <div
      class="s-test-process__subject is-selected"
      style="text-align: center;"
      @click="moveToTest"
    >
      <span>{{ $trans.get('test.test_preview') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VTestMenu',
  methods: {
    moveToTest() {
      this.$store.commit('SET_TEST_STATUS', 'preview');
    },
  },
};
</script>
