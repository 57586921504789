<template>
  <div class="s-test-process__navigation">
    <div
      class="s-test-process__navigation-backward"
      @click="prev"
    >
      <span>
        {{ $trans.get('test.prev_question') }}
      </span>
    </div>
    <div
      v-if="TEST.status === 'begin'"
      class="s-test-process__navigation-count-steps"
    >
      {{ `${CURRENT_SUBJECT.curQuestionIdx + 1} / ${COUNT_QUESTIONS}` }}
    </div>
    <div
      v-if="TEST.status === 'wait'"
      class="s-test-process__navigation-end"
      @click="confirmEndOfTest"
    >
      {{ $trans.get('test.end_of_test') }}
    </div>
    <div
      v-if="TEST.status === 'preview'"
      class="s-test-process__navigation-end s-test-process__navigation-result"
      @click="gotoResult"
    >
      {{ $trans.get('test.test_show_result') }}
    </div>
    <div
      class="s-test-process__navigation-forward"
      @click="next"
    >
      <span>
        {{ $trans.get('test.next_question') }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import endOfTest from './mixins/endOfTest';

export default {
  name: 'VTestNavigation',
  mixins: [endOfTest],
  computed: {
    ...mapGetters([
      'COUNT_QUESTIONS',
      'CURRENT_SUBJECT',
      'CURRENT_QUESTION',
      'CURRENT_QMAP_NUM',
      'TEST',
    ]),
  },
  methods: {
    gotoResult() {
      this.$store.commit('SET_TEST_STATUS', 'end');
    },
    prev() {
      if (this.TEST.status === 'begin') {
        this.$store.commit('SET_PREV_QUESTION_SMART');
      } else {
        this.$store.commit('SET_PREV_QUESTION');
      }
    },
    next() {
      if (this.TEST.status === 'begin') {
        this.$store.commit('SET_NEXT_QUESTION_SMART');
      } else {
        this.$store.commit('SET_NEXT_QUESTION');
      }
    },
  },
};
</script>
