import VTable from '../VTable';

export default {
  components: { VTable },
  props: {
    firstRows: { type: Array, default: () => [] },
    firstCols: { type: Array, default: () => [] },
    firstAdaptiveWhen: { type: Number, default: 1024 },
    firstTitle: String,

    secondRows: { type: Array, default: () => [] },
    secondCols: { type: Array, default: () => [] },
    secondAdaptiveWhen: { type: Number, default: 1024 },
    secondTitle: String,
  },
};
