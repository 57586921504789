<template>
  <div
    class="s-timer"
    :class="level"
  >
    <div class="d-flex s-str-ico s-str-ico--thm-clock">
      <span v-if="hours">{{ getHours }}</span>
      <span v-if="minutes">{{ getMinutes }}</span>
      <span v-if="seconds">{{ getSeconds }}</span>
    </div>
  </div>
</template>

<script>
import endOfTest from './mixins/endOfTest';
import timeConvert from './helpers/timeConvert';

export default {
  name: 'VTimer',
  mixins: [endOfTest],
  props: {
    timestampStart: {
      type: Number,
      default: 0,
    },
    timestampEnd: {
      type: Number,
      default: 0,
    },
    timestampLeft: {
      type: Number,
      default: 0,
    },
    className: {
      type: String,
      default: '',
    },
    hours: {
      type: Boolean,
      default: true,
    },
    minutes: {
      type: Boolean,
      default: true,
    },
    seconds: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    time: {
      hours: '',
      minutes: '',
      seconds: '',
    },
    leftTime: 0,
    timer: null,
    level: '',
  }),

  computed: {
    getHours() {
      return this.time.hours;
    },
    getMinutes() {
      return this.time.minutes;
    },
    getSeconds() {
      return this.time.seconds;
    },
  },

  watch: {
    timestampLeft(val) {
      clearInterval(this.timer);
      this.timerStart();
    },
    // Перезагрузка страницы если у пользователя кончилось время и отключен интерннет
    leftTime(val) {
      if (val === 0 && navigator.onLine === false) {
        window.location.reload();
      }
    },
  },

  mounted() {
    this.timerStart();
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  methods: {
    timeConvert() {
      this.time = timeConvert(this.leftTime);
    },
    timerStart() {
      this.leftTime = this.timestampLeft;
      this.timer = setInterval(this.calcTime, 1000);
      this.calcTime();
    },
    calcTime() {
      if (this.leftTime > 0) {
        this.leftTime -= 1000;
        const percent = parseInt(
          this.leftTime / (this.timestampEnd - this.timestampStart) * 100,
          10,
        );

        if (percent >= 50) {
          this.level = 'is-level1';
        } else if (percent < 50 && percent >= 25) {
          this.level = 'is-level2';
        } else {
          this.level = 'is-level3';
        }

        this.timeConvert();
      } else {
        clearInterval(this.timer);
        this.endOfTest();
      }
    },
  },
};
</script>
<style scoped>
  .s-timer span:not( :last-child )::after {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
</style>
