<template>
  <div class="d-flex align-items-end">
    <div v-if="field.type !== 'btn-field'" class="s-w-10rem mr-10 sc-settings__text">
      {{ field.title }}: <i
        v-if="field.required && !item?.new"
        class="s-c-red"
      > *</i>
    </div>
    <div class="d-flex align-items-end">
      <component
        :is="field.type"
        v-if="field.type !== 'btn-field'"
        :key="itemEl.name"
        v-model="itemEl.value"
        :field="itemEl"
        field-name=""
        class="m-0 sc-settings__input"
      />
      <div v-else class="d-flex align-items-center">
        <q-btn
          class="mr-20"
          :label="itemEl.value.label"
          :color="itemEl.value.color"
          @click.stop.prevent="setModal(
            itemEl.value.modal,
            null,
            {
              modalSubmit: (data) => { updateModalData(data); },
            },
            { getUsers, module },
          )"
        />
        <span class="sc-settings__text">{{ itemEl.value.value }}</span>
      </div>
      <q-icon
        v-if="field.clearable"
        :name="itemEl.value && !itemEl?.new ? 'close' : 'check'"
        :color="itemEl.value && !itemEl?.new ? 'red' : 'green'"
        class="ml-10 sn--cursor-pointer"
        @click.stop.prevent="updateData"
      />
    </div>
  </div>
</template>

<script>
import {
  QIcon,
} from '@quasar/components';
import requests from './mixins/requests';

export default {
  name: 'VChildItem',
  components: {
    QIcon,
  },
  mixins: [requests],
  props: {
    module: {
      type: Object,
      default: null,
    },
    field: {
      type: Object,
      default: null,
    },
    item: {
      type: Object,
      default: null,
    },
    getUsers: {
      type: String,
      default: null,
    },
  },
  computed: {
    itemEl() {
      return this.item;
    },
  },
  watch: {
    'itemEl.value': function () {
      if (!this.field.clearable && this.field.type !== 'btn-field') {
        this.updateData();
      }
    },
  },
  methods: {
    updateModalData(data) {
      eventBus.$emit('updated-child-element', data);
    },
    async updateData() {
      const data = {
        key: this.module.key,
        field_key: this.itemEl.name,
        value: this.itemEl.value,
        action: this.itemEl?.new ? 'create' : 'delete',
      };
      eventBus.$emit('updated-child-element', data);
    },
  },
};
</script>
