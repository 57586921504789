import { StoreTranslator } from '@common/mixins';
import { Helper } from '@common/src/helpers';

export default {
  mixins: [StoreTranslator],
  props: {
    value: {},
    urlFiles: {
      type: String,
      required: true,
    },
    urlFilesStore: {
      type: String,
      required: true,
    },
    urlFilesDestroy: {
      type: String,
      required: true,
    },
    urlFilesMultiDestroy: {
      type: String,
      required: true,
    },
    urlFilesDownload: {
      type: String,
      required: true,
    },
  },
  data() {
    const vm = this;
    return {
      processSend: false,
      files: [],
      currentFile: null,
      isMultiDeleteActive: false,
      selectedFiles: [],
    };
  },
  mounted() {
    const vm = this;
    vm.loadFiles();
  },
  computed: {
    deleteBtnLabel() {
      if (this.isMultiDeleteActive) {
        if (this.selectedFiles.length) {
          return this.trans('button.confirm_delete_user_files');
        }
        return this.trans('button.cancel_delete_user_files');
      }
      return this.trans('button.delete_user_files');
    },
  },

  methods: {
    handleSelectAllFilesBtn() {
      if (this.files.length === this.selectedFiles.length) {
        this.selectedFiles = [];
      } else {
        this.selectedFiles = this.files.map(item => item.id);
      }
    },
    handleMultiDeleteBtn() {
      if (!this.isMultiDeleteActive) {
        this.isMultiDeleteActive = true;
      } else if (!this.selectedFiles.length) {
        this.isMultiDeleteActive = false;
      } else {
        this.openConfirm();
      }
    },
    handleAddFileToDelete(file) {
      if (this.isMultiDeleteActive) {
        const index = this.selectedFiles.indexOf(file);
        if (index > -1) {
          this.selectedFiles.splice(index, 1);
        } else {
          this.selectedFiles.push(file);
        }
      }
    },
    loadFiles() {
      const vm = this;
      const config = {
        responseType: 'json',
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      vm.$http.get(vm.urlFiles, config).then(
        (response) => {
          vm.$set(vm, 'processSend', false);
          vm.$nextTick(() => {
            vm.$set(vm, 'files', response.data);
          });
        },
        (response) => {
          vm.$set(vm, 'processSend', false);

          if (response.status === 422 || response.status === 423) {
            let errors = [];
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
            });
            show_notice(errors, 'error');
          } else {
            console.log(response);
          }
        },
      );
    },
    onClickInputFile() {
      const el = document.querySelector('#user-file-upload-input');
      if (el.onclick) {
        el.onclick();
      } else if (el.click) {
        el.click();
      }
    },
    onChangeInputFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      const file = files[0];
      e.target.value = null;
      this.upload(file);
    },
    upload(file) {
      const vm = this;
      if (vm.processSend === true) {
        return false;
      }
      vm.$set(vm, 'processSend', true);
      vm.$globalLoading.show();
      const formData = new FormData();

      formData.append('file', file);
      const config = {
        responseType: 'json',
        headers: {
          'X-CSRF-TOKEN': window.core_project.csrfToken,
          'Accept-Language': window.core_project.locale,
        },
      };
      vm.$http.post(vm.urlFilesStore, formData, config).then(
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(vm, 'processSend', false);
          const files = _.cloneDeep(vm.files);
          const isFileExists = files.find(file => file.id === response.data.id);
          if (!isFileExists) {
            files.unshift(response.data);
          } else {
            show_notice(vm.trans('notice.file_already_exists'), 'warn');
          }
          vm.$set(vm, 'files', files);
        },
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(vm, 'processSend', false);

          if (response.status === 422 || response.status === 423) {
            let errors = [];
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
            });
            show_notice(errors, 'error');
          } else {
            console.log(response);
          }
        },
      );
    },
    openConfirm(file) {
      const vm = this;
      vm.$set(vm, 'currentFile', file);
      $('#modal-confirm-destroy').modal('show');
    },
    destroy(file) {
      const vm = this;
      vm.$globalLoading.show();

      let url = '';
      if (!this.isMultiDeleteActive) {
        url = Helper.urlGenerate(vm.urlFilesDestroy, { id: file.id });
      } else {
        url = this.urlFilesMultiDestroy;
      }
      const config = {
        responseType: 'json',
        headers: {
          'Accept-Language': window.core_project.locale,
          'X-CSRF-TOKEN': window.core_project.csrfToken,
        },
      };

      const data = {};
      if (this.isMultiDeleteActive) {
        data.fileIds = this.selectedFiles;
      }

      const successResp = (response) => {
        vm.$globalLoading.hide();
        $('#modal-confirm-destroy').modal('hide');
        this.selectedFiles = [];
        this.currentFile = null;
        this.isMultiDeleteActive = false;

        vm.$set(vm, 'processSend', false);
        vm.loadFiles();
      };

      const failedResp = (response) => {
        vm.$globalLoading.hide();
        $('#modal-confirm-destroy').modal('hide');
        vm.$set(vm, 'processSend', false);

        if (response.status === 422 || response.status === 423) {
          let errors = [];
          _.forEach(response.data.errors, (value, key) => {
            errors = errors.concat(value);
          });
          show_notice(errors, 'error');
        } else {
          console.log(response);
        }
      };

      if (this.isMultiDeleteActive) {
        vm.$http.post(url, data, config).then(successResp, failedResp);
      } else {
        vm.$http.delete(url, data, config).then(successResp, failedResp);
      }
    },
  },
};
