import { StoreTranslator } from '@common/mixins';


export default {
  mixins: [StoreTranslator],
  props: {
    urlBack: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    model: {
      type: [Array, Object],
      required: false,
    },
  },
  data() {
    const vm = this;
    const fields = {

      type: {
        value: null,
        name: 'type',
        component: 'v-multi-select-field',
        labelTransKey: 'label.training_schedule_type',
        values: [],
        required: true,
        loadValues: {
          url: '/api/refs/schedules/types',
          headers: {
            'X-Permission': 'training-schedule-create',
          },
        },
        extraAttributes: {
          disabled: vm.method === 'patch',
        },
        v_if: true,
      },
      name: {
        value: null,
        name: 'name',
        component: 'text-field',
        labelTransKey: 'fields.name',
        values: [],
        required: true,
        v_if: true,
      },
      group_id: {
        value: null,
        name: 'group_id',
        component: 'v-multi-select-field',
        labelTransKey: 'main.group',
        values: [],
        required: true,
        loadValues: {
          url: '/api/groups',
          headers: {
            'X-Permission': 'training-schedule-create',
          },
        },
        extraAttributes: {
          disabled: vm.method === 'patch',
        },
      },
      org_education_time_group_id: {
        value: '',
        name: 'org_edu_time_group_id',
        component: 'v-multi-select-field',
        labelTransKey: 'menu.class_schedule',
        values: [],
        required: true,
        loadValues: {
          url: '/api/refs/org-education-time-group',
          headers: {
            'X-Permission': 'training-schedule-create',
          },
        },
        extraAttributes: {
          disabled: vm.method === 'patch',
        },
      },
      calendar_thematic_plan_ids: {
        value: [],
        name: 'calendar_thematic_plan_ids',
        component: 'v-multi-select-field',
        labelTransKey: 'calendar-thematic-plan.label',
        values: [],
        required: false,
        loadValues: {
          url: '/api/refs/calenar-thematic-plans',
          headers: {
            'X-Permission': 'training-schedule-create',
          },
        },
        extraAttributes: {
          multiple: true,
        },
      },
      date_semester_one_start: {
        value: '',
        name: 'date_semester_one_start',
        component: 'date-field',
        labelTransKey: 'label.date_semester_one_start',
        required: true,
      },
      date_semester_one_end: {
        value: '',
        name: 'date_semester_one_end',
        component: 'date-field',
        labelTransKey: 'label.date_semester_one_end',
        required: true,
      },
      date_semester_two_start: {
        value: '',
        name: 'date_semester_two_start',
        component: 'date-field',
        labelTransKey: 'label.date_semester_two_start',
      },
      date_semester_two_end: {
        value: '',
        name: 'date_semester_two_end',
        component: 'date-field',
        labelTransKey: 'label.date_semester_two_end',
      },
    };
    return {
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
          // TODO привести к одному варианту
          if (data.redirect) {
            window.location = data.redirect;
          }
        },
      },
    };
  },
  beforeCreate() {
    this.$trans.add(['fields', 'warning_text', 'placeholder', 'label', 'button', 'main', 'notice', 'menu', 'training_schedule', 'calendar-thematic-plan']);
  },
  mounted() {
    const vm = this;
    if (vm.model !== undefined) {
      _.forEach(vm.model, (value, key) => {
        if (vm.form.fields.hasOwnProperty(key)) {
          vm.$nextTick(() => {
            vm.form.fields[key].value = value;
          });
        }
      });
    }
  },

  destroyed() {
  },
  methods: {
    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(form.fields, (el, key) => {
        if (el.v_if !== undefined && !el.v_if) {
          return;
        }

        if ((typeof el.value === 'object') && el.value !== null) {
          const arrField = [];
          _.forEach(el.value, (arrEl) => {
            arrField.push(arrEl.id);
          });
          _.set(data, key, arrField);
        } else {
          _.set(data, vm.nameToDot(el.name), el.value);
        }
      });
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
  watch: {
    'form.fields.type.value': function (newValue, oldValue) {
      switch (newValue) {
        case 'for_individual':
          this.form.fields.group_id.v_if = false;
          break;
        case 'for_mixed':
          this.form.fields.group_id.v_if = false;
          break;
        default:
          this.form.fields.group_id.v_if = true;
      }
    },
  },
};
