import JournalEditPoint from './Journal/JournalEditPoint';
import JournalGenerateWeeks from './Journal/JournalGenerateWeeks';
import JournalCalcResult from './Journal/JournalCalcResult';
import JournalDayForm from './Journal/JournalDayForm';
import Confirm from './Confirm';
import ConfirmNew from './ConfirmNew';
import ConfirmWithMessage from './ConfirmWithMessage';
import StatementAttestationAveragePoint from './StatementAttestationAveragePoint';
import SelectUserFile from '../../views/SelectUserFile';
import ReassignSpecialtyPlaces from './ReassignSpecialtyPlaces';
import VAttendanceModal from './Attendance/DayAttendance';
import VContingentAttendanceModal from './Attendance/ContingentAttendance';
import VMultipleButtonsModal from './MultipleButtons';
import VModalExamQuestionsImport from './ExamQuestions/Import';
import VHolidaysModal from './Attendance/Holidays';
import ProgramChapter from './CurriculumWorkingProgram/ProgramChapter';
import ProgramLesson from './CurriculumWorkingProgram/ProgramLesson';
import ProgramDuplicate from './CurriculumWorkingProgram/ProgramDuplicate';
import VQuestionVisibilityModal from './ExamQuestions/VQuestionVisibilityModal';
import MultiExportModal from './MultiExportModal';
import VGraduatedContingentForm from './Contingent';
import StatementSocialStatus from './StatementSocialStatus';
import EditDiplomaMargins from './EditDiplomaMargins';
import DuplicateContingentTranscript from './DuplicateContingentTranscript';
import TranscriptChangePosition from './TranscriptChangePosition';
import CurriculumAddSection from './CurriculumAddSection';
import DormitoryRoomModal from './Dormitory/RoomModal';
import DormitoryToAnotherRoomModal from './Dormitory/ToAnotherRoomModal';
import SelectFileByElementModal from './SelectFileByElementModal';
import StatementAppealModal from './StatementAppealModal';
import VAgreementModal from './VAgreementModal';
import CurriculumDeleteSection from './CurriculumDeleteSection';
import VUploadFIleModal from './Settings/VUploadFIleModal';
import SettingConfirmModal from './Settings/SettingConfirmModal';
import ExceptionUsersModal from './Settings/ExceptionUsersModal';
import VAddPracticeInfo from './Practice';
import VAddPracticeDiary from './Practice/Diary';
import EducationResultContingentTranscript from './EducationResultContingentTranscript';
import DocumentFlow from './DocumentFlow';
import CalendarThematicPlanDuplicateModal from './CalendarThematicPlanDuplicateModal';
import ContingentSocialAchievementAdd from './ContingentSocialAchievementAdd';
import PracticeGradeModal from './Practice/PracticeGradeModal';
import PracticeCompletionModal from './Practice/PracticeCompletionModal';
import DiplomaFullNameDeclensionModal from './DiplomaFullNameDeclensionModal';
import EditDiplomaSubjectsCountModal from './Settings/EditDiplomaSubjectsCountModal';
import JournalDayPlanDuplicate from './Journal/JournalDayPlanDuplicate';
import ContingentTranscriptExportModal from './ContingentTranscriptExportModal';

export default function (Vue) {
  Vue.component('v-modal-journal-edit-point', JournalEditPoint);
  Vue.component('v-modal-journal-generate-weeks', JournalGenerateWeeks);
  Vue.component('v-modal-journal-calc-result', JournalCalcResult);
  Vue.component('v-modal-journal-day-form', JournalDayForm);
  Vue.component('v-modal-confirm', Confirm);
  Vue.component('v-modal-confirm-new', ConfirmNew);
  Vue.component('v-modal-statement-attestation-average-point', StatementAttestationAveragePoint);
  Vue.component('v-modal-select-user-files', SelectUserFile);
  Vue.component('v-modal-reassign-specialty-places', ReassignSpecialtyPlaces);
  Vue.component('v-modal-confirm-with-message', ConfirmWithMessage);
  Vue.component('v-modal-attendance', VAttendanceModal);
  Vue.component('v-modal-contingent-attendance', VContingentAttendanceModal);
  Vue.component('v-modal-multi-button', VMultipleButtonsModal);
  Vue.component('v-modal-exam-questions-import', VModalExamQuestionsImport);
  Vue.component('v-holidays-modal', VHolidaysModal);
  Vue.component('v-modal-add-chapter', ProgramChapter);
  Vue.component('v-modal-add-lesson', ProgramLesson);
  Vue.component('v-modal-program-duplicate', ProgramDuplicate);
  Vue.component('v-question-visibility-modal', VQuestionVisibilityModal);
  Vue.component('v-multiple-export-modal', MultiExportModal);
  Vue.component('v-graduated-contingent-form-modal', VGraduatedContingentForm);
  Vue.component('v-statement-social-status-modal', StatementSocialStatus);
  Vue.component('v-modal-edit-diploma-margins', EditDiplomaMargins);
  Vue.component('v-modal-duplicate-contingent-transcript', DuplicateContingentTranscript);
  Vue.component('v-modal-transcript-change-position', TranscriptChangePosition);
  Vue.component('v-modal-curriculum-add-section', CurriculumAddSection);
  Vue.component('v-dormitory-room-modal', DormitoryRoomModal);
  Vue.component('v-dormitory-to-another-room-modal', DormitoryToAnotherRoomModal);
  Vue.component('v-select-file-by-element-modal', SelectFileByElementModal);
  Vue.component('v-statement-appeal-modal', StatementAppealModal);
  Vue.component('v-agreement-modal', VAgreementModal);
  Vue.component('v-modal-curriculum-delete-section', CurriculumDeleteSection);
  Vue.component('v-modal-upload-file', VUploadFIleModal);
  Vue.component('v-setting-confirm-modal', SettingConfirmModal);
  Vue.component('v-exception-user-modal', ExceptionUsersModal);
  Vue.component('v-add-practice-modal', VAddPracticeInfo);
  Vue.component('v-add-practice-diary-modal', VAddPracticeDiary);
  Vue.component('v-modal-education-result-contingent-transcript', EducationResultContingentTranscript);
  Vue.component('v-document-flow-modal', DocumentFlow);
  Vue.component('v-calendar-thematic-plan-duplicate-modal', CalendarThematicPlanDuplicateModal);
  Vue.component('v-contingent-social-achievement-add', ContingentSocialAchievementAdd);
  Vue.component('v-practice-grade-modal', PracticeGradeModal);
  Vue.component('v-practice-completion-modal', PracticeCompletionModal);
  Vue.component('v-diploma-full-name-declension-modal', DiplomaFullNameDeclensionModal);
  Vue.component('v-edit-diploma-subject-count-modal', EditDiplomaSubjectsCountModal);
  Vue.component('v-modal-journal-day-plan-duplicate', JournalDayPlanDuplicate);
  Vue.component('v-contingent-transcript-export-modal', ContingentTranscriptExportModal);
}
