<template>
  <q-dialog
    persistent
    :value="modal"
    @input="handleModal"
  >
    <v-modal
      size="480"
      class="contest__modal"
    >
      <div
        class="row no-gutters justify-content-center"
        :loading="loading"
      >
        <p class="contest__modelHeading">
          {{ trans('contest.modal_heading') }}
        </p>
      </div>
      <div class="contest__form">
        <div class="d-flex align-items-center justify-content-sm-between">
          <p>
            {{ trans('label.applicant.name') }}
          </p>
          <p class="contest__right">
            {{ user.fullName }}
          </p>
        </div>
        <div class="d-flex align-items-center justify-content-sm-between">
          <p>
            {{ trans('label.applicant.organization') }}
          </p>
          <p class="contest__right">
            {{ user.personal.organization.short_name }}
          </p>
        </div>
        <div class="d-flex align-items-center justify-content-sm-between">
          <p>
            {{ trans('label.email') }}
          </p>
          <p
            v-if="user.email"
            class="contest__right"
          >
            {{ user.email }}
          </p>
          <q-input
            v-else
            v-model="form.email"
            required
          />
        </div>
        <div class="d-flex align-items-center justify-content-sm-between">
          <p>
            {{ trans('label.insta_account') }}
          </p>
          <q-input
            v-model="form.account"
            required
          />
        </div>
      </div>
      <div class="mt-20 row-sort-between-btn pl-20 pr-20">
        <q-btn
          class="s-btn--w-150"
          color="grey"
          :label="trans('button.cancel')"
          :loading="loading"
          @click="handleModal"
        />
        <q-btn
          class="s-btn--w-200"
          color="green"
          :label="trans('button.save')"
          :loading="loading"
        />
      </div>
    </v-modal>
  </q-dialog>
</template>

<script>
import VModal from '@vjs/components/VModal';

export default {
  name: 'VContestModalView',
  components: {
    VModal,
  },
  props: {
    modal: Boolean,
    user: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        email: '',
        account: '',
      },
    };
  },
  methods: {
    async handleModal() {
      if (!this.loading) {
        this.$emit('modal-close', false);
      }
    },
  },
};
</script>
