import { StoreTranslator } from '@common/mixins';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/ru.js';

export default {
  mixins: [StoreTranslator],
  props: {
    url: {
      type: String,
      required: true,
    },
    urlUploadFile: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    types: {
      type: Array,
    },
    post: {
      type: [Object, Array],
      required: false,
    },
    urlUserFiles: {
      type: String,
      required: false,
    },
  },
  data() {
    const vm = this;
    const fields = {
      name: {
        value: '',
      },
      post_type_id: {
        value: null,
      },
      is_comments: {
        value: 1,
      },
      file: {
        value: null,
        user_file_name: '',
      },
      body: {
        value: '',
        editor: ClassicEditor,
        editorConfig: {
          language: 'ru',
          toolbar: {
            items: [
              'heading',
              '|',
              'bold',
              'italic',
              'link',
              'bulletedList',
              'numberedList',
              'imageUpload',
              'blockQuote',
              'insertTable',
              'mediaEmbed',
              'undo',
              'redo',
            ],
          },
          image: {
            toolbar: ['toggleImageCaption', 'imageTextAlternative'],
          },
        },
      },
    };
    if (vm.post) {
      fields.name.value = vm.post.name;
      if (vm.types) {
        fields.post_type_id.value = vm.post.post_type_id;
      }
      if (vm.post.file) {
        fields.file.value = vm.post.file.id;
        fields.file.user_file_name = vm.post.file.upload_file;
      }
      fields.is_comments.value = vm.post.is_comments;
      fields.body.value = vm.post.body;
    }
    return {
      UploadAdapter: function UploadAdapter(loader) {
        this.loader = loader;
        this.upload = () =>
        // TODO реализовать загрузку фото возращать надо ссылку на изображение
        // TODO переписать на vm.$http.post

          this.loader.file
            .then(file => new Promise((resolve, reject) => {
              const body = new FormData();
              body.append('file', file);
              const config = {
                responseType: 'json',
                method: 'POST',
                headers: {},
              };
              config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;
              vm.$http.post(vm.urlUploadFile, body, config).then(
                response => resolve(response.data),
                (response) => {
                  if (response.status === 422 || response.status === 423) {
                    let errors = [];
                    _.forEach(response.data.errors, (value, key) => {
                      errors = errors.concat(value);
                    });
                    show_notice(errors, 'error');
                  } else {
                    console.log(response);
                  }
                  return reject('error');
                },
              );
            }));
        this.abort = () => {
          console.log('Abort upload.');
        };
      },
      openModalFiles: false,
      form: {
        processSend: false,
        action: vm.url,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
        },
      },
    };
  },
  mounted() {
    const vm = this;
  },
  destroyed() {
  },
  methods: {
    openSelectFile(file) {
      const vm = this;
      vm.form.fields.file = file;
      vm.$set(vm, 'openModalFiles', true);
    },
    onSelectClose() {
      const vm = this;
      vm.$set(vm, 'openModalFiles', false);
    },
    selectFile(file) {
      const vm = this;
      vm.form.fields.file.value = file.id;
      vm.form.fields.file.user_file_name = file.name;
      vm.$set(vm, 'openModalFiles', false);
    },
    onEditorReady(instance) {
      const fileRepository = instance.plugins.get('FileRepository');

      if (fileRepository != null) {
        fileRepository.createUploadAdapter = loader => new this.UploadAdapter(loader);
      }
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(form.fields, (el, key) => {
        data[key] = el.value;
      });
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
            });
            show_notice(errors, 'error');
            form.errors = response.data.errors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return form.errors[field] !== undefined;
    },
    getError(form, field) {
      return _.first(form.errors[field]);
    },

  },
};
