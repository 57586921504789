import Vue from 'vue';
import './Common';
import './Views';

import vSelect from 'vue-select';
import VNoResults from '@vjs/components/VNoResults';
import TableCurriculum from './Views/TableCurriculum';
import SelectUserFile from './Views/SelectUserFile';
import DocumentFlowStatementCreate from './Views/DocumentFlow/StatementCreate';
import DocumentFlowStatementFiles from './Views/DocumentFlow/StatementFiles';
import UserFiles from './Views/UserFiles';
import PostForm from './Views/Posts/Form';
import PostCommentForm from './Views/PostComments/Form';
import IssueForm from './Views/Issues/Form';
import IssueCommentForm from './Views/Issues/Comments/Form';
import ProfileForm from './Views/Profile/Form';
import ProfileAvatar from './Views/ProfileAvatar';
import UserStatementAdmissionForm from './Views/UserStatementAdmission/StatementForm';
import UserStatementAdmissionFormFiles from './Views/UserStatementAdmission/FilesForm';
import ScheduleForm from './Views/Schedule/Form';
import GroupManagement from './Views/Group/Management';
import DistributionContingentForm from './Views/DistributionContingent/Form';
import DistributionContingentGroupForm from './Views/DistributionContingentGroup/Form';
import AdditionalPeopleInfoForm from './Views/AdditionalPeopleInfo/Form';
import UserGovServiceStatementForm from './Views/UserGovServiceStatement/StatementForm';
import UserGovServiceStatementFormFiles from './Views/UserGovServiceStatement/FilesForm';
import ExamForm from './Views/Exam/Form';
import ExamTrainingSchedule from './Views/Exam/TrainingSchedule';
import PersonnelForm from './Views/Personnel/Form';
import LoadingForm from './Views/Loading/Form';
import 'vue-select/dist/vue-select.css';
import VTestProcess from './Views/TestProcess/VTestProcess.vue';
import VStatementServiceSteps from './Views/VStatementServiceSteps/VStatementServiceSteps';
import VAdditionalInfoForm from './Views/UserGovServiceStatement/AdditionalInfoForm';
import VMedicalCommissionForm from './Views/MedicalCommission/Form';

Vue.component('v-select', vSelect);
Vue.component('vue-table-curriculum', TableCurriculum);
Vue.component('vue-document-flow-statement-create', DocumentFlowStatementCreate);
Vue.component('vue-document-flow-statement-files', DocumentFlowStatementFiles);
Vue.component('vue-select-user-file', SelectUserFile);
Vue.component('vue-user-files', UserFiles);
Vue.component('vue-post-form', PostForm);
Vue.component('vue-post-comment-form', PostCommentForm);
Vue.component('vue-issue-form', IssueForm);
Vue.component('vue-issue-comment-form', IssueCommentForm);
Vue.component('vue-profile-form', ProfileForm);
Vue.component('vue-profile-avatar', ProfileAvatar);
Vue.component('vue-user-statement-admission-form', UserStatementAdmissionForm);
Vue.component('vue-user-statement-admission-form-files', UserStatementAdmissionFormFiles);
Vue.component('vue-user-gov-service-statement-form', UserGovServiceStatementForm);
Vue.component('vue-user-gov-service-statement-form-files', UserGovServiceStatementFormFiles);
Vue.component('v-statement-service-steps', VStatementServiceSteps);
Vue.component('vue-schedule-form', ScheduleForm);
Vue.component('vue-group-management', GroupManagement);
Vue.component('vue-distribution-contingent-form', DistributionContingentForm);
Vue.component('vue-distribution-contingent-group-form', DistributionContingentGroupForm);
Vue.component('vue-additional-people-info-form', AdditionalPeopleInfoForm);
Vue.component('vue-exam-form', ExamForm);
Vue.component('vue-exam-training-schedule', ExamTrainingSchedule);
Vue.component('vue-personnel-form', PersonnelForm);
Vue.component('v-no-results', VNoResults);
Vue.component('v-test-process', VTestProcess);
Vue.component('vue-loading-form', LoadingForm);
Vue.component('v-additional-info-form', VAdditionalInfoForm);
Vue.component('vue-medical-commission-form', VMedicalCommissionForm);
