<template>
  <q-dialog
    :value="modal"
    @input="handleModal"
  >
    <v-modal
      size="480"
    >
      <div class="mb-30">
        {{ trans('notice.region_works_with_egov') }}
      </div>
      <div class="row justify-content-between">
        <div class="col-12 col-sm-auto mb-10">
          <q-btn
            v-close-popup
            class="s-w-100"
            width="20"
            :label="trans('button.close')"
            label-wider
            color="grey"
          />
        </div>
        <div class="col-12 col-sm-auto">
          <q-btn
            class="s-w-100"
            width="20"
            :label="trans('button.go_to')"
            color="green"
            target="_blank"
            type="a"
            :to="link"
          />
        </div>
      </div>
    </v-modal>
  </q-dialog>
</template>

<script>
import {
  QDialog,
  QBtn,
} from '@quasar/components';
import { ClosePopup } from '@quasar/directives';
import VModal from '@vjs/components/VModal';

export default {
  name: 'VPassportAdmissionModal',
  components: {
    QDialog,
    QBtn,
    VModal,
  },

  directives: { ClosePopup },

  props: {
    modal: Boolean,
    link: {
      type: String,
      default: '',
    },
  },

  methods: {
    handleModal() {
      this.$emit('modal-close', false);
    },
  },
};
</script>
