<template>
  <div style="">
    <table class="s-table s-table--striped s-table--thm-head-grey">
      <thead class="s-table__head">
        <tr>
          <th
            class="s-to-center"
          >
            {{ trans('main.profession') }}
          </th>
          <th
            class="s-to-center"
          >
            {{ trans('statement.qualification') }}
          </th>
          <th
            class="s-to-center"
          >
            {{ trans('main.count_places') }}
          </th>
          <th
            class="s-to-center"
          >
            {{ trans('label.edu_level') }}
          </th>
          <th
            class="s-to-center "
          >
            {{ trans('table.prepare_type') }}
          </th>
          <th
            class="s-to-center"
          >
            {{ trans('label.edu_lang') }}
          </th>
          <th
            class="s-to-center"
          >
            {{ trans('main.edu_form') }}
          </th>
          <th
            class="s-to-center s-maxw-200"
          >
            {{ trans('statement.start_date_slash_end_date') }}
          </th>
          <th
            class="s-to-center"
          >
            {{ trans('statement.statements_count') }}
          </th>
        </tr>
      </thead>
      <tbody class="s-table__body">
        <template v-for="(data, parentKey) in table">
          <tr
            v-for="(item, childKey) in data.items"
            :key="`parent-${parentKey}-child-${childKey}`"
          >
            <td
              v-if="childKey === 0"
              :data-table-head="trans('main.profession')"
              :rowspan="data.items.length"
            >
              <div>
                {{ data.profession }}
              </div>
            </td>
            <td
              :data-table-head="trans('statement.qualification')"
            >
              <div>
                {{ item.qualification }}
              </div>
            </td>
            <td
              :data-table-head="trans('main.overall_places')"
            >
              <div class="s-to-center">
                {{ item.places_count }}
              </div>
            </td>
            <td
              :data-table-head="trans('label.edu_level')"
            >
              <div class="s-to-center">
                {{ item.edu_level }}
              </div>
            </td>
            <td
              :data-table-head="trans('table.prepare_type')"
            >
              <div
                :class="$q.screen.width > 490 ? 's-to-center' : ''"
              >
                {{ item.prepare_type }}
              </div>
            </td>
            <td
              :data-table-head="trans('label.edu_lang')"
            >
              <div :class="$q.screen.width > 490 ? 's-to-center' : ''">
                {{ item.edu_lang }}
              </div>
            </td>
            <td
              :data-table-head="trans('main.edu_form')"
            >
              <div class="s-to-center">
                {{ item.edu_form }}
              </div>
            </td>
            <td
              :data-table-head="trans('statement.start_date_slash_end_date')"
            >
              <div class="s-to-center">
                {{ `${item.date_start} - ${item.date_end}` }}
              </div>
            </td>
            <td
              :data-table-head="trans('statement.start_date_slash_end_date')"
            >
              <div class="s-to-center">
                {{ item.statements_count }}
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <v-passport-admission-modal
      v-if="admissionModal && admissionModalLink"
      :modal="admissionModal"
      :link="admissionModalLink"
      @modal-close="admissionModal = $event"
    />
  </div>
</template>

<script>
import {
  QBtn,
} from '@quasar/components';
import VPassportAdmissionModal from './VPassportAdmissionModal.vue';

export default {
  name: 'VPassportTable',

  components: {
    QBtn,
    VPassportAdmissionModal,
  },

  props: {
    table: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      admissionModal: false,
      admissionModalLink: '',
    };
  },

  methods: {
    handleBtn(btn) {
      if (!btn.isActive) {
        if (btn.admissionModal) {
          this.admissionModal = true;
          this.admissionModalLink = btn.admissionModalLink;
        }

        if (btn.message) {
          this.$notify({
            text: btn.message,
            type: 'error',
          });
        }

        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
  table {
    td:first-child,
    tr:first-child th:first-child {
      min-width: 33rem;
      max-width: 33rem;
    }

    td:nth-child( 2 ),
    tr:first-child th:nth-child( 2 ) {
      max-width: 23rem;
    }
  }

  .s-table__head {
    th {
      border: 1px solid #F3F3F3;
      color: #737A97;
    }

    tr {
      &:first-child th {
        &:last-child,
        &:first-child {
          border-right: unset !important;
          border-left: unset !important;
        }
      }

      &:first-child th,
      &:last-child th {
        border-bottom: unset !important;
      }
    }
  }

  .s-table__body {
    tr {
      td {
        &:not( :last-child ) {
          border-right: 1px solid #F3F3F3;
        }
      }

      &:first-child {
        box-shadow: inset 0 6px 5px -5px #DEDEDE;

        td {
          border-top: unset;
        }
      }

      &:last-child td {
        border-bottom: unset;
      }
    }
  }

  tbody {
    tr {
      &:nth-child( odd ) {
        background-color: unset;
      }
    }
  }

  @media screen and ( max-width: 1008px ) {
    table {
      td:first-child,
      tr:first-child th:first-child {
        min-width: unset;
        max-width: unset;
      }

      td:nth-child( 2 ),
      tr:first-child th:nth-child( 2 ) {
        min-width: unset;
        max-width: unset;
      }
    }

    .s-table__body {
      tr:first-child {
        box-shadow: unset;

        td {
          border-top: 1px solid #F3F3F3;
        }
      }
    }
  }
</style>
