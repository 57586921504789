export default {
  methods: {
    makeArray(number) {
      return [...Array(number).keys()];
    },
    mapDefault(numbers) {
      return numbers.map(item => ({
        label: item + 1,
        value: item + 1,
      }));
    },
  },
};
