import { Helper } from '@common/src/helpers';

export default {
  makeTimeSheetUrl: (url, groupId) => Helper.urlGenerate(url, { groupId }),
  vm: null,
  attendanceApiConfig: {
    timeSheetGet: '',
    timeSheetMark: '',
    timeSheetDaysMark: '',
    attendanceApiConfig: '',
    timeSheetExport: '',
  },
  timeSheet: [],
  attendance: [],
  holidays: {
    first_semester_start: null,
    first_semester_end: null,
    second_semester_start: null,
    second_semester_end: null,
  },
  overallData: [],
  availableDates: {},
  currentMonth: {},
  currentYear: null,
  dateStrings: [],
  dayColumns: {},
  dayCount: null,
  isGroupTrashed: false,
  allowEditAll: false,
  markTypesArr: [],
  groupId: null,
  name: '',
  back: '',
};
