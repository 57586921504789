import Vue from 'vue';
import store from './VueJS/store';
import VJournalForm from './VueJS/views/VJournalForm/index';


if (document.getElementById('v-journal-form-app')) {
  window.vJournalFormApp = new Vue({
    el: '#v-journal-form-app',
    components: { VJournalForm },
    store,
  });
}
