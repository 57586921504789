<template>
  <table
    class="tbl-cont table-curriculum"
  >
    <thead class="tbl-cont__head">
      <tr>
        <th
          rowspan="3"
          class="sticky-col first-col"
        >
          {{ $trans.get('curriculum.table_plan_head_index') }}
        </th>
        <th
          rowspan="3"
          class="sticky-col second-col"
        >
          {{
            $trans.get('curriculum.table_plan_head_discipline') }}
        </th>
        <th rowspan="3">
          <span class="text_bottom_to_up">
            Количество часов по ГОСО
          </span>
        </th>
        <th
          colspan="4"
          rowspan="2"
        >
          {{
            $trans.get('curriculum.table_plan_head_control') }}
        </th>
        <th colspan="4">
          {{ $trans.get('curriculum.table_plan_head_duration') }}
        </th>
        <th :colspan="semesterNumbers">
          {{
            $trans.get('curriculum.table_plan_head_courses') }}
        </th>
      </tr>
      <tr>
        <th rowspan="2">
          <span class="text_bottom_to_up">
            {{ $trans.get('curriculum.table_plan_head_duration_total') }}
          </span>
        </th>
        <th colspan="3">
          {{ $trans.get('curriculum.of_them_on') }}
        </th>
        <th
          v-for="i in courseNumber"
          colspan="2"
          class="course-number"
        >
          {{ $trans.get('curriculum.number_course',{'n':i}) }}
        </th>
      </tr>
      <tr>
        <th>
          <span class="text_bottom_to_up">
            {{ $trans.get('curriculum.table_plan_head_control_exam') }}
          </span>
        </th>
        <th>
          <span class="text_bottom_to_up">
            {{ $trans.get('curriculum.table_plan_head_control_test') }}
          </span>
        </th>
        <th>
          <span class="text_bottom_to_up">
            {{ $trans.get('curriculum.table_plan_head_control_work') }}
          </span>
        </th>
        <th>
          <span class="text_bottom_to_up">
            {{ $trans.get('curriculum.table_plan_head_control_project') }}
          </span>
        </th>
        <th>
          <span class="text_bottom_to_up">
            {{ $trans.get('curriculum.table_plan_head_duration_theory') }}
          </span>
        </th>
        <th>
          <span class="text_bottom_to_up">
            {{ $trans.get('curriculum.table_plan_head_duration_practice') }}
          </span>
        </th>
        <th>
          <span class="text_bottom_to_up">
            {{ $trans.get('curriculum.table_plan_head_duration_project') }}
          </span>
        </th>
        <template v-for="(i, key) in courseNumber">
          <th
            :key="'1-'+i"
            class="semester-number"
          >
            {{ $trans.get('journal.number_semester', {'n':1}) }}
          </th>
          <th
            v-if="key != courseNumber - 1 || semesterNumbers%2 != 1"
            :key="'2-'+i"
            class="semester-number"
          >
            {{ $trans.get('journal.number_semester', {'n':2}) }}
          </th>
        </template>
      </tr>
    </thead>
    <tbody>
      <template v-for="group in groups">
        <tr class="table-curriculum-body__title">
          <td class="sticky-col first-col">
            {{
              group.education_group_discipline.code }}
          </td>
          <td class="sticky-col second-col">
            <div>
              {{ group.education_group_discipline.name }}
            </div>
          </td>
          <td v-for="i in (9+semesterNumbers)" />
        </tr>
        <template
          v-for="(curriculum_working_discipline, index) in group.curriculum_working_disciplines_parents"
        >
          <tr>
            <td class="sticky-col first-col">
              <div
                v-if="!checkEditValue( curriculum_working_discipline)"
              >
                {{ group.mc_data.indexCode }} - {{ index+1 }}
              </div>
              <div v-else>
                <input
                  v-model="group.mc_data.indexCode"
                  type="text"
                  class="s-form__input"
                > - {{ index+1 }}
              </div>
            </td>
            <td
              class="sticky-col second-col"
            >
              <div
                v-if="!checkEditValue( curriculum_working_discipline)"
                class="table-curriculum-body__discipline_name"
              >
                {{
                  curriculum_working_discipline.education_discipline.name
                }}
              </div>
              <div
                v-else
                class="table-curriculum-body__discipline_name"
                style="padding-right: unset;"
              >
                <label class="s-form__label mb-10">
                  {{ $trans.get('fields.curriculum_discipline_id') }}:
                </label>
                <v-multiselect
                  v-model="disciplinesEdits[curriculum_working_discipline.id].education_discipline"
                  class="mb-10"
                  label="name"
                  value="id"
                  style="max-width: 300px;"
                  track-by="id"
                  :options="getRefDisciplines()"
                  :placeholder="$trans.get('placeholder.choose')"
                >
                  <template slot="noOptions">
                    {{ $trans.get('select.list_is_empty') }}
                  </template>
                  <template slot="noResult">
                    {{ $trans.get('select.list_is_empty') }}
                  </template>
                </v-multiselect>
              </div>
              <template
                v-if="!(curriculum_working_discipline.children&&curriculum_working_discipline.children.length>0)"
              >
                <div
                  v-if="checkEditValue( curriculum_working_discipline)"
                  class="table-curriculum-button-wrapper"
                >
                  <div
                    class="s-btn s-btn--thm-grey"
                    style="margin-bottom: 1rem; width:100%;"
                    @click="hideEditValue(curriculum_working_discipline)"
                  >
                    <div
                      class="s-str-ico s-str-ico--thm-angle-left s-str-ico--wider"
                    >
                      {{ $trans.get('curriculum.action_cancel') }}
                    </div>
                  </div>
                  <div
                    class="s-btn s-btn--thm-green"
                    style="width:100%;"
                    @click="saveEditValue(curriculum_working_discipline, group.mc_data.indexCode)"
                  >
                    <div
                      class="s-str-ico s-str-ico--thm-check s-str-ico--wider"
                    >
                      {{
                        $trans.get('curriculum.action_save') }}
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="isEdit"
                  class="table-curriculum-control-wrapper"
                >
                  <div
                    class="s-btn-table s-btn--ico"
                    @click="showEditValue(curriculum_working_discipline)"
                  >
                    <div class="s-ico s-ico--thm-pencil" />
                  </div>
                  <div
                    class="s-btn-table s-btn--ico"
                    @click="showConfirmDelete(curriculum_working_discipline)"
                  >
                    <div class="s-ico s-ico--thm-trash" />
                  </div>
                </div>
              </template>
            </td>
            <td>
              <div class="table-curriculum-body__numeric">
                {{
                  curriculum_working_discipline.mc_data.times&&curriculum_working_discipline.mc_data.times.all
                }}
              </div>
            </td>
            <td>
              <div
                v-if="checkEditValue(curriculum_working_discipline)"
                class="table-curriculum-body__numeric"
              >
                <input
                  v-model="disciplinesEdits[curriculum_working_discipline.id].controlForm.exam"
                  type="text"
                  class="s-form__input"
                >
              </div>
              <div
                v-else
                class="table-curriculum-body__numeric"
              >
                {{
                  getControlOfType(curriculum_working_discipline,'controlForm.exam') }}
              </div>
            </td>
            <td>
              <div
                v-if="checkEditValue(curriculum_working_discipline)"
                class="table-curriculum-body__numeric"
              >
                <input
                  v-model="disciplinesEdits[curriculum_working_discipline.id].controlForm.pass"
                  type="text"
                  class="s-form__input"
                >
              </div>
              <div
                v-else
                class="table-curriculum-body__numeric"
              >
                {{ getControlOfType(curriculum_working_discipline,'controlForm.pass')
                }}
              </div>
            </td>
            <td>
              <div
                v-if="checkEditValue(curriculum_working_discipline)"
                class="table-curriculum-body__numeric"
              >
                <input
                  v-model="disciplinesEdits[curriculum_working_discipline.id].controlForm.control_work"
                  type="text"
                  class="s-form__input"
                >
              </div>
              <div
                v-else
                class="table-curriculum-body__numeric"
              >
                {{
                  getControlOfType(curriculum_working_discipline,'controlForm.control_work')
                }}
              </div>
            </td>
            <td>
              <div
                v-if="checkEditValue(curriculum_working_discipline)"
                class="table-curriculum-body__numeric"
              >
                <input
                  v-model="disciplinesEdits[curriculum_working_discipline.id].controlForm.course_work"
                  type="text"
                  class="s-form__input"
                >
              </div>
              <div
                v-else
                class="table-curriculum-body__numeric"
              >
                {{
                  getControlOfType(curriculum_working_discipline,'controlForm.course_work')
                }}
              </div>
            </td>
            <td>
              <div class="table-curriculum-body__numeric">
                {{ getOfType(curriculum_working_discipline,
                             'duration.theory',
                             'duration.practice',
                             'duration.projects',
                ) }}
              </div>
            </td>
            <td>
              <div
                v-if="checkEditValue(curriculum_working_discipline)"
                class="table-curriculum-body__numeric"
              >
                <input
                  v-model="disciplinesEdits[curriculum_working_discipline.id].duration.theory"
                  type="text"
                  class="s-form__input"
                >
              </div>
              <div
                v-else
                class="table-curriculum-body__numeric"
              >
                {{ getOfType(curriculum_working_discipline,'duration.theory') }}
              </div>
            </td>
            <td>
              <div
                v-if="checkEditValue(curriculum_working_discipline)"
                class="table-curriculum-body__numeric"
              >
                <input
                  v-model="disciplinesEdits[curriculum_working_discipline.id].duration.practice"
                  type="text"
                  class="s-form__input"
                >
              </div>
              <div
                v-else
                class="table-curriculum-body__numeric"
              >
                {{
                  getOfType(curriculum_working_discipline,'duration.practice') }}
              </div>
            </td>
            <td>
              <div
                v-if="checkEditValue(curriculum_working_discipline)"
                class="table-curriculum-body__numeric"
              >
                <input
                  v-model="disciplinesEdits[curriculum_working_discipline.id].duration.projects"
                  type="text"
                  class="s-form__input"
                >
              </div>
              <div
                v-else
                class="table-curriculum-body__numeric"
              >
                {{
                  getOfType(curriculum_working_discipline,'duration.projects') }}
              </div>
            </td>
            <td
              v-for="semesterNumber in semesterNumbers"
              style="font-style: italic; font-weight: bolder;"
            >
              <div
                v-if="checkEditValue(curriculum_working_discipline)"
                class="table-curriculum-body__numeric"
              >
                <input
                  v-model="disciplinesEdits[curriculum_working_discipline.id].semester[semesterNumber]"
                  type="text"
                  class="s-form__input"
                >
              </div>
              <div
                v-else
                class="table-curriculum-body__numeric"
              >
                {{
                  getOfType(curriculum_working_discipline,'semester.'+semesterNumber)
                }}
              </div>
            </td>
          </tr>
          <template
            v-if="curriculum_working_discipline.children&&curriculum_working_discipline.children.length"
          >
            <tr
              v-for="(curriculum_working_discipline_child, indexChild) in curriculum_working_discipline.children"
            >
              <td class="sticky-col first-col">
                {{ curriculum_working_discipline_child.is_result
                  ? $trans.get('curriculum.is_result')
                  : group.mc_data.indexCode }} -
                {{ index+1 }}.{{
                  curriculum_working_discipline_child.is_result
                    ? curriculum_working_discipline_child.resultIndex
                    : curriculum_working_discipline_child.disciplineIndex
                }}
              </td>
              <td
                class="sticky-col second-col"
              >
                <div
                  v-if="!checkEditValue( curriculum_working_discipline_child)"
                  class="table-curriculum-body__discipline_name"
                >
                  {{
                    curriculum_working_discipline_child.is_result
                      ? curriculum_working_discipline_child.education_result.name
                      : curriculum_working_discipline_child.education_discipline.name
                  }}
                </div>
                <div
                  v-else
                  class="table-curriculum-body__discipline_name"
                  style="padding-right: unset;"
                >
                  <label class="s-form__label mb-10">
                    {{ $trans.get('fields.curriculum_discipline_id') }}:
                  </label>
                  <v-multiselect
                    v-model="disciplinesEdits[curriculum_working_discipline_child.id].education_discipline"
                    class="mb-10"
                    style="max-width: 300px;"
                    label="name"
                    value="id"
                    track-by="id"
                    :options="curriculum_working_discipline_child.is_result
                      ? getRefResults()
                      : getRefDisciplines()"
                    :placeholder="$trans.get('placeholder.choose')"
                  >
                    <template slot="noOptions">
                      {{ $trans.get('select.list_is_empty') }}
                    </template>
                    <template slot="noResult">
                      {{ $trans.get('select.list_is_empty') }}
                    </template>
                  </v-multiselect>
                </div>
                <div
                  v-if="checkEditValue( curriculum_working_discipline_child)"
                  class="table-curriculum-button-wrapper"
                >
                  <div
                    class="s-btn s-btn--thm-grey"
                    style="margin-bottom: 1rem; width:100%;"
                    @click="hideEditValue(curriculum_working_discipline_child)"
                  >
                    <div
                      class="s-str-ico s-str-ico--thm-angle-left s-str-ico--wider"
                    >
                      {{ $trans.get('curriculum.action_cancel') }}
                    </div>
                  </div>
                  <div
                    class="s-btn s-btn--thm-green"
                    style="width:100%;"
                    @click="saveEditValue(curriculum_working_discipline_child)"
                  >
                    <div
                      class="s-str-ico s-str-ico--thm-check s-str-ico--wider"
                    >
                      {{ $trans.get('curriculum.action_save') }}
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="isEdit"
                  class="table-curriculum-control-wrapper"
                >
                  <div
                    class="s-btn-table s-btn--ico"
                    @click="showEditValue(curriculum_working_discipline_child)"
                  >
                    <div class="s-ico s-ico--thm-pencil" />
                  </div>
                  <div
                    class="s-btn-table s-btn--ico"
                    @click="showConfirmDelete(curriculum_working_discipline_child)"
                  >
                    <div class="s-ico s-ico--thm-trash" />
                  </div>
                </div>
              </td>
              <td>
                <div class="table-curriculum-body__numeric">
                  {{
                    curriculum_working_discipline_child.mc_data.times&&curriculum_working_discipline_child.mc_data.times.all
                  }}
                </div>
              </td>
              <td>
                <div
                  v-if="checkEditValue(curriculum_working_discipline_child)"
                  class="table-curriculum-body__numeric"
                >
                  <input
                    v-model="disciplinesEdits[curriculum_working_discipline_child.id].controlForm.exam"
                    type="text"
                    class="s-form__input"
                  >
                </div>
                <div
                  v-else
                  class="table-curriculum-body__numeric"
                >
                  {{
                    getControlOfType(curriculum_working_discipline_child,'controlForm.exam')
                  }}
                </div>
              </td>
              <td>
                <div
                  v-if="checkEditValue(curriculum_working_discipline_child)"
                  class="table-curriculum-body__numeric"
                >
                  <input
                    v-model="disciplinesEdits[curriculum_working_discipline_child.id].controlForm.pass"
                    type="text"
                    class="s-form__input"
                  >
                </div>
                <div
                  v-else
                  class="table-curriculum-body__numeric"
                >
                  {{
                    getControlOfType(curriculum_working_discipline_child,'controlForm.pass')
                  }}
                </div>
              </td>
              <td>
                <div
                  v-if="checkEditValue(curriculum_working_discipline_child)"
                  class="table-curriculum-body__numeric"
                >
                  <input
                    v-model="disciplinesEdits[curriculum_working_discipline_child.id].controlForm.control_work"
                    type="text"
                    class="s-form__input"
                  >
                </div>
                <div
                  v-else
                  class="table-curriculum-body__numeric"
                >
                  {{
                    getControlOfType(curriculum_working_discipline_child,'controlForm.control_work')
                  }}
                </div>
              </td>
              <td>
                <div
                  v-if="checkEditValue(curriculum_working_discipline_child)"
                  class="table-curriculum-body__numeric"
                >
                  <input
                    v-model="disciplinesEdits[curriculum_working_discipline_child.id].controlForm.course_work"
                    type="text"
                    class="s-form__input"
                  >
                </div>
                <div
                  v-else
                  class="table-curriculum-body__numeric"
                >
                  {{
                    getControlOfType(curriculum_working_discipline_child,'controlForm.course_work')
                  }}
                </div>
              </td>
              <td>
                <div class="table-curriculum-body__numeric">
                  {{ getOfType(curriculum_working_discipline_child,
                               'duration.theory',
                               'duration.practice',
                               'duration.projects',
                  ) }}
                </div>
              </td>
              <td>
                <div
                  v-if="checkEditValue(curriculum_working_discipline_child)"
                  class="table-curriculum-body__numeric"
                >
                  <input
                    v-model="disciplinesEdits[curriculum_working_discipline_child.id].duration.theory"
                    type="text"
                    class="s-form__input"
                  >
                </div>
                <div
                  v-else
                  class="table-curriculum-body__numeric"
                >
                  {{
                    getOfType(curriculum_working_discipline_child,'duration.theory')
                  }}
                </div>
              </td>
              <td>
                <div
                  v-if="checkEditValue(curriculum_working_discipline_child)"
                  class="table-curriculum-body__numeric"
                >
                  <input
                    v-model="disciplinesEdits[curriculum_working_discipline_child.id].duration.practice"
                    type="text"
                    class="s-form__input"
                  >
                </div>
                <div
                  v-else
                  class="table-curriculum-body__numeric"
                >
                  {{
                    getOfType(curriculum_working_discipline_child,'duration.practice')
                  }}
                </div>
              </td>
              <td>
                <div
                  v-if="checkEditValue(curriculum_working_discipline_child)"
                  class="table-curriculum-body__numeric"
                >
                  <input
                    v-model="disciplinesEdits[curriculum_working_discipline_child.id].duration.projects"
                    type="text"
                    class="s-form__input"
                  >
                </div>
                <div
                  v-else
                  class="table-curriculum-body__numeric"
                >
                  {{
                    getOfType(curriculum_working_discipline_child,'duration.projects')
                  }}
                </div>
              </td>
              <td
                v-for="semesterNumber in semesterNumbers"
                style="font-style: italic; font-weight: bolder;"
              >
                <div
                  v-if="checkEditValue(curriculum_working_discipline_child)"
                  class="table-curriculum-body__numeric"
                >
                  <input
                    v-model="disciplinesEdits[curriculum_working_discipline_child.id].semester[semesterNumber]"
                    type="text"
                    class="s-form__input"
                  >
                </div>
                <div
                  v-else
                  class="table-curriculum-body__numeric"
                >
                  {{
                    getOfType(curriculum_working_discipline_child,'semester.'+semesterNumber)
                  }}
                </div>
              </td>
            </tr>
          </template>
        </template>

        <tr v-if="isEdit">
          <td class="sticky-col first-col" />
          <td class="sticky-col second-col">
            <div>
              <div
                class="s-btn s-btn--thm-blue"
                style="width: 100%;"
                @click="showForm('add_and_edit_discipline','store',{group:group})"
              >
                <div class="s-str-ico s-str-ico--thm-plus s-str-ico--wider">
                  {{
                    $trans.get('curriculum.action_add_new') }}
                </div>
              </div>
            </div>
          </td>
          <td :colspan="(9+semesterNumbers)" />
        </tr>
        <tr class="table-curriculum-body__title">
          <td class="sticky-col first-col" />
          <td class="sticky-col second-col">
            {{ $trans.get('curriculum.discipline_group_total') }}
          </td>
          <td>
            <div class="table-curriculum-body__numeric">
              {{ group.mc_data.times.all }}
            </div>
          </td>
          <td>
            <div class="table-curriculum-body__numeric">
              {{ countGroup(group,'controlForm.exam') }}
            </div>
          </td>
          <td>
            <div class="table-curriculum-body__numeric">
              {{ countGroup(group,'controlForm.pass') }}
            </div>
          </td>
          <td>
            <div class="table-curriculum-body__numeric">
              {{ countGroup(group,'controlForm.control_work') }}
            </div>
          </td>
          <td>
            <div class="table-curriculum-body__numeric">
              {{ countGroup(group,'controlForm.course_work') }}
            </div>
          </td>
          <td>
            <div class="table-curriculum-body__numeric">
              {{ groupTotal(group,
                            'duration.theory',
                            'duration.practice',
                            'duration.projects',
              ) }}
            </div>
          </td>
          <td>
            <div class="table-curriculum-body__numeric">
              {{ groupTotal(group,'duration.theory') }}
            </div>
          </td>
          <td>
            <div class="table-curriculum-body__numeric">
              {{ groupTotal(group,'duration.practice') }}
            </div>
          </td>
          <td>
            <div class="table-curriculum-body__numeric">
              {{ groupTotal(group,'duration.projects') }}
            </div>
          </td>
          <td
            v-for="semesterNumber in semesterNumbers"
            style="font-style: italic;"
          >
            <div class="table-curriculum-body__numeric">
              {{ groupTotal(group,'semester.'+semesterNumber) }}
            </div>
          </td>
        </tr>
      </template>
      <tr class="table-curriculum-body__title table-curriculum-bottom">
        <td class="sticky-col first-col" />
        <td class="sticky-col second-col">
          {{ $trans.get('curriculum.general_total') }}
        </td>
        <td>
          <div class="table-curriculum-body__numeric">
            {{ totalAll }}
          </div>
        </td>
        <td>
          <div class="table-curriculum-body__numeric">
            {{ total('controlForm.exam') }}
          </div>
        </td>
        <td>
          <div class="table-curriculum-body__numeric">
            {{ total('controlForm.pass') }}
          </div>
        </td>
        <td>
          <div class="table-curriculum-body__numeric">
            {{ total('controlForm.control_work') }}
          </div>
        </td>
        <td>
          <div class="table-curriculum-body__numeric">
            {{ total('controlForm.course_work') }}
          </div>
        </td>
        <td>
          <div class="table-curriculum-body__numeric">
            {{ total(
              'duration.theory',
              'duration.practice',
              'duration.projects',
            ) }}
          </div>
        </td>
        <td>
          <div class="table-curriculum-body__numeric">
            {{ total('duration.theory') }}
          </div>
        </td>
        <td>
          <div class="table-curriculum-body__numeric">
            {{ total('duration.practice') }}
          </div>
        </td>
        <td>
          <div class="table-curriculum-body__numeric">
            {{ total('duration.projects') }}
          </div>
        </td>
        <td
          v-for="semesterNumber in semesterNumbers"
          style="font-style: italic;"
        >
          <div class="table-curriculum-body__numeric">
            {{ total('semester.'+semesterNumber) }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import checkEditValue from './mixins/checkEditValue';
import getRefs from './mixins/getRefs';
import actions from './mixins/actions';
import total from './mixins/total';

export default {
  name: 'VTraditionalCurriculum',
  mixins: [checkEditValue, getRefs, actions, total],
  props: {
    courseNumber: {
      type: Number,
      default: 0,
    },
    semesterNumbers: {
      type: Number,
      default: 0,
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    confirm: {
      type: Object,
      required: false,
    },
    groups: {
      type: [Object, Array],
    },
    forms: {
      type: [Object, Array],
    },
    disciplinesEdits: {
      type: [Object, Array],
    },
    totalAll: {
      type: Number,
      required: false,
      default: 0,
    },
    refs: {
      type: [Object, Array],
    },
    urlDisciplineStore: {
      type: String,
      required: true,
    },
    urlDisciplineUpdate: {
      type: String,
      required: true,
    },
    urlDisciplineDestroy: {
      type: String,
      required: true,
    },
    urlDisciplineValuesUpdate: {
      type: String,
      required: true,
    },
  },
};
</script>
