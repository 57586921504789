import { StoreTranslator } from '@common/mixins';

export default {
  mixins: [StoreTranslator],
  props: {
    value: {},
    openModal: {
      type: Boolean,
      default: false,
      required: true,
    },
    urlFiles: {
      type: String,
      required: true,
    },
    urlFileStore: {
      type: String,
      required: true,
    },
  },
  data() {
    const vm = this;
    return {
      processSend: false,
      files: [],
      selectFile: {
        id: null,
      },

    };
  },
  mounted() {
    const vm = this;
    vm.loadFiles();

    window.addEventListener('keyup', (ev) => {
      if (ev.key === 'Escape') {
        vm.modalClose();
      }
      return true;
    });
  },

  destroyed() {
  },
  watch: {
    openModal(newValue, oldValue) {
      const vm = this;
      if (!oldValue) {
        vm.$set(vm, 'selectFile', { id: null });
      }
      if (newValue) {
        $('#modal-user-files').modal('show');
      } else {
        $('#modal-user-files').modal('hide');
      }
    },
  },

  methods: {
    loadFiles() {
      const vm = this;
      const config = {
        responseType: 'json',
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      vm.$http.get(vm.urlFiles, config).then(
        (response) => {
          vm.$set(vm, 'processSend', false);
          vm.$nextTick(() => {
            vm.$set(vm, 'files', response.data);
          });
        },
        (response) => {
          vm.$set(vm, 'processSend', false);

          if (response.status === 422 || response.status === 423) {
            let errors = [];
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
            });
            show_notice(errors, 'error');
          } else {
            console.log(response);
          }
        },
      );
    },
    modalClose() {
      const vm = this;
      vm.$emit('select-close');
      vm.$set(vm, 'selectFile', { id: null });
    },
    onDblclickSelectFile(file) {
      const vm = this;
      vm.$emit('input', file);
    },
    onChangeSelectFile(file) {
      const vm = this;
      if (vm.selectFile.id === file.id) {
        vm.$set(vm, 'selectFile', { id: null });
      } else {
        vm.$set(vm, 'selectFile', _.cloneDeep(file));
      }
    },
    onSaveSelect() {
      const vm = this;
      if (vm.selectFile.id !== null) {
        vm.$emit('input', vm.selectFile);
        vm.$set(vm, 'selectFile', { id: null });
      }
    },
    onClickInputFile() {
      const el = document.querySelector('#user-file-upload-input');
      if (el.onclick) {
        el.onclick();
      } else if (el.click) {
        el.click();
      }
    },
    onChangeInputFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      const file = files[0];
      e.target.value = null;
      this.upload(file);
    },
    upload(file) {
      const vm = this;
      if (vm.processSend === true) {
        return false;
      }
      vm.$set(vm, 'processSend', true);
      vm.$globalLoading.show();
      const formData = new FormData();

      formData.append('file', file);
      const config = {
        responseType: 'json',
        headers: {
          'X-CSRF-TOKEN': window.core_project.csrfToken,
          'Accept-Language': window.core_project.locale,
        },
      };
      vm.$http.post(vm.urlFileStore, formData, config).then(
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(vm, 'processSend', false);
          const files = _.cloneDeep(vm.files);
          files.unshift(response.data);
          vm.$set(vm, 'files', files);
        },
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(vm, 'processSend', false);

          if (response.status === 422 || response.status === 423) {
            let errors = [];
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
            });
            show_notice(errors, 'error');
          } else {
            console.log(response);
          }
        },
      );
    },

  },
};
