<template>
  <div class="sc-curriculum-program__heading mt-20">
    <div class="sc-curriculum-program__heading--title">
      <div
        class="sc-curriculum-program__heading--label s-upper"
      >
        {{ name }}
      </div>
      <div class="s-combine-title__line" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VCurriculumProgramHeading',
  props: {
    name: { type: String, required: true },
  },
};
</script>
