<template>
  <div class="sc-questionnaire__result">
    <div
      v-for="(question, index) in results"
      :key="index"
      class="sc-questionnaire__result--wrapper"
    >
      <div
        class="sc-questionnaire__result--question"
        v-html="question.question"
      />
      <div
        v-for="(answer, key) in question.answers"
        :key="key"
        class="d-flex align-items-baseline"
      >
        <p
          class="sc-questionnaire__result--result"
        >
          {{ calcResult(answer.value, true) }}
        </p>
        <div class="sc-questionnaire__result--answer-wrapper">
          <div
            class="sc-questionnaire__result--answer"
            v-html="answer.answer"
          />
          <div
            class="sc-questionnaire__result--progress"
            :style="'width: ' + calcResult(answer.value)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VResult',
  props: {
    results: {
      type: Array,
      default: null,
    },
  },
  methods: {
    calcResult(val, isValue = false) {
      return val !== 0 || isValue ? `${val * 100}%` : '1%';
    },
  },
};
</script>
