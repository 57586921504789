<template>
  <div class="candidate-list">
    <v-form-inform class="m-0" :message="trans('warning_text.exception_users')" />
    <q-input
      v-model="searchUser"
      name="searchUser"
      :placeholder="trans('placeholder.search')"
      @input="search"
    />
    <div
      v-for="(item, index) in activeUsers"
      :key="index"
      class="candidate-item"
    >
      <input
        :id="'users-' + item.id+'-1'"
        :key="'success-' + index"
        v-model="item.status"
        class="__success"
        type="radio"
        :value="1"
        checked
        @change="changeStatus(item.id, 1)"
      >
      <label :for="'users-' + item.id+'-1'" />
      <input
        :id="'users-' + item.id+'-0'"
        :key="'reject-' + index"
        v-model="item.status"
        class="__reject"
        type="radio"
        :value="0"
        @change="changeStatus(item.id, 0)"
      >
      <label :for="'users-' + item.id+'-0'" />
      <div class="candidate-item__name">
        {{ item.name }}
      </div>
    </div>
    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :label="trans('button.save')"
        @click="updateData"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExceptionUsersModal',
  props: {
    getUsers: {
      type: String,
      default: null,
    },
    module: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      searchUser: null,
      users: [],
      activeUsers: [],
    };
  },
  async mounted() {
    await requestGetDataHelper(this, {
      url: this.getUsers,
      fullRes: false,
      hardSet: true,
      variableName: 'users',
      params: {
        key: this.module.key,
      },
    });
    this.updateList();
  },
  methods: {
    search() {
      const vm = this;
      if (!['', null].includes(vm.searchUser)) {
        this.activeUsers = this.users.filter(
          item => item.status === 0 && item.name.toLowerCase().includes(vm.searchUser.toLowerCase()),
        );
      } else {
        this.updateList();
      }
    },
    async updateData() {
      this.$emit('modalSubmit', {
        key: this.module.key,
        users: this.activeUsers,
        value: true,
      });
    },
    changeStatus(index, status) {
      this.users.filter(item => item.id === index).map(
        (item) => {
          item.status = status;
          return item;
        },
      );
      setTimeout(this.updateList, 0);
    },
    updateList() {
      this.activeUsers = this.users.filter(
        item => item.status === 1,
      );
    },
  },
};
</script>
