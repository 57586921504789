import { Form, Files, eventBus } from '@common/mixins';
import { Helper } from '@common/src/helpers';
import _ from 'lodash';
import { QForm, QSlideTransition } from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import SelectUserFile from '@app_college/views/SelectUserFile';

export default {
  name: 'VContingentAttendanceModal',
  components: { QForm, QSlideTransition, SelectUserFile },
  mixins: [Form, Files],
  props: {
    reasons: {
      type: Array,
      required: true,
    },
    uriUserFiles: {
      type: String,
      required: true,
    },
  },
  data() {
    const fields = {
      contingentId: null,
      date: [],
      attendance: null,
      userFile: [],
    };
    return {
      fields,
    };
  },
  computed: {
    validate() {
      return this.fields?.contingentId !== null && this.fields?.attendance !== null && this.fields?.date.length > 0;
    },
    attendance() {
      return this.$store.getters.attendance;
    },
    dates() {
      const items = this.attendance
        .filter(item => item.contingentId === this.fields.contingentId)
        .reduce((key, item) => item.days, []);
      return Object.entries(items)
        .filter(
          item =>
            (
              item[1]?.attendance.attendance === 0
              || (item[1]?.attendance.attendance === 2 && item[1]?.attendance.contingent_file_id == null)
            )
            && item[1]?.canEdit,
        )
        .map(item => ({ label: item[0], value: item[1].attendance.attendanceId }));
    },
    contingents() {
      return this.attendance
        .filter(item => item.absentDays > 0 && Object.entries(item.days).filter(el => el[1].canEdit).length > 0)
        .map(item => ({ label: item.full_name, value: item.contingentId }));
    },
  },
  methods: {
    async handleAjaxFormSubmit() {
      this.$globalLoading.show();
      const data = {};
      _.forEach(this.fields, (el, key) => {
        _.set(data, key, el);
      });
      const { attendanceApiConfig, makeTimeSheetUrl, groupId } = this.$store.getters;
      const res = await requestWrapper.call(this, {
        url: makeTimeSheetUrl(attendanceApiConfig.timeSheetDaysMark.apiUrl, groupId),
        method: 'post',
        data,
      });

      if (!res.error) {
        await this.$store.dispatch('GET_TIMESHEET');
        eventBus.$emit('update-heights');
        this.$emit('onClose');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
