import { QIcon } from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import VDiary from './components/VDiary';
import VWriting from './components/VWriting';
import VResultGrade from './components/VResultGrade';

export default {
  components: {
    QIcon,
    VDiary,
    VWriting,
    VResultGrade,
  },
  props: {
    contingent: {
      type: Object,
      default: {},
    },
    href: {
      type: String,
      default: '',
    },
    tabConfigs: {
      type: Object,
      default: () => ({
        diary_report: {
          active: false,
          grade: null,
        },
        writing_report: {
          active: false,
          grade: null,
        },
      }),
    },
    cans: {
      type: Object,
      default: {},
    },
    apiConfig: {
      type: Object,
      default: () => ({
        diary_report: '',
        writing_report: '',
        action: '',
        download: '',
        changeStatus: '',
        addGrade: '',
        uploadWriting: '',
      }),
    },
  },
  computed: {
    tabs() {
      const result = [];
      if (this.tabConfigs.diary_report.active) {
        result.push({
          ...this.tabConfigs.diary_report,
        });
      }
      if (this.tabConfigs.writing_report.active) {
        result.push({
          ...this.tabConfigs.writing_report,
        });
      }
      return result;
    },
    hasNoCharacteristics() {
      return this.cans.canUploadCharacteristic && this.forms.characteristic?.file === null;
    },
    staticUrl() {
      return window.core_project.staticUrl;
    },
    swipeDirection() {
      const mappedTabs = this.tabs.map(it => it.key);
      const prevTabIndex = mappedTabs.indexOf(this.prevTab);
      const activeTabIndex = mappedTabs.indexOf(this.activeTab);
      return activeTabIndex > prevTabIndex ? 'swipe-left' : 'swipe-right';
    },
    activeComponent() {
      switch (this.activeTab) {
        case 'diary_report':
          return 'v-diary';
        case 'writing_report':
          return 'v-writing';
        default:
          return 'v-table';
      }
    },
    activeComponentProps() {
      const tab = this.tabs.filter(el => el.key === this.activeTab);
      switch (this.activeTab) {
        case 'diary_report':
          return {
            cols: this.forms?.[this.activeTab].columns,
            rows: this.forms?.[this.activeTab].rows,
            adaptiveWhen: 840,
            cans: this.cans,
            status: tab[0]?.status,
            grade: tab[0]?.grade,
            binds: {
              action: this.apiConfig.action,
              method: 'post',
              type: 'diary_report',
              contingent: this.contingent.id,
              changeStatus: this.apiConfig.changeStatus,
              addGrade: this.apiConfig.addGrade,
            },
          };
        case 'writing_report':
          return {
            action: this.apiConfig.action,
            method: 'post',
            type: 'writing_report',
            contingent: this.contingent.id,
            body: this.forms.writing_report?.data,
            cans: this.cans,
            status: tab[0]?.status,
            grade: tab[0]?.grade,
            uploadUrl: this.apiConfig.uploadWriting,
            changeStatus: this.apiConfig.changeStatus,
            addGrade: this.apiConfig.addGrade,
          };
        default:
          return {};
      }
    },
    activeTabComputed() {
      return this.tabs[0]?.key;
    },
    prevTabComputed() {
      return this.tabs[0]?.key;
    },
  },
  data() {
    return {
      activeTab: 'diary_report',
      prevTab: 'diary_report',
      forms: {
        diary_report: [],
        writing_report: null,
        characteristic: [],
      },
    };
  },
  async mounted() {
    await this.$trans.add(['label', 'button', 'practice', 'warning_text']);
    _.forEach(this.tabs, async (tab) => {
      await this.requestGetData(tab.key, true, true);
    });
    await this.requestGetData('characteristic', true, true);
    this.activeTab = this.activeTabComputed;
    this.prevTab = this.prevTabComputed;
  },
  methods: {
    async handleTab(tab) {
      if (this.activeTab !== tab) {
        this.prevTab = this.activeTab;
        this.activeTab = tab;
      }
    },
    async requestGetData(
      type = this.activeTab,
      enableLoading = false,
      disableLoading = false,
    ) {
      if (enableLoading) {
        this.$globalLoading.show();
      }
      const url = Helper.urlGenerate(this.apiConfig.results, { type });
      const res = await requestWrapper.call(this, {
        url,
      });
      if (!res.error) {
        this.forms = { ...this.forms, ...res };
      } else {
        this.$notify({
          text: this.trans('notice.error_on_server'),
          type: 'error',
        });
      }
      if (disableLoading) {
        this.$globalLoading.hide();
      }
    },
    triggerModal() {
      if (this.forms.characteristic?.file) {
        return;
      }
      const events = {};
      events.modalSubmit = async (file) => {
        this.forms.characteristic = file;
        await this.uploadCharacteristics();
      };
      this.$sModal.open('v-modal-upload-file', {
        id: 'v-modal-upload-file',
        component: 'v-modal-upload-file',
        notPersistent: true,
        binds: {
          accepts: ['doc', 'docx', 'pdf', 'png', 'jpg', 'jpeg'],
        },
        events,
      });
    },
    async uploadCharacteristics() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.action,
        method: 'post',
        data: {
          file: this.forms.characteristic,
          type: 'characteristic',
          contingent: this.contingent.id,
        },
      });

      if (!res.error) {
        this.$emit('onClose');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.loading = !this.loading;
      this.$globalLoading.hide();
    },
  },
};
