import {
  requestWrapper,
} from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import SelectUserFile from '@app_college/views/SelectUserFile';
import Files from '@app_college/views/VStatementCreateView/mixins/Files';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: 'VDocumentFlowStatement',
  components: {
    SelectUserFile,
  },
  mixins: [Files],
  props: {
    urlStore: {
      type: String,
      default: null,
    },
    urlGetTypes: {
      type: String,
      default: null,
    },
    uriGetSemesters: {
      type: String,
      default: null,
    },
    urlGetFileTypes: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        language: 'ru',
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'blockQuote',
            'undo',
            'redo',
          ],
        },
      },
      options: {
        types: [],
        semesters: [],
      },
      form: {
        type: null,
        files: [],
        semesters: [],
        goal: null,
        info_ru: null,
        info_kz: null,
      },
    };
  },
  async mounted() {
    await this.$trans.add([
      'warning_text',
      'label',
      'button',
      'document_flow',
      'fields',
    ]);
    await this.getTypes();
    await this.getSemesters();
  },
  computed: {
    validate() {
      if (this.isTranscript) {
        return this.form.semesters.length && ![null, ''].includes(this.form.goal);
      }
      let isRequiredFilesFilled = true;
      for (const fileKey in this.form.files) {
        const file = this.form.files[fileKey];
        if (file?.required) {
          if (file.user_file_id !== null) {
            isRequiredFilesFilled = true;
          } else {
            isRequiredFilesFilled = false;
            break;
          }
        } else {
          isRequiredFilesFilled = true;
        }
      }
      return this.form.type !== null && isRequiredFilesFilled;
    },
    isTranscript() {
      return this.form.type === 32;
    },
    isArchived() {
      return this.form.type === 37;
    },
    isCertificateFromEducationPlace() {
      return this.form.type === 29;
    },
  },
  methods: {
    async getTypes() {
      await this.load(this.urlGetTypes, 'options', 'types');
    },
    async getSemesters() {
      await this.load(this.uriGetSemesters, 'options', 'semesters');
    },
    async loadFiles() {
      this.clearFields();
      await this.load(this.urlGetFileTypes, 'form', 'files');
    },
    async load(url, group, option) {
      const vm = this;
      this.optionsLoading = true;
      const res = await requestWrapper.call(this, {
        url,
        params: vm.form,
      });
      if (!res.error) {
        vm.$set(_.get(vm, group), option, _.get(res.options, option, []));
      }
      this.optionsLoading = true;
    },
    async requestAction() {
      this.loading = true;
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'POST',
        url: this.urlStore,
        data: this.form,
      });
      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.loading = false;
        this.$globalLoading.hide();
      }
    },
    clearFile(filename) {
      if (this.form.files[filename].user_file_id !== null) {
        this.form.files[filename].user_file_id = null;
        this.form.files[filename].user_file_name = null;
      }
    },
    selectFile(file) {
      this.form.files[this.activeFile].user_file_id = file.id;
      this.form.files[this.activeFile].user_file_name = file.name;
      this.modalFiles = false;
      this.activeFile = null;
    },
    clearFields() {
      this.form.files = [];
      this.form.semesters = [];
      this.form.goal = null;
    },
  },
};
