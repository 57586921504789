import SelectUserFile from '@app_college/views/SelectUserFile';
import eventHub from '@vjs/config/eventHub';
import Files from '@app_college/views/VStatementCreateView/mixins/Files';

export default {
  name: 'StatementSocialStatus',
  components: {SelectUserFile},
  mixins: [
    Files,
  ],
  props: {
    socialStatuses: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      form: {
        soc_status_id: null,
        doc_for_privilege: null,
      },
    };
  },

  async mounted() {
    await this.$trans.add(['label', 'button']);
  },

  computed: {
    validate() {
      return this.form.soc_status_id === 1 || this.form.doc_for_privilege !== null;
    },
  },

  methods: {
    sendToParent() {
      eventHub.$emit('addSocialStatus', this.form);
      this.$emit('onClose');
    },
    clearFile(filename) {
      if (this.form[filename] !== null) {
        this.form[filename] = null;
      }
    },
  },
};
