import Vue from 'vue';

import store from './VueJS/store';
import VExamThemes from './VueJS/views/VExamThemes/VExamThemes.vue';

if (document.getElementById('v-exam-themes-app')) {
  window.vExamThemesApp = new Vue({
    el: '#v-exam-themes-app',
    components: { VExamThemes },
    store,
  });
}
