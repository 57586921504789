import { Form, Files, eventBus } from '@common/mixins';
import { Helper } from '@common/src/helpers';
import _ from 'lodash';
import { QForm, QSlideTransition } from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import SelectUserFile from '@app_college/views/SelectUserFile';

export default {
  name: 'VAttendanceModal',
  components: { QForm, QSlideTransition, SelectUserFile },
  mixins: [Form, Files],
  props: {
    resource: {
      type: Object,
      required: true,
    },
    reasons: {
      type: Array,
      required: true,
    },
    uriUserFiles: {
      type: String,
      required: true,
    },
  },
  data() {
    const fields = {
      contingentId: null,
      date: null,
      attendance: null,
      userFile: [],
    };
    return {
      fields,
    };
  },
  async mounted() {
    _.forEach(this.resource, (value, attribute) => {
      if (Object.prototype.hasOwnProperty.call(this.fields, attribute)) {
        _.set(this.fields, `${attribute}`, value);
      }
    });
  },
  computed: {
    validate() {
      return this.fields?.attendance !== null;
    },
  },

  methods: {
    async handleAjaxFormSubmit() {
      this.$globalLoading.show();
      const data = {};
      _.forEach(this.fields, (el, key) => {
        _.set(data, key, el);
      });
      const { attendanceApiConfig, makeTimeSheetUrl, groupId } = this.$store.getters;
      const res = await requestWrapper.call(this, {
        url: makeTimeSheetUrl(attendanceApiConfig.timeSheetMark.apiUrl, groupId),
        method: 'post',
        data,
      });

      if (!res.error) {
        await this.$store.dispatch('GET_TIMESHEET');
        eventBus.$emit('update-heights');
        this.$emit('onClose');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
