import { QPopupProxy, QIcon, QSlideTransition } from '@quasar/components';
import { Helper } from '@common/src/helpers';
import _ from 'lodash';
import { requestWrapper } from '@vjs/helpers';
import { eventBus } from '@common/mixins';

export default {
  name: 'VJournalControl',

  components: { QPopupProxy, QIcon, QSlideTransition },

  props: {
    uriGetData: String,
  },
  data() {
    return {
      resultSpoiler: false,
    };
  },

  methods: {
    makeBtn(
      label = '',
      onClick = () => {},
      color = 'blue',
      outline,
      icon,
      withHelp,
    ) {
      return {
        color, icon, label, onClick, outline, withHelp,
      };
    },
    handleExport() {
      const {
        isModule, apiConfig, currentFilter, makeJournalUrl, journalId,
      } = this.$store.getters;
      const result = [];
      _.forEach(apiConfig.export, (btn, key) => {
        result.push({
          url: makeJournalUrl(btn, journalId),
          label: this.trans(`journal.exports.${key}`),
          params: {
            [isModule ? 'discipline_id' : 'semester']: currentFilter,
            method: 'GET',
            headers: {
              'Accept-Language': window.core_project.locale,
            },
          },
        });
      });
      this.$sModal.open('v-multiple-export-modal', {
        title: this.trans('export.choose_export_type'),
        component: 'v-multiple-export-modal',
        notPersistent: true,
        binds: {
          items: result,
        },
      });
    },
    showGenerateModal() {
      if (!this.isModule && _.isEmpty(this.refs.generateMonths)) {
        this.$notify({
          text: this.trans('journal.errors.no_days_for_generate'),
          type: 'warn',
        });
        return;
      }

      this.$sModal.open('v-modal-journal-generate-weeks', {
        component: 'v-modal-journal-generate-weeks',
        notPersistent: true,
      });
    },
    showConfirmModal() {
      this.$sModal.open('v-modal-confirm', {
        title: this.trans('notice.are_you_sure'),
        component: 'v-modal-confirm',
        notPersistent: true,
        events: { onApprove: this.confirmRestore },
      });
    },
    async confirmRestore() {
      this.$globalLoading.show();
      const {
        apiConfig, makeJournalUrl, journalId, currentFilter,
      } = this.$store.getters;

      const url = makeJournalUrl(apiConfig.restore, journalId);
      const res = await requestWrapper.call(this, {
        url,
        method: 'put',
      });

      if (!res.error) {
        await this.$store.dispatch(
          'GET_JOURNAL', { [this.isModule ? 'discipline_id' : 'semester']: currentFilter },
        );
        eventBus.$emit('onCloseAll');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }

      this.$globalLoading.hide();
    },
    async showCalcResultModal() {
      await this.$store.dispatch('GET_CALC_RESULT_DATA');
      this.$sModal.open('v-modal-journal-calc-result', {
        component: 'v-modal-journal-calc-result',
        notPersistent: true,
      });
    },
    statusChangeModal() {
      this.$sModal.open('v-modal-confirm-new', {
        id: 'v-modal-confirm-new',
        title: this.trans('notice.are_you_sure'),
        component: 'v-modal-confirm-new',
        // notPersistent: true,
        binds: {
          type: 'request',
          action: this.$store.getters.apiConfig.changeStatus,
          requestData: { status: this.status === 'closed' ? 'open' : 'closed' },
          method: 'post',
        },
      });
    },
  },
  computed: {
    table() { return this.$store.getters.infoTable; },
    cans() { return this.$store.getters.cans; },
    refs() { return this.$store.getters.refs; },
    isPersonal() { return this.$store.getters.isPersonal; },
    isModule() { return this.$store.getters.isModule; },
    status() { return this.$store.getters.status; },
    resultPopupTexts() {
      if (this.isModule) {
        return [
          this.trans('journal.calc_result_popup.final_module'),
          this.trans('journal.calc_result_popup.year_module'),
          this.trans('journal.calc_result_popup.summary_module'),
        ];
      }
      return [
        this.trans('journal.calc_result_popup.attestation'),
        this.trans('journal.calc_result_popup.year'),
        this.trans('journal.calc_result_popup.final'),
      ];
    },
    buttons() {
      const result = [];
      const {
        makeBtn, trans, cans, $store, isPersonal,
        handleExport, showCalcResultModal, showGenerateModal, showConfirmModal, statusChangeModal,
      } = this;

      const { isRemarksTableActive, students } = $store.getters;

      if (cans.calcResults) {
        result.push(makeBtn(trans('journal.calculate_result'), showCalcResultModal, undefined, undefined, undefined, true));
      }

      if (cans.export) {
        result.push(makeBtn(trans('journal.export'), handleExport, 'green', false, 'download'));
      }

      if (!isRemarksTableActive && !isPersonal && students.length > 1) {
        result.push(makeBtn(
          $store.getters.showRetiredStudents ? trans('journal.hide_retired') : trans('journal.show_retired'),
          () => $store.commit('TOGGLE_SHOW_RETIRED_STUDENTS'),
          undefined,
          true,
        ));
      }

      if (cans.generateDays) {
        result.push(makeBtn(trans('journal.generate_week'), showGenerateModal, undefined, true, 'calendar-alt'));
      }

      if (cans.restore) {
        result.push(makeBtn(trans('button.restore'), showConfirmModal, 'orange', true, 'trash-restore'));
      }
      if (cans.canChangeStatus && this.status === 'closed') {
        result.push(makeBtn(trans('button.journal_open'), statusChangeModal, 'orange', undefined, undefined, ''));
      }
      if (cans.canChangeStatus && this.status === 'open') {
        result.push(makeBtn(trans('button.journal_close'), statusChangeModal, 'orange', undefined, undefined, ''));
      }

      return result;
    },
  },
};
