import { QBtn, QField, QTooltip } from '@quasar/components';
import { Helper } from '@common/src/helpers';
import VConfirmDialog from '@vjs/modals/VConfirmDialog.vue';
import { requestWrapper } from '@vjs/helpers';
import _ from 'lodash';
import VConfirmDialogNew from '@vjs/modals/VConfirmDialogNew.vue';
import VFormInform from '@vjs/components/VFormInform';
import VApplicationDocsModal from './VApplicationDocsModal.vue';
import VApplicationSubjectsModal from './VApplicationSubjectsModal.vue';

export default {
  name: 'VApplicationItem',

  components: {
    QBtn,
    QTooltip,
    QField,
    VConfirmDialog,
    VApplicationDocsModal,
    VApplicationSubjectsModal,
    VConfirmDialogNew,
    VFormInform,
  },

  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
    type: {
      type: String,
      default: '',
    },
    identifier: {
      type: Number,
      required: true,
    },
    columns: {
      type: Array,
      default: () => ([]),
    },
    hidden: {
      type: Array,
      default: () => ([]),
    },
    commonData: {
      type: Object,
      default: () => ({}),
    },
    docsBtns: {
      type: Array,
      default: () => ([]),
    },
    canRemove: Boolean,
    uriRemove: {
      type: String,
      required: true,
    },
    steps: {
      type: Array,
      default: () => ([]),
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      options: {
        required: [],
        class: '',
      },
      modalConfirmRemove: false,
      modalConfirmGrantRefuse: false,
      loadingRemove: false,
      stepsTrigger: false,
      loading: false,
      docsModal: {
        active: false,
        uriUserFiles: '',
        uriUpdateFiles: '',
        type: '',
        select: {},
      },
      form: {
        refuse: '',
      },
      uriGrantRefuse: '',
      subjectsModal: false,
      userName: '',
    };
  },
  computed: {
    renderDocsModal() {
      const isExists = this.docsBtns.find(item => item?.value?.active);
      return !!isExists;
    },
  },
  methods: {
    handleOpenModal(value) {
      this.docsModal.active = true;
      this.docsModal.uriUserFiles = value.uriUserFiles;
      this.docsModal.uriUpdateFiles = value.uriAction;
      this.docsModal.type = value.type;
      this.docsModal.select = value.select;
    },
    handleCloseModal() {
      this.docsModal.active = false;
      this.docsModal.uriUserFiles = '';
      this.docsModal.uriUpdateFiles = '';
      this.docsModal.type = '';
      this.docsModal.select = {};
    },
    getAttribute(item, attribute) {
      let value = _.get(item, attribute, '');
      if (_.isArray(value)) {
        let tmpValue = '';
        _.forEach(value, (itm) => {
          tmpValue += itm;
        });
        value = tmpValue;
      }
      return value;
    },
    getIconByStatus(status = '') {
      switch (status) {
        case 'done': return 'check-circle';
        case 'waiting': return 'waiting';
        case 'inactive': return 'clock';
        case 'failed': return 'na';
        default: return '';
      }
    },

    async triggerSubject(link, userName) {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: link,
      });

      if (!res.error) {
        this.options = res.options;
        this.userName = userName;
        this.subjectsModal = true;
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
    async removeApplication() {
      this.$globalLoading.show();
      this.loadingRemove = true;
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriRemove,
      });
      this.loadingRemove = false;

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.modalConfirmRemove = false;
      } else {
        this.modalConfirmRemove = false;
        this.$emit('removed', this.identifier, this.type);
      }
      this.$globalLoading.hide();
    },
    setGrantRefuse(link) {
      this.modalConfirmGrantRefuse = true;
      this.uriGrantRefuse = link;
    },
    async grantRefuse() {
      this.$globalLoading.show();
      this.loadingRemove = true;
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriGrantRefuse,
        params: this.form,
      });
      if (res.error) {
        this.loadingRemove = false;
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      } else {
        this.modalConfirmGrantRefuse = false;
        this.$emit('refused', this.identifier, this.type);
      }
    },
  },
};
