import { QPagination } from '@quasar/components';
import MXListView from '@vjs/mixins/MXListView';
import {scrollTop} from "@vjs/helpers";

export default {
  props: {
    loadDataUri: {
      type: String,
      default: '',
    },
  },
  components: {
    QPagination,
  },
  mixins: [MXListView],
  data() {
    return {
      personnel: [],
    };
  },
  computed: {
    // Отдаёт кол-во очереди на страницу
    paginate() {
      const current = (this.currentPage - 1) * this.countByPage;
      return this.personnel.slice(current, current + this.countByPage);
    },
    // Считает кол-во страниц в пагинации по выставленным в data параметрам
    countOfPages() {
      let count = parseInt(this.personnel.length / this.countByPage, 10);
      const remains = this.personnel.length % this.countByPage;
      count += remains !== 0 ? 1 : 0;
      this.currentPage = 1;
      return count <= 1 ? 0 : count;
    },
  },
  async mounted() {
    await this.$trans.add([
      'warning_text',
    ]);
    await this.loadData();
  },
  methods: {
    async loadData() {
      await requestGetDataHelper(this, {
        url: this.loadDataUri, variableName: 'personnel', hardSet: true,
      });
    },
    // Запускается на событие @input в q-pagination
    async changePagination() {
      // Перевод страницы к началу при выборе страницы
      scrollTop(
        window,
        window.scrollY,
        window.scrollY - Math.abs(this.$refs.catalog.getBoundingClientRect().top),
        500,
      );
    },
  },
};
