<template>
  <q-dialog
    persistent
    :value="modal"
    @input="handleModal"
  >
    <v-modal
      :title="trans('notice.confirm')"
      size="700"
    >
      <div
        class="row no-gutters"
        :loading="loading"
      >
        <div
          v-for="(question, question_key) in questionItems"
          :key="question_key"
          class="col-auto mb-10"
          style="margin-right: 0.5rem; margin-left: 0.5rem;"
        >
          <q-btn
            class="s-btn--w-45"
            :color="question.is_visible ? 'aqua' : 'grey'"
            squire
            :loading="loading"
            :label="question_key+1"
            @click.prevent="question.is_visible ? questionHide(question.id) : questionShow(question.id)"
          >
            <q-tooltip>
              <div v-html="question.question" />
            </q-tooltip>
          </q-btn>
        </div>
      </div>
      <div class="mt-20 row-sort-between-btn">
        <q-btn
          class="s-btn--w-200"
          color="aqua"
          :label="trans('button.show_all')"
          :loading="loading"
          @click="showAllQuestions"
        />
        <q-btn
          class="s-btn--w-200"
          color="grey"
          :label="trans('button.hide_all')"
          :loading="loading"
          @click="hideAllQuestions"
        />
        <q-btn
          class="s-btn--w-150"
          color="grey"
          :label="trans('button.close')"
          :loading="loading"
          @click="handleModal"
        />
      </div>
    </v-modal>
  </q-dialog>
</template>

<script>
import {
  QDialog,
  QBtn,
  QTooltip,
} from '@quasar/components';
import VModal from '@vjs/components/VModal';
import _ from 'lodash';

export default {
  name: 'VExamQuestionModal',
  components: {
    QDialog,
    QBtn,
    QTooltip,
    VModal,
  },
  props: {
    modal: Boolean,
    questionItems: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  beforeCreate() {
    this.$trans.add(['label', 'notice']);
  },
  methods: {
    async showAllQuestions() {
      this.loading = true;
      await this.$store.dispatch('SHOW_ALL_QUESTIONS');
      this.loading = false;
    },
    async hideAllQuestions() {
      this.loading = true;
      const res = await this.$store.dispatch('HIDE_ALL_QUESTIONS');

      this.$notify({
        text: res.text,
        type: res.type,
      });

      this.$emit('modal-close', false);
      this.loading = false;
    },
    questionShow(id) {
      this.$store.dispatch('SHOW_QUESTION', id);
    },
    questionHide(id) {
      this.$store.dispatch('HIDE_QUESTION', id);
    },
    async handleModal() {
      if (!this.loading) {
        this.$emit('modal-close', false);
      }
    },
  },
};
</script>
