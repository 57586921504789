import Validation from '@vjs/mixins/Validation';
import VApplicationItem from '@app_college/views/VApplicationListView/VApplicationItem';
import { scrollTop } from '@vjs/helpers';

export default {
  mixins: [Validation],
  components: {
    VApplicationItem,
  },
  props: {
    uriFindUser: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iin: null,
      statements: [],
      itemsPaginate: [],
      currentPage: 1,
      countByPage: 5,
    };
  },
  computed: {
    checkIinDisable() {
      return !this.validateIin(this.iin);
    },
    checkIinBtnDisable() {
      return !this.checkIinDisable;
    },
    countOfPages() {
      const count = Math.ceil(this.statements.length / this.countByPage);
      this.currentPage = 1;
      return count <= 1 ? 0 : count;
    },
    itemsPaginated() {
      return this.calcPagination(this.currentPage);
    },
  },
  async mounted() {
    await this.$trans.add(['statement', 'admission', 'validation', 'button', 'fields']);
  },
  methods: {
    removeApplication(id, type) {
      this.items = this.items.filter(itm => itm.type !== type || (itm.type === type && itm.id !== id));
    },
    clearSearchResults() {
      this.iin = null;
      this.statements = [];
    },
    async findUserByIIN() {
      await requestGetDataHelper(this, {
        url: this.uriFindUser, variableName: 'statements', fullRes: true, hardSet: true, params: { iin: this.iin }
      });
    },
    calcPagination(val) {
      const cur = (val - 1) * this.countByPage;
      return this.statements.slice(cur, cur + this.countByPage);
    },
    // Запускается на событие @input в q-pagination
    async changePagination() {
      // Перевод страницы к началу при выборе страницы
      scrollTop(
        window,
        window.scrollY,
        window.scrollY - Math.abs(this.$refs.catalog.getBoundingClientRect().top),
        500,
      );
    },
  },
};
