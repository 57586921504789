import Vue from 'vue';
import axios from 'axios';

import store from './VueJS/store';
import VLibrary from './VueJS/views/VLibrary/VLibrary.vue';


Vue.prototype.$axios = axios;

if (document.getElementById('v-library-app')) {
  window.vm = new Vue({
    el: '#v-library-app',
    components: { VLibrary },
    store,
  });
}
