import { eventBus, StoreTranslator } from '@common/mixins';


export default {
  mixins: [StoreTranslator],
  props: {
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    urlSearchIin: {
      type: String,
      required: true,
    },
    urlUserFiles: {
      type: String,
      required: true,
    },
    urlSendStatement: {
      type: String,
      required: true,
    },
    urlLinkByIin: {
      type: String,
      required: true,
    },
    parentIin: {
      required: true,
    },
  },
  data() {
    const vm = this;
    const fields = {
      childIin: {
        value: '',
        name: 'child_iin',
        component: 'iin-field',
        labelTransKey: 'label.child_iin',
        required: true,
        extraAttributes: {
          disabled: false,
        },
        v_if: true,
      },
    };
    const files = [
      {
        labelTransKey: 'label.statement_service_transfer_identity_card',
        required: 1,
        user_file_id: null,
        user_file_name: null,
        v_if: false,
      },
      {
        labelTransKey: 'label.statement_service_decision_reason_leave_parent',
        required: 1,
        user_file_id: null,
        user_file_name: null,
        v_if: false,
      },
    ];
    const actions = {
      search_by_iin: {
        name: 'search_by_iin',
        labelTransKey: 'button.search_button',
      },
      apply_statement: {
        name: 'apply_statement',
        labelTransKey: 'button.request_statement',
      },
    };
    return {
      openModalFiles: false,
      currentSelectFile: {},
      fields,
      files,
      actions,
      currentAction: actions.search_by_iin.name,
      childInfo: false,
      errors: [],
    };
  },
  beforeCreate() {
    this.$trans.add(['fields', 'warning_text', 'placeholder', 'label', 'button', 'main', 'notice', 'cabinet', 'curriculum']);
  },
  methods: {
    runLoader() {
      this.$globalLoading.show();
    },
    stopLoader() {
      this.$globalLoading.hide();
    },
    selectFile(file) {
      const vm = this;

      vm.currentSelectFile.user_file_id = file.id;
      vm.currentSelectFile.user_file_name = file.name;
      vm.$set(vm, 'openModalFiles', false);
    },
    onSelectClose() {
      const vm = this;
      vm.$set(vm, 'openModalFiles', false);
    },
    openSelectFile(file) {
      const vm = this;

      vm.currentSelectFile = file;
      vm.$set(vm, 'openModalFiles', true);
    },
    searchByIIN() {
      const vm = this;
      vm.runLoader();
      const data = {
        parent_iin: vm.parentIin,
        child_iin: vm.fields.childIin.value,
      };

      const config = {
        method: 'POST',
      };

      vm.$http.post(vm.urlSearchIin, data, config)
        .then((response) => {
          vm.$set(vm, 'childInfo', response.data.child);
          vm.stopLoader();
        })
        .catch((response) => {
          vm.handleError(response);
        });
    },
    sendStatement() {
      const vm = this;
      if (!vm.canSend) {
        return;
      }
      vm.runLoader();

      const data = {
        child_iin: vm.fields.childIin.value,
        docs: vm.files.map(item => ({
          name: vm.trans(item.labelTransKey),
          user_file_id: item.user_file_id,
        })),
      };

      const config = {
        method: 'POST',
        headers: {},
      };

      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;
      vm.$http.post(vm.urlSendStatement, data, config)
        .then((response) => {
          vm.stopLoader();
          if (response.data.redirect) {
            window.location = response.data.redirect;
          }
        })
        .catch((response) => {
          vm.handleError(response);
        });
    },
    linkByIin() {
      const vm = this;

      if (!vm.canLink) {
        return;
      }

      vm.runLoader();
      const data = {
        child_iin: vm.fields.childIin.value,
      };

      const config = {
        method: 'POST',
        headers: {},
      };

      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;
      vm.$http.post(vm.urlLinkByIin, data, config)
        .then((response) => {
          if (response.data.redirect) {
            vm.stopLoader();
            window.location = response.data.redirect;
          }
        })
        .catch((response) => {
          vm.handleError(response);
        });
    },
    handleError(response) {
      const { data } = response;
      const vm = this;
      vm.stopLoader();
      if (response.status === 422 || response.status === 423) {
        let errors = [];
        _.forEach(data.errors, (value) => {
          errors = errors.concat(value);
        });
        if (!errors.length && data.message) {
          show_notice(data.message, 'error');
        }
        show_notice(errors, 'error');
        vm.$set(vm, 'errors', data.errors);
      } else {
        console.log(response);
      }
    },
  },
  computed: {
    canSend() {
      const vm = this;
      const docs = vm.files.map(item => item.user_file_id);

      if (docs.indexOf(null) !== -1 || !vm.fields.childIin.value) {
        return false;
      }

      return true;
    },
    canLink() {
      const vm = this;

      return !!vm.fields.childIin.value;
    },
  },
};
