import Vue from 'vue';
import store from './VueJS/store';
import VAdmissionResultList from './VueJS/views/VAdmissionResult/List/index';


if (document.getElementById('v-admission-result-list-app')) {
  window.vAdmissionResultListApp = new Vue({
    el: '#v-admission-result-list-app',
    components: { VAdmissionResultList },
    store,
  });
}
