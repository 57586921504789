<template>
  <div class="s-w-100 sc-journal__legend">
    <div
      @click="spoiler = !spoiler"
    >
      <div class="sc-journal__legend--title">
        <div
          class="sc-journal__legend--label s-upper"
        >
          {{ trans('label.mark_types_title') }}
        </div>
        <div class="s-combine-title__line">
          <div
            class="sc-journal__legend--button"
          >
            <span
              class="s-ico"
              :class="`s-ico--thm-angle-double-${spoiler ? 'up' : 'down'}`"
            />
            <span class="sc-journal__legend--button-text">
              {{ trans(`button.${spoiler ? 'hide_spoiler' : 'show_spoiler'}`) }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <q-slide-transition>
      <div v-if="spoiler">
        <table>
          <thead>
            <tr>
              <th
                v-for="(itm, key) in computedMarkTypesArr[0]"
                :key="key"
              />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(markTypeGroup, groupKey) in computedMarkTypesArr"
              :key="groupKey"
            >
              <td
                v-for="(markType, key) in markTypeGroup"
                :key="`${groupKey}-${key}`"
              >
                <div class="row no-gutters sc-journal__legend--cell">
                  <div class="col-2">
                    <span :style="`color: ${markType.color}`"> {{ markType.character }} </span>
                  </div>
                  <div class="col-10">
                    <span>{{ markType.label }}</span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </q-slide-transition>
  </div>
</template>

<script>
import { QSlideTransition } from '@quasar/components';

export default {
  name: 'VTimeSheetLegend',
  components: { QSlideTransition },
  data: () => ({ spoiler: true }),
  computed: {
    markTypesArr() { return this.$store.getters.markTypesArr; },
    computedMarkTypesArr() {
      if (this.$q.screen.width < 680) {
        return _.chunk(this.markTypesArr, 1);
      }
      if (this.$q.screen.width < 980) {
        return _.chunk(this.markTypesArr, 2);
      }
      if (this.$q.screen.width < 1280) {
        return _.chunk(this.markTypesArr, 3);
      }
      return _.chunk(this.markTypesArr, 4);
    },
  },
};
</script>
