import { Helper } from '@common/src/helpers';
import { StoreTranslator } from '@common/mixins';
import { QTr } from '@quasar/components/table';


export default {
  name: 'MultiExportModal',
  components: { QTr },
  mixins: [StoreTranslator],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      hoveredBtn: null,
    };
  },
  methods: {
    exportTable(btn) {
      this.$globalLoading.show();
      const vm = this;

      const config = {
        responseType: 'blob',
        params: btn.params,
      };

      this.$http.get(btn.url, config)
        .then(
          (response) => {
            vm.$globalLoading.hide();
            const origFilename = (response.headers.get('Content-Disposition') || '').split('filename*=utf-8\'\'')[1];
            let filename = '';
            try {
              filename = decodeURIComponent(origFilename);
            } catch (e) {
              filename = origFilename;
            }
            const result = document.createElement('a');
            result.href = window.URL.createObjectURL(response.body);
            result.download = filename;
            result.click();
            this.$emit('onClose');
          },
          (response) => {
            vm.$globalLoading.hide();
            Helper.handlerResponseError(response);
          },
        );
    },
  },
};
