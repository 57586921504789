<template>
  <div class="sc-settings__modal">
    <v-form-inform :message="message" />
    <div class="row">
      <v-wrapper-q-number-input
        v-model.number="form.first_page"
        class="col-md-6"
        :extra-binds="{
          label: trans('label.first_page'),
          counter: true,
          config: {
            withoutMask: true,
            min: 1,
            max: 20,
          }
        }"
      />
      <v-wrapper-q-number-input
        v-model.number="form.second_page"
        class="col-md-6"
        :extra-binds="{
          label: trans('label.second_page'),
          counter: true,
          config: {
            withoutMask: true,
            min: 1,
            max: 50,
          }
        }"
      />
      <v-wrapper-q-number-input
        v-model.number="form.third_page"
        class="col-md-6"
        :extra-binds="{
          label: trans('label.third_page'),
          counter: true,
          config: {
            withoutMask: true,
            min: 1,
            max: 50,
          }
        }"
      />
      <v-wrapper-q-number-input
        v-model.number="form.fourth_page"
        class="col-md-6"
        :extra-binds="{
          label: trans('label.fourth_page'),
          counter: true,
          config: {
            withoutMask: true,
            min: 1,
            max: 30,
          }
        }"
      />
    </div>
    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :label="trans('button.approve')"
        :disable="!validate()"
        @click="approve"
      />
    </div>
  </div>
</template>

<script>
import { Helper } from '@common/src/helpers';

export default {
  props: {
    action: {
      type: String,
      default: '',
    },
    method: {
      type: String,
      default: 'post',
    },
    message: {
      type: String,
      default: '',
    },
    subjectCount: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        first_page: this.subjectCount.first_page,
        second_page: this.subjectCount.second_page,
        third_page: this.subjectCount.third_page,
        fourth_page: this.subjectCount.fourth_page,
      },
    };
  },
  methods: {
    async approve() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.action,
        method: 'post',
        data: this.form,
      });
      if (!res.error) {
        this.$emit('onClose');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
    validate() {
      return this.form.first_page
        && this.form.second_page
        && this.form.third_page
        && this.form.fourth_page;
    },
  },
};
</script>
