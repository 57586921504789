import { StoreTranslator } from '@common/mixins';

export default {
  mixins: [StoreTranslator],
  props: {
    urlLoadData: {
      type: String,
      required: true,
    },
    manualGroupId: {
      type: Number,
      String,
      required: false,
    },
  },
  beforeCreate() {
    this.$trans.add(['fields', 'warning_text', 'placeholder', 'label', 'button', 'main', 'notice', 'training_schedule', 'table']);
  },
  data() {
    return {
      groupId: this.manualGroupId,
      weekId: '',
      groups: [],
      weeks: [],
      columns: [],
      rows: [],

    };
  },
  mounted() {
    this.loadData(this.groupId);
  },

  methods: {
    getColumns() {
      return _.get(this.columns, this.weekId, []);
    },
    getRows() {
      return _.get(this.rows, this.weekId, []);
    },
    changeGroup() {
      this.weekId = null;
      this.loadData(this.groupId);
    },
    loadData(groupId) {
      const vm = this;

      const config = {
        responseType: 'json',
        method: 'GET',
        params: {
          group_id: groupId,
        },
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      vm.$http.get(vm.urlLoadData, config).then(
        (response) => {
          const { data } = response;
          if (data.groups) {
            vm.$set(vm, 'groups', data.groups);
          }
          if (data.groupId) {
            vm.$set(vm, 'groupId', data.groupId);
          }
          vm.$set(vm, 'columns', data.columns);
          vm.$set(vm, 'weeks', data.weeks);
          vm.$set(vm, 'rows', data.rows);
          vm.$set(vm, 'weekId', data.weekId);
        },

      );
    },
  },
};
