export default {
  props: {
    value: {},
    name: {
      type: String,
      required: false,
    },
    options: {
      type: [Object, Array],
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    // TODO почему то не работает поиск не возможно ввести значение
    minimumResultsForSearch: {
      type: Number,
      default: -1,
    },
    templateFunction: {
      type: Function,
      required: false,
    },
    dropdownParent: {
      type: [String],
      required: false,
    },

  },
  mounted() {
    const vm = this;

    $(vm.$el)
    // init select2
      .select2(vm.getSettings()).val(this.value).trigger('change')
    // emit event on change.
      .on('change', function () {
        vm.$emit('input', this.value);
      });

    $(vm.$el).on('select2:select', function (e) {
      vm.$emit('input', this.value);
    });
  },
  watch: {
    value(value) {
      // update value
      $(this.$el).val(value).trigger('change.select2');
    },
    placeholder(value) {
      // TODO жестокий костыль что бы поменять placeholder
      const vm = this;
      $(vm.$el).off().select2('destroy');
      $(vm.$el)
      // init select2
        .select2(vm.getSettings()).val(this.value).trigger('change')
      // emit event on change.
        .on('change', function () {
          vm.$emit('input', this.value);
        });
    },
    options(options) {
      const vm = this;
      $(vm.$el).select2('destroy');
      const settings = vm.getSettings();
      settings.data = options;
      // update options
      $(vm.$el).select2(settings)
        .val(vm.value)
        .trigger('change')
        .on('change', () => {
          vm.$emit('input', '');
        });
      $(vm.$el).select2();
    },
  },
  destroyed() {
    $(this.$el).off().select2('destroy');
  },
  methods: {
    getSettings() {
      const vm = this;
      const settings = {
        minimumResultsForSearch: -1,
      };
      if (vm.dropdownParent) {
        settings.dropdownParent = $(vm.$el).closest(vm.dropdownParent);
      }
      if (vm.placeholder) {
        settings.placeholder = vm.placeholder;
      }
      if (vm.minimumResultsForSearch) {
        settings.minimumResultsForSearch = vm.minimumResultsForSearch;
      }
      if (vm.templateFunction) {
        settings.templateResult = vm.templateFunction;
        settings.templateSelection = vm.templateFunction;
      }
      return settings;
    },
  },
};
