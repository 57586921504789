import Vue from 'vue';
import store from '@vjs/store';
import VLoginForm from '@common/views/VLoginForm';

if (document.getElementById('v-login-form-app')) {
  window.vRegistrationSms = new Vue({
    el: '#v-login-form-app',
    components: { VLoginForm },
    store,
  });
}
