import Vue from 'vue';
import store from './VueJS/store';
import VExamQuestions from './VueJS/views/VExamQuestions/VExamQuestions.vue';


if (document.getElementById('v-exam-questions-app')) {
  window.vExamQuestionsApp = new Vue({
    el: '#v-exam-questions-app',
    components: { VExamQuestions },
    store,
  });
}
