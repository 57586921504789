import { Room, RoomEvent, VideoPresets } from 'livekit-client';
import { LiveKitWrapper } from 'vue-react-wrapper';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import ChatMixin from './ChatMixin';
import '@livekit/components-styles';

export default {
  mixins: [ChatMixin],
  name: 'VLiveKitView',
  components: { LiveKitWrapper },
  props: {
    apiConfig: {
      type: Object,
      default: () => ({
        createRoom: null,
        getToken: null,
        closeRoom: null,
        checkRoom: null,
      }),
    },
    centrifugeConfig: {
      type: Object,
      default: () => ({
        endpoint: null,
        token: null,
        channel: null,
      }),
    },
    isAdmin: Boolean,
    callStarted: Boolean,
    callEnded: Boolean,
  },
  data() {
    return {
      room: null,
      callStartedLocal: this.callStarted,
      isConnected: false,
      finishProcess: false,
      config: {
        host: null,
        token: null,
        chatConfig: {
          index: null,
          store: null,
          read: null,
        },
      },
    };
  },
  computed: {
    controlBtns() {
      const trans = key => this.trans(`livekit.control_btn_${key}`);
      const makeBtn = (key, color, click) => ({
        label: trans(key),
        color,
        click,
      });
      const result = [];
      if (this.callEnded) {
        return [];
      }
      if (this.isAdmin && !this.callStartedLocal) {
        result.push(makeBtn('start', 'blue', this.handleCreateRoom));
      }
      if (this.callStartedLocal && !this.isConnected) {
        result.push(makeBtn('connect', 'orange', this.connectToRoom));
      }
      if (this.isAdmin && this.callStartedLocal) {
        result.push(makeBtn('finish', 'red', this.handleFinishBtn));
      }
      return result;
    },
  },
  async mounted() {
    await this.$trans.add(['livekit']);
    if (this.callStarted && !this.callEnded) {
      await requestGetDataHelper(this, {
        url: this.apiConfig.getToken,
        variableName: 'config',
        hardSet: true,
      });
      await this.initRoom();
    }
  },
  methods: {
    handleFinishBtn() {
      const events = {};
      events.onApprove = this.handleFinish;
      this.$sModal.open('v-modal-confirm-with-message', {
        id: 'v-modal-confirm-with-message',
        title: this.trans('notice.are_you_sure'),
        component: 'v-modal-confirm-with-message',
        notPersistent: true,
        binds: { type: 'event', inform: this.trans('livekit.finish_modal_inform') },
        events,
      });
    },
    async handleFinish(message) {
      this.finishProcess = true;
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.apiConfig.closeRoom,
        data: { message },
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
        this.finishProcess = false;
      } else {
        this.isConnected = false;
      }
    },
    async handleCreateRoom() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.apiConfig.createRoom,
      });

      if (!res.error) {
        this.config = res.data;
        this.callStartedLocal = true;
        await this.initRoom();
        await this.connectToRoom();
      }
      this.$globalLoading.hide();
    },
    async initRoom() {
      this.room = new Room({
        adaptiveStream: true,
        dynacast: true,
        videoCaptureDefaults: {
          resolution: VideoPresets.h720.resolution,
        },
      });
      await this.room.prepareConnection(this.config.host, this.config.token);

      this.room.on(RoomEvent.Disconnected, async () => {
        if (this.isConnected && !this.finishProcess) {
          this.isConnected = false;
          await this.checkRoom();
        }
      });

      await this.initCentrifuge();
      await this.loadChatMessages();
    },
    async checkRoom() {
      return await requestGetDataHelper(this, {
        url: this.apiConfig.checkRoom,
        returnData: true,
      });
    },
    async connectToRoom() {
      if (await this.checkRoom()) {
        this.isConnected = true;
      }
    },
  },
};
