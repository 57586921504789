<template>
  <q-dialog
    :value="modal"
    @input="handleModal"
  >
    <v-modal
      :title="name"
      size="big"
    >
      <table
        class="s-table-cabinet s-table-cabinet--modal"
      >
        <thead class="s-table__head">
          <tr class="row s-w-100 no-gutters">
            <th
              v-for="column in required"
              class="s-to-center"
              :class="classForTable"
            >
              {{ column.name }}
            </th>
          </tr>
        </thead>
        <tbody class="s-table__body">
          <tr class="row s-w-100 no-gutters">
            <td
              v-for="column in required"
              class="s-to-center"
              :class="classForTable"
              :data-table-head="column.name"
            >
              {{ column.grade }}
            </td>
          </tr>
          <tr v-if="appeal.canAdd">
            <td
              class="d-flex"
              style="height: auto"
              :class="$q.screen.width < 1009 && $q.screen.width > 480 ? 'justify-content-end' : 'justify-content-center'"
            >
              <q-btn
                class="d-block"
                :label="trans('button.apply_for_appeal')"
                color="blue"
                no-caps
                @click="showModal()"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </v-modal>
  </q-dialog>
</template>

<script>
import {
  QDialog,
} from '@quasar/components';
import VModal from '@vjs/components/VModal';
import { ClosePopup } from '@quasar/directives';

export default {
  name: 'VApplicationSubjectsModal',
  components: {
    QDialog,
    VModal,
  },
  directives: { ClosePopup },
  props: {
    modal: Boolean,
    name: {
      type: String,
      default: '',
    },
    classForTable: {
      type: String,
      default: '',
    },
    required: {
      type: Array,
      default: () => ([]),
    },
    appeal: {
      type: Object,
      default: null,
    },
  },
  methods: {
    handleModal() {
      this.$emit('modal-close', false);
    },
    showModal() {
      this.$emit('modal-close', false);
      this.$sModal.open('v-statement-appeal-modal', {
        component: 'v-statement-appeal-modal',
        notPersistent: true,
        binds: {
          uriUserFiles: this.appeal.link,
          action: this.appeal.action,
          type: 'request',
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  .s-table-cabinet--modal {
    table-layout: fixed;
    width: 100%;
    box-shadow: 0 0 1rem rgb(104 124 143 / 28%);
    border-radius: 5px;

    .s-table__body {
      td {
        background-color: rgba(50, 72, 165, 0.005);
        color: #202B56;

        &:not( :last-child ) {
          border-right: 1px solid #EBEDFB;
        }
      }
    }

    .tbl-grade-7 {
      width: 14.27%;
    }

    .tbl-grade-5 {
      width: 20%;
    }

    .s-table__head {
      th {
        background-color: #FFFFFF;
        color: #8F95AC;

        &:not( :last-child ) {
          border-right: 1px solid #EBEDFB;
        }
      }
    }
  }
  @media screen and ( max-width: 510px ) {
    .s-table__body [ data-table-head ] {
      flex-direction: row;

      &::before {
        width: fit-content;
        padding-bottom: 0;
      }

      &:last-of-type {
        &::before {
          width: fit-content;
        }
      }
    }
  }
  @media screen and ( max-width: 480px ) {
    .s-table__body [ data-table-head ] {
      flex-direction: row !important;
      justify-content: space-between;
    }
  }
</style>
