<template>
  <div class="sc-questionnaire__table--wrapper">
    <table>
      <tbody>
        <template v-for="(attribute, key) in attributes">
          <tr
            :key="key"
            class="sc-questionnaire__table--row"
          >
            <td class="sc-questionnaire__table--label">
              {{ attribute.label }}:
            </td>
            <td
              class="sc-questionnaire__table--value"
              v-html="attribute.value"
            />
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'VQuestionnaireDetails',
  props: {
    resource: {
      type: Object,
      default: null,
    },
    isRespondent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    attributes() {
      const items = [];
      _.forEach(this.resource, (item, key) => {
        if ((!this.isRespondent && key !== 'results') || !['questions', 'participants', 'results'].includes(key)) {
          items.push(item);
        }
      });
      return items;
    },
  },
};
</script>
