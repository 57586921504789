export default {
  name: 'VCatalogItem',

  props: {
    linkImage: String,
    linkItem: String,
    title: String,
    phoneNumber: String,
    address: String,
    orgType: String,
    admissionActive: Boolean,
    loading: Boolean,
  },
};
