import moment from 'moment';

export default {
  methods: {
    getLabel(col, data) {
      if (data.is_total) {
        return col.attendance.attendance;
      }
      return col.attendance.attendance !== null || col.is_work === false
        ? col.attendance.attendanceLabel
        : '';
    },
    checkIsCurrentDay(date) {
      const parsedDate = moment(date, 'Y-MM-DD').format('DD/MM/Y');
      if (this.currentDay === parsedDate) {
        return true;
      }
      return moment().set({ year: this.$store.getters.currentYear, month: this.$store.getters.currentMonth.value - 1}).endOf('month').format('DD/MM/Y') === parsedDate;
    },
    handleSetScrollWidth() {
      const interval = setInterval(() => {
        const sheet = this.$refs.timeSheet;
        if (sheet) {
          clearInterval(interval);
          sheet.handleSetScrollWidth();
        }
      }, 1500);
    },
    getMonthStyle(daysCount) {
      switch (daysCount) {
        case 1:
        case 2:
          return 'font-size: 12.3px';
        case 3:
          return 'font-size: ';
        default:
          return '';
      }
    },
    getHeight(index) {
      return this.rowHeight[index];
    },
    reCalcSizes() {
      this.calcHeaderHeight();
      this.calcRowsHeight();
      this.$forceUpdate();
    },
    calcRowsHeight() {
      const { timeSheetData, $refs, rowHeight } = this;
      // eslint-disable-next-line consistent-return
      timeSheetData.forEach((val, index) => {
        const shiftedFixed = $refs[`fixedRow-${index}`] ? $refs[`fixedRow-${index}`][0] : undefined;
        const shiftedScrolled = $refs[`scrollRow-${index}`] ? $refs[`scrollRow-${index}`][0] : undefined;
        if (
          typeof shiftedFixed === 'undefined'
          || typeof shiftedScrolled === 'undefined'
        ) {
          return null;
        }
        rowHeight[index] = shiftedFixed.clientHeight > shiftedScrolled.clientHeight
          ? shiftedFixed.clientHeight
          : shiftedScrolled.clientHeight;
      });
      this.rowHeight = rowHeight;
      return this.rowHeight;
    },
    calcHeaderHeight() {
      const { $refs: { fixedHeader, scrollHeader } } = this;
      if (
        typeof fixedHeader === 'undefined'
        || typeof scrollHeader === 'undefined'
      ) {
        return null;
      }
      this.headerHeight = fixedHeader.clientHeight > scrollHeader.clientHeight
        ? fixedHeader.clientHeight
        : scrollHeader.clientHeight;
      return this.headerHeight;
    },
  },
};
