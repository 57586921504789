import _ from 'lodash';
import { StoreTranslator } from '@common/mixins';

export default {
  name: 'CalendarThematicPlanModalAddTheme',
  mixins: [StoreTranslator],
  props: {
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      default: 'POST',
      required: true,
    },
    resource: {
      type: [Array, Object],
      required: false,
    },
  },
  data() {
    return {
      processSend: false,
      fields: {
        class_index: {
          value: '',
          name: 'class_index',
          component: 'text-field',
          labelTransKey: 'calendar-thematic-plan.class_index',
          v_show: true,
          required: true,
          extraAttributes: {
            maxlength: 255,
          },
        },
        name: {
          value: '',
          name: 'name',
          component: 'text-field',
          labelTransKey: 'fields.name',
          v_show: true,
          required: true,
          extraAttributes: {
            maxlength: 255,
          },
        },
        date: {
          value: '',
          name: 'date',
          component: 'date-field',
          labelTransKey: 'label.date',
          required: true,
        },
        hour_count: {
          value: '',
          name: 'hour_count',
          component: 'text-field',
          labelTransKey: 'calendar-thematic-plan.hour_count',
          v_show: true,
          required: true,
        },
        type: {
          value: '',
          name: 'type',
          component: 'text-field',
          labelTransKey: 'calendar-thematic-plan.type',
          v_show: true,
          required: true,
          extraAttributes: {
            maxlength: 255,
          },
        },
        homework: {
          value: '',
          name: 'homework',
          component: 'text-field',
          labelTransKey: 'calendar-thematic-plan.homework',
          v_show: true,
          required: false,
          extraAttributes: {
            maxlength: 255,
          },
        },
        note: {
          value: '',
          name: 'note',
          component: 'text-field',
          labelTransKey: 'calendar-thematic-plan.note',
          v_show: true,
          required: false,
          extraAttributes: {
            maxlength: 255,
          },
        },
      },
      errors: [],
      currentId: null,
    };
  },
  beforeCreate() {
    this.$trans.add([
      'fields',
      'calendar-thematic-plan',
      'label',
    ]);
  },
  methods: {
    clearFields() {
      for (const key in this.fields) {
        if (this.fields[key]?.value !== undefined) {
          this.$set(this.fields[key], 'value', null);
        }
      }
      this.$set(this, 'currentId', null);
      this.errors = [];
    },
    closeModal() {
      this.$emit('modalClose');
    },
    handleAjaxFormSubmit() {
      const vm = this;
      const data = {};
      _.forEach(vm.fields, (el, key) => {
        if ((typeof el.value === 'object') && el.value !== null) {
          const arrField = [];
          _.forEach(el.value, (arrEl) => {
            arrField.push(arrEl.id);
          });
          _.set(data, key, arrField);
        } else if (el.hasOwnProperty('fill')) {
          el.fill(data);
        } else {
          _.set(data, vm.nameToDot(el.name), el.value);
        }
      });

      if (vm.currentId) {
        data.id = vm.currentId;
      }

      const { action } = vm;
      if (vm.processSend === true) {
        return false;
      }
      vm.$set(vm, 'processSend', true);
      vm.$globalLoading.show();

      vm.errors = [];

      const config = {
        responseType: 'json',
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      vm.$http.post(action, data, config).then(
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(vm, 'processSend', false);
          vm.$emit('success');
          vm.errors = [];
          show_notice(response.data.message, 'notice');
        },
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(vm, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            vm.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleVShow(field) {
      const vm = this;
      if (field.v_show !== undefined) {
        if (_.isFunction(field.v_show)) {
          return field.v_show(vm);
        }
        return field.v_show;
      }

      return true;
    },
    hasError(field) {
      return _.has(this.errors, field);
    },
    getError(field) {
      return _.first(_.get(this.errors, field));
    },
    getErrors(fieldName) {
      return _.get(this.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
  watch: {
    resource(newVal) {
      const vm = this;
      if (!_.isEmpty(newVal)) {
        _.forEach(newVal, (value, key) => {
          if (vm.fields.hasOwnProperty(key)) {
            vm.$set(vm.fields[key], 'value', value);
          } else if (key === 'id') {
            vm.$set(vm, 'currentId', value);
          }
        });
      } else {
        this.clearFields();
      }
    },
  },
};
