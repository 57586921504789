<template>
  <div
    class="s-file-select"
    :class="{ 's-file-select--selected':isSelected }"
  >
    <div
      class="s-file__preview"
      :style="{ backgroundImage: `url(${file.url})`}"
    >
      <div
        class="s-file__overlay-control"
        @dblclick="$emit('onDblclickFile', file)"
        @click="$emit('onClickFile', file)"
      >
        <div class="s-file__btn s-file__btn--thm-select" />
      </div>
    </div>
    <div class="s-file__name">
      <div
        class="s-str-ico s-str-ico--thm-clip"
        :title="file.name"
      >
        {{ file.name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    file: {
      type: Object,
      required: true,
    },
    isSelected: Boolean,
  },
};
</script>
