<template>
  <div>
    <v-wrapper-q-select
      v-model="form.contingent_transcript"
      :options="options"
      :extra-binds="{
        label: trans('label.select_transcript_contingent'),
        placeholder: trans('placeholder.select_from_list'),
        hideBottomSpace: true,
        required: true,
      }"
    />

    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :label="trans('button.approve')"
        @click="approve"
      />
    </div>
  </div>
</template>

<script>
import { Helper } from '@common/src/helpers';

export default {
  props: {
    action: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    isDuplicateFromTranscript: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      form: {
        contingent_transcript: null,
        is_duplicate_from_transcript: this.isDuplicateFromTranscript,
      },
    };
  },
  methods: {
    async approve() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.action,
        params: this.form,
      });

      this.$globalLoading.hide();
      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      } else {
        this.$emit('getTranscripts', res?.data ?? res);
        this.$emit('onClose');
      }
    },
  },
};
</script>
