<template>
  <div class="s-vue-modal__body">
    <div class="row-sort-between-btn">
      <div
        class="s-btn s-btn--w-200 s-btn--thm-grey"
        @click="$modal.dismiss"
      >
        {{ $trans.get('test.continue_test') }}
      </div>
      <div
        class="s-btn s-btn--w-200 s-btn--thm-green"
        @click="confirm"
      >
        {{ $trans.get('test.end_of_test') }}
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store';

export default {
  store,
  name: 'VModalConfirmExit',
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    async confirm() {
      await this.callback();
      this.$modal.dismiss();
    },
  },
};
</script>

<style lang="scss" scoped>
  .s-vue-modal__buttons-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 2rem 0;

    > .s-btn {
      margin: 0 2rem;
    }
  }
</style>
