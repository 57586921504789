import { StoreTranslator } from '@common/mixins';


export default {
  mixins: [StoreTranslator],
  props: {
    urlBack: {
      type: String,
      required: true,
    },
    urlListStatements: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    statementsAdmissionId: {
      type: Number,
      required: false,
    },
  },
  data() {
    const vm = this;
    const fields = {
      statement_admission: {
        value: vm.statementsAdmissionId,
        name: 'statement_admission',
        component: 'v-multi-select-field',
        labelTransKey: 'menu.commissions',
        values: [],
        required: true,
        v_show: true,
        loadValues: {
          autoLoad: true,
          type: 'statement-admissions',
        },
        events: {
          input: vm.loadStatements,
        },
        templateOption: '<b>Квалификация</b>: {name}<br/> <b>Форма обучения</b>: {edu_form} <br/> <b>Язык обучения</b>: {edu_lang}'
          + '<br/> <b>Уровень образования</b>: {edu_level}',
      },
      date: {
        value: '',
        name: 'date',
        component: 'date-field',
        labelTransKey: 'label.date',
        required: true,
      },
      time: {
        value: '',
        name: 'time',
        component: 'select-time-field',
        labelTransKey: 'label.date_time',
        required: true,
      },
      location: {
        value: '',
        name: 'location',
        component: 'text-field',
        labelTransKey: 'fields.location',
        required: true,
      },
      statements: {
        value: [],
        name: 'statements',
        component: 'list-statements-field',
        labelTransKey: 'fields.statements',
        required: true,
      },
    };

    return {
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.redirect) {
            window.location = data.redirect;
          }
        },
      },
    };
  },
  beforeCreate() {
    this.$trans.add(['menu']);
  },
  mounted() {
    const vm = this;
  },

  destroyed() {
  },
  methods: {
    loadStatements() {
      const vm = this;
      const { fields } = vm.form;

      if (!fields.statement_admission.value) {
        return;
      }

      const config = {
        responseType: 'json',
        method: 'GET',
        params: {
          // edu_level: fields.edu_level.value,
          statements_admission_id: fields.statement_admission.value,
        },
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      vm.$http.get(vm.urlListStatements, config).then(
        (response) => {
          const { data } = response;
          vm.$set(vm.form.fields.statements, 'value', data);
          if (data.length === 0) {
            show_notice(this.trans('notice.entrance_exam_no_statements'), 'error');
          }
        },
        (response) => {
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
          }
        },
      );
    },

    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleVShow(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_show !== undefined) {
        if (_.isFunction(field.v_show)) {
          return field.v_show(vm);
        }
        return field.v_show;
      }

      return true;
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(form.fields, (el, key) => {
        _.set(data, vm.nameToDot(el.name), el.value);
      });
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
};
