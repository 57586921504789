import { QInput } from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import SelectUserFile from '@app_college/views/SelectUserFile';

export default {
  components: { QInput, SelectUserFile },
  name: 'StatementAppealModal',
  props: {
    uriUserFiles: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'event',
    },
    action: {
      type: String,
      default: '',
    },
    method: {
      type: String,
      default: 'post',
    },
  },
  data() {
    return {
      message: '',
      file: {
        id: null,
        name: null,
      },
      activeFile: '',
      modalFiles: false,
    };
  },
  methods: {
    async approve() {
      switch (this.type) {
        case 'request':
          await this.request();
          break;
        case 'event':
        default:
          this.$emit('onApprove', this.message);
          break;
      }
    },
    async request() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.action,
        method: this.method,
        [this.method === 'get' ? 'params' : 'data']: { message: this.message, file: this.file },
      });

      if (!res.error) {
        this.$emit('onClose');
        this.$emit('onApprove');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
    clearFile() {
      if (this.file.id !== null) {
        this.file = {
          id: null,
          name: null,
        };
      }
    },
    selectFile(file) {
      this.file = file;
      this.modalFiles = false;
      this.activeFile = null;
    },
    fileModalClose() {
      this.activeFile = null;
      this.modalFiles = false;
    },
    triggerFile(filename) {
      this.activeFile = filename;
      this.modalFiles = true;
    },
  },
};
