import {
  QForm, QInput, QField, QSlideTransition,
} from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import { eventBus } from '@common/mixins';

export default {
  name: 'ReassignSpecialtyPlacesModal',
  components: {
    QForm, QInput, QField, QSlideTransition,
  },
  props: {
    uriSubmit: String,
    uriGetSpecialties: String,
    uriGetEduLevels: String,
    row: Object,
  },
  data() {
    return {
      form: {
        specialtyId: null,
        eduLevel: null,
        places: null,
      },
      eduLevels: [],
      specialities: [],
    };
  },

  async mounted() {
    await this.$trans.add(['label', 'button', 'statement']);
    await this.requestGetSpecialities();
  },

  computed: {
    validate() {
      const { specialtyId, eduLevel, places } = this.form;
      return specialtyId !== null
        && eduLevel !== null
        && places !== null
        && places !== 0;
    },
  },

  methods: {
    handleInput() {
      const min = 0;
      const max = this.row.places_count;
      const value = Number(this.form.places);

      if (value < min) {
        this.form.places = min;
      } else if (value > max) {
        this.form.places = max;
      } else if (this.form.places !== value) {
        this.form.places = value; // Дабы избежать значений по типу '000'
      }
    },
    async requestGetSpecialities() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.uriGetSpecialties,
      });

      if (!res.error) {
        this.specialities = res.data ?? [];
      }
      this.$globalLoading.hide();
    },
    async requestGetEduLevels() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.uriGetEduLevels,
        params: { professionId: this.form.specialtyId },
      });

      if (!res.error) {
        this.eduLevels = res.data ?? [];
      }
      this.$globalLoading.hide();
    },
    async handleAjaxFormSubmit() {
      this.$globalLoading.show();

      const data = this.form;

      const res = await requestWrapper.call(this, {
        url: this.uriSubmit,
        method: 'post',
        data,
      });

      if (!res.error) {
        await eventBus.$emit('getItems');
        this.$globalLoading.hide();
        this.$emit('onClose');
      } else {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
  },
};
