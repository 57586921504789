import {
  QSpinnerGears,
  QTooltip,
  QInput,
  QToggle,
  QForm,
  QBtn,
  QDialog,
  QSlideTransition,
} from '@quasar/components';
import { mapGetters } from 'vuex';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import VHeadings from '../Components/VHeadings';
import VControlButtons from '../Components/VControlButtons';
import helpers from '../mixins/helpers';

export default {
  name: 'VQuestion',
  components: {
    QSpinnerGears,
    QTooltip,
    QInput,
    QToggle,
    QForm,
    QBtn,
    QDialog,
    QSlideTransition,
    VHeadings,
    VControlButtons,
  },
  mixins: [helpers],
  props: {
    questionProp: {
      type: Object,
      required: true,
    },
    questionNumber: {
      type: Number,
      required: true,
    },
    loading: Boolean,
  },
  data() {
    return {
      visibleListAnswers: false,
      storeData: this.$store,
      editor: ClassicEditor,
      editorConfig: {
        language: 'ru',
        toolbar: {
          items: [
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'blockQuote',
            'insertTable',
            'undo',
            'redo',
          ],
        },
      },
      disableSave: false,
    };
  },
  computed: {
    dataQuestion() {
      return this.questionProp;
    },
    ...mapGetters(['questionId']),
  },
  methods: {
    changeVisibility() {
      this.visibleListAnswers = true;
    },
    async questionForm() {
      const vm = this;
      if (vm.disableSave) return;
      this.$store.commit('SET_METHOD', 'PATCH');
      vm.disableSave = true;
      await this.$store.dispatch('CREATE_QUESTIONNAIRE_QUESTION', {
        question: this.dataQuestion.question,
        answers: this.dataQuestion.answers,
      });
      vm.disableSave = false;
    },
  },
};
