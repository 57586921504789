import '@app_college/components/Legacy';
import Vue from 'vue';
import store from '@app_college/store';
import bootstrap from '@app_college/bootstrap';
import componentsViews from '@app_college/components/Views';
import componentsIndex from '@app_college/components/Index';
import componentsModals from '@app_college/components/Modals';
import componentsForm from '@common/components/Form';

bootstrap(Vue);
componentsViews(Vue);
componentsIndex(Vue);
componentsForm(Vue);
componentsModals(Vue);

[
  'v-footer-app',
  'cl-vue-container',
]
  .forEach((element) => {
    if (document.getElementById(element)) {
      const config = {};
      config.store = store;
      const vm = new Vue(config);
      vm.$mount(`#${element}`);
    }
  });

window.Vue = Vue;
