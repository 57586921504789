<template>
  <q-tabs
    v-model="tab"
    active-color="green"
    indicator-color="green"
    class="sc-journal-tabs"
    :class="{'sc-journal-tabs--without-scroll': $q.platform.is.desktop}"
    mobile-arrows
    no-caps
    @input="handleClickStudentTab"
  >
    <q-tab
      v-for="(tab, key) in students"
      :key="key"
      :class="$q.platform.is.mobile ? '' : 's-w-100'"
      :name="tab.journalId"
      :label="tab.shortName"
    />
  </q-tabs>
</template>

<script>
import { QTabs, QTab } from '@quasar/components';
import { eventBus } from '@common/mixins';

export default {
  name: 'VJournalTabs',
  components: { QTabs, QTab },
  data: () => ({ tab: null }),
  computed: {
    students() { return this.$store.getters.students; },
  },
  mounted() { this.tab = this.$store.getters.journalId; },
  methods: {
    handleClickStudentTab(journalId) {
      this.$store.commit('SET_JOURNAL_ID', journalId);
      eventBus.$emit('reloadJournal');
    },
  },
};
</script>
