import eventHub from '@vjs/config/eventHub';
import {
  QBtn,
  QTooltip,
} from '@quasar/components';

export default {
  name: 'VLibraryDescription',
  props: {
    itsLibrarian: {
      type: Boolean,
      default: false,
    },
    canDeleteBook: {
      type: Boolean,
      default: false,
    },
    getBook: {
      type: String,
      default: '/',
    },
  },
  components: {
    QBtn,
    QTooltip,
  },
  data() {
    return {
    };
  },
  methods: {
    getType(litType) {
      return this.$store.state.library.types.full
        .filter(v => v.value === parseInt(litType, 10))[0].label;
    },
    openDestroyModal() {
      eventHub.$emit('openDestroyFromDesc', true);
    },
    openEditModal() {
      eventHub.$emit('openEditFromDesc', true);
    },
    scrollToTop() {
      window.scrollTo(0, 70);
    },
  },
  computed: {
    description() {
      return this.$store.state.library.description;
    },
  },
  mounted() {
    this.scrollToTop();
  },
};
