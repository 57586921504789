import { QIcon } from '@quasar/components';
import SelectUserFile from '@app_college/views/SelectUserFile';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';

export default {
  name: 'VStatementAppeal',
  components: {
    QIcon,
    SelectUserFile,
  },
  props: {
    apiConfig: {
      type: Object,
      default: {
        appealBackToList: null,
        downloadAppeal: null,
        uploadAppealSolution: null,
      },
    },
    uriUserFiles: {
      type: String,
      required: true,
    },
    fullName: {
      type: String,
    },
    description: {
      type: String,
    },
    canProcess: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadFile: {
        id: null,
        name: null,
      },
      activeFile: '',
      modalFiles: false,
    };
  },
  mounted() {
    this.$trans.add(['warning_text', 'button']);
  },
  methods: {
    async selectFile(file) {
      this.uploadFile = file;
      await this.request();
      this.modalFiles = false;
      this.activeFile = null;
    },
    fileModalClose() {
      this.activeFile = null;
      this.modalFiles = false;
    },
    triggerFile(filename) {
      this.activeFile = filename;
      this.modalFiles = true;
    },
    async request() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.apiConfig.uploadAppealSolution.apiUrl,
        method: 'post',
        [this.method === 'get' ? 'params' : 'data']: { file: this.uploadFile },
      });

      if (!res.error) {
        this.$emit('onClose');
        this.$emit('onApprove');
        this.canProcess = false;
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
