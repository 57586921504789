import {
  QDialog,
} from '@quasar/components';
import VModal from '@vjs/components/VModal';

export default {
  name: 'FilePreviewModal',
  components: {
    QDialog,
    VModal,
  },
  props: {
    modal: Boolean,
    src: {
      type: String,
      default: '',
    },
  },
};
