import { Helper } from '@common/src/helpers';
import { requestWrapper } from '@vjs/helpers';

export default {
  name: 'VMultipleButtonsModal',
  props: {
    buttons: {
      type: Array,
      required: true,
    },
    meta: {
      type: [Array, Object],
      required: false,
    },
  },

  methods: {
    async handleButton(btn) {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: btn.url,
        method: btn.method ?? 'GET',
        params: this.meta,
      });

      if (!res.error) {
        this.$emit('onClose');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
