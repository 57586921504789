import Vue from 'vue';
import store from './VueJS/store';
import VStatementAdmissionForm from './VueJS/views/VStatementAdmissionForm/index';

if (document.getElementById('v-statement-admission-form-app')) {
  window.vDocFlowApp = new Vue({
    el: '#v-statement-admission-form-app',
    components: { VStatementAdmissionForm },
    store,
  });
}
