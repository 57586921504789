<template>
  <notifications
    :duration="-1"
    class="s-notification__wrapper"
    :position="''"
    :width="''"
  >
    <template v-slot:body="note">
      <div
        class="s-notification"
        :class="note.item.type"
      >
        <button
          class="s-ico s-ico--thm-close s-notification__close"
          @click="note.close"
        />
        <div
          v-if="note.item.title"
          class="s-notification__head"
        >
          <strong class="s-notification__title">
            {{ note.item.title }}
          </strong>
        </div>
        <div class="s-notification__body">
          <div
            class="s-notification__message"
            v-html="note.item.text"
          />
          <div
            v-if="note.item.data && note.item.data.links"
            class="s-notification__links-wrapper"
          >
            <a
              v-for="(link, index) in note.item.data.links"
              :key="index"
              :href="link.url"
              class="s-notification__link"
            >
              {{ link.text }}
            </a>
          </div>
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
export default {
  name: 'Notify',
  mounted() {
    const message = {};
    /* if (window.core_project) {
      if (window.core_project.messages) {
        message.type = 'success';
        message.messages = window.core_project.messages;
      } else if (window.core_project.errors) {
        message.type = 'error';
        message.messages = window.core_project.errors;
      } else if (window.core_project.hints) {
        message.type = 'warn';
        message.messages = window.core_project.hints;
      }
    } */
    if (Object.keys(message).length !== 0) {
      if (Array.isArray(message.messages)) {
        message.messages.forEach((item) => {
          this.$notify({
            type: message.type,
            title: window.L.message,
            text: item,
            duration: 10000,
          });
        });
      } else {
        this.$notify({
          type: message.type,
          title: window.L.message,
          text: message.messages,
          duration: 10000,
        });
      }
    }
  },
};
</script>
