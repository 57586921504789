import Vue from 'vue';
import TrainingScheduleForm from './TrainingSchedule/Form';
import ContingentForm from './Contingent/Form';
import ContingentAdditionalInfoForm from './Contingent/AdditionalInfoForm';
import ChildrenForm from './Children/Form';
import SelectOrganizationForm from './SelectOrganization/Form';
import SelectRoleForm from './SelectRole/Form';
import HomeworkForm from './Homework/Form';
import AssignedHomework from './AssignedHomework/Form';
import AssignedHomeworkAnswer from './AssignedHomeworkAnswer/Form';
import AssignedHomeworkAnswerVerify from './AssignedHomeworkAnswerVerify/Form';
import AssignedHomeworkRevision from './AssignedHomeworkRevision/Form';
import OnlineLectureForm from './OnlineLecture/Form';
import ExamThemesForm from './ExamThemes/Form';
import ExamTicketsForm from './ExamTickets/Form';
import ExamQuestionGroupForm from './ExamQuestionGroup/Form';
import HomeworkQuestionGroupForm from './HomeworkQuestionGroup/Form';
import SubgroupsForm from './Subgroups/Form';
import SubgroupsManagement from './Subgroups/Management';
import GroupForm from './Group/Form';
import EducationModuleForm from './EducationModule/Form';
import CalendarThematicPlan from './CalendarThematicPlan/Form';
import VCalendarThematicPlanShow
from './CalendarThematicPlan/Show/VCalendarThematicPlanShow.vue';
import VExternalStatementSignButtons
from './VExternalStatementSign/Buttons/VStatementSignButtons.vue';
import VExternalStatementSignModals
from './VExternalStatementSign/Modals/VStatementSignModals.vue';

import VStatementSerivceSignModals
from './VStatementServiceSign/Modals/VStatementSignModals.vue';
import VStatementSerivceSignButtons
from './VStatementServiceSign/Buttons/VStatementSignButtons.vue';
import VGovServiceSign
from './UserGovServiceStatement/Modals/VGovServiceSign.vue';
import VEducationResultForm from './EducationResult/Form';
import PersonnelAdditionalInfoForm from './Personnel/AdditionalInfoForm';

import VideoConferencesCreateForm from './VideoConferencesCreateForm/Form';

Vue.component('vue-training-schedule-form', TrainingScheduleForm);
Vue.component('vue-contingent-form', ContingentForm);
Vue.component('vue-contingent-additional-info-form', ContingentAdditionalInfoForm);
Vue.component('vue-children-form', ChildrenForm);
Vue.component('vue-select-organization-form', SelectOrganizationForm);
Vue.component('vue-select-role-form', SelectRoleForm);

Vue.component('vue-homework-form', HomeworkForm);
Vue.component('vue-assigned-homework-form', AssignedHomework);
Vue.component('vue-assigned-homework-answer-form', AssignedHomeworkAnswer);
Vue.component('vue-assigned-homework-answer-verify-form', AssignedHomeworkAnswerVerify);
Vue.component('vue-assigned-homework-revision-form', AssignedHomeworkRevision);

Vue.component('vue-online-lecture-form', OnlineLectureForm);

Vue.component('vue-exam-themes-form', ExamThemesForm);
Vue.component('vue-exam-tickets-form', ExamTicketsForm);
Vue.component('vue-exam-question-group-form', ExamQuestionGroupForm);
Vue.component('vue-homework-question-group-form', HomeworkQuestionGroupForm);

Vue.component('vue-subgroups-form', SubgroupsForm);
Vue.component('vue-subgroups-management', SubgroupsManagement);
Vue.component('vue-group-form', GroupForm);

Vue.component('vue-education-module-form', EducationModuleForm);
Vue.component('vue-calendar-thematic-plan-form', CalendarThematicPlan);
Vue.component('v-calendar-thematic-plan-show', VCalendarThematicPlanShow);

Vue.component('v-external-statement-sign-buttons', VExternalStatementSignButtons);
Vue.component('v-external-statement-sign-modals', VExternalStatementSignModals);

Vue.component('v-statement-service-sign-buttons', VStatementSerivceSignButtons);
Vue.component('v-statement-service-sign-modals', VStatementSerivceSignModals);

Vue.component('v-gov-service-sign-modals', VGovServiceSign);
Vue.component('v-education-result-form', VEducationResultForm);
Vue.component('v-personnel-additional-info-form', PersonnelAdditionalInfoForm);

Vue.component('v-video-conferences-create-form', VideoConferencesCreateForm);
