import Centrifuge from 'centrifuge';
import { clearInterval, setInterval } from 'worker-timers';
import SignNcalayer from './SignNcalayer';
import SignQr from './SignQr';
import SignPhone from './SignPhone';


export default {
  components: {
    SignNcalayer,
    SignQr,
    SignPhone,
  },

  props: {
    ncaLayerAction: {
      type: String,
      required: true,
    },
    getData: {
      type: String,
      required: true,
    },
    updateQr: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      desktop: this.$q.platform.is.desktop,

      qr: null,

      phoneLink: '',
      phoneLinkBusiness: '',

      socketConfig: {
        channel: null,
        endpoint: null,
        token: null,
      },

      ncaLayerConfig: {
        method: 'signXml',
        args: ['PKCS12', 'SIGNATURE', '', '', ''],
      },

      centrifuge: null,
      centrifugeSubscription: null,

      qrInterval: null,
      localTimestamp: 0,
      qrExpiredAt: 0,
      qrExpired: false,
    };
  },

  async beforeMount() {
    await this.initData();
    if (this.desktop) {
      this.initQrTimer();
      await this.initCentrifuge();
    }
  },

  beforeDestroy() {
    if (this.desktop) {
      clearInterval(this.qrInterval);
      this.centrifugeSubscription.unsubscribe();
      this.centrifuge.disconnect();
    }
  },

  methods: {
    async initData() {
      const res = await requestGetDataHelper(this, {
        url: this.getData,
        returnData: true,
        fullRes: true,
      });

      if (!res.error) {
        this.socketConfig = res.socketConfig;
        this.qr = res.svg;
        this.phoneLink = res.deepLink.egov;
        this.phoneLinkBusiness = res.deepLink.business;
        this.ncaLayerConfig = res.ncaLayerConfig;
      }
    },
    async initCentrifuge() {
      this.centrifuge = new Centrifuge(this.socketConfig.endpoint, {
        subscribeEndpoint: '/broadcasting/auth',
        subscribeHeaders: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
      });

      this.centrifuge.setToken(this.socketConfig.token);
      this.centrifugeSubscription = this.centrifuge.subscribe(this.socketConfig.channel,
        (message) => {
          const event = message?.data?.event ?? '';
          const data = message?.data?.data ?? {};
          this.onUserEvents(event, data);
        });

      this.centrifuge.connect();
    },
    async onUserEvents(event, data) {
      switch (event) {
        case 'failed':
          this.$notify({ text: data.message, type: 'error' });
          break;
        case 'success':
          window.location.href = data.redirect;
          break;
        default:
      }
    },
    initQrTimer() {
      this.localTimestamp = moment().unix();
      this.qrExpiredAt = moment().unix() + (60 * 5);
      // this.qrExpiredAt = moment().unix() + 10; //TODO: для теста
      this.qrInterval = setInterval(this.handleQrTimer, 1000);

      document.addEventListener('visibilitychange', async () => {
        if (document.hidden) {
          clearInterval(this.qrInterval);
        } else {
          this.localTimestamp = moment().unix();
          this.qrInterval = setInterval(this.handleQrTimer, 1000);
        }
      });
    },
    handleQrTimer() {
      this.localTimestamp += 1;
      if (this.localTimestamp >= this.qrExpiredAt) {
        clearInterval(this.qrInterval);
        this.qrExpired = true;
      }
    },
    async handleUpdateQr() {
      const res = await requestGetDataHelper(this, {
        url: this.updateQr,
        fullRes: true,
        returnData: true,
      });
      this.qr = String(res);
      this.qrExpired = false;
      this.initQrTimer();
    },
  },
};
