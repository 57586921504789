import Vue from 'vue';
import Vuex from 'vuex';
import translator from '@common/plugins/Translator/store';
import state from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    translator,
  },
  state: { ...state },
  mutations: { ...mutations },
  actions: { ...actions },
  getters: { ...getters },
});
