import Vue from 'vue';
import axios from 'axios';
import store from './VueJS/store';
import VDocFlowForm from './VueJS/views/VDocFlowForm/index';


if (document.getElementById('v-doc-flow-form-app')) {
  window.vDocFlowFormApp = new Vue({
    el: '#v-doc-flow-form-app',
    components: { VDocFlowForm },
    store,
  });
}
