import { StoreTranslator } from '@common/mixins';
import { Helper } from '@common/src/helpers';
import { requestWrapper } from '@vjs/helpers';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  mixins: [StoreTranslator],
  props: {
    urlStore: {
      type: String,
      required: true,
    },
    urlRefs: {
      type: String,
      required: true,
    },
    urlFileTypes: {
      type: String,
      required: true,
    },
    urlUserFiles: {
      type: String,
      required: true,
    },
    urlGetSemesters: {
      type: String,
      required: false,
    },
    uriCheckIin: {
      type: String,
      required: false,
    },
  },
  data() {
    const vm = this;
    return {
      editor: ClassicEditor,
      editorConfig: {
        language: 'ru',
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'blockQuote',
            'undo',
            'redo',
          ],
        },
      },
      openModalFiles: false,
      currentSelectFile: {},
      access: false,
      refs: {
        types: [],
      },
      options: {
        semesters: [],
      },
      form: {
        processSend: false,
        action: vm.urlStore,
        method: 'POST',
        errors: [],
        fields: {
          type: '',
          files: [],
          iin: null,
          goal: null,
          semesters: [],
          info_ru: null,
          info_kz: null,
        },
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
        },
      },
      userFullName: null,
      iinChecked: false,
    };
  },
  computed: {
    showIinField() {
      if (!this.form.fields.type) {
        return false;
      }
      const type = this.refs.types.find(el => el.id == this.form.fields.type);
      return type.student_docs;
    },
    isTranscript() {
      return this.form.fields.type === '32';
    },
    isArchived() {
      return this.form.fields.type === '37';
    },
  },
  mounted() {
    const vm = this;
    vm.loadRefs();
  },

  destroyed() {
  },
  methods: {
    loadRefs() {
      const vm = this;
      $.ajax({
        url: vm.urlRefs,
        method: 'GET',
        dataType: 'JSON',
        headers: {
          'Accept-Language': window.core_project.locale,
        },
        success(response) {
          vm.$set(vm, 'refs', response);
        },
      });
    },
    loadFileTypes() {
      const vm = this;

      $.ajax({
        url: Helper.urlGenerate(vm.urlFileTypes, { docType: vm.form.fields.type }),
        method: 'GET',
        dataType: 'JSON',
        headers: {
          'Accept-Language': window.core_project.locale,
        },
        success(response) {
          const files = [];
          _.forEach(response, (value, key) => {
            value.user_file_id = null;
            value.user_file_name = null;
            files.push(value);
          });
          vm.$set(vm.form.fields, 'files', files);
        },
      });
    },
    openSelectFile(file) {
      const vm = this;

      vm.currentSelectFile = file;
      vm.$set(vm, 'openModalFiles', true);
    },
    onSelectClose() {
      const vm = this;
      vm.$set(vm, 'openModalFiles', false);
    },
    selectFile(file) {
      const vm = this;

      vm.currentSelectFile.user_file_id = file.id;
      vm.currentSelectFile.user_file_name = file.name;
      vm.$set(vm, 'openModalFiles', false);
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = _.cloneDeep(form.fields);
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      form.errors = [];
      vm.$http.interceptors.push({
        request(request) {
          return request;
        },
        response(response) {
          return response;
        },
      });
      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      if (method.toLowerCase() === 'post' || method.toLowerCase() === 'delete') {
        config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;
      }
      vm.$http.post(action, data, config).then(
        (response) => {
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
            });
            show_notice(errors, 'error');
            form.errors = response.data.errors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return form.errors[field] !== undefined;
    },
    getError(form, field) {
      return _.first(form.errors[field]);
    },
    async checkIin(form) {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.uriCheckIin,
        params: { iin: this.form.fields.iin, type: this.form.fields.type },
      });

      if (res.error) {
        form.errors = res.data.errors;
        Helper.handlerResponseErrorNew(this, res);
      } else {
        form.errors = [];
        this.userFullName = res.data.fullName;
        this.iinChecked = true;
        await requestGetDataHelper(this, {
          url: this.urlGetSemesters,
          variableName: 'options',
          params: { iin: this.form.fields.iin, type: this.form.fields.type },
        });
      }

      this.$globalLoading.hide();
    },
  },
  watch: {
    'form.fields.type': function (newValue, oldValue) {
      const vm = this;
      vm.loadFileTypes();
    },
  },
};
