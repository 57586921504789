import {
  QTable,
  QBtn,
} from '@quasar/components';
import VNoResults from '@vjs/components/VNoResults';
import {
  requestWrapper,
} from '@vjs/helpers';
import axios from 'axios';

export default {
  name: 'VNameBookView',
  components: {
    VNoResults,
    QTable,
    QBtn,
  },
  props: {
    urlGroupsList: String,
    urlContingentList: String,
    urlExportContingentList: String,
  },
  data() {
    return {
      loading: false,
      filters: {
        group: null,
      },
      pagination: {
        rowsPerPage: 20,
      },
      options: {
        groups: [],
      },
      table: {
        rows: [],
        columns: [],
      },
    };
  },
  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'warning_text',
      'export',
    ]);
    await this.requestGetOptions();
  },
  methods: {
    async requestGetOptions() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.urlGroupsList,
      });
      if (!res.error) {
        this.options.groups = res.data;
      }
      this.$globalLoading.hide();
    },
    async requestGetContingentList() {
      this.$globalLoading.show();
      this.loading = true;
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.urlContingentList,
        params: this.filters,
      });
      if (!res.error) {
        this.table = res;
      }
      this.$globalLoading.hide();
      this.loading = false;
    },
    async exportContingents() {
      this.$globalLoading.show();
      axios({
        url: `${this.urlExportContingentList}`,
        method: 'GET',
        responseType: 'blob',
        params: this.filters,
      })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          const date = new Date();
          const group = this.options.groups.find(o => o.value === this.filters.group);
          const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
          fileLink.setAttribute('download',
            `${this.trans('export.filenames.monitoring_name_book_export', {
              group: group.label,
              date: formattedDate,
            })}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.$globalLoading.hide();
        });
    },
  },
};
