import VNoResults from '@vjs/components/VNoResults';
import {
  QPagination,
} from '@quasar/components';
import {
  requestWrapper,
  genArray,
  scrollTop,
} from '@vjs/helpers';
import eventHub from '@vjs/config/eventHub';
import VApplicationItem from './VApplicationItem';
import VApplicationServiceItem from './VApplicationServiceItem';

export default {
  name: 'VApplicationListView',
  components: {
    QPagination,
    VNoResults,
    VApplicationItem,
    VApplicationServiceItem,
  },
  data() {
    return {
      items: [],
      itemsPaginate: [],
      loading: true,
      currentPage: 1,
      countByPage: 5,
    };
  },

  props: {
    uriList: {
      type: String,
      required: true,
    },
  },

  async mounted() {
    await this.$trans.add([
      'statement',
      'button',
      'label',
      'notice',
      'organization',
      'warning_text',
      'fields',
    ]);

    await this.requestGetApplications();
    eventHub.$on('updatedDocs', async () => {
      this.loading = true;
      await this.requestGetApplications();
    });
  },

  computed: {
    countOfPages() {
      const count = Math.ceil(this.items.length / this.countByPage);
      this.currentPage = 1;
      return count <= 1 ? 0 : count;
    },
    itemsPaginated() {
      if (this.loading) {
        return genArray({
          id: 0,
          identifierDate: '',
          label: '',
          type: '',
          locked: {},
          remove: false,
          uriRemove: '',
          canRemove: false,
          desc: genArray({
            label: '',
            value: '',
          }, 2),
          descAdditional: [],
          steps: [],
          docsBtns: [],
          docsBtn: {},
        }, 4);
      }
      return this.calcPagination(this.currentPage);
    },
  },

  methods: {
    async requestGetApplications() {
      const applicationsRes = await requestWrapper.call(this, {
        url: this.uriList,
      });
      if (!applicationsRes.error) {
        this.items = applicationsRes;
        this.loading = false;
      }
    },
    calcPagination(val) {
      const cur = (val - 1) * this.countByPage;
      return this.items.slice(cur, cur + this.countByPage);
    },
    // Запускается на событие @input в q-pagination
    async changePagination() {
      // Перевод страницы к началу при выборе страницы
      scrollTop(
        window,
        window.scrollY,
        window.scrollY - Math.abs(this.$refs.catalog.getBoundingClientRect().top),
        500,
      );
    },
    removeApplication(id, type) {
      this.items = this.items.filter(itm => itm.type !== type || (itm.type === type && itm.id !== id));
    },
  },
};
