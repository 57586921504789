<template>
  <div>
    <q-input
      v-model="form.name"
      :label="trans('label.name')"
      required
      :placeholder="trans('placeholder.name')"
    />
    <v-wrapper-q-select
      v-model="form.type_of_event"
      :options="options.typeOfEvent"
      :extra-binds="{
        label: trans('label.type_of_event'),
        hideBottomSpace: true,
        required: true
      }"
    />
    <v-wrapper-q-select
      v-model="form.type_of_direction"
      :options="options.typeOfDirection"
      :extra-binds="{
        label: trans('label.type_of_direction'),
        hideBottomSpace: true,
        required: true
      }"
    />
    <v-wrapper-q-select
      v-model="form.event_lvl"
      :options="options.eventLvl"
      :extra-binds="{
        label: trans('label.event_lvl'),
        hideBottomSpace: true,
        required: true
      }"
    />
    <v-wrapper-q-date
      v-model="form.date_participation"
      :extra-binds="{
        input: { required: true },
        label: trans('label.date_participation'),
        hideBottomSpace: true
      }"
    />
    <v-wrapper-q-select
      v-model="form.reward"
      :options="options.reward"
      :extra-binds="{
        label: trans('label.reward'),
        hideBottomSpace: true,
        required: true
      }"
    />
    <v-wrapper-q-number-input
      v-model.number="form.point"
      :extra-binds="{
        label: trans('label.achievement_point'),
        counter: true,
        config: {
          min: 0,
          max: 100
        },
        required: true
      }"
    />
    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :label="trans('button.approve')"
        @click="approve"
      />
    </div>
  </div>
</template>

<script>
import { Helper } from '@common/src/helpers';

export default {
  props: {
    action: {
      type: String,
      required: true,
    },
    options: {
      type: [Object, Array],
      required: true,
    },
    getExistingData: {
      type: String,
      required: true,
    },
    achievementId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      form: {
        name: null,
        type_of_event: null,
        type_of_direction: null,
        event_lvl: null,
        date_participation: null,
        reward: null,
        point: null,
      },
    };
  },
  async mounted() {
    if (this.achievementId) {
      const getExistDataConfig = {
        url: this.getExistingData,
        variableName: 'form',
        params: {
          achievement_id: this.achievementId,
        },
      };
      await requestGetDataHelper(this, getExistDataConfig);
    }
  },
  methods: {
    async approve() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.action,
        method: this.achievementId ? 'patch' : 'post',
        params: this.form,
      });
      this.$globalLoading.hide();
      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      } else {
        this.$emit('onApprove');
        this.$emit('onClose');
      }
    },
  },
};
</script>
