import { Helper } from '@common/src/helpers';
import arrayHelper from '../mixins/arrayHelper';

export default {
  name: 'VDormitoryForm',
  mixins: [arrayHelper],
  props: {
    resource: {
      type: Object,
      default: null,
    },
    uriAction: {
      type: String,
      default: null,
    },
    backLink: {
      type: String,
      default: null,
    },
    uriGetOptions: {
      type: String,
      default: null,
    },
    method: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      fields: {
        name: null,
        address: null,
        floors_count: null,
        floors: [],
      },
    };
  },
  computed: {
    validate() {
      return this.fields.name !== null
        && this.fields.address !== null
        && this.fields.floors_count !== null
        && this.fields.floors.length > 0
        && this.isFilledRooms;
    },
    isFilledRooms() {
      return this.fields.floors.filter(
        item => !item.rooms_count
          || item.rooms.length === 0
          || item.rooms.filter(
            el => el.number === null || !el.places_count
          ).length !== 0
      ).length === 0;
    },
    floors() {
      return this.mapDefault(this.makeArray(30));
    },
  },
  async mounted() {
    await this.$trans.add(['dormitory', 'button', 'filter_fields', 'select']);
    _.forEach(this.resource, (value, attribute) => {
      if (Object.prototype.hasOwnProperty.call(this.fields, attribute)) {
        _.set(this.fields, `${attribute}`, value);
      }
    });
    eventBus.$on('update-floor', (event) => {
      this.fields.floors[event.floor].color = 'green';
      this.fields.floors[event.floor].rooms = event.rooms;
    });
  },
  methods: {
    fillFloors() {
      this.fields.floors = this.makeArray(this.fields.floors_count.value).map(item => ({
        label: item + 1,
        value: item + 1,
        rooms_count: null,
        rooms: [],
        color: 'grey',
      }));
    },
    changeColor(key) {
      if (this.fields.floors[key].rooms.length !== this.fields.floors[key].rooms_count) {
        this.fields.floors[key].rooms = [];
      }
      if (!this.isFilledRooms) {
        this.fields.floors[key].color = this.fields.floors[key].rooms_count ? 'blue' : 'grey';
      } else {
        this.fields.floors[key].rooms.color = 'green';
      }
    },
    async handleAjaxFormSubmit() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.uriAction,
        method: this.method,
        data: this.fields,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      }

      this.$globalLoading.hide();
    },
    fillRoomsInfo(key) {
      this.$sModal.open('v-dormitory-room-modal', {
        component: 'v-dormitory-room-modal',
        notPersistent: true,
        title: this.trans('dormitory.modal.title'),
        size: 'large',
        binds: {
          floor: key,
          resource: this.fields.floors[key],
        },
      });
    },
  },
};
