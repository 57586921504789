import {
  QBtn, QPagination, QTabs, QTab,
} from '@quasar/components';
import { requestWrapper, scrollTop } from '@vjs/helpers';
import VPhotoAlbum from '@common/components/VPhotoAlbum';
import { Helper } from '@common/src/helpers';
import VPassportTable from './VPassportTable';
import ModalUserInfo from './Modals/UserInfo';
import VPassportPagePostItem from './VPassportPagePostItem';

export default {
  name: 'VPassportPageView',

  components: {
    QBtn,
    QTabs,
    QTab,
    QPagination,
    VPhotoAlbum,
    VPassportTable,
    VPassportPagePostItem,
  },

  props: {
    guest: {
      type: Boolean,
      required: true,
    },
    urlCallCommission: {
      type: String,
      required: true,
    },
    uriGetData: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      tab: 'about',
      organization: {
        name: '',
        description: '',
        conditions: '',
        picItems: [],
        aboutOrg: [],
        tables: [],
        govServices: [],
        call_commission: false,
        events: [],
        news: [],
        education: [],
        commission_work_schedule: [],
      },
      posts: {
        items: [],
        currentPage: 1,
        countByPage: 5,
      },
      isFullInformation: false,
    };
  },
  computed: {
    postsPaginated() {
      const current = (this.posts.currentPage - 1) * this.posts.countByPage;
      return this.posts.items.slice(current, current + this.posts.countByPage);
    },
    postsCountOfPages() {
      let count = parseInt(this.posts.items.length / this.posts.countByPage, 10);
      const remains = this.posts.items.length % this.posts.countByPage;
      count += remains !== 0 ? 1 : 0;
      this.currentPage = 1;
      return count <= 1 ? 0 : count;
    },
    navButtons() {
      const items = [
        { name: 'about', label: this.trans('label.about_organization') },
      ];

      if (this.organization.description) {
        items.push({
          name: 'description',
          label: this.trans('label.description'),
        });
      }

      if (this.organization.conditions) {
        items.push({
          name: 'conditions',
          label: this.trans('fields.conditions_of_admission'),
        });
      }

      if (this.organization.commission_work_schedule.length > 0) {
        items.push({
          name: 'commissionWorkSchedule',
          label: this.trans('admission.structure.work_schedule'),
        });
      }

      if (this.organization.tables.length > 0) {
        items.push({
          name: 'admission',
          label: this.trans('breadcrumb.commission'),
        });
      }

      if (this.organization.call_commission) {
        items.push({
          name: 'callCommission',
          label: this.trans('button.call_commission'),
        });
      }

      return items;
    },
    tabs() {
      return [
        { name: 'about', label: this.trans('label.description') },
        { name: 'events', label: this.trans('menu.events') },
        { name: 'news', label: this.trans('menu.news') },
        { name: 'education', label: this.trans('menu.education') },
      ];
    },
  },
  async mounted() {
    await this.$trans.add([
      'label',
      'fields',
      'main',
      'index',
      'table',
      'button',
      'breadcrumb',
      'statement',
      'notice',
      'menu',
      'warning_text',
      'organization',
      'admission',
    ]);

    this.$globalLoading.show();
    const res = await requestWrapper.call(this, {
      url: this.uriGetData,
    });

    if (!res.error) {
      this.organization = res.data;
    }
    this.$globalLoading.hide();
  },

  methods: {
    scrollTo(name = '', fromPaginate = false) {
      if (!fromPaginate) {
        this.tab = 'about';
      }

      this.$nextTick(() => {
        if (this.$refs[name]) {
          const scrollValue = fromPaginate
            ? window.scrollY - Math.abs(this.$refs[name].getBoundingClientRect().top)
            : window.scrollY + Math.abs(this.$refs[name].getBoundingClientRect().top);

          scrollTop(
            window,
            window.scrollY,
            scrollValue,
            500,
          );
        }
      });
    },
    callCommission() {
      if (this.guest) {
        this.$sModal.open('confirm-destroy-file', {
          component: ModalUserInfo,
          binds: {
            action: this.urlCallCommission,
          },
        });
      } else {
        window.location = this.urlCallCommission;
      }
    },
    handleGovServiceBtn(btn) {
      if (!btn.isActive) {
        Helper.showNotice(btn.message, 'error');
        return false;
      }
    },
    changedTab(val) {
      this.posts.items = this.organization[val] ?? [];
    },
  },
};
