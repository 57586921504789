import { Form } from '@common/mixins';
import _ from 'lodash';
import { QForm, QSlideTransition } from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';

export default {
  mixins: [Form],
  name: 'ModalJournalDayForm',
  components: { QForm, QSlideTransition },
  props: {
    day: {
      type: Object,
      default: null,
    },
  },
  data() {
    const refs = this.$store.getters.refs.day;
    const fields = {
      type: {
        value: null,
        name: 'type',
        component: 'q-select-field',
        labelTransKey: 'training_schedule.fields.type',
        values: _.get(refs, 'types', []),
        required: true,
        v_show: true,
        extraAttributes: {
          disabled: this.isEdit,
        },
        v_if(vm) { return vm.checkVIf('type'); },
      },
      pointType: {
        value: '',
        name: 'point_type',
        component: 'q-select-field',
        labelTransKey: 'label.point_type',
        values: _.get(refs, 'pointTypes', []),
        required: true,
        v_if(vm) { return vm.checkVIf('point_type'); },
        extraAttributes: {
          disabled: this.isEdit,
        },
        events: {
          input: () => {
            this.fields.all_subjects.value = false;
          },
        },
      },
      all_subjects: {
        value: null,
        name: 'all_subjects',
        component: 'q-checkbox-field',
        checkboxLabelTransKey: 'label.all_subjects',
        required: true,
        v_if(vm) { return vm.checkVIf('all_subjects'); },
      },
      moduleSubject: {
        value: null,
        name: 'moduleSubject',
        component: 'q-select-field',
        labelTransKey: this.$store.getters.withResult ? 'label.results_label' : 'label.subject',
        values: _.get(refs, 'disciplines', []),
        required: true,
        extraAttributes: {
          disabled: this.isEdit,
        },
        v_if(vm) { return vm.checkVIf('moduleSubject'); },
      },
      byCalendarThematicPlan: {
        value: false,
        name: 'byCalendarThematicPlan',
        component: 'q-checkbox-field',
        checkboxLabelTransKey: 'label.by_calendar_thematic_plan',
        required: false,
        v_if(vm) { return vm.checkVIf('byCalendarThematicPlan'); },
        extraAttributes: {
          disabled: this.isEdit,
        },
      },
      byCurriculumWorkingProgram: {
        value: false,
        name: 'byCurriculumWorkingProgram',
        component: 'q-checkbox-field',
        checkboxLabelTransKey: 'label.by_curriculum_working_program',
        required: false,
        v_if(vm) { return vm.checkVIf('byCurriculumWorkingProgram'); },
        extraAttributes: {
          disabled: this.isEdit,
        },
      },
      calendarThematicPlanThemeId: {
        value: null,
        name: 'calendar_thematic_plan_theme_id',
        component: 'q-select-field',
        labelTransKey: 'label.calendar_thematic_plan_theme',
        values: [],
        required: true,
        v_if(vm) { return vm.checkVIf('calendar_thematic_plan_theme_id'); },
        extraAttributes: {
          disabled: this.isEdit,
        },
      },
      curriculumWorkingProgramLessonId: {
        value: null,
        name: 'curriculum_working_program_lesson_id',
        component: 'q-select-field',
        labelTransKey: 'label.curriculum_working_program_lesson',
        values: [],
        required: true,
        v_if(vm) { return vm.checkVIf('curriculum_working_program_lesson_id'); },
        extraAttributes: {
          disabled: this.isEdit,
        },
      },
      theme: {
        value: '',
        name: 'theme',
        component: 'text-field',
        labelTransKey: 'label.journal_day_theme',
        v_if(vm) { return vm.checkVIf('theme'); },
        required: true,
        extraAttributes: {
          disabled: this.isEdit,
        },
      },
      date: {
        value: '',
        name: 'date',
        component: 'q-date-field',
        labelTransKey: 'table.date',
        required: true,
        extraAttributes: {
          disabled: this.isEdit,
        },
      },
      hours: {
        value: '',
        name: 'hours',
        component: 'number-field',
        labelTransKey: 'placeholder.load_hours',
        required: true,
        v_if(vm) { return vm.checkVIf('hours'); },
        extraAttributes: {
          disabled: this.isEdit,
        },
        config: {
          mask: null,
          regex: '\\d{1,2}([.,][0-9])?',
        },
      },
      additionalTeachers: {
        value: null,
        name: 'additionalTeachers',
        component: 'q-select-field',
        labelTransKey: 'label.additional_teachers',
        values: _.get(refs, 'teachers', []),
        required: false,
        v_if(vm) { return vm.checkVIf('additionalTeachers'); },
        extraAttributes: {
          emitValue: false,
          mapOptions: true,
          multiple: true,
          oldSearch: true,
          disabled: this.isEdit,
        },
      },
    };

    return {
      fields,
      themes: [],
      lessons: [],
      isEdit: !!this.day,
      isMounted: false,
    };
  },

  async mounted() {
    if (this.isEdit) {
      await this.loadData();
    } else {
      if (this.isModule && this.$store.getters.currentFilter) {
        this.fields.moduleSubject.value = this.$store.getters.currentFilter;
      }
      await this.loadThemes();
      await this.loadLessons();
    }
    this.isMounted = true;
  },

  computed: {
    refs() { return this.$store.getters.refs; },
    isModule() { return this.$store.getters.isModule; },
    hasCtp() { return this.$store.getters.hasCtp; },
    hasCwp() { return this.$store.getters.hasCwp; },
    validate() {
      const { fields } = this;
      return (fields.date.value && fields.hours.value)
        && _.find(fields, field => !field.extraAttributes?.disabled);
    },
    checkVIf() {
      return (field) => {
        const typeValue = this.fields.type.value;
        const defaultTypes = ['day', 'homework', 'attestation_day', 'elective'];
        switch (field) {
          case 'type':
            return !this.isEdit || ['day', 'attestation_day', 'consulting_day', 'elective'].includes(typeValue);
          case 'all_subjects':
            return this.isModule && this.fields.pointType.value === 'final_credit';
          case 'moduleSubject':
            return this.isModule
              && !this.fields.all_subjects.value
              && ['day', 'attestation_day', 'elective', 'consulting_day', 'module_edu_result'].includes(typeValue);
          case 'point_type':
            return typeValue === 'day';
          case 'byCalendarThematicPlan':
            return this.hasCtp
              && this.themes?.length
              && this.fields.type.value
              && (this.fields.moduleSubject.value || !this.isModule)
              && (!this.isEdit || [...defaultTypes, 'consulting_day'].includes(typeValue));
          case 'byCurriculumWorkingProgram':
            return this.hasCwp
              && this.lessons?.length
              && this.fields.type.value
              && (this.fields.moduleSubject.value || !this.isModule)
              && (!this.isEdit || [...defaultTypes, 'consulting_day'].includes(typeValue));
          case 'calendar_thematic_plan_theme_id':
            return this.fields.byCalendarThematicPlan.value && this.hasCtp && this.themes?.length;
          case 'curriculum_working_program_lesson_id':
            return this.fields.byCurriculumWorkingProgram.value && this.hasCwp && this.lessons?.length;
          case 'theme':
            // eslint-disable-next-line no-case-declarations
            const themeTypes = ['day', 'attestation_day', 'elective', 'consulting_day'];
            if (this.isEdit) {
              themeTypes.push('homework');
            }
            return (!this.fields.byCalendarThematicPlan.value && !this.fields.byCurriculumWorkingProgram.value) && themeTypes.includes(typeValue);
          case 'hours':
            // eslint-disable-next-line no-case-declarations
            const hoursTypes = ['day', 'attestation_day', 'elective', 'consulting_day'];
            if (this.isEdit) {
              hoursTypes.push('homework');
              hoursTypes.push('exam_day');
            }
            return hoursTypes.includes(typeValue);
          case 'additionalTeachers':
            return this.isEdit || [...defaultTypes, 'consulting_day'].includes(typeValue);
          default:
            return true;
        }
      };
    },
  },

  methods: {
    async loadData() {
      this.$globalLoading.show();

      const url = Helper.urlGenerate(
        this.$store.getters.apiConfig.date.fields,
        { dayId: this.day.id },
      );

      const res = await requestWrapper.call(this, { url });

      if (!res.error) {
        _.forEach(res, (fieldData, fieldName) => {
          // eslint-disable-next-line no-prototype-builtins
          if (this.fields.hasOwnProperty(fieldName)) {
            if (fieldName === 'calendarThematicPlanThemeId' && fieldData.value) {
              this.$set(this.fields.byCalendarThematicPlan, 'value', true);
            }
            if (fieldName === 'curriculumWorkingProgramLessonId' && fieldData.value) {
              this.$set(this.fields.byCurriculumWorkingProgram, 'value', true);
            }
            this.$set(this.fields[fieldName], 'value', fieldData.value);
            this.$set(this.fields[fieldName].extraAttributes, 'disabled', !fieldData.editable);
          }
        });
      }

      await this.loadThemes();
      await this.loadLessons();
      this.$globalLoading.hide();
    },
    async handleAjaxFormSubmit() {
      this.$globalLoading.show();

      const data = {};
      _.forEach(this.fields, (el) => {
        _.set(data, this.nameToDot(el.name), el.value);
      });

      const { apiConfig, makeJournalUrl, journalId } = this.$store.getters;
      const url = Helper.urlGenerate(
        apiConfig.date[this.isEdit ? 'update' : 'store'],
        { dayId: this.day?.id },
      );
      const res = await requestWrapper.call(this, {
        url: makeJournalUrl(url, journalId),
        method: this.isEdit ? 'patch' : 'post',
        data,
      });

      if (!res.error) {
        const filter = this.$store.getters.currentFilter;
        await this.$store.dispatch(
          'GET_JOURNAL', { [this.isModule ? 'discipline_id' : 'semester']: filter },
        );
        this.$store.commit('RECALCULATE_INFO_TABLE_HOURS');
        this.$emit('onClose');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }

      this.$globalLoading.hide();
    },
    async loadThemes() {
      const vm = this;
      if (!this.hasCtp) {
        return;
      }
      this.$globalLoading.show();

      const params = {};

      if (this.isModule) {
        if (!this.fields.moduleSubject || !this.fields.moduleSubject.value) {
          this.$globalLoading.hide();
          return;
        }
        params.moduleSubject = this.fields.moduleSubject.value;
      }

      const { apiConfig, makeJournalUrl, journalId } = this.$store.getters;
      const res = await requestWrapper.call(this, {
        url: makeJournalUrl(apiConfig.date.themes, journalId),
        params,
      });

      if (!res.error) {
        vm.$set(this, 'themes', res.data);
        if (!this.themes.length) {
          this.$set(this.fields.calendarThematicPlanThemeId, 'value', null);
        }
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }

      this.$globalLoading.hide();
    },
    async loadLessons() {
      const vm = this;
      if (!this.hasCwp) {
        return;
      }
      this.$globalLoading.show();

      const params = {};

      if (this.isModule) {
        if (!this.fields.moduleSubject || !this.fields.moduleSubject.value) {
          this.$globalLoading.hide();
          return;
        }
        params.moduleSubject = this.fields.moduleSubject.value;
      }

      const { apiConfig, makeJournalUrl, journalId } = this.$store.getters;
      const res = await requestWrapper.call(this, {
        url: makeJournalUrl(apiConfig.date.lessons, journalId),
        params,
      });

      if (!res.error) {
        vm.$set(this, 'lessons', res.data);
        if (!this.lessons.length) {
          this.$set(this.fields.curriculumWorkingProgramLessonId, 'value', null);
        }
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }

      this.$globalLoading.hide();
    },
    changeTheme(val) {
      if (!this.hasCtp) {
        return;
      }
      const theme = this.fields.calendarThematicPlanThemeId.values
        .find(item => item.id === val);

      if (theme) {
        this.$set(this.fields.date, 'value', theme.date);
        this.$set(this.fields.theme, 'value', theme.name);
        this.$set(this.fields.hours, 'value', theme.hours);
      } else {
        this.$set(this.fields.date, 'value', null);
        this.$set(this.fields.theme, 'value', null);
        this.$set(this.fields.hours, 'value', null);
      }
    },
    changeLesson(val) {
      if (!this.hasCwp) {
        return;
      }
      const lesson = this.fields.curriculumWorkingProgramLessonId.values
        .find(item => item.id === val);

      if (lesson) {
        this.$set(this.fields.theme, 'value', lesson.name);
        if (lesson.hours) {
          this.$set(this.fields.hours, 'value', lesson.hours);
        }
      } else {
        this.$set(this.fields.theme, 'value', null);
      }
      if (!lesson?.hours) {
        this.$set(this.fields.hours, 'value', null);
        this.$set(this.fields.hours.extraAttributes, 'disabled', false);
      }
    },

    async confirmDelete() {
      this.$globalLoading.show();
      const url = Helper.urlGenerate(
        this.$store.getters.apiConfig.date.destroy,
        { dayId: this.day.id },
      );
      const res = await requestWrapper.call(this, {
        url,
        method: 'delete',
      });

      if (!res.error) {
        if (['day', 'consulting_day', 'exam_day'].includes(this.day.type)) {
          this.$store.commit('DELETE_DAY_COL', this.day.id);
        } else {
          const filter = this.$store.getters.currentFilter;
          await this.$store.dispatch(
            'GET_JOURNAL', { [this.isModule ? 'discipline_id' : 'semester']: filter },
          );
        }
        this.$store.commit('RECALCULATE_INFO_TABLE_HOURS');
        this.$emit('onCloseAll');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }

      this.$globalLoading.hide();
    },
  },
  watch: {
    themes(newVal) {
      this.fields.calendarThematicPlanThemeId.values = newVal;
    },
    lessons(newVal) {
      this.fields.curriculumWorkingProgramLessonId.values = newVal;
    },
    // eslint-disable-next-line func-names
    'fields.byCalendarThematicPlan.value': function (val) {
      this.$set(this.fields.theme.extraAttributes, 'disabled', !!val);
      if (!val) {
        this.fields.theme.value = null;
        this.fields.date.value = null;
        this.fields.hours.value = null;
      }
    },
    // eslint-disable-next-line func-names
    'fields.calendarThematicPlanThemeId.value': function (val) {
      if (this.isMounted) {
        this.changeTheme(val);
      }
    },
    // eslint-disable-next-line func-names
    'fields.moduleSubject.value': async function () {
      if (!this.isEdit) {
        this.fields.byCalendarThematicPlan.value = false;
        this.fields.byCurriculumWorkingProgram.value = false;
      }
      await this.loadThemes();
      await this.loadLessons();
    },
    // eslint-disable-next-line func-names
    'fields.byCurriculumWorkingProgram.value': function (val) {
      this.$set(this.fields.theme.extraAttributes, 'disabled', !!val);
      if (!val) {
        this.fields.theme.value = null;
        this.fields.date.value = null;
        this.fields.hours.value = null;
        this.$set(this.fields.hours.extraAttributes, 'disabled', false);
      }
    },
    // eslint-disable-next-line func-names
    'fields.curriculumWorkingProgramLessonId.value': function (val) {
      if (this.isMounted) {
        this.changeLesson(val);
      }
    },
  },
};
