import {
  QBtn,
} from '@quasar/components';
import eventHub from '@vjs/config/eventHub';
import {requestWrapper} from "@vjs/helpers";

export default {
  name: 'VStepEduNavigator',
  components: {
    QBtn,
  },
  props: {
    uriStatic: {
      type: String,
      required: true,
    },
    uriNavigation: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      access: false,
      direction: 'swipe-left',
    };
  },
  created() {
    eventHub.$on('SET_NEXT_STEP', this.requestForm);
  },
  beforeDestroy() {
    eventHub.$off('SET_NEXT_STEP', this.requestForm);
  },
  computed: {
    backgroundLeft() {
      return this.$q.screen.width > 1024
        ? `${this.uriStatic}/${'base/images/college/statement/background_1.svg'}`
        : `${this.uriStatic}/${'base/images/college/statement/background_m1.svg'}`;
    },
    backgroundRight() {
      let backgroundRight = `${this.uriStatic}/${'base/images/college/statement/background_m3.svg'}`;
      if (this.$q.screen.width > 1024) {
        backgroundRight = `${this.uriStatic}/${'base/images/college/statement/background_2.svg'}`;
      } else if (this.$q.screen.width < 1024 && this.$q.screen.width > 450) {
        backgroundRight = `${this.uriStatic}/${'base/images/college/statement/background_m2.svg'}`;
      }
      return backgroundRight;
    },
    banner() {
      return this.$q.screen.width > 590
        ? `${this.uriStatic}/${'base/images/college/statement/banner_1.png'}`
        : `${this.uriStatic}/${'base/images/college/statement/banner_2.png'}`;
    },
  },
  methods: {
    async logAction() {
      if (this.uriNavigation) {
        await requestWrapper.call(this, {
          url: this.uriNavigation,
        });
      }
      window.open(this.trans('statement.edu_navigator_link'), '_blank');
    },
    handleNext() {
      this.direction = 'swipe-left';
      eventHub.$emit('SET_NEXT_STEP');
    },
    async requestForm() {
      this.loading = true;
      this.$emit('loading', true);
      this.$store.commit('SET_NEXT_STEP');
      this.$store.commit('SET_EDU_NAVIGATOR');
      this.loading = false;
      this.$emit('loading', false);
      return true;
    },
  },
};
