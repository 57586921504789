export default {
  name: 'VDormitoryRecords',
  props: {
    uriGetData: {
      type: String,
      required: true,
    },
    noResultTitle: {
      type: String,
      default: '',
    },
    noResultText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      rooms: [],
    };
  },
  async mounted() {
    await this.$trans.add(['dormitory', 'table', 'warning_text']);
    await requestGetDataHelper(this, { url: this.uriGetData, variableName: 'rooms' });
  },
  computed: {
    isItemsEmpty() {
      return Object.keys(this.rooms).length === 0;
    },
  },
};
