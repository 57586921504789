import VNoResults from '@vjs/components/VNoResults';
import {
  QField,
  QPagination,
  QTooltip,
  QBtn,
} from '@quasar/components';
import { mapGetters } from 'vuex';
import { genArray, scrollTop } from '@vjs/helpers';
import { eventBus } from '@common/mixins';
import VQuestionAdd from './VQuestion/VQuestionAdd.vue';
import VQuestion from './VQuestion/VQuestion.vue';

export default {
  name: 'VQuestionsList',
  components: {
    VNoResults,
    QField,
    QPagination,
    QTooltip,
    QBtn,
    VQuestionAdd,
    VQuestion,
  },
  props: {
    apiConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      currentPage: 1,
      countByPage: 5,
      loading: true,
      getQuestionAdd: false,
    };
  },
  async mounted() {
    await this.$trans.add([
      'warning_text',
      'cabinet',
      'label',
      'button',
      'test',
      'table',
      'main',
      'questionnaire',
    ]);
    this.$store.commit('SET_QUESTIONNAIRE_VM', this);
    this.$store.commit('SET_QUESTION_API_CONFIG', this.apiConfig);
    await this.$store.dispatch('GET_GROUP_QUESTIONS');
    this.handleEvents();
    this.loading = false;
  },
  computed: {
    ...mapGetters(['questions', 'paginatePage']),
    paginate() {
      if (this.loading) {
        return genArray({}, 5);
      }
      const current = (this.currentPage - 1) * this.countByPage;
      return this.questions.slice(current, current + this.countByPage);
    },
    countOfPages() {
      let count = parseInt(this.questions.length / this.countByPage, 10);
      const remains = this.questions.length % this.countByPage;
      count += remains !== 0 ? 1 : 0;
      this.currentPage = this.paginatePage ?? 1;
      return count <= 1 ? 0 : count;
    },
  },
  methods: {
    async changePagination() {
      // Перевод страницы к началу при выборе страницы
      this.$store.commit('SET_PAGINATE_PAGE', this.currentPage);
      scrollTop(
        window,
        window.scrollY,
        window.scrollY - Math.abs(this.$refs.questions.getBoundingClientRect().top),
        500,
      );
    },
    questionNum(key) {
      let res = key + 1;
      if (this.currentPage > 1) {
        res += (_.toNumber(this.currentPage) - 1) * _.toNumber(this.countByPage);
      }
      return res;
    },
    questionAdd() {
      this.getQuestionAdd = !this.getQuestionAdd;
    },
    openModal() {
      this.$sModal.open('v-question-visibility-modal', {
        component: 'v-question-visibility-modal',
        notPersistent: true,
        title: '',
      });
    },
    handleEvents() {
      eventBus.$on('close-question', () => {
        this.getQuestionAdd = !this.getQuestionAdd;
      });
      eventBus.$on('update-questions', async () => {
        await this.$store.dispatch('GET_GROUP_QUESTIONS');
      });
    },
  },
};
