export default {
  props: {
    uriUserFiles: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeFile: '',
      modalFiles: false,
    };
  },
  methods: {
    clearFile(filename) {
      if (this.fields[filename].length !== 0) {
        this.fields[filename] = [];
      }
    },
    selectFile(file) {
      this.fields[this.activeFile] = file;
      this.modalFiles = false;
      this.activeFile = null;
    },
    fileModalClose() {
      this.activeFile = null;
      this.modalFiles = false;
    },
    triggerFile(filename) {
      this.activeFile = filename;
      this.modalFiles = true;
    },
  },
};
