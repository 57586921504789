import { Helper } from '@common/src/helpers';
import { QCheckbox } from '@quasar/components';

export default {
  components: { QCheckbox },
  props: {
    action: {
      type: String,
      default: '',
    },
    method: {
      type: String,
      default: '',
    },
    uriGetRefs: {
      type: String,
      default: '',
    },
    configs: {
      type: Object,
      default: {
        isTranscript: false,
        isArchived: false,
        hasPeriod: false,
      },
    },
    dualLangInputs: {
      type: Object,
      default: {},
    },
    resource: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      options: {
        professions: [],
      },
      form: {
        eng_full_name: null,
        full_name_declension_kz: null,
        full_name_declension_ru: null,
        profession: null,
        diploma_serial_and_num: null,
        registration_number: null,
        register_date: null,
        is_old_org: false,
        date_from: null,
        date_to: null,
      },
    };
  },
  computed: {
    isTranscript() {
      return this.type === 32;
    },
    isArchived() {
      return this.type === 37;
    },
    hasPeriod() {
      return ![29, 33].includes(this.type);
    },
  },
  async mounted() {
    await this.$trans.add(['label', 'warning_text', 'button']);
    await requestGetDataHelper(this, {
      url: this.uriGetRefs, variableName: 'options',
    });
    _.forEach(this.resource, (value, attribute) => {
      if (Object.prototype.hasOwnProperty.call(this.form, attribute)) {
        _.set(this.form, `${attribute}`, value);
      }
    });
  },
  methods: {
    async handleAjaxFormSubmit() {
      this.$globalLoading.show();
      const data = {};
      _.forEach(this.form, (fieldValue, fieldKey) => {
          if (fieldValue) {
            _.set(data, fieldKey, fieldValue);
          }
      });
      const res = await requestWrapper.call(this, {
        url: this.action,
        method: this.method,
        data,
      });

      if (!res.error) {
        this.$emit('onClose');
        if (res?.redirect !== undefined) {
          window.onbeforeunload = null;
          window.location.href = res.redirect;
        }
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
