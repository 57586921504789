import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['CURRENT_SUBJECT', 'CURRENT_QMAP_NUM']),
  },
  methods: {
    // Переключение пагинации на карте ответов на текущий вопрос
    checkQuestionMap() {
      const curIdx = parseInt(this.CURRENT_SUBJECT.curQuestionIdx / 20, 10);
      if (curIdx !== this.CURRENT_QMAP_NUM) {
        this.$store.commit('SET_QMAP_NUM', curIdx);
      }
    },
  },
};
