import Vue from 'vue';
import axios from 'axios';

import store from './VueJS/store';
import VDiplomaForm from './VueJS/views/VDiplomaForm/index';


if (document.getElementById('v-diploma-form-app')) {
  window.vDiplomaFormApp = new Vue({
    el: '#v-diploma-form-app',
    components: { VDiplomaForm },
    store,
  });
}
