<template>
  <canvas
    ref="diagram"
    :class="selector"
  />
</template>

<script>
export default {
  name: 'VDiagramRing',
  props: {
    percent: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 150,
    },
    height: {
      type: Number,
      default: 150,
    },
    stroke: {
      type: Number,
      default: 15,
    },
    strokeColor: {
      type: String,
      default: '',
    },
    background: {
      type: String,
      default: '#D1D8DC',
    },
    backgroundText: {
      type: String,
      default: '#FFFFFF',
    },
    textColor: {
      type: String,
      default: '#333333',
    },
    font: {
      type: String,
      default: '3.2rem/1.2em sans-serif',
    },
    selector: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    diagram: null,
    lvl1: '#33CC66',
    lvl2: '#F5942E',
    lvl3: '#FF0000',
  }),
  mounted() {
    const canvas = this.$refs.diagram;
    canvas.width = this.width;
    canvas.height = this.height;
    this.diagram = canvas.getContext('2d');
    this.draw();
  },
  methods: {
    draw() {
      // backStroke
      this.diagram.fillStyle = this.background;
      this.diagram.strokeStyle = this.background;
      this.diagram.beginPath();
      this.diagram.moveTo((this.width / 2), (this.height / 2));
      this.diagram.arc(
        (this.width / 2),
        (this.height / 2),
        (this.width / 2),
        0,
        (Math.PI * 2),
        false,
      );
      this.diagram.closePath();
      this.diagram.fill();

      // Анимация заполнения шкалы
      let calcPercent = 0;
      let customStrokeColor = '';
      const interval = setInterval(() => {
        calcPercent += 1;
        // Если calcPercent больше, то заканчиваем анимацию
        if (calcPercent > this.percent) {
          calcPercent = this.percent;
          clearInterval(interval);
        }
        // Меняем цвет диаграммы в процентном соотношении
        if (calcPercent <= 33) {
          customStrokeColor = this.lvl3;
        } else if (calcPercent > 33 && calcPercent <= 66) {
          customStrokeColor = this.lvl2;
        } else {
          customStrokeColor = this.lvl1;
        }
        // Отрисовка диаграммы
        this.diagram.fillStyle = this.strokeColor !== '' ? this.strokeColor : customStrokeColor;
        this.diagram.beginPath();
        this.diagram.moveTo((this.width / 2), (this.height / 2));
        const start = 270 * (Math.PI / 180);
        this.diagram.arc(
          (this.width / 2),
          (this.height / 2),
          (this.width / 2),
          start,
          start + (Math.PI / 180) * calcPercent * 360 / 100,
          false,
        );
        this.diagram.closePath();
        this.diagram.fill();

        // Отрисовка белого слоя поверх предыдущего
        this.diagram.fillStyle = this.backgroundText;
        this.diagram.beginPath();
        this.diagram.moveTo(
          (this.width / 2),
          (this.height / 2),
        );
        this.diagram.arc(
          (this.width / 2),
          (this.height / 2),
          (this.width / 2) - this.stroke,
          0,
          (Math.PI * 2),
          false,
        );
        this.diagram.closePath();
        this.diagram.fill();

        // Отрисовка текста
        this.diagram.fillStyle = this.textColor;
        this.diagram.font = this.font;
        this.diagram.textAlign = 'center';
        this.diagram.textBaseline = 'middle';
        this.diagram.fillText(
          `${calcPercent}%`,
          (this.width / 2),
          (this.height / 2),
        );
      }, 30);
    },
  },
};
</script>
