import { StoreTranslator } from '@common/mixins';
import { Helper } from '@common/src/helpers';
import CalendarThematicPlanModalAddTheme from './Modals/AddTheme';
import CalendarThematicPlanModalDestroyTheme from './Modals/DestroyTheme';

export default {
  name: 'VCalendarThematicPlanShow',
  mixins: [StoreTranslator],
  components: {
    CalendarThematicPlanModalAddTheme,
    CalendarThematicPlanModalDestroyTheme,
  },
  props: {
    urlLoadHeader: {
      type: String,
      default: '',
      required: true,
    },
    urlLoadTable: {
      type: String,
      default: '',
      required: true,
    },
    urlExportTable: {
      type: String,
      default: '',
      required: true,
    },
    urlStoreTheme: {
      type: String,
      default: '',
      required: true,
    },
    urlDeleteTheme: {
      type: String,
      default: '',
      required: true,
    },
    urlGetDuplicatePlans: {
      type: String,
      default: '',
      required: true,
    },
    urlDuplicateThemes: {
      type: String,
      default: '',
      required: true,
    },
    isArchived: false,
    canAddTheme: false,
  },
  data() {
    return {
      headerData: {},
      tableData: [],
      plansOptions: [],
      currentItemData: {},
      addModalActive: false,
      destroyModalActive: false,
      loading: false,
    };
  },
  mounted() {
    this.$trans.add(['statement', 'label', 'table', 'button', 'placeholder']);
    this.loadHeader();
    this.loadTable();
    this.requestGetPlans();
  },
  methods: {
    handleError(response) {
      this.stopLoader();
      Helper.handlerResponseError(response);
    },
    loadHeader() {
      const vm = this;
      vm.runLoader();

      const config = {
        responseType: 'json',
        method: 'GET',
      };

      vm.$http.get(vm.urlLoadHeader, config)
        .then(
          (response) => {
            vm.$set(vm, 'headerData', response.data);
            vm.stopLoader();
          },
          vm.handleError,
        );
    },
    loadTable() {
      const vm = this;
      vm.runLoader();

      const config = {
        responseType: 'json',
        method: 'GET',
      };

      vm.$http.get(vm.urlLoadTable, config)
        .then(
          (response) => {
            vm.$set(vm, 'tableData', response.data.data);
            vm.stopLoader();
          },
          vm.handleError,
        );
    },
    requestGetPlans() {
      const vm = this;
      vm.runLoader();

      const config = {
        responseType: 'json',
        method: 'GET',
      };

      vm.$http.get(vm.urlGetDuplicatePlans, config)
        .then(
          (response) => {
            vm.$set(vm, 'plansOptions', response.data);
            vm.stopLoader();
          },
          vm.handleError,
        );
    },
    openModalAdd() {
      this.$set(this, 'addModalActive', true);
    },
    openModalEdit(item) {
      this.currentItemData = item;
      this.$set(this, 'addModalActive', true);
    },
    openModalDestroy(item) {
      this.currentItemData = item;
      this.$set(this, 'destroyModalActive', true);
    },
    closeModals() {
      this.$set(this, 'currentItemData', {});
      this.$set(this, 'addModalActive', false);
      this.$set(this, 'destroyModalActive', false);
    },
    runLoader() {
      this.$globalLoading.show();
    },
    stopLoader() {
      this.$globalLoading.hide();
    },
    async success() {
      this.closeModals();
      await this.loadTable();
      await this.loadHeader();
    },

    exportDownload() {
      const vm = this;

      this.runLoader();
      const config = {
        responseType: 'blob',
        method: 'GET',
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };

      vm.$http.get(vm.urlExportTable, config)
        .then(
          (response) => {
            this.stopLoader();
            const filenameRegex = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(response.headers.get('Content-Disposition').replace(/['"]/g, '') || '');
            const orig_filename = matches.filter(i => !!i).pop().replace(/['"]/g, '');
            let filename = '';
            try {
              filename = decodeURIComponent(escape(orig_filename));
            } catch (e) {
              filename = orig_filename;
            }
            const result = document.createElement('a');
            result.href = window.URL.createObjectURL(response.body);
            result.download = filename;
            result.click();
          },
          (response) => {
            this.stopLoader();
            this.handleError(response);
            console.warn(response);
          },
        );
    },
    openDuplicateModal() {
      const onApprove = async () => {
        await this.loadTable();
      };
      const events = { onApprove };

      this.$sModal.open('v-calendar-thematic-plan-duplicate-modal', {
        id: 'v-calendar-thematic-plan-duplicate-modal',
        title: this.trans('notice.are_you_sure'),
        component: 'v-calendar-thematic-plan-duplicate-modal',
        notPersistent: true,
        binds: {
          action: this.urlDuplicateThemes,
          options: this.plansOptions,
        },
        events,
      });
    },
  },
  watch: {
    addModalActive(val) {
      const modal = $('#calendar-thematic-plan-theme-add');
      if (val) {
        modal.modal('show');
      } else {
        modal.modal('hide');
      }
    },
    destroyModalActive(val) {
      const modal = $('#ctp-theme-confirm-destroy');
      if (val) {
        modal.modal('show');
      } else {
        modal.modal('hide');
      }
    },
  },
};
