import Vue from 'vue';
import store from './VueJS/store';
import VStatementFilesEdit from './VueJS/views/VStatementFilesEdit/index';


if (document.getElementById('v-statement-files-edit-app')) {
  window.vDocFlowApp = new Vue({
    el: '#v-statement-files-edit-app',
    components: { VStatementFilesEdit },
    store,
  });
}
