import { QTable, QTr, QTh } from '@quasar/components';

export default {
  components: { QTable, QTr, QTh },
  props: {
    rows: { type: Array, default: () => [] },
    cols: { type: Array, default: () => [] },
    adaptiveWhen: { type: Number, default: 1024 },
    headerTitle: String,
  },
};
