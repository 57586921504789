import {
  QToggle,
  QIcon,
} from '@quasar/components';
import VChildItem from './VChildItem';
import requests from './mixins/requests';

export default {
  components: {
    QToggle,
    QIcon,
    VChildItem,
  },
  mixins: [ requests ],
  props: {
    getData: {
      type: String,
      default: null,
    },
    getUsers: {
      type: String,
      default: null,
    },
    action: {
      type: String,
      default: null,
    },
    searchUri: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      items: [],
      users: [],
    };
  },
  async mounted() {
    await this.$trans.add(['label', 'settings', 'placeholder', 'warning_text', 'notice', 'button']);
    await this.loadData(this.getData);
    eventBus.$on('updated-child-element', async (data) => {
      await this.updateModuleAccess(this.module, this.action, 'post', data);
    });
  },
  methods: {
    showSpoilerData(item) {
      item.spoiler = !item.spoiler;
    },
    async handleChange(index, mIndex, module, btn = null) {
      // const vm = this;
      const events = {};
      let modal;
      let url = this.action;
      let method = 'post';
      let binds = {
        title: module?.title,
      };
      if (module.type === 'file') {
        modal = btn.openModal;
        url = modal?.action;
        method = modal?.method;
        events.modalSubmit = async (file) => {
          await this.updateModuleAccess(module, url, method, {
            file,
            key: module.key,
          });
        };
      } else if (module.type === 'btn') {
        modal = module.value.openModal;
        binds = module.value.openModal?.binds;
      } else {
        modal = module.modal;
        if (!module.value) {
          await this.updateModuleAccess(module, url, method);
          return;
        }
        binds = {
          type: 'event',
          title: module.title,
          message: module.confirmationText,
        };
      }
      if (!modal) {
        return;
      }
      const func = async () => {
        await this.updateModuleAccess(module, url, method);
      };
      const closeFunc = async () => {
        if (module.type === 'bool') {
          this.items[index].modules[mIndex].value = !module.value;
        }
      };
      events.onApprove = func;
      events.onSuccess = func;
      this.setModal(
        modal?.component ?? modal,
        this.trans('notice.are_you_sure'),
        events,
        binds,
        closeFunc,
      );
    },
  },
};
