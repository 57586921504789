import { StoreTranslator } from '@common/mixins';


export default {
  mixins: [StoreTranslator],
  props: {
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    resource: {
      type: [Array, Object],
      required: false,
    },
    additionalContingentInfo: {
      type: Object,
      required: false,
    },
  },
  data() {
    const vm = this;
    const fields = {
      health_status: {
        value: '',
        name: 'health_status',
        component: 'v-multi-select-field',
        labelTransKey: 'label.health_status',
        values: [],
        required: true,
        loadValues: {
          type: 'health-statuses',
        },
      },
      sports_achievements: {
        value: '',
        name: 'sports_achievements',
        component: 'v-multi-select-field',
        labelTransKey: 'label.sports_achievements',
        values: [],
        required: true,
        loadValues: {
          type: 'sports-achievements',
        },
      },
      family_status: {
        value: '',
        name: 'family_status',
        component: 'v-multi-select-field',
        labelTransKey: 'label.family_status',
        values: [],
        required: true,
        loadValues: {
          type: 'family-statuses',
        },
      },
      achievements: {
        value: '',
        name: 'achievements',
        component: 'v-multi-select-field',
        labelTransKey: 'label.achievements',
        values: [],
        required: true,
        loadValues: {
          type: 'achievements',
        },
      },
      actual_place_residence: {
        value: '',
        name: 'actual_place_residence',
        component: 'v-multi-select-field',
        labelTransKey: 'label.actual_place_residence',
        values: [],
        required: true,
        loadValues: {
          type: 'actual-place-residences',
        },
      },
      family_type: {
        value: '',
        name: 'family_type',
        component: 'v-multi-select-field',
        labelTransKey: 'label.family_type',
        values: [],
        required: true,
        loadValues: {
          type: 'family-types',
        },
      },
      financial_situation: {
        value: '',
        name: 'financial_situation',
        component: 'v-multi-select-field',
        labelTransKey: 'label.financial_situation',
        values: [],
        required: true,
        loadValues: {
          type: 'financial-situations',
        },
      },
      number_of_children: {
        value: '',
        name: 'number_of_children',
        component: 'number-field',
        labelTransKey: 'label.number_of_children',
        required: true,
      },
      father_status: {
        value: '',
        name: 'father_status',
        component: 'v-multi-select-field',
        labelTransKey: 'label.father_status',
        values: [],
        required: false,
        loadValues: {
          type: 'occupation-statuses',
        },
      },
      father_disability: {
        value: null,
        name: 'father_disability',
        labelTransKey: 'label.father_disability',
        component: 'v-multi-select-field',
        loadValues: {
          type: 'yes-or-no',
        },
        values: [],
        required: false,
      },
      mother_status: {
        value: '',
        name: 'mother_status',
        component: 'v-multi-select-field',
        labelTransKey: 'label.mother_status',
        values: [],
        required: false,
        loadValues: {
          type: 'occupation-statuses',
        },
      },
      mother_disability: {
        value: null,
        name: 'mother_disability',
        labelTransKey: 'label.mother_disability',
        component: 'v-multi-select-field',
        loadValues: {
          type: 'yes-or-no',
        },
        values: [],
        required: false,
      },
    };

    if (vm.resource !== undefined) {
      if (vm.resource.values) {
        _.forEach(vm.resource.values, (value, key) => {
          if (fields.hasOwnProperty(key)) {
            fields[key].value = value;
          }
        });
      }
      if (vm.resource.hides) {
        _.forEach(vm.resource.hides, (attribute) => {
          if (fields.hasOwnProperty(attribute)) {
            _.set(fields, `${attribute}.extraAttributes.disabled`, true);
          }
        });
      }
    }

    return {
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
          // TODO привести к одному варианту
          if (data.redirect) {
            window.location = data.redirect;
          }
        },
      },
    };
  },
  mounted() {
    const vm = this;
  },

  destroyed() {
  },
  methods: {
    onInputEduLevel(value) {
      const vm = this;
      const config = {
        responseType: 'json',
        method: 'GET',
        params: {
          edu_level: value,
        },
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      vm.$http.get(vm.urlListStatements, config).then(
        (response) => {
          const { data } = response;
          vm.$set(vm.form.fields.statements, 'value', data);
        },
        (response) => {
          console.log(response);
        },
      );
    },

    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(form.fields, (el, key) => {
        _.set(data, vm.nameToDot(el.name), el.value);
      });
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
};
