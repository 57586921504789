<template>
  <div class="sc-questionnaire__question-wrapper">
    <div class="sc-questionnaire__center--row">
      <div
        class="sc-questionnaire__question-wrapper--question"
        v-html="currentQuestion.question"
      />
    </div>
    <div class="sc-questionnaire__center--row">
      <div class="sc-questionnaire__center--value">
        <ul class="sc-questionnaire__question-wrapper--answers">
          <template
            v-for="item in currentQuestion.answers"
          >
            <li
              v-if="!item.is_optional"
              :key="item.id"
              class="sc-questionnaire__question-wrapper--answer sn-ck-editor-data"
              :class="{
                'is-selected': item.checked,
                'is-loading': loading.answer && item.id === selectedID,
              }"
              @click="selectAnswer(item.id, true)"
              v-html="item.text"
            />
            <div
              v-else-if="currentQuestion.with_option"
              :key="item.id"
              class="d-flex align-items-end justify-content-between"
            >
              <q-checkbox
                keep-color
                v-model="item.checked"
                :label="trans('questionnaire.labels.other')"
                class="mb-20"
                color="blue"
                @input="selectAnswer(item.id)"
              />
              <q-input
                v-model="item.own_answer"
                maxlength="45"
                counter
                class="s-w-100"
                :required="item.checked"
                @blur="selectAnswer(item.id, true)"
              />
            </div>
          </template>
        </ul>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { QCheckbox } from '@quasar/components';

export default {
  name: 'VTestQuestion',
  components: {
    QCheckbox,
  },
  data: () => ({
    selectedID: null,
  }),
  computed: {
    ...mapGetters(['currentQuestion', 'loading']),
  },
  methods: {
    selectAnswer(id, nextQuestion = false) {
      const filterAnswer = this.currentQuestion.answers.filter(o => o.id === id);
      this.selectedID = id;
      this.$store.dispatch('SEND_PROCESS_ANSWER', {
        ownAnswer: filterAnswer[0]?.own_answer,
        subjectId: this.currentQuestion.subjectID,
        questionId: this.currentQuestion.id,
        questionIdx: this.currentQuestion.curQuestionIdx,
        answerId: id,
      });
      eventBus.$on('next-question', async () => {
        if (nextQuestion) {
          this.$store.commit('NEXT_QUESTION');
          eventBus.$off('next-question');
        }
      });
    },
  },
};
</script>
