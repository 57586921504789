export default {
  props: {
    contingent: {
      type: Object,
      default: {},
    },
    isActive: {
      type: Boolean,
      default: true,
    },
  },
  async mounted() {
    await this.$trans.add(['table', 'title', 'label']);
  },
};
