import VCurriculumProgramTitle from './components/VCurriculumProgramTitle';
import VCurriculumProgramInfo from './components/VCurriculumProgramInfo';

export default {
  name: 'VCurriculumWorkingProgramView',
  components: {
    VCurriculumProgramTitle,
    VCurriculumProgramInfo,
  },
  props: {
    apiConfig: {
      type: Object,
      default: () => ({
        back: '',
        chapters: '',
        contentList: '',
        infoTable: '',
        lessons: '',
        totalHours: '',
        duplicate: { programs: '', disciplines: '', action: '' },
        lesson: { update: '', delete: '' },
      }),
    },
    groupName: {
      type: String,
      required: '',
    },
    parentId: {
      type: Number,
      required: true,
    },
    courseNumber: {
      type: Number,
      default: 0,
    },
    semesterNumber: {
      type: Number,
      default: 0,
    },
    isArchived: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    this.$globalLoading.show();
    await this.$trans.add([
      'label',
      'button',
      'curriculum',
      'placeholder',
      'table',
    ]);
    this.$store.commit('SET_VM', this);
    this.$store.commit('SET_PARENT_ID', this.parentId);
    this.$store.commit('SET_PROGRAM_API_CONFIG', this.apiConfig);
    this.$store.commit('SET_SEMESTER_NUMBER', this.semesterNumber);
    this.$store.commit('SET_COURSE_NUMBER', this.courseNumber);
    this.$store.commit('SET_IS_ARCHIVED', this.isArchived);
    await this.$store.dispatch('GET_CURRICULUM_PROGRAM_INFO_TABLE');
    await this.$store.dispatch('GET_CURRICULUM_PROGRAM_TOTAL_HOURS_TABLE');
    await this.$store.dispatch('GET_CURRICULUM_PROGRAM_CONTENT_TABLE');
    await this.$store.dispatch('GET_CURRICULUM_PROGRAM_DUPLICATE_PROGRAMS');
    this.$globalLoading.hide();
  },
};
