import { Helper } from '@common/src/helpers';

export default {
  makeJournalUrl: (url, journalId) => Helper.urlGenerate(url, { journalId }),
  vm: null,

  apiConfig: {
    remarks: {
      store: '',
      list: '',
    },
    generateWeek: {
      store: '',
      data: '',
      filters: '',
    },
    date: {
      store: '',
      update: '',
      destroy: '',
      fields: '',
      themes: '',
      lessons: '',
    },
    point: {
      store: '',
      destroy: '',
    },
    calcResult: {
      action: '',
      accesses: '',
    },
    userFiles: {
      get: '',
      store: '',
    },
    back: '',
    export: '',
    infoTable: '',
    mainTable: '',
    students: '',
    refs: '',
    restore: '',
  },

  cans: {
    changeRemarks: false,
    seeRemarks: false,
    calcResults: false,
    edit: false,
    addDay: false,
    export: false,
    generateDays: false,
    calcResultShowDiscipline: false,
    calcResultShowDate: false,
    calcResultAllowAbsents: false,
    restore: false,
    canChangeStatus: false,
  },

  refs: {
    disciplines: [],
    semesters: [],
    generateMonths: [],
    calcResultTypes: [],
    day: {
      disciplines: [],
      pointTypes: [],
      types: [],
      teachers: [],
    },
  },

  remarks: [],
  journalTeachers: [],
  infoTable: {},
  journalDays: [],
  totalHours: null,
  students: [],
  colorsArr: [],

  journalId: null,
  currentSemester: null,
  currentFilter: null,
  typeOfEvaluation: 'number',

  showRetiredStudents: true,
  hasCtp: false,
  hasCwp: false,
  isRemarksTableActive: false,
  isPersonal: false,
  isModule: false,
  withResult: false,
  isStudent: false,
};
