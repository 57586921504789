export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    const vm = this;
    return {
      link: {
        href: _.get(vm.config, 'link.href', '#'),
        title: _.get(vm.config, 'link.title'),
        classes: _.get(vm.config, 'link.classes', ['s-btn']),
      },
      label: _.get(vm.config, 'label'),
      icon: _.get(vm.config, 'icon'),
    };
  },
  methods: {
    onClick(event) {
      // TODO в будущем использовать компоненты  modal
      if (this.config.hasOwnProperty('onClick')) {
        event.preventDefault();
        if (this.config.onClick.hasOwnProperty('openModal')) {
          const config = this.config.onClick.openModal;
          const modal = $(`#${config.id}`);
          modal.modal('show');
          modal.find('form').attr('action', config.action);
        }
      }
    },
  },
  watch: {
    config(newValue) {
      const vm = this;

      vm.$set(vm, 'link', _.get(vm.config, 'link', {}));
      vm.$set(vm, 'label', _.get(vm.config, 'label'));
      vm.$set(vm, 'icon', _.get(vm.config, 'icon'));
    },
  },
};
