/* eslint-disable camelcase */
import { QInput } from '@quasar/components/input';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import { QRadio } from '@quasar/components/radio';

export default {
  components: { QRadio, QInput },
  props: {
    value: [],
    uriCheckIin: String,
    disable: Boolean,
    error: Boolean,
    errorMessage: String,
  },
  data() {
    return {
      localValue: [],
      iin: null,
      isChairman: false,
    };
  },

  mounted() {
    this.setInitialValue();
  },
  methods: {
    async checkIin() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.uriCheckIin,
        params: { iin: this.iin },
      });

      if (!res.error) {
        const { data } = res;
        const exists = this.localValue.find(itm => itm.user_id === data.user_id);
        if (exists) {
          this.$notify({
            text: this.trans('notice.commission_member_already_exists'),
            type: 'warn',
          });
        } else {
          let is_chairman = false;
          if (this.localValue.length === 0) {
            is_chairman = true;
            this.isChairman = 0;
          }
          this.localValue.push({
            user_id: data.user_id,
            fullName: data.fullName,
            is_chairman,
          });
        }
        this.iin = null;
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }

      this.$globalLoading.hide();
    },
    handleIsChairman(key) {
      const tmpValue = _.cloneDeep(this.localValue).map((itm) => {
        itm.is_chairman = false;
        return itm;
      });
      tmpValue[key].is_chairman = true;
      this.$set(this, 'localValue', tmpValue);
    },
    emitChange(value = null) {
      this.$emit('input', value);
    },
    setInitialValue() {
      if (this.value) {
        this.localValue = this.value;
        this.isChairman = _.findIndex(this.value, itm => itm.is_chairman);
      }
    },
  },
  watch: {
    value(value) {
      // update value
      this.setInitialValue();
    },
    localValue(val) {
      this.emitChange(val);
    },
  },
};
