export default function (question, el) {
  const { answers } = question;
  const countCorrect = answers.filter(v => v.correct);
  const countNotCorrect = answers.filter(v => v.correct === 0);
  if (countCorrect.length === answers.length) {
    answers[el].correct = 0;
    answers[el].disabled = true;
  } else
  if (countNotCorrect.length === answers.length) {
    answers[el].correct = 1;
    answers[el].disabled = false;
  }

  return answers;
}
