import Vue from 'vue';
import store from './VueJS/store';
import VPassportPageView from './VueJS/views/VPassportPageView/index';


if (document.getElementById('v-passport-page-view-app')) {
  window.vPassportPageViewApp = new Vue({
    el: '#v-passport-page-view-app',
    components: { VPassportPageView },
    store,
  });
}
