import Vue from 'vue';
import sentry from '@vendor/sentry';
import store from './VueJS/store';
import VDiplomaList from './VueJS/views/VDiplomaList/index';


if (document.getElementById('v-diploma-list-app')) {
  window.vDiplomaListApp = new Vue({
    el: '#v-diploma-list-app',
    components: { VDiplomaList },
    store,
  });
}
