export default {
  methods: {
    getRefDisciplines() {
      return this.refs.disciplines;
    },
    getRefResults() {
      return this.refs.results;
    },
    getControlOfType(el, ...types) {
      const vm = this;
      let result = 0;
      const disciplinesEdit = vm.disciplinesEdits[el.id];
      if (el.children && el.children.length > 0) {
        _.forEach(el.children, (value) => {
          let val = vm.getControlOfType(value, ...types);
          if (val && typeof val === 'string' && val.includes(',')) {
            val = val.split(',').length;
          } else if (val > 0) {
            val = 1;
          }
          result += val;
        });
      } else {
        _.forEach(types, (value) => {
          let val = 0;
          if (disciplinesEdit === undefined) {
            val = _.get(el, value);
          } else {
            val = _.get(disciplinesEdit, value);
          }
          if (val && val.includes(',')) {
            result = val;
          } else {
            val = parseFloat(val);
            if (_.isNaN(val)) {
              val = 0;
            }
            result += val;
          }
        });
      }
      return result;
    },
    getOfType(el, ...types) {
      const vm = this;
      let result = 0;
      const disciplinesEdit = vm.disciplinesEdits[el.id];
      if (el.children && el.children.length > 0) {
        _.forEach(el.children, (value) => {
          let val = vm.getOfType(value, ...types);
          if (val && typeof val === 'string' && val.includes(',')) {
            val = val.replace(',', '.');
          }
          result += val;
        });
      } else {
        _.forEach(types, (value) => {
          let val = 0;
          if (disciplinesEdit === undefined) {
            val = _.get(el, value);
          } else {
            val = _.get(disciplinesEdit, value);
          }
          if (val && val.includes(',')) {
            val = val.replace(',', '.');
          }
          val = parseFloat(val);
          if (_.isNaN(val)) {
            val = 0;
          }
          result += val;
        });
      }
      return result;
    },
  },
};
