import eventHub from '@vjs/config/eventHub';

export default {
  name: 'Modals',

  data() {
    return {
      modals: [],
    };
  },

  computed: {
    // Get the current window
    current() {
      return this.modals[this.$last];
    },

    $last() {
      return this.modals.length - 1;
    },

    body() {
      if (typeof document !== 'undefined') {
        return document.querySelector('body');
      }
    },
  },

  created() {
    // Create a new Modal instance
    eventHub.$on('new', (params) => {
      const defaults = {
        title: null,
        dismissable: true,
        name: '',
        size: 'md',
        escapable: true,
        fullscreen: false,
        center: false,
        isTop: false,
        isScroll: false,
        isBottom: false,
        isLeft: false,
        isRight: false,
        className: '',
        classes: {},
        styles: '',
        onClose() {},
        onDismiss() {},
      };

      const options = Object.assign(defaults, params);

      this.modals.push(options);

      this.modals.forEach((modal) => {
        this.setClasses(modal);
        // this.setStyles(modal);
      });

      // Let know everyone else that a new Modal is open
      eventHub.$emit('opened', {
        index: this.$last,
        options,
      });

      this.body.classList.add('modal-open');

      document.querySelector('.s-vue-modal__wrapper').scrollTop = 0;
    });

    // When a close event is receive, close the Modal instance
    eventHub.$on('close', (data) => {
      let index = null;

      // If an $index was given on the data
      if (data && data.$index) {
        index = data.$index;
      }

      // Close the most recent Modal instance
      if (index === null) {
        index = this.$last;
      }

      this.close(data, index);
    });

    // Same for dismiss
    eventHub.$on('dismiss', (index) => {
      let lIndex = index;
      // Close the most recent Modal instance
      if (lIndex === null) {
        lIndex = this.$last;
      }

      this.dismiss(lIndex);
    });
  },

  methods: {
    setClasses(params) {
      params.classes = {
        's-vue-modal--is-fullscreen': params.fullscreen,
        's-vue-modal--is-center': params.center,
        's-vue-modal--is-top': params.isTop && !params.isScroll && !params.center,
        's-vue-modal--is-bottom': params.isBottom && !params.isScroll && !params.center,
        's-vue-modal--is-left': params.isLeft,
        's-vue-modal--is-right': params.isRight,
        's-vue-modal--is-scroll-fullscreen': params.isScroll && params.fullscreen,
        's-vue-modal--is-scroll': params.isScroll && !params.fullscreen,
        [`s-vue-modal--${params.size}`]: !params.fullscreen,
        [params.className]: !!params.className,
      };
    },

    setStyles(params) {
      let translateX = '0';
      let translateY = '0';

      if (params.center) {
        translateX = '-50%';
        translateY = '-50%';
      }

      if (params.isRight || params.isLeft) {
        translateX = '0%';
      }

      if ((params.isTop || params.isBottom)
        && !params.isScroll
        && !params.center) {
        translateY = '0%';
      }

      params.styles = `transform: translate(${translateX}, ${translateY})`;
    },

    // Remove the given index from the modals array
    splice(index = null) {
      if (index === -1) {
        return;
      }

      // If there's nothing to close, ignore it
      if (!this.modals.length) {
        return;
      }

      // If there's no index, pop() it
      if (index === null) {
        this.modals.pop();
      } else {
        this.modals.splice(index, 1);
      }

      // And if it was the last window, notify that all instances are destroyed
      if (!this.modals.length) {
        this.body.classList.remove('modal-open');
        eventHub.$emit('destroyed');
      }
    },

    doClose(data = null, index) {
      // If there's nothing to close, ignore it
      if (!this.modals.length) {
        return;
      }

      if (!this.modals[index]) {
        return;
      }

      this.splice(index);

      const modals = document.querySelector('.s-vue-modal__wrapper');

      if (modals) {
        modals.scrollTop = 0;
      }
    },

    // Close the modal and pass any given data
    close(data = null, index = null) {
      // Can't close if there's no modal open
      if (this.modals.length === 0) return;

      let localIndex = index;

      // If the index is a function, pass the current open modal index
      if (index && typeof index === 'function') {
        localIndex = index(data, this.modals);
      }

      // If the index is either null or undefined
      if (typeof localIndex !== 'number') localIndex = this.$last;

      // Notify the app about this window being closed
      eventHub.$emit('closed', {
        index: localIndex,
        instance: this.modals[index],
        data,
      });

      // Close callback
      if (localIndex !== false && this.modals[localIndex]) this.modals[localIndex].onClose(data);

      this.doClose(data, localIndex);
    },

    // Dismiss the active modal
    dismiss(index = null) {
      let localIndex = index;

      // If the index is a function, pass the current open modal index
      if (index && typeof index === 'function') {
        localIndex = index(this.$last);
      }

      // If the index is either null or undefined
      if (typeof localIndex !== 'number') {
        localIndex = this.$last;
      }

      if (localIndex >= 0) {
        // Check dismiss callback result for prevention
        if (this.modals[localIndex].onDismiss() === false) {
          return;
        }

        // Notify the app about this window being closed
        eventHub.$emit('dismissed', {
          index: localIndex,
          instance: this.modals[localIndex],
        });

        this.doClose(null, localIndex);
      }
    },

    handleEscapeKey() {
      if (!this.modals.length) {
        return;
      }

      if (this.current.escapable) {
        this.dismiss();
      }
    },
  },
};
