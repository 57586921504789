import {
  QTooltip,
  QInput,
  QToggle,
  QForm,
  QBtn,
} from '@quasar/components';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import VHeadings from '../Components/VHeadings';
import VControlButtons from '../Components/VControlButtons';
import helpers from '../mixins/helpers';

export default {
  name: 'VQuestionAdd',
  components: {
    QTooltip,
    QInput,
    QToggle,
    QForm,
    QBtn,
    VHeadings,
    VControlButtons,
  },
  mixins: [helpers],
  created() {
    this.dataQuestion.answers.push({ answer: null });
    this.dataQuestion.answers.push({ answer: null });
  },
  data() {
    return {
      storeData: this.$store,
      dataQuestion: {
        question: '',
        answers: [],
      },
      editor: ClassicEditor,
      editorConfig: {
        language: 'ru',
        toolbar: {
          items: [
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'blockQuote',
            'insertTable',
            'undo',
            'redo',
          ],
        },
      },

      disableSave: false,
    };
  },
  methods: {
    async questionAddForm() {
      const vm = this;
      if (vm.disableSave) return;
      this.$store.commit('SET_METHOD', 'POST');
      vm.disableSave = true;
      await this.$store.dispatch('CREATE_QUESTIONNAIRE_QUESTION', {
        question: this.dataQuestion.question,
        answers: this.dataQuestion.answers,
      });
      vm.disableSave = false;
    },
  },
};
