import {
  requestWrapper,
} from '@vjs/helpers';
import {
  QForm,
  QInput,
  QBtn,
  QIcon,
} from '@quasar/components';
import VFormInform from '@vjs/components/VFormInform';
import eventHub from '@vjs/config/eventHub';
import _ from 'lodash';
import Validation from '../mixins/Validation';
import Files from '../mixins/Files';
import StatementFiles from '../mixins/StatementFiles';
import SelectUserFile from '../../SelectUserFile';

export default {
  name: 'VStepDocuments',

  components: {
    QForm,
    QInput,
    QBtn,
    QIcon,
    VFormInform,
    SelectUserFile,
  },

  mixins: [Validation, Files, StatementFiles],

  props: {
    uriSendStatement: {
      type: String,
      required: true,
    },
    needSign: {
      type: Boolean,
      default: true,
      required: false,
    },
    withPpz: false,
    forGrant: true,
    forImport: false,
  },

  data() {
    const form = {
      // doc_statement: [],
      // diploma: [],
      // certificate: [],
      doc_photo: [],
      doc_medical_examination_086_y: [],
      // doc_medical_examination_086_flyura: [],
      doc_medical_examination_088: [],
      // doc_for_privilege: [],
    };
    if (this.withPpz) {
      form.diploma = [];
      form.diploma_2 = [];
      form.social_help_receipt = [];
      form.education_department_direction = [];
    }
    if (!this.forGrant) {
      form.diploma = [];
      form.diploma_2 = [];
    }
    return {
      loading: false,
      signXml: null,
      form,
    };
  },
  created() {
    eventHub.$on('SET_NEXT_STEP', this.requestForm);
    this.$store.commit('SET_ACCESS_STEP', {
      step: 6,
      access: false,
    });
  },

  beforeDestroy() {
    eventHub.$off('SET_NEXT_STEP', this.requestForm);
  },

  computed: {
    validate() {
      let check = true;
      if (!this.forImport && this.needSign && check) {
        check = this.signXml !== null;
      }
      if (!this.forGrant || this.isPpz) {
        check = check && this.form.diploma.length > 0 && this.form.diploma_2.length > 0;
      }
      return this.form.doc_medical_examination_086_y.length > 0 && this.form.doc_photo.length > 0 && check;
    },
  },
  watch: {
    validate(val) {
      this.$store.commit('SET_ACCESS_STEP', {
        step: 6,
        access: val,
      });
    },
  },

  methods: {
    async sign() {
      this.$emit('loading', true);
      const webSocket = new WebSocket('wss://127.0.0.1:13579/');
      let callback = null;
      const xmlToSign = '<status>Sign</status>';
      webSocket.onopen = (event) => {
        callback = (response) => {
          this.signXml = response.responseObject;
          this.$emit('loading', false);
        };
        webSocket.send(JSON.stringify(
          {
            id: 1,
            module: 'kz.gov.pki.knca.commonUtils',
            method: 'signXml',
            args: ['PKCS12', 'SIGNATURE', xmlToSign, '', ''],
          },
        ));
      };

      webSocket.onclose = (event) => {
        if (event.wasClean) {
          console.log('connection has been closed');
        } else {
          console.log('Connection error');
          this.$notify({
            text: this.trans('notice.ncalayer_not_run'),
            type: 'error',
          });
          this.$emit('loading', false);
        }
        console.log(`Code: ${event.code} Reason: ${event.reason}`);
      };

      webSocket.onmessage = (event) => {
        const result = JSON.parse(event.data);
        console.log(event);
        console.log(result);
        if (result != null) {
          if (result?.code === '200') {
            if (typeof callback === 'function') {
              callback(result);
            }
          } else if (result?.code === '500') {
            this.$emit('loading', false);
          }
        }
      };
    },
    async requestForm() {
      this.$emit('loading', true);
      const statement = this.$store.state.vSteps.qualificationInfo;
      const { signXml } = this;
      const subjects = this.$store.state.vSteps.subjectsInfo;
      const files = {};
      _.each(this.form, (item, index) => {
        if (item.length !== 0) {
          files[index] = item;
        }
      });
      const { doc_relation, isParent } = this.$store.state.vSteps.userInfo;
      let user = {};
      if (isParent || this.forImport) {
        if (doc_relation.length > 0) {
          files.doc_relation = doc_relation;
        }
        // user = this.$store.state.vSteps.userInfo;
      }
      user = this.$store.state.vSteps.userInfo;
      const resData = {
        statement, subjects, files, user, signXml,
      };
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriSendStatement,
        data: resData,
      });

      if (res.error) {
        if (res.data.errors) {
          this.$notify({
            text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
            type: 'error',
          });
        }
        if (_.has(res.data.errors, 'signXml')) {
          this.signXml = null;
        }
        this.$emit('loading', false);
        return false;
      }
      if (!res.error) {
        if (!this.forImport) {
          this.$emit('loading', false);
          this.$store.state.vSteps.currentStep = 7;
        }
      }
    },
  },
};
