import Vue from 'vue';

import store from './VueJS/store';
import VExamTickets from './VueJS/views/VExamTickets/VExamTickets.vue';

if (document.getElementById('v-exam-tickets-app')) {
  window.vExamTicketsApp = new Vue({
    el: '#v-exam-tickets-app',
    components: { VExamTickets },
    store,
  });
}
