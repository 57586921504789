import { mapGetters } from 'vuex';
import VQuestionnaireTitle from './Components/VQuestionnaireTitle';
import VQuestionnaireDetails from './Components/VQuestionnaireDetails';
import VResults from './Components/VResults';

export default {
  name: 'VQuestionnaire',
  props: {
    resource: {
      type: Object,
      default: null,
    },
    isRespondent: {
      type: Boolean,
      default: false,
    },
    canProcess: {
      type: Boolean,
      default: false,
    },
    apiProcessConfig: {
      type: Object,
      default: null,
    },
  },
  components: {
    VQuestionnaireTitle,
    VQuestionnaireDetails,
    VResults,
  },
  async mounted() {
    await this.$trans.add(['label', 'questionnaire']);
    await this.$store.commit('SET_QUESTIONNAIRE_VM', this);
    this.$store.commit('SET_PROCESS_API_CONFIG', this.apiProcessConfig);
    await this.$store.dispatch('GET_QUESTIONNAIRE_DETAIL');
  },
  computed: {
    ...mapGetters(['questionnaireDetails']),
  },
};
