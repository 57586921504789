<template>
  <q-form
    autocorrect="off"
    autocapitalize="off"
    autocomplete="off"
    spellcheck="false"
  >
    <div
      class="row"
    >
      <v-form-inform
        :message="trans('title.full_name_declension_info')"
        class="col-12"
      />
      <q-item-label
        class="col-12"
      >
        {{ trans('label.to_who_from_lang.ru') }}
      </q-item-label>
      <q-input
        v-model="form.full_name_ru.surname"
        :label="trans('label.surname')"
        class="col-4 col-sm-12 col-lg-4"
      />
      <q-input
        v-model="form.full_name_ru.firstname"
        :label="trans('label.firstname')"
        class="col-4 col-sm-12 col-lg-4"
      />
      <q-input
        v-model="form.full_name_ru.lastname"
        :label="trans('label.lastname')"
        class="col-4 col-sm-12 col-lg-4"
      />
      <q-item-label
        class="col-12"
      >
        {{ trans('label.to_who_from_lang.kz') }}
      </q-item-label>
      <q-input
        v-model="form.full_name_kz.surname"
        :label="trans('label.surname')"
        class="col-4 col-sm-12 col-lg-4"
      />
      <q-input
        v-model="form.full_name_kz.firstname"
        :label="trans('label.firstname')"
        class="col-4 col-sm-12 col-lg-4"
      />
      <q-input
        v-model="form.full_name_kz.lastname"
        :label="trans('label.lastname')"
        class="col-4 col-sm-12 col-lg-4"
      />
    </div>
    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :label="trans('button.approve')"
        @click="approve"
      />
    </div>
  </q-form>
</template>

<script>
import { Helper } from '@common/src/helpers';
import { QInput, QItemLabel } from '@quasar/components';

export default {
  components: {
    QItemLabel,
    QInput,
  },
  props: {
    action: {
      type: String,
      default: '',
    },
    fileName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {
        full_name_ru: {
          surname: null,
          firstname: null,
          lastname: null,
        },
        full_name_kz: {
          surname: null,
          firstname: null,
          lastname: null,
        },
      },
    };
  },
  beforeCreate() {
    this.$trans.add([
      'title',
    ]);
  },
  methods: {
    approve() {
      this.$globalLoading.show();
      const config = {
        responseType: 'blob',
        params: { data: this.form },
      };

      this.$http.get(this.action, config)
        .then(
          (response) => {
            this.$globalLoading.hide();
            const result = document.createElement('a');
            result.href = window.URL.createObjectURL(response.body);
            result.download = this.fileName;
            result.click();
            this.$emit('onClose');
          },
          (response) => {
            this.$globalLoading.hide();
            Helper.handlerResponseError(response);
          },
        );
    },
  },
};
</script>
