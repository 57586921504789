export default {
  state: {
    description: {},
    types: {},
    create_book: {},
    modal: false,
    modalType: '',
    qualification: [],
    book: {},
    cover: {},
    year: '',
  },
  mutations: {
    setDescription(state, payload) {
      state.description = payload;
    },
    setTypes(state, payload) {
      state.types = payload;
    },
    setCreateBook(state, payload) {
      state.create_book = payload;
    },
    setModal(state, payload) {
      state.modal = payload;
    },
    setModalType(state, payload) {
      state.modalType = payload;
    },
    setQualification(state, payload) {
      state.qualification = payload;
    },
    setBook(state, payload) {
      state.create_book.book = payload;
      state.book = payload;
    },
    setCover(state, payload) {
      state.create_book.cover = payload;
      state.cover = payload;
    },
    setYear(state, payload) {
      state.create_book.year = payload;
      state.year = payload;
    },
  },
  actions: {},
  getters: {},
};
