import _ from 'lodash';

export default {
  SET_ATTENDANCE_VM: (state, payload) => {
    state.vm = payload;
  },
  SET_ATTENDANCE_GROUP_ID: (state, payload) => {
    state.groupId = payload;
  },
  SET_ATTENDANCE_API_CONFIG: (state, payload) => {
    state.attendanceApiConfig = payload;
  },
  SET_MARK_TYPES_ARR: (state, payload) => {
    state.markTypesArr = payload;
  },
  SET_GROUP_NAME: (state, payload) => {
    state.name = payload;
  },
  SET_BACK_LINK: (state, payload) => {
    state.back = payload;
  },
  SET_ALLOW_EDIT: (state, payload) => {
    state.allowEditAll = payload;
  },
};
