<template>
  <div class="row align-items-stretch justify-content-center sc-statement-steps__wrapper mb-30">
    <div
      v-for="(step,index) in steps"
      :key="index"
      class="col mb-30 d-flex justify-content-center"
    >
      <div
        class="sc-statement-steps"
        :class="getStatus(step.status)"
      >
        <div class="sc-statement-steps__item">
          <div class="sc-statement-steps__icon">
            <img :src="step.image">
          </div>
        </div>
        <div class="sc-statement-steps__label">
          {{ step.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VStatementServiceSteps',
  props: {
    steps: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getStatus(status) {
      switch (status) {
        case 'done': return 'is-passed';
        case 'waiting': return 'is-active';
        default: return '';
      }
    },
  },
};
</script>
