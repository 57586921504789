import _ from 'lodash';
import transportToQuestion from '../helpers/transportToQuestion';
import transportToSubject from '../helpers/transportToSubject';

export default {
  // Выставляем ссылки для отправки запросов
  SET_ENDPOINTS: (state, payload = {}) => {
    _.forEach(payload,
      (value, key) => {
        if (state.endpoints.hasOwnProperty(key) && value) {
          state.endpoints[key] = value;
        }
      });
    state.loading[payload.name] = payload.status;
  },
  // Переключает режим "Загрузка"
  SET_LOADING: (state, payload = { name: 'common', status: false }) => {
    state.loading[payload.name] = payload.status;
  },

  // Устанавливает сообщение об ошибке для загрузочного экрана
  SET_LOADING_MESSAGE: (state, payload) => {
    state.loading.message = payload;
  },

  // Устанавливает id выбранного предмета
  SET_CURRENT_SUBJECT: (state, payload = 0) => {
    state.curSubjectID = payload;
    localStorage.setItem('curSubjectID', payload);
  },

  // Устанавливает id выбранного вопроса и его индекс в предмете
  SET_CURRENT_QUESTION: (state, payload = { id: 0, idx: 0 }) => {
    const curSubject = _.find(state.data, o => o.id === state.curSubjectID);
    curSubject.curQuestionID = payload.id;
    curSubject.curQuestionIdx = payload.idx;
    localStorage.setItem('curQuestionID', payload.id);
    localStorage.setItem('curQuestionIdx', payload.idx);
  },

  // Переключает на предыдущий вопрос в текущем предмете
  SET_PREV_QUESTION: (state) => {
    const curSubject = _.find(state.data, o => o.id === state.curSubjectID);

    if (curSubject.curQuestionIdx - 1 >= 0) {
      curSubject.curQuestionIdx -= 1;
    } else {
      curSubject.curQuestionIdx = curSubject.questions.length - 1;
    }

    curSubject.curQuestionID = curSubject.questions[curSubject.curQuestionIdx].id;
    localStorage.setItem('curQuestionID', curSubject.curQuestionID);
    localStorage.setItem('curQuestionIdx', curSubject.curQuestionIdx);

    const curIdx = parseInt(curSubject.curQuestionIdx / 20, 10);
    if (curIdx !== state.curQuestionsMapPage) {
      state.curQuestionsMapPage = curIdx;
    }
  },

  // Переключает на следующий вопрос в текущем предмете
  SET_NEXT_QUESTION: (state) => {
    const curSubject = _.find(state.data, o => o.id === state.curSubjectID);
    if (curSubject.curQuestionIdx + 1 < curSubject.questions.length) {
      curSubject.curQuestionIdx += 1;
    } else {
      curSubject.curQuestionIdx = 0;
    }

    curSubject.curQuestionID = curSubject.questions[curSubject.curQuestionIdx].id;
    localStorage.setItem('curQuestionID', curSubject.curQuestionID);
    localStorage.setItem('curQuestionIdx', curSubject.curQuestionIdx);

    const curIdx = parseInt(curSubject.curQuestionIdx / 20, 10);
    if (curIdx !== state.curQuestionsMapPage) {
      state.curQuestionsMapPage = curIdx;
    }
  },

  // Переключает на следующий вопрос в текущем предмете, если на него ещё не ответили
  SET_PREV_QUESTION_SMART: (state) => {
    const curSubject = _.find(state.data, o => o.id === state.curSubjectID);
    if (curSubject.leftAnswers !== 0) {
      transportToQuestion(curSubject, false, state);
    } else {
      transportToSubject(state, false);
    }
  },

  // Переключает на следующий вопрос в текущем предмете, если на него ещё не ответили
  SET_NEXT_QUESTION_SMART: (state) => {
    const curSubject = _.find(state.data, o => o.id === state.curSubjectID);
    if (curSubject.leftAnswers !== 0) {
      transportToQuestion(curSubject, true, state);
    } else {
      transportToSubject(state, true);
    }
  },

  // Устанавливает флаг true для выбранного ответа
  SET_ANSWER: (state, payload) => {
    const curSubject = _.find(state.data, o => o.id === payload.subjectId);
    _.forEach(curSubject.questions[payload.questionIdx].answers,
      (o) => {
        if (o.id === payload.answerId) {
          o.checked = true;
          // console.time('SELECT_ANSWER');
          // Подсчёт оставшихся вопросов без ответа
          const selectedAnswers = curSubject.questions.filter(
            i => i.answers.find(q => q.checked === true),
          );
          curSubject.leftAnswers = curSubject.questions.length - selectedAnswers.length;
          // console.timeEnd('SELECT_ANSWER');
        } else {
          o.checked = false;
        }
      });
  },

  // Устанавливает текущую страницу на карте ответов
  SET_QMAP_NUM: (state, payload) => {
    state.curQuestionsMapPage = payload;
  },

  // Данные о текущем тестировании
  SET_TEST: (state, payload) => {
    state.test = payload;
  },

  // Устанавливает время регулярно раз в 15 с (только для IOS)
  SET_TEST_TIME: (state, payload) => {
    state.test.testEndTime = payload.testEndTime;
    state.test.testStartTime = payload.testStartTime;
    state.test.testTimeLeft = payload.testTimeLeft;
  },

  // Устанавливает статус теста
  SET_TEST_STATUS: (state, payload) => {
    state.test.status = payload;
  },


  // Данные о вопросах по предметам
  SET_DATA: (state, payload) => {
    // console.time('SET_DATA');
    state.data = payload.map((subject) => {
      let leftAnswers = subject.questions.length;

      return {
        ...subject,
        curQuestionID: subject.questions[0].id,
        curQuestionIdx: 0,
        questions: [
          ...subject.questions.map((question, index) => ({
            id: question.id,
            idx: index,
            subjectID: subject.id,
            question: question.text,
            answers: [
              ...question.answers.map((answer) => {
                // Рассчёт для добавления в объект свойства с остатком неотвеченных вопросов
                if (answer.checked) {
                  leftAnswers -= 1;
                }

                return answer;
              }),
            ],
          })),
        ],
        leftAnswers,
      };
    });
    // console.timeEnd('SET_DATA');
  },
};
