import {
  QForm,
  QBtn,
  QSelect,
  QIcon,
  QInput,
  QTable,
  QTh,
  QTr,
  QTd,
  QTooltip,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import VNoResults from '@vjs/components/VNoResults';

export default {
  name: 'VAdmissionResultOrgList',
  components: {
    QForm,
    QBtn,
    QSelect,
    QInput,
    QTable,
    QTh,
    QTr,
    QTd,
    QIcon,
    QTooltip,
    VNoResults,
  },
  props: {
    uriGetData: {
      type: String,
      required: true,
    },
    yearsOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      table: {
        rows: [],
        columns: [],
      },
      pagination: {
        rowsPerPage: 0,
      },
      filter: '',
      loading: false,
      noResults: false,
      yearFilter: null,
    };
  },

  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'placeholder',
      'warning_text',
      'table',
      'fields',
      'statement',
    ]);

    this.table.columns = [
      {
        name: 'index',
        required: false,
        label: '№',
        align: 'center',
        field: 'index',
        width: 75,
      },
      {
        name: 'name',
        required: true,
        label: this.trans('label.organization_name'),
        align: 'center',
        field: 'name',
        width: 500,
      },
      // {
      //   name: 'govOrderPlaces',
      //   required: true,
      //   label: this.trans('statement.gov_order_places'),
      //   align: 'center',
      //   field: 'govOrderPlaces',
      //   width: 150,
      // },
      {
        name: 'countStatements',
        required: true,
        label: this.trans('statement.total_statements'),
        align: 'center',
        field: 'countStatements',
        width: 150,
      },
      {
        name: 'actions',
        required: false,
        label: this.trans('table.operations'),
        align: 'center',
        field: 'actions',
        width: 150,
      },
    ];

    if (this.yearsOptions.length > 0) {
      this.yearFilter = this.yearsOptions[0].value;
    }

    await this.requestGetTable();
  },
  methods: {
    async requestGetTable() {
      this.loading = true;
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.uriGetData,
        params: {
          year: this.yearFilter,
        },
      });
      if (!res.error) {
        if (res.rows) {
          this.table.rows = res.rows;
          this.noResults = res.rows.length === 0;
        }
      }
      this.loading = false;
      this.$globalLoading.hide();
    },
    getCellClass(name) {
      switch (name) {
        case 'govOrderPlaces':
          return 's-to-center';
        case 'countStatements':
          return 's-to-center';
        default:
          return '';
      }
    },
  },
};
