import {
  QBtn,
  QIcon,
  QSelect,
  QTable,
  QTd,
  QTh,
  QTooltip,
  QTr,
} from '@quasar/components';
import VNoResults from '@vjs/components/VNoResults';

export default {
  name: 'VMonitoringList',

  components: {
    QTooltip,
    QSelect,
    QTable,
    QIcon,
    QTr,
    QTh,
    QTd,
    QBtn,
    VNoResults,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    noResultsTitle: {
      type: String,
      default: '',
    },
    noResultsLabel: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    table: {
      pagination: {
        page: 1,
        rowsPerPage: 0,
      },
      columns: [],
      title: '',
    },
  }),

  mounted() {
    this.table.columns = [
      {
        name: 'name',
        required: true,
        label: 'label',
        align: 'left',
        field: 'name',
        sortable: false,
      },
      {
        name: 'url',
        required: true,
        label: '',
        align: 'left',
        field: 'url',
        sortable: false,
      },
    ];
  },

  beforeCreate() {
    this.$trans.add(['button']);
  },
};
