import {Helper} from "@common/src/helpers";

export default {
  name: 'VDormitoryButtons',
  props: {
    action: {
      type: String,
    },
    method: {
      type: String,
      default: 'post',
    },
    organizationId: {
      type: Number,
      default: null,
    },
    sex: {
      type: String,
      required: false,
    },
    exclude: {
      type: String,
      required: false,
    },
  },
  mounted() {
    this.$trans.add(['dormitory', 'button', 'placeholder']);
  },
  methods: {
    async handleAjaxFormSubmit() {
      this.$globalLoading.show();
      const status = 'checkIn';
      const res = await requestWrapper.call(this, {
        url: Helper.urlGenerate(this.action, { status }),
        method: this.method,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$emit('onClose');
      this.$globalLoading.hide();
    },
    confirmModal() {
      const events = {
        onApprove: async () => {
          this.handleAjaxFormSubmit();
        },
      };
      this.$sModal.open('v-modal-confirm-new', {
        title: this.trans('notice.are_you_sure'),
        component: 'v-modal-confirm-new',
        notPersistent: true,
        events,
      });
    },
    triggerModal() {
      this.$sModal.open('v-dormitory-to-another-room-modal', {
        component: 'v-dormitory-to-another-room-modal',
        notPersistent: true,
        title: this.trans('dormitory.modal.title_another'),
        binds: {
          action: this.action,
          method: this.method,
          organizationId: this.organizationId,
          uriChangeStatus: this.uriChangeStatus,
          sex: this.sex,
          exclude: this.exclude,
        },
      });
    },
  },
};
