import { StoreTranslator } from '@common/mixins';
import eventHub from '@vjs/config/eventHub';
import VNoResults from '@vjs/components/VNoResults';
import MultiExportModal from './MultiExportModal';

export default {
  mixins: [StoreTranslator],
  components:
    {
      MultiExportModal,
      VNoResults,
    },
  props: {
    url: {
      type: String,
      required: true,
    },
    exportUrl: {
      type: String,
      required: false,
    },
    exportActive: {
      required: false,
    },
    storageName: {
      type: String,
      required: false,
    },
    filterClearActive: {
      type: Boolean,
      required: false,
    },
    isFilterExportActive: {
      type: Boolean,
      default: true,
    },
    isCheckable: {
      type: Boolean,
      default: false,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    multiExportUrl: {
      type: Array,
      default: () => ([]),
    },
    actionsClass: {
      type: String,
      default: '',
    },
    operationsWrapperClass: {
      type: String,
      default: '',
    },
    noResultTitle: {
      type: String,
      default: '',
    },
    noResultText: {
      type: String,
      default: '',
    },
    openFilterByDefault: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const vm = this;
    return {
      showFilter: this.openFilterByDefault,
      total: 0,
      rowTotal: undefined,
      groupFilters: [],
      items: [],
      columns: [],
      hideActions: true,
      pagination: {
        links: [],
        currentPage: 1,
      },
      isActiveExport: this.exportActive,
      filterExportActive: this.isFilterExportActive,
      exportUrlWithParams: '',
    };
  },
  beforeCreate() {
    this.$trans.add([
      'fields',
      'warning_text',
      'placeholder',
      'label',
      'button',
      'main',
      'notice',
      'table',
      'statement',
      'filter_fields',
      'training_schedule',
      'roles',
    ]);
  },
  async mounted() {
    if (
      !this.storageName
       || (
         this.storageName
         && sessionStorage
         && !sessionStorage.getItem(this.storageName)
       )
    ) {
      await this.loadData(this.url, { withFilters: true });
    }
    if (this.storageName && sessionStorage.getItem(this.storageName)) {
      this.loadFiltersFromSessionStorage({}, this.storageName);
      await this.loadData(this.url);
      await this.loadData(this.url, { withFilters: true });
      if (this.showFilters) {
        this.toggleFilter();
      }
    }
  },

  computed: {
    computedGetParams() {
      return this.getParams();
    },
  },

  destroyed() {
  },
  watch: {
    groupFilters: {
      handler(val) {
        if (this.isCheckable) {
          let isTypeSelected = false;
          let isGroupSelected = false;
          _.forEach(this.groupFilters, (filterGroup, filterGroupKey) => {
            _.forEach(filterGroup.items, (filter, filterKey) => {
              if (filter.name === 'filters[group_id]') {
                isGroupSelected = !!filter.value && !isTypeSelected;
              } else if (filter.name === 'filters[journal_type]') {
                isTypeSelected = !!filter.value && filter.value !== 'normal';
              }
            });
          });
          this.filterExportActive = isTypeSelected || isGroupSelected;
        }
      },
      deep: true,
    },
  },
  methods: {
    async clearFilters() {
      const { groupFilters } = this;
      _.forEach(this.groupFilters, (filterGroup, filterGroupKey) => {
        _.forEach(filterGroup.items, (filter, filterKey) => {
          if (filter.component === 'v-multi-select-field') {
            groupFilters[filterGroupKey].items[filterKey].value = ' ';
          } else if (filter.component === 'between-field') {
            groupFilters[filterGroupKey].items[filterKey].configComponent.fieldFrom.value = null;
            groupFilters[filterGroupKey].items[filterKey].configComponent.fieldTo.value = null;
          } else {
            groupFilters[filterGroupKey].items[filterKey].value = null;
          }
        });
      });
      this.$set(this, 'groupFilters', groupFilters);
      eventHub.$emit('clearFilters', true);
      await this.loadData(this.url, { withFilters: true });
      if (sessionStorage && sessionStorage.getItem(this.storageName)) {
        sessionStorage.removeItem(this.storageName);
      }
    },
    // Установка сохранённых фильтров или установка по умолчанию
    loadFiltersFromSessionStorage(rewriteKeysObj = {}, keyName = '') {
      if (sessionStorage && sessionStorage.getItem(keyName)) {
        const keysObj = JSON.parse(sessionStorage.getItem(keyName));
        Object.keys(keysObj).forEach((item) => {
          if (rewriteKeysObj[item]) {
            keysObj[item] = rewriteKeysObj[item];
          }
          this[item] = keysObj[item];
        });
        return true;
      }
      return false;
    },
    // Сохранение выбранных фильтров в localStorage
    saveFiltersToSessionStorage(keysObj = {}, keyName = '') {
      if (sessionStorage) {
        sessionStorage.setItem(keyName, JSON.stringify(keysObj));
      }
    },

    isSpoiler(group) {
      return _.get(group, 'spoiler', false);
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    getAttribute(item, attribute) {
      let val = '';
      if (attribute.includes('*')) {
        const pathes = attribute.split('.');
        let values = _.cloneDeep(item);
        _.each(pathes, (path) => {
          if (path === '*') {
            return;
          } if (_.isArray(values)) {
            values = _.map(values, subValue => (subValue && subValue.hasOwnProperty(path) ? subValue[path] : null));
          } else {
            values = _.get(values, path);
          }
          values = _.filter(values, o => o !== null);
        });
        if (_.isArray(values)) {
          val = values.join(', ');
        } else {
          val = values;
        }
      } else {
        val = _.get(item, attribute, '');
      }
      return val;
    },
    chunkedFilters(items) {
      return _.chunk(items, 3);
    },
    async sendFilters() {
      await this.loadData(this.url);
      if (this.storageName) {
        this.saveFiltersToSessionStorage({
          groupFilters: this.groupFilters,
        }, this.storageName);
      }
    },
    exportTable() {
      const vm = this;

      if (!vm.isActiveExport) {
        return;
      }

      vm.$globalLoading.show();
      const config = {
        responseType: 'json',
        method: 'GET',
        params: vm.getParams(),
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };

      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      const request = vm.$http.get(vm.exportUrl, config);

      request.then(
        (response) => {
          show_notice(vm.trans('notice.export_start_success'), 'notice');
          vm.$set(vm, 'isActiveExport', false);
          vm.$globalLoading.hide();
        },
        (response) => {
          console.log(response);
        },
      );
    },
    async loadData(url, params = {}, method = 'GET') {
      const vm = this;

      const body = this.getParams();
      vm.$globalLoading.show();
      const config = {
        responseType: 'json',
        method,
        params,
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };

      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(url, body, config);
          break;
        case 'delete':
          request = vm.$http.delete(url, config);
          break;
        case 'patch':
          request = vm.$http.patch(url, body, config);
          break;
        case 'get':
          config.params = _.merge(config.params, body);
          request = vm.$http.get(url, config);
          break;
      }
      await request.then(
        (response) => {
          vm.$globalLoading.hide();
          const { data } = response;
          vm.$set(vm, 'items', data.data);
          if (vm.items.length) {
            vm.$set(vm, 'isActiveExport', true);
            vm.$set(vm, 'exportUrlWithParams', vm.getExportUrlWithParams());
          } else {
            vm.$set(vm, 'isActiveExport', false);
            vm.$set(vm, 'exportUrlWithParams', false);
          }
          vm.$set(vm, 'total', data.meta.total);
          vm.$set(vm, 'rowTotal', data.total);
          vm.$set(vm, 'columns', data.meta.columns);
          if (data.meta.hideActions !== undefined) {
            vm.$set(vm, 'hideActions', false);
          }
          if (_.isArray(data.meta.groupFilters)) {
            const { groupFilters } = data.meta;
            _.forEach(data.meta.groupFilters, (filterGroup, filterGroupKey) => {
              _.forEach(filterGroup.items, (filter, filterKey) => {
                if (
                  filter.component === 'v-multi-select-field'
                  && filter.value
                  && parseInt(filter.value, 0)
                ) {
                  groupFilters[filterGroupKey].items[filterKey].value = parseInt(filter.value, 0);
                }
                if (filter.component === 'between-field') {
                  if (vm.groupFilters && vm.groupFilters[filterGroupKey] && vm.groupFilters[filterGroupKey].items[filterKey]) {
                    groupFilters[filterGroupKey].items[filterKey] = vm.groupFilters[filterGroupKey].items[filterKey];
                    groupFilters[filterGroupKey].items[filterKey] = vm.groupFilters[filterGroupKey].items[filterKey];
                  }
                }
              });
            });
            vm.$set(vm, 'groupFilters', groupFilters);

            vm.dispatchEvents();
          }
          if (data.generated_links) {
            vm.$set(vm.pagination, 'links', data.generated_links);
            vm.$set(vm.pagination, 'currentPage', data.meta.current_page);
          }
        },
        (response) => {
          vm.$globalLoading.hide();
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
            });
            show_notice(errors, 'error');
            vm.$set(form, 'errors', response.data.errors);
          } else {
            console.log(response);
          }
        },
      );
    },
    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
    dispatchEvents() {
      const vm = this;
      _.forEach(vm.groupFilters, (groupFilter) => {
        _.forEach(groupFilter.items, (filter) => {
          if (filter.hasOwnProperty('dispatchFilterEvents')) {
            filter.events = {};
            _.forEach(filter.dispatchFilterEvents, (dispatchFilterEventConfig, dispatchFilterEvent) => {
              filter.events[dispatchFilterEvent] = () => {
                vm[dispatchFilterEventConfig.func].apply(vm, dispatchFilterEventConfig.args);
              };
            });
          }
        });
      });
    },
    getParams() {
      const vm = this; const
        body = {};
      _.forEach(vm.groupFilters, (groupFilter) => {
        _.forEach(groupFilter.items, (filter) => {
          if (filter.hasOwnProperty('subs')) {
            _.forEach(filter.subs, (subFilter) => {
              if (subFilter.hasOwnProperty('fill')) {
                subFilter.fill(body);
              } else {
                _.set(body, this.nameToDot(subFilter.name), subFilter.value);
              }
            });
          } else {
            if (filter.hasOwnProperty('fill')) {
              filter.fill(body);
            } else if (filter.hasOwnProperty('name')) {
              _.set(body, this.nameToDot(filter.name), filter.value);
            }
            if (filter.value === true) {
              _.set(body, this.nameToDot(filter.name), 1);
            } else if (filter.value === false) {
              _.set(body, this.nameToDot(filter.name), 0);
            }
          }
        });
      });

      return body;
    },
    serialiseObject(obj, prefix) {
      const str = [];
      let p;
      for (p in obj) {
        if (obj.hasOwnProperty(p)) {
          const k = prefix ? `${prefix}[${p}]` : p;
          const v = obj[p];
          if (v !== null && typeof v === 'object') {
            str.push(this.serialiseObject(v, k));
          } else {
            str.push(`${encodeURIComponent(k)}=${v != null ? encodeURIComponent(v) : ''}`);
          }
        }
      }
      return str.join('&');
    },
    getExportUrlWithParams() {
      const params = this.serialiseObject(this.getParams());
      return `${this.exportUrl}?${params.toString()}`;
    },

    itemIndexNumber(itemIndex) {
      const index = (this.pagination.currentPage - 1) * 15 + itemIndex + 1;

      return index;
    },
    getCellClass(column) {
      if (column.cellClass) {
        return column.cellClass;
      }
      return '';
    },
    openMultiExportModal() {
      $('#modal-multi-export').modal('show');
    },
    handleMultiExportModal() {
      show_notice(this.trans('notice.export_start_success'), 'notice');
      this.$set(this, 'isActiveExport', false);
      $('#modal-multi-export').modal('hide');
    },
  },
};
