import {
  requestWrapper,
} from '@vjs/helpers';
import {
  QForm,
  QInput,
  QField,
  QOptionGroup,
  QSelect,
} from '@quasar/components';
import VFormInform from '@vjs/components/VFormInform';
import eventHub from '@vjs/config/eventHub';
import Validation from '../mixins/Validation';

export default {
  name: 'VStepQualification',

  components: {
    QForm,
    QInput,
    QField,
    QOptionGroup,
    QSelect,
    VFormInform,
  },

  mixins: [Validation],

  props: {
    uriQualificationData: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      professionLabel: '',
      qualificationLabel: '',
      type_of_training_label: '',
      form: {
        edu_lang: null,
        educational_institution: '',
        educational_institution_id: null,
        education: null,
        specialty: null,
        qualification: null,
        type_of_training: null,
      },
      options: {
        eduLangs: [],
      },
    };
  },

  created() {
    eventHub.$on('SET_NEXT_STEP', this.requestForm);
  },


  async mounted() {
    this.$emit('loading', true);
    this.loading = true;

    if (this.$store.state.vSteps.userInfo !== null) {
      const {
        educational_institution,
        educational_institution_id,
        education,
      } = this.$store.state.vSteps.userInfo;
      this.form.educational_institution = educational_institution;
      this.form.educational_institution_id = educational_institution_id;
      this.form.education = education;
    }

    const res = await requestWrapper.call(this, {
      url: this.uriQualificationData,
    });

    if (!res.error) {
      this.options = res.options;
      this.professionLabel = res.professions.label;
      this.qualificationLabel = res.qualifications.label;
      this.type_of_training_label = res.eduForms.label;
      this.form.specialty = res.professions.value;
      this.form.qualification = res.qualifications.value;
      this.form.type_of_training = res.eduForms.value;
    }

    if (this.$store.state.vSteps.qualificationInfo !== null) {
      this.form = this.$store.state.vSteps.qualificationInfo;
    }

    this.loading = false;
    this.$emit('loading', false);
  },
  beforeDestroy() {
    eventHub.$off('SET_NEXT_STEP', this.requestForm);
  },

  computed: {
    validate() {
      return this.form.edu_lang !== null;
    },
  },

  watch: {
    validate(val) {
      this.$store.commit('SET_ACCESS_STEP', {
        step: 4,
        access: val,
      });
    },
  },

  methods: {
    async requestForm() {
      this.$emit('loading', true);
      this.$store.commit('SET_NEXT_STEP');
      this.$store.commit('SET_QUALIFICATION_INFO', this.form);
      this.$emit('loading', false);
      return true;
    },
  },
};
