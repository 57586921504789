<template>
  <div class="btn-group">
    <template v-if="dataQuestion">
      <q-btn
        :color="!dataQuestion.with_option ? 'grey' : 'green'"
        squire
        :label="trans('questionnaire.buttons.other')"
        @click.prevent="questionChangeOption(dataQuestion.id)"
      />
      <q-btn
        :color="!dataQuestion.is_visible ? 'grey' : 'aqua'"
        icon="eye"
        squire
        @click.prevent="questionChangeVisibility(dataQuestion.id)"
      >
        <q-tooltip>{{ questionToggleText }}</q-tooltip>
      </q-btn>
      <q-btn
        v-if="!canEditQuestion"
        color="blue"
        icon="pencil"
        squire
        @click.prevent="changeQuestion(dataQuestion.id)"
      >
        <q-tooltip>{{ trans('label.change_question') }}</q-tooltip>
      </q-btn>
    </template>
    <template v-if="canEditQuestion || !dataQuestion">
      <q-btn
        v-if="dataQuestion"
        color="red"
        icon="trash"
        squire
        @click="confirmDelete(dataQuestion.id)"
      >
        <q-tooltip>{{ trans('label.delete_question') }}</q-tooltip>
      </q-btn>
      <q-btn
        color="orange"
        icon="close"
        squire
        @click.prevent="cancelQuestion()"
      >
        <q-tooltip>{{ trans('label.cancel_question') }}</q-tooltip>
      </q-btn>
      <q-btn
        type="submit"
        color="green"
        icon="check"
        :loading="disableSave"
        squire
      >
        <q-tooltip>{{ trans('label.save_question') }}</q-tooltip>
      </q-btn>
    </template>
  </div>
</template>

<script>
import {
  QTooltip,
  QBtn,
} from '@quasar/components';
import { mapGetters } from 'vuex';
import helpers from '../mixins/helpers';

export default {
  name: 'VControlButtons',
  components: {
    QTooltip,
    QBtn,
  },
  mixins: [helpers],
  props: {
    dataQuestion: {
      type: Object,
      default: null,
    },
    disableSave: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['questionId']),
    questionToggleText() {
      return !this.dataQuestion.is_visible
        ? this.trans('label.question_disabled')
        : this.trans('label.question_enabled');
    },
  },
};
</script>
