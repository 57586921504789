import SelectUserFile from '@app_college/views/SelectUserFile';
import eventHub from '@vjs/config/eventHub';
import Files from '@app_college/views/VStatementCreateView/mixins/Files';
import {requestWrapper} from "@vjs/helpers";
import {Helper} from "@common/src/helpers";

export default {
  name: 'SelectFileByElementModal',
  components: {SelectUserFile},
  mixins: [
    Files,
  ],
  props: {
    selectLabel: {
      type: String,
      default: '',
    },
    uriGetOptions: {
      type: String,
      required: true,
    },
    action: {
      type: String,
    },
    method: {
      type: String,
    },
  },
  data() {
    return {
      form: {
        option: null,
        doc_for_option: null,
      },
      options: [],
    };
  },

  async mounted() {
    await this.$trans.add(['label', 'button']);
    await requestGetDataHelper(this, { url: this.uriGetOptions, variableName: 'options', hardSet: true });
  },

  computed: {
    validate() {
      return this.form.option !== null && this.form.doc_for_option !== null;
    },
  },

  methods: {
    async handleAjaxFormSubmit() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.action,
        method: this.method,
        data: this.form,
      });

      if (!res.error) {
        this.$emit('onClose');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }

      this.$globalLoading.hide();
    },
    clearFile(filename) {
      if (this.form[filename] !== null) {
        this.form[filename] = null;
      }
    },
  },
};
