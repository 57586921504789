import { Helper } from '@common/src/helpers';
import { Form } from '@common/mixins';

export default {
  props: {
    uriGetRefs: {
      type: String,
      default: true,
    },
    action: {
      type: String,
      default: true,
    },
    method: {
      type: String,
      default: true,
    },
    practice: {
      type: Object,
      default: {},
    },
  },
  mixins: [Form],
  data() {
    return {
      langs: ['kz', 'ru'],
      form: {
        enterprise: null,
        enterprisePersonnel: null,
        basis: {
          kz: null,
          ru: null,
          date: null,
        },
        practiceResultType: null,
      },
      options: {
        enterprises: [],
        enterprisePersonnel: [],
        practiceResultTypes: [],
      },
    };
  },
  computed: {
    personnel() {
      if (!this.form.enterprise) {
        return [];
      }
      return this.options.enterprisePersonnel
        .filter(el => el.enterprise_id === this.form.enterprise);
    },
    validate() {
      return ((this.practice.type === 'educational' && this.practice.place === 1)
        || (this.form.enterprise && this.form.enterprisePersonnel))
        && this.form.basis.kz && this.form.basis.ru
        && this.form.basis.date
        && this.form.practiceResultType
        && this.form.practiceResultType.length > 0;
    },
    resultTypes() {
      return this.practice.type === 'educational' && this.practice.place === 1
        ? this.options.practiceResultTypes.filter(it => it.id === 'exam')
        : this.options.practiceResultTypes.filter(it => it.id !== 'exam');
    },
  },
  async mounted() {
    this.$trans.add(['practice']);
    await this.loadRefs();
  },
  methods: {
    clearForm() {
      this.form.enterprisePersonnel = null;
    },
    async loadRefs() {
      await requestGetDataHelper(this, {
        url: this.uriGetRefs, variableName: 'options',
      });
    },
    async handleAjaxFormSubmit() {
      this.$globalLoading.show();
      const data = this.form;
      const res = await requestWrapper.call(this, {
        url: this.action,
        method: this.method,
        data,
      });

      if (!res.error) {
        this.$emit('onClose');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
