import {Helper} from "@common/src/helpers";
export default {
  name: 'VGraduatedContingentForm',
  props: {
    action: {
      type: String,
      required: true,
    },
    uriGetRefs: {
      type: String,
      required: true,
    },
    uriGetResource: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fields: {
        region_id: null,
        organization_id: null,
        year: null,
      },
      options: {
        regions: [],
        organizations: [],
      },
      contingentData: null,
    };
  },
  async mounted() {
    await requestGetDataHelper(this, { url: this.uriGetResource, variableName: 'contingentData'});
    _.forEach(this.contingentData, (attribute, key) => {
      if (this.fields.hasOwnProperty(key)) {
        this.fields[key] = attribute;
      }
    });
    await this.loadRefs();
  },
  computed: {
    validate() {
      return this.fields.region_id !== null && this.fields.organization_id && this.fields.year;
    },
  },
  methods: {
    async loadRefs() {
      await requestGetDataHelper(this, {
        url: this.uriGetRefs, variableName: 'options', params: this.fields, hardSet: true, fullRes: true,
      });
    },
    async handleAjaxFormSubmit() {
      this.$globalLoading.show();
      const data = this.fields;
      const res = await requestWrapper.call(this, {
        url: this.action,
        method: 'post',
        data,
      });
      if (!res.error) {
        this.$emit('onClose');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
