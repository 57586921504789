<template>
  <div
    class="s-chat-popup__message--block"
    :class="checkIsSelfMessage(source.user_id) ? 'self' : ''"
  >
    <div class="s-chat-popup__message">
      <div class="s-chat-popup__message--user">
        {{ source.name }}
      </div>
      <div class="s-chat-popup__message--text">
        {{ source.message }}
      </div>
    </div>
    <div class="s-chat-popup__message--time">
      {{ source.time }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'VChatMessage',
  props: {
    checkIsSelfMessage: {
      type: Function,
      required: true,
    },
    source: {
      type: Object,
      default: () => ({
        id: null,
        message: null,
        name: null,
        user_id: null,
        time: null,
      }),
    },
  },
};
</script>
