export default {
  questionId: state => state.questionId,
  questions: state => state.questions,
  method: state => state.method,
  questionGroup: state => state.questionGroup,
  currentQuestion: state => state.currentQuestion,
  questionnaireDetails: state => state.questionnaireDetails,
  loading: state => state.loading,
  paginatePage: state => state.paginatePage,
};
