import { StoreTranslator } from '@common/mixins';
import { scrollTop } from '@vjs/helpers';

export default {
  mixins: [StoreTranslator],
  props: {
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    refs: {
      type: Object,
      required: true,
    },
    urlSearchIin: {
      type: String,
      required: true,
    },
    urlBack: {
      type: String,
      required: false,
      default: null,
    },
    contingent: {
      type: [Array, Object],
      required: false,
    },
    hides: {
      type: [Array, Object],
      required: false,
      default: () => ([]),
    },
    role: {
      type: String,
      default: '',
    },
  },
  data() {
    const vm = this;
    const fields = {
      userId: {
        value: '',
        name: 'user[user_id]',
      },
      foreigner: {
        value: false,
        name: 'user[foreigner]',
        checkboxLabelTransKey: 'label.is_resident',
        component: 'boolean-field',
        events: {
          input: () => {
            if (vm.contingent === undefined) {
              if (vm.form.fields.foreigner.value) {
                vm.isSearch = false;
                vm.form.fields.iin.v_if = false;
                vm.form.fields.iin.required = false;
                vm.form.fields.document_number.v_if = true;
                vm.form.fields.document_number.required = true;
                vm.changeStatusValidIIN(false);
                vm.clearInputs();
              } else {
                vm.isSearch = true;
                vm.form.fields.iin.v_if = true;
                vm.form.fields.iin.required = true;
                vm.form.fields.document_number.v_if = false;
                vm.form.fields.document_number.required = false;
              }
            } else {
              vm.form.fields.foreigner.v_if = false;
              vm.form.fields.document_number.v_if = true;
              vm.form.fields.document_number.required = true;
              vm.form.fields.document_number.extraAttributes.disabled = true;
            }
          },
        },

      },
      citizenship_id: {
        beforeTitle: 'label.general_information',
        value: '',
        name: 'user[citizenship_id]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.citizenship',
        values: _.get(vm.refs, 'citizenships', []),
        required: true,
      },
      iin: {
        value: '',
        name: 'user[iin]',
        component: 'iin-field',
        labelTransKey: 'label.iin',
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      document_number: {
        v_if: false,
        value: '',
        name: 'user[document_number]',
        component: 'text-field',
        labelTransKey: 'label.identification_number',
        required: false,
        extraAttributes: {
          disabled: false,
        },
      },
      nationality_id: {
        value: '',
        name: 'user[nationality_id]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.nationality',
        values: _.get(vm.refs, 'nationalities', []),
        required: true,
      },
      migration_status: {
        value: '',
        name: 'user[migration_status]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.migration_status',
        values: _.get(vm.refs, 'migrationStatuses', []),
        required: true,
      },
      surname: {
        value: '',
        name: 'user[surname]',
        component: 'text-field',
        labelTransKey: 'label.surname',
        required: true,
        extraAttributes: {
          disabled: false,
        },

      },
      firstname: {
        value: '',
        name: 'user[firstname]',
        component: 'text-field',
        labelTransKey: 'label.firstname',
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      lastname: {
        value: '',
        name: 'user[lastname]',
        component: 'text-field',
        labelTransKey: 'label.middle_name',
        extraAttributes: {
          disabled: false,
        },
      },
      born_date: {
        value: '',
        name: 'user[born_date]',
        component: 'date-field',
        labelTransKey: 'label.born_date',
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      gender: {
        value: '',
        name: 'user[sex]',
        labelTransKey: 'label.gender',
        component: 'radio-field',
        values: [
          {
            labelTransKey: 'label.male',
            value: 'm',
          },
          {
            labelTransKey: 'label.female',
            value: 'w',
          },
        ],
        required: true,

      },
      email: {
        value: '',
        name: 'user[email]',
        component: 'text-field',
        label: 'E-mail',
        required: false,
      },
      phone: {
        value: '',
        name: 'user[phone]',
        component: 'phone-field',
        labelTransKey: 'label.phone',
        required: true,
      },
      region_id: {
        value: null,
        name: 'user[region_id]',
        component: 'v-multi-select-field',
        labelTransKey: 'cabinet.field_region',
        values: [],
        required: true,
        loadValues: {
          type: 'regions',
        },
        events: {
          change: () => {
            if (this.form.fields.region_id.value) {
              this.form.fields.province_id.loadValues.where.region_id = this.form.fields.region_id.value;
              this.form.fields.province_id.loadValues.reload();
              this.form.fields.province_id.v_show = true;
            } else {
              this.form.fields.province_id.v_show = false;
            }
          },
        },
      },
      province_id: {
        v_show: false,
        value: null,
        name: 'user[province_id]',
        component: 'v-multi-select-field',
        labelTransKey: 'cabinet.field_province',
        values: [],
        required: true,
        loadValues: {
          autoLoad: false,
          type: 'districts',
          where: {
            region_id: null,
          },
        },
        events: {
          change: () => {
            if (this.form.fields.province_id.value) {
              this.form.fields.city_id.loadValues.where.department_id = this.form.fields.province_id.value;
              this.form.fields.city_id.loadValues.reload();
              this.form.fields.city_id.v_show = true;
            } else {
              this.form.fields.city_id.loadValues.setEmpty();
              this.form.fields.city_id.v_show = false;
            }
          },
        },
      },
      city_id: {
        v_show: false,
        value: null,
        name: 'user[city_id]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.locality',
        values: [],
        required: true,
        loadValues: {
          autoLoad: false,
          type: 'localities',
          where: {
            department_id: null,
          },
        },
      },
      type_of_residence: {
        value: '',
        name: 'contingent[type_of_residence]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.type_of_residence',
        values: _.get(vm.refs, 'typeOfResidence', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      address: {
        value: '',
        name: 'user[address]',
        component: 'text-field',
        labelTransKey: 'label.address',
        required: true,
      },
      education_level_id: {
        value: '',
        name: 'contingent[education_level_id]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.education_level',
        values: _.get(vm.refs, 'educationLevel', []),
        required: true,
      },
      education_department_id: {
        beforeTitle: 'main.education_info',
        value: '',
        name: 'contingent[education_department_id]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.education_department',
        values: [],
        required: true,
        loadValues: {
          type: 'education-departments',
          headers: {
            'X-Permission': 'contingent-create',
          },
        },
        events: {
          change: () => {
            if (this.form.fields.education_department_id.value) {
              this.form.fields.course_number.loadValues.where = {};
              this.form.fields.course_number.loadValues.where.education_department_id = this.form.fields.education_department_id.value;
              this.form.fields.course_number.v_show = true;
              this.form.fields.course_number.loadValues.reload();
              this.form.fields.edu_form.loadValues.where = {};
              this.form.fields.group_id.loadValues.where = {};
              this.form.fields.education_language_id.loadValues.where = {};
            } else {
              this.form.fields.course_number.loadValues.where = {};
              this.form.fields.edu_form.loadValues.where = {};
              this.form.fields.group_id.loadValues.where = {};
              this.form.fields.education_language_id.loadValues.where = {};
              this.form.fields.course_number.loadValues.setEmpty();
              this.form.fields.course_number.v_show = false;
            }
          },
        },
      },
      course_number: {
        value: null,
        name: 'contingent[course_number]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.course',
        values: [],
        required: true,
        v_show: false,
        loadValues: {
          autoLoad: false,
          type: 'courses/by-group',
          where: {
            education_department_id: null,
          },
          headers: {
            'X-Permission': 'contingent-create',
          },
        },
        events: {
          change: () => {
            if (this.form.fields.course_number.value) {
              this.form.fields.education_language_id.loadValues.where = {};
              this.form.fields.education_language_id.loadValues.where = this.form.fields.course_number.loadValues.where;
              this.form.fields.education_language_id.loadValues.where.course_number = this.form.fields.course_number.value;
              this.form.fields.education_language_id.v_show = true;
              this.form.fields.education_language_id.loadValues.reload();
              this.form.fields.edu_form.loadValues.where = {};
              this.form.fields.group_id.loadValues.where = {};
            } else {
              this.form.fields.edu_form.loadValues.where = {};
              this.form.fields.group_id.loadValues.where = {};
              this.form.fields.education_language_id.loadValues.where = {};
              this.form.fields.education_language_id.loadValues.setEmpty();
              this.form.fields.education_language_id.v_show = false;
            }
          },
        },
        extraAttributes: {
          disabled: false,
        },
      },
      education_language_id: {
        value: null,
        name: 'contingent[education_language_id]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.edu_lang',
        values: [],
        required: true,
        v_show: false,
        loadValues: {
          autoLoad: false,
          type: 'education-languages/by-group',
          where: {
            education_department_id: null,
            course_number: null,
          },
          headers: {
            'X-Permission': 'contingent-create',
          },
        },
        events: {
          change: () => {
            if (this.form.fields.education_language_id.value) {
              this.form.fields.edu_form.loadValues.where = {};
              this.form.fields.edu_form.loadValues.where = this.form.fields.education_language_id.loadValues.where;
              this.form.fields.edu_form.loadValues.where.education_language_id = this.form.fields.education_language_id.value;
              this.form.fields.group_id.loadValues.where = {};
              this.form.fields.edu_form.v_show = true;
              this.form.fields.edu_form.loadValues.reload();
            } else {
              this.form.fields.group_id.loadValues.where = {};
              this.form.fields.edu_form.loadValues.where = {};
              this.form.fields.edu_form.loadValues.setEmpty();
              this.form.fields.edu_form.v_show = false;
            }
          },
        },
        extraAttributes: {
          disabled: false,
        },
      },
      edu_form: {
        value: null,
        name: 'contingent[edu_form]',
        component: 'v-multi-select-field',
        labelTransKey: 'main.edu_form',
        values: [],
        required: true,
        v_show: false,
        loadValues: {
          autoLoad: false,
          type: 'education-forms/by-group',
          where: {
            education_department_id: null,
            course_number: null,
            education_language_id: null,
          },
          headers: {
            'X-Permission': 'contingent-create',
          },
        },
        events: {
          change: () => {
            if (this.form.fields.edu_form.value) {
              this.form.fields.group_id.loadValues.where = {};
              this.form.fields.group_id.loadValues.where = this.form.fields.edu_form.loadValues.where;
              this.form.fields.group_id.loadValues.where.edu_form = this.form.fields.edu_form.value;
              this.form.fields.group_id.v_show = true;
              this.form.fields.group_id.loadValues.reload();
            } else {
              this.form.fields.group_id.loadValues.where = {};
              this.form.fields.group_id.loadValues.setEmpty();
              this.form.fields.group_id.v_show = false;
            }
          },
        },
        extraAttributes: {
          disabled: false,
        },
      },
      education_type: {
        value: null,
        name: 'contingent[education_type]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.type_of_training',
        values: [],
        required: true,
        v_show: true,
        loadValues: {
          autoLoad: true,
          type: 'education-types',
          headers: {
            'X-Permission': 'contingent-create',
          },
        },
      },
      group_id: {
        value: null,
        name: 'contingent[group_id]',
        component: 'v-multi-select-field',
        labelTransKey: 'main.group',
        values: [],
        required: true,
        v_show: false,
        loadValues: {
          autoLoad: false,
          type: 'groups/filters',
          where: {
            education_department_id: null,
            course_number: null,
            education_language_id: null,
            edu_form: null,
          },
          headers: {
            'X-Permission': 'contingent-create',
          },
        },
      },
      specialty_and_classifier: {
        value: null,
        name: 'contingent[specialty_and_classifier]',
        component: 'v-tree-select-field',
        labelTransKey: 'label.specialty_and_classifier',
        values: _.get(vm.refs, 'specialtyAndClassifier', []),
        required: false,
        events: {
          change: () => {
            if (this.form.fields.specialty_and_classifier.value && this.form.fields.specialty_and_classifier.value !== '-1') {
              this.form.fields.specialty_and_classifier_2.v_show = true;
            } else {
              this.form.fields.specialty_and_classifier_2.v_show = false;
            }
          },
        },
        extraAttributes: {
          disabled: false,
        },
      },
      specialty_and_classifier_2: {
        v_show: false,
        value: '',
        name: 'contingent[specialty_and_classifier_2]',
        component: 'v-tree-select-field',
        labelTransKey: 'label.specialty_and_classifier_2',
        values: _.get(vm.refs, 'specialtyAndClassifier2', []),
        required: false,
        events: {
          change: () => {
            if (this.form.fields.specialty_and_classifier_2.value && this.form.fields.specialty_and_classifier_2.value !== '-1') {
              this.form.fields.specialty_and_classifier_3.v_show = true;
            } else {
              this.form.fields.specialty_and_classifier_3.v_show = false;
            }
          },
        },
      },
      specialty_and_classifier_3: {
        v_show: false,
        value: '',
        name: 'contingent[specialty_and_classifier_3]',
        component: 'v-tree-select-field',
        labelTransKey: 'label.specialty_and_classifier_3',
        values: _.get(vm.refs, 'specialtyAndClassifier2', []),
        required: false,
      },
      employment: {
        value: '',
        name: 'contingent[employment]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.employment',
        values: _.get(vm.refs, 'employment', []),
        required: true,
      },
      funding_type: {
        value: null,
        name: 'contingent[funding_type]',
        component: 'v-tree-select-field',
        labelTransKey: 'label.funding_type',
        values: _.get(vm.refs, 'fundingType', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      // education_type: {
      //   value: '',
      //   name: 'contingent[education_type]',
      //   component: 'v-multi-select-field',
      //   labelTransKey: 'label.type_of_training',
      //   values: _.get(vm.refs, 'educationTypes', []),
      //   required: true,
      //   extraAttributes: {
      //   },
      // },
      type_enrollment: {
        value: '',
        name: 'contingent_nedb_info[type_enrollment]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.type_enrollment',
        values: _.get(vm.refs, 'enrollmentTypes', []),
        required: false,
        extraAttributes: {
        },
      },
      arrival_date: {
        value: '',
        name: 'contingent[arrival_date]',
        component: 'date-field',
        labelTransKey: 'label.arrival_date',
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      arrival_number: {
        value: '',
        name: 'contingent[arrival_number]',
        component: 'text-field',
        labelTransKey: 'label.arrival_number',
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      performance_card_number: {
        value: '',
        name: 'contingent[performance_card_number]',
        component: 'text-field',
        labelTransKey: 'label.performance_card_number',
        required: false,
      },
      name_book_number: {
        value: '',
        name: 'contingent[name_book_number]',
        component: 'text-field',
        labelTransKey: 'label.name_book_number',
        required: false,
      },
      arrived: {
        value: '',
        name: 'contingent[arrived]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.arrived',
        values: _.get(vm.refs, 'arrived', []),
        required: true,
      },
      training_period: {
        value: '',
        name: 'contingent[training_period]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.training_period',
        values: _.get(vm.refs, 'trainingPeriod', []),
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      left_second_course: {
        value: '',
        name: 'contingent[left_second_course]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.left_second_course',
        values: _.get(vm.refs, 'leftSecondCourse', []),
        required: true,
      },
      academic_leave: {
        value: '',
        name: 'contingent[academic_leave]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.academic_leave',
        values: _.get(vm.refs, 'academicLeave', []),
        required: true,
        events: {
          change: () => {
            if (this.form.fields.academic_leave.value === '04') {
              this.form.fields.academic_leave_date_of_order.v_show = false;
              this.form.fields.academic_leave_date_of_order.value = '';
            } else if (this.form.fields.academic_leave.value) {
              this.form.fields.academic_leave_date_of_order.v_show = true;
            } else {
              this.form.fields.academic_leave_date_of_order.value = '';
              this.form.fields.academic_leave_date_of_order.v_show = false;
            }
          },
        },
        extraAttributes: {
          disabled: false,
        },
      },
      academic_leave_date_of_order: {
        v_if(vm) {
          return vm.form.fields.academic_leave.value && vm.form.fields.academic_leave.value !== '04';
        },
        value: '',
        name: 'contingent[academic_leave_date_of_order]',
        component: 'date-field',
        labelTransKey: 'label.academic_leave_date_of_order',
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
      is_usage_active_monitoring: {
        v_if: true,
        beforeTitle: 'table.additional_info',
        value: true,
        name: 'contingent[is_usage_active_monitoring]',
        component: 'boolean-field',
        checkboxLabelTransKey: 'fields.is_usage_active_monitoring',
      },
      education_additional: {
        value: 1,
        name: 'contingent[education_additional]',
        component: 'v-multi-select-field',
        labelTransKey: 'label.education_additional',
        values: _.get(vm.refs, 'education_additional', []),
        required: true,
      },
    };
    let isSearch = true;
    if (vm.contingent !== undefined) {
      isSearch = false;
      _.forEach(vm.contingent, (value, key) => {
        if (fields.hasOwnProperty(key)) {
          fields[key].value = value;
        }
      });
    }
    if (vm.hides) {
      _.forEach(vm.hides, (attribute) => {
        if (fields.hasOwnProperty(attribute)) {
          _.set(fields, `${attribute}.extraAttributes.disabled`, true);
        }
      });
    }
    return {
      isSearch,
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
          // TODO привести к одному варианту
          if (data.redirect) {
            window.location = data.redirect;
          }
        },
      },
    };
  },
  beforeCreate() {
    this.$trans.add(['fields', 'warning_text', 'placeholder', 'label', 'button', 'main', 'notice', 'cabinet', 'curriculum', 'table']);
  },
  mounted() {
    const vm = this;
  },

  destroyed() {
  },
  methods: {
    changeStatusValidIIN(value) {
      const vm = this;

      if (value) {
        vm.form.fields.iin.extraAttributes.disabled = true;
        vm.form.fields.surname.extraAttributes.disabled = true;
        vm.form.fields.firstname.extraAttributes.disabled = true;
        vm.form.fields.lastname.extraAttributes.disabled = true;
        vm.form.fields.born_date.extraAttributes.disabled = true;
      } else {
        vm.form.fields.iin.extraAttributes.disabled = false;
        vm.form.fields.surname.extraAttributes.disabled = false;
        vm.form.fields.firstname.extraAttributes.disabled = false;
        vm.form.fields.lastname.extraAttributes.disabled = false;
        vm.form.fields.born_date.extraAttributes.disabled = false;
      }
    },
    clearInputs() {
      const vm = this;
      vm.form.fields.iin.value = '';
      vm.form.fields.surname.value = '';
      vm.form.fields.firstname.value = '';
      vm.form.fields.lastname.value = '';
      vm.form.fields.born_date.value = '';
      vm.form.fields.address.value = '';
    },
    manualCreate() {
      this.$set(this, 'isSearch', false);
    },
    backSearch() {
      if (this.urlBack) {
        window.location = this.urlBack;
      } else {
        this.$set(this, 'isSearch', true);
        this.changeStatusValidIIN(false);
      }
    },
    searchByIIN() {
      const vm = this;
      const { form } = vm;

      vm.$globalLoading.show();
      const config = {
        responseType: 'json',
        method: 'POST',
      };
      const { fields } = form;

      vm.$http.post(
        vm.urlSearchIin,
        {
          iin: fields.iin.value,
        },
        config,
      )
        .then(
          (response) => {
            vm.$globalLoading.hide();
            const { data } = response;
            if (data.user !== undefined) {
              vm.$set(vm, 'isSearch', false);
              vm.changeStatusValidIIN(true);
              _.forEach(response.data.user, (value, key) => {
                if (fields.hasOwnProperty(key)) {
                  vm.$set(fields[key], 'value', value);
                }
              });
              if (response.data.contingent) {
                _.forEach(response.data.contingent, (value, key) => {
                  if (fields.hasOwnProperty(key)) {
                    vm.$set(fields[key], 'value', value);
                    if (fields[key].value) {
                      fields[key].extraAttributes.disabled = true;
                    }
                  }
                });
              } else {
                show_notice(this.trans('warning_text.contingent_not_exists'), 'message');
              }
            }
          },
          (response) => {
            vm.$globalLoading.hide();
            if (response.status === 422 || response.status === 423) {
              let errors = [];
              _.forEach(response.data.errors, (value, key) => {
                errors = errors.concat(value);
              });
              show_notice(errors, 'error');
              vm.$set(form, 'errors', response.data.errors);
            } else {
              console.log(response);
            }
          },
        );
    },
    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleVShow(field) {
      const vm = this;
      if (field.v_show !== undefined) {
        if (_.isFunction(field.v_show)) {
          return field.v_show(vm);
        }
        return field.v_show;
      }

      return true;
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(form.fields, (el, key) => {
        _.set(data, vm.nameToDot(el.name), el.value);
      });
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      vm.$globalLoading.show();

      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            scrollTop(
              window,
              window.scrollY,
              window.scrollY - Math.abs(vm.$refs.formStart.getBoundingClientRect().top),
              500,
            );
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            if (errors.length > 5) {
              show_notice(vm.trans('notice.has_not_filled_fields'), 'error');
            } else {
              show_notice(errors, 'error');
            }
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
};
