import {eventBus, StoreTranslator} from '@common/mixins';
import { Helper } from '@common/src/helpers';

export default {
  mixins: [StoreTranslator],
  props: {
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    profile: {
      type: [Object, Array],
      required: false,
    },
    type: {
      type: Number,
      required: true,
    },
    isContingent: {
      type: Boolean,
      required: false,
      default: true,
    },
    isAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
    uriGetProfile: {
      type: String,
      required: true,
    },
  },
  data() {
    const vm = this;
    const fields = {
      search_iin: {
        v_show: vm.isAdmin,
        value: '',
        name: 'iin',
        component: 'search-iin-field',
        labelTransKey: 'label.iin',
        extraAttributes: {
          disabled: false,
        },
        events: {
          'on-search': vm.onClickSearch,
        },
      },
      type: {
        value: vm.type,
        name: 'statement[type]',
      },
      isContingent: {
        value: vm.isContingent,
        name: 'statement[isContingent]',
      },
      iin: {
        v_show: !vm.isAdmin,
        value: '',
        name: 'user[iin]',
        component: 'text-field',
        labelTransKey: 'label.iin',
        extraAttributes: {
          disabled: true,
        },
      },
      surname: {
        v_show: !vm.isAdmin,
        value: '',
        name: 'user[surname]',
        component: 'text-field',
        labelTransKey: 'label.surname',
        extraAttributes: {
          disabled: true,
        },
      },
      firstname: {
        v_show: !vm.isAdmin,
        value: '',
        name: 'user[firstname]',
        component: 'text-field',
        labelTransKey: 'label.firstname',
        extraAttributes: {
          disabled: true,
        },
      },
      lastname: {
        v_show: !vm.isAdmin,
        value: '',
        name: 'user[lastname]',
        component: 'text-field',
        labelTransKey: 'label.middle_name',
        extraAttributes: {
          disabled: true,
        },
      },
      born_date: {
        v_show: !vm.isAdmin,
        value: '',
        name: 'user[born_date]',
        component: 'date-field',
        labelTransKey: 'label.born_date',
        extraAttributes: {
          disabled: true,
        },
      },
      gender: {
        v_show: !vm.isAdmin,
        value: '',
        name: 'user[sex]',
        labelTransKey: 'label.gender',
        component: 'radio-field',
        extraAttributes: {
          disabled: true,
        },
        values: [
          {
            labelTransKey: 'label.male',
            value: 'm',
          },
          {
            labelTransKey: 'label.female',
            value: 'w',
          },
        ],
      },
      phone: {
        v_show: !vm.isAdmin,
        value: '',
        name: 'user[phone]',
        component: 'phone-field',
        labelTransKey: 'label.phone',
        extraAttributes: {
          disabled: true,
        },
      },

    };
    if (vm.type === 1) {
      fields.doc_type = {
        value: '',
        name: 'statement[doc_type]',
        component: 'v-select-field',
        labelTransKey: 'label.application_type',
        values: [],
        required: true,
        loadValues: {
          type: 'application-types',
        },
        events: {
          input: () => {
            if (this.form.fields.doc_type.value === 1) {
              this.form.fields.course.v_show = true;
              this.form.fields.profession_id.v_show = true;
              this.form.fields.period.configComponent.fieldFrom.value = null;
              this.form.fields.period.configComponent.fieldTo.value = null;
              this.form.fields.period.v_show = false;
            } else if (this.form.fields.doc_type.value === 0) {
              this.form.fields.course.value = '';
              this.form.fields.course.v_show = false;
              this.form.fields.profession_id.value = null;
              this.form.fields.profession_id.v_show = false;
              this.form.fields.period.v_show = true;
            }
          },
        },
      };
      fields.reason_leave = {
        value: '',
        name: 'statement[reason_leave]',
        component: 'v-select-field',
        labelTransKey: 'label.reason_leave',
        values: [],
        required: true,
        loadValues: {
          type: 'reasons-leave',
        },
      };
      fields.profession_id = {
        v_show: false,
        value: null,
        name: 'statement[profession_id]',
        component: 'v-select-field',
        labelTransKey: 'statement.specialty',
        values: [],
        required: true,
        loadValues: {
          type: 'professions',
        },
      };
      fields.course = {
        v_show: false,
        value: '',
        name: 'statement[course]',
        component: 'text-field',
        labelTransKey: 'label.course',
        required: true,
      };
      fields.period = {
        v_show: false,
        name: 'statement[period]',
        component: 'between-field',
        // value: '',
        labelTransKey: 'label.academe_period',
        required: true,
        configComponent: {
          component: 'date-field',
          fieldFrom: {
            name: 'date_start',
            value: null,
          },
          fieldTo: {
            name: 'date_end',
            value: null,
          },
        },
      };
    } else if (vm.type === 5) {
      fields.reason_transfer = {
        value: '',
        name: 'statement[reason_transfer]',
        component: 'v-select-field',
        labelTransKey: 'label.reason_transfer',
        values: [],
        required: true,
        loadValues: {
          type: 'reasons-transfer',
        },
      };
    } else if (vm.type === 4) {
      fields.duplicate_doc_type = {
        value: '',
        name: 'statement[duplicate_doc_type]',
        component: 'v-select-field',
        labelTransKey: 'label.duplicate_doc_type',
        values: [],
        required: true,
        loadValues: {
          type: 'duplicate-doc-type',
        },
      };
      fields.reasons_restore = {
        value: '',
        name: 'statement[reasons_restore]',
        component: 'v-select-field',
        labelTransKey: 'label.reasons_restore',
        values: [],
        required: true,
        loadValues: {
          type: 'reasons-restore',
        },
      };
      fields.leave_year = {
        value: '',
        name: 'statement[leave_year]',
        component: 'text-field',
        labelTransKey: 'label.graduated_year',
        required: true,
      };
    } else if (vm.type === 3) {
      fields.social_statuses = {
        value: '',
        name: 'statement[social_status]',
        component: 'v-select-field',
        labelTransKey: 'label.service_user_category',
        values: [],
        required: false,
        loadValues: {
          type: 'service-social-statuses',
        },
      };
      fields.is_asp = {
        value: false,
        name: 'statement[is_asp]',
        component: 'q-checkbox-field',
        checkboxLabelTransKey: 'label.is_address_help_receiver',
        required: false,
      };
    }
    if (vm.type === 2) {
      if (!vm.isContingent) {
        fields.profession_id = {
          value: '',
          name: 'statement[profession_id]',
          component: 'v-select-field',
          labelTransKey: 'statement.specialty',
          values: [],
          required: true,
          loadValues: {
            type: 'professions',
          },
        };
        fields.edu_form = {
          value: '',
          name: 'statement[edu_form]',
          component: 'v-select-field',
          labelTransKey: 'statement.edu_form',
          values: [],
          required: true,
          loadValues: {
            type: 'edu-forms',
          },
        };
        fields.course = {
          value: '',
          name: 'statement[course]',
          component: 'text-field',
          labelTransKey: 'label.course',
          required: true,
        };
        fields.group_name = {
          value: '',
          name: 'statement[group_name]',
          component: 'text-field',
          labelTransKey: 'label.group_name',
          required: true,
        };
        fields.enroll_year = {
          value: '',
          name: 'statement[enroll_year]',
          component: 'text-field',
          labelTransKey: 'label.application_year',
          required: true,
        };
        fields.leave_year = {
          value: '',
          name: 'statement[leave_year]',
          component: 'text-field',
          labelTransKey: 'label.leave_year',
          required: true,
        };
      }
      fields.reasons_dropout = {
        value: '',
        name: 'statement[reasons_dropout]',
        component: 'v-select-field',
        labelTransKey: 'label.reasons_dropout',
        values: [],
        required: true,
        loadValues: {
          type: 'reasons-dropout',
        },
      };
    }
    if (vm.profile !== undefined) {
      _.forEach(vm.profile, (value, key) => {
        if (fields.hasOwnProperty(key)) {
          fields[key].value = value;
        }
      });
    }
    return {
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
        },
      },
    };
  },
  beforeCreate() {
    this.$trans.add(['contingent', 'statement', 'warning_text', 'placeholder', 'label', 'button', 'main', 'notice']);
  },
  mounted() {
    const vm = this;
  },
  watch: {},
  computed: {
    validate() {
      return !this.isAdmin
        || (
          this.form.fields.search_iin.value
          && this.form.fields.iin.value
        );
    },
  },
  destroyed() {
  },
  methods: {
    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleVShow(field) {
      const vm = this;
      if (field.v_show !== undefined) {
        if (_.isFunction(field.v_show)) {
          return field.v_show(vm);
        }
        return field.v_show;
      }

      return true;
    },
    async onClickSearch(newVal) {
      if (this.form?.fields && this.form?.fields.search_iin.value) {
        await this.requestForCheckIin(newVal);
      }
    },
    async requestForCheckIin(iin) {
      if (!iin) {
        return;
      }
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.uriGetProfile,
        params: {
          iin,
        },
      });
      eventBus.$emit('all-change-loader', false);
      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      } else {
        _.forEach(res.data, (value, key) => {
          if (this.form.fields.hasOwnProperty(key)) {
            this.form.fields[key].value = value;
            if (key !== 'iin') {
              this.form.fields[key].v_show = true;
            }
            if (!value) {
              this.form.fields[key].extraAttributes.disabled = false;
            }
          }
        });
        this.form.fields.search_iin.extraAttributes.disabled = true;
      }
      this.$globalLoading.hide();
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(form.fields, (el, key) => {
        if (el.hasOwnProperty('fill')) {
          el.fill(data);
        } else if (el.hasOwnProperty('name')) {
          _.set(data, vm.nameToDot(el.name), el.value);
        }
      });
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
};
