import {
  QBtn,
} from '@quasar/components';

export default {
  name: 'VStepCongratulations',

  components: {
    QBtn,
  },

  props: {
    uriStatic: {
      type: String,
      default: '',
    },
    uriStatementList: {
      type: String,
      required: true,
    },
  },
};
