import { Helper } from '@common/src/helpers';

export default {
  makeQuestionUrl: (url, questionId) => Helper.urlGenerate(url, { questionId }),
  vm: null,
  questions: [],
  method: null,
  questionGroupId: null,
  questionId: null,
  questionApiConfig: {
    questionHide: '',
    questionUpdate: '',
    questionDelete: '',
    questionsHide: '',
    questionAdd: '',
    questionsGet: '',
  },
  processApiConfig: {
    loadData: '',
    storeAnswer: '',
    storeResult: '',
    getDetails: '',
    questionnaireStart: '',
  },
  loading: {
    common: true,
    answer: false,
    message: '',
  },
  questionGroup: null,
  currentQuestion: {},
  questionnaireDetails: null,
  paginatePage: null,
};
