import ImageViewer from '@common/components/ImageViewer';
import VoiceRecorder from '@common/components/VoiceRecorder';
import VInfoCard from '@common/components/VInfoCard';
import ImageModal from './ImageModal';
import AudioPlayer from './AudioPlayer';

export default function (Vue) {
  Vue.component('image-modal', ImageModal);
  Vue.component('vue-image-modal', ImageModal);
  Vue.component('vue-audio-player', AudioPlayer);
  Vue.component('image-viewer', ImageViewer);
  Vue.component('voice-recorder', VoiceRecorder);
  Vue.component('v-info-card', VInfoCard);
}
