import {
  QTable, QTr, QTh, QTd, QTooltip, QInput,
} from '@quasar/components';
import VNoResults from '@vjs/components/VNoResults';
import moment from 'moment';
import { requestWrapper } from '@vjs/helpers';
import VConfirmDialog from '@vjs/modals/VConfirmDialog.vue';
import { eventBus } from '@common/mixins';

export default {
  name: 'VJournalRemarks',

  components: {
    QTable,
    QTr,
    QTh,
    QTd,
    QTooltip,
    QInput,
    VNoResults,
    VConfirmDialog,
  },

  props: {},
  data() {
    return {
      modal: false,
      activeModalId: null,
      activeModalLink: null,
      isAddingNewRemark: false,
      newRemark: {
        description: null,
        id: null,
        teacher: null,
        creator: window.core_project.user_name,
        date: moment().format('D.MM.Y'),
        position: window.core_project.position,
      },
    };
  },

  methods: {
    openConfirmModal(row) {
      this.activeModalLink = row.deleteLink;
      if (!this.cans.changeRemarks) {
        this.activeModalLink = row.viewLink;
      }
      this.activeModalId = row.id;

      this.$sModal.open('v-modal-confirm', {
        title: this.trans('notice.are_you_sure'),
        component: 'v-modal-confirm',
        notPersistent: true,
        events: { onApprove: this.handleConfirmModal },
      });
    },
    async handleConfirmModal() {
      this.$globalLoading.show();

      const config = {
        url: this.activeModalLink,
        method: !this.cans.changeRemarks ? 'post' : 'delete',
      };

      const res = await requestWrapper.call(this, config);

      if (!res.error) {
        if (!this.cans.changeRemarks) {
          this.$store.commit('VIEW_REMARK', this.activeModalId);
        } else {
          this.$store.commit('DELETE_REMARK', this.activeModalId);
        }

        eventBus.$emit('onCloseAll');
        this.activeModalLink = null;
        this.activeModalId = null;
      }

      this.$globalLoading.hide();
    },
    async handleCreateRemark() {
      this.$globalLoading.show();

      const { apiConfig, makeJournalUrl, journalId } = this.$store.getters;
      const res = await requestWrapper.call(this, {
        url: makeJournalUrl(apiConfig.remarks.store, journalId),
        method: 'post',
        params: {
          teacher: this.newRemark.teacher,
          description: this.newRemark.description,
        },
      });

      if (!res.error && res?.data?.id) {
        this.newRemark = { ...this.newRemark, ...res.data };
        this.$store.commit('ADD_NEW_REMARK', _.cloneDeep(this.newRemark));

        this.newRemark.description = null;
        this.newRemark.teacher = null;
        this.newRemark.id = null;
        this.isAddingNewRemark = false;
      }

      this.$globalLoading.hide();
    },
    handleCancelCreation() {
      this.newRemark.description = null;
      this.newRemark.teacher = null;
      this.isAddingNewRemark = false;
    },
  },

  computed: {
    validate() {
      return this.newRemark.description != null && this.newRemark.teacher != null;
    },
    cans() { return this.$store.getters.cans; },
    rows() { return this.$store.getters.remarks; },
    teachers() { return this.$store.getters.journalTeachers; },
    cols() {
      return [
        {
          label: this.trans('label.create_date'),
          name: 'date',
          field: 'date',
          align: 'center',
          width: 170,
          sortable: false,
        },
        {
          label: this.trans('label.teacher'),
          name: 'teacher',
          field: 'teacher',
          align: 'center',
          width: 250,
          sortable: false,
        },
        {
          label: this.trans('table.remark_description'),
          name: 'description',
          field: 'description',
          align: 'center',
          width: 600,
          sortable: false,
        },
        {
          label: this.trans('table.creator'),
          name: 'creator',
          field: 'creator',
          align: 'center',
          width: 200,
          sortable: false,
        },
        {
          label: this.trans('table.position'),
          name: 'position',
          field: 'position',
          align: 'center',
          width: 200,
          sortable: false,
        },
        {
          label: this.trans('table.operations'),
          name: 'actions',
          field: 'actions',
          align: 'center',
          width: 75,
          sortable: false,
        },
      ];
    },
  },
};
