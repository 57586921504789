// import {eventBus} from '@common/mixins';
import moment from 'moment';
import VNoResults from '@vjs/components/VNoResults';
import VTimeSheetTable from './components/VTimeSheetTable';
import VTimeSheetFilter from './components/VTimeSheetFilter';
import VTimeSheetTitle from './components/VTimeSheetTitle';
import VTimeSheetLegend from './components/VTimeSheetLegend';

export default {
  name: 'VTimeSheetView',
  components: {
    VTimeSheetTable,
    VTimeSheetFilter,
    VTimeSheetTitle,
    VNoResults,
    VTimeSheetLegend,
  },
  props: {
    apiConfig: {
      type: Object,
      default: () => ({}),
    },
    dataConfig: {
      type: Object,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    isWorker: {
      type: Boolean,
      default: false,
    },
    groupId: {
      type: Number,
      required: true,
    },
    groupName: {
      type: String,
      required: '',
    },
    back: {
      type: String,
      required: '',
    },
    uriUserFiles: {
      type: String,
      required: true,
    },
    isExportable: {
      type: Boolean,
      default: false,
    },
    educatorName: {
      type: String,
      required: '',
    },
  },
  data() {
    const {
      dataConfig: {
        reasons, allowEditAll,
      },
    } = this;
    return {
      allowEditAll,
      reasonsList: reasons,
      reloading: false,
      loading: false,
      timeSheetLoad: false,
      currentDay: moment().format('DD/MM/Y'),
    };
  },
  async mounted() {
    this.$globalLoading.show();
    await this.$trans.add([
      'label',
      'button',
      'table',
      'select',
      'funding',
      'placeholder',
      'attendance',
      'main',
      'warning_text',
    ]);
    this.$store.commit('SET_ATTENDANCE_VM', this);
    this.$store.commit('SET_ATTENDANCE_GROUP_ID', this.groupId);
    this.$store.commit('SET_ATTENDANCE_API_CONFIG', this.apiConfig);
    this.$store.commit('SET_MARK_TYPES_ARR', this.dataConfig?.markTypes);
    this.$store.commit('SET_BACK_LINK', this.back);
    this.$store.commit('SET_ALLOW_EDIT', this.allowEditAll);
    await this.initTimeSheet();
    this.$refs.timeSheet.handleSetScrollWidth();
    this.$refs.timeSheet.scrollTableToToday();
    this.$globalLoading.hide();
  },
  computed: {
    attendance() {
      return this.$store.getters.attendance;
    },
  },
  methods: {
    async initTimeSheet() {
      await this.$store.dispatch('GET_TIMESHEET');
      if (this.attendance.length > 0) {
        this.$refs.timeSheet.reCalcSizes();
      }
    },
  },
};
