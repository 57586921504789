import {
  QForm,
  QBtn,
  QSelect,
  QSpinnerGears,
  QInput,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import Validation
from '@vjs/mixins/Validation';
import _ from 'lodash';
import VNoResults from '@vjs/components/VNoResults';
import VFormInform from '@vjs/components/VFormInform/VFormInform';

export default {
  name: 'VDiplomaForm',

  mixins: [Validation],
  components: {
    QForm,
    QBtn,
    QSelect,
    QInput,
    QSpinnerGears,
    VNoResults,
    VFormInform,
  },
  props: {
    uriAction: {
      type: String,
      required: true,
    },
    uriGetGroups: {
      type: String,
      required: true,
    },
    uriGetQualifications: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: {
        qualifications: [],
        qualificationsFiltered: [],
        evaluationTypes: [],
      },
      qualification: null,
      evaluationType: null,
      groups: [],
      groupsSelected: [],
      loading: false,
      loadingGroups: false,
    };
  },

  computed: {
    validate() {
      return this.groupsSelected.length !== 0
        && this.evaluationType !== null;
    },
  },

  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'placeholder',
      'journal',
      'select',
      'main',
      'warning_text',
      'curriculum',
    ]);

    this.evaluationType = {
      value: null,
      label: this.trans('select.placeholder'),
    };

    this.options.evaluationTypes = [
      {
        value: '0',
        label: this.trans('journal.type_of_evaluation.number'),
      },
      {
        value: '1',
        label: this.trans('journal.type_of_evaluation.percent'),
      },
    ];

    await this.requestGetQualifications();
  },
  methods: {
    // Поиск по селекту квалификаций
    filterQualifications(val, update) {
      if (val === '') {
        update(() => {
          this.options.qualifications = this.options.qualificationsFiltered;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.options.qualifications = this.options.qualificationsFiltered.filter(item => _.lowerCase(item.label).indexOf(needle) > -1);
      });
    },
    // добавление группы в список выбранных групп
    addGroup(key) {
      this.groupsSelected.push(this.groups[key]);
    },
    // удаление группы из списка выбранных групп
    deleteGroup(key) {
      this.groupsSelected.splice(key, 1);
    },

    // Запрос на получение опций квалификаций
    async requestGetQualifications() {
      const res = await requestWrapper.call(this, {
        url: this.uriGetQualifications,
        params: {
          quasar: true,
        },
      });

      if (!res.error) {
        this.options.qualifications = res.data;
        this.options.qualificationsFiltered = res.data;
      }
    },

    // запрос на получение опций групп
    async requestGetGroups() {
      this.groups = [];
      const res = await requestWrapper.call(this, {
        url: this.uriGetGroups,
        params: {
          quasar: true,
          qualification_id: this.qualification,
        },
      });

      if (!res.error) {
        this.groups = res.data;
      }
    },
    // запрос на отправку данных для сохранения
    async requestAction() {
      this.loading = true;
      const groups = [];
      for (const el of this.groupsSelected) {
        groups.push(el.value);
      }
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriAction,
        data: {
          groups,
          type_of_evaluation: this.evaluationType,
        },
      });

      if (res.error && res.data.errors) {
        this.$notify({
          text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
          type: 'error',
        });
      }
      this.loading = false;
    },
    // обработка текста в списке групп при не выбранной квалификации или отсутствии групп
    groupsListLabel() {
      if (!this.qualification) {
        return this.trans('placeholder.please_select_qualification');
      }
      return this.trans('placeholder.qualification_without_groups');
    },
  },
  watch: {
    // обработка выбранных групп, чтобы не было возможности выбрать уже выбранные
    groups(newValue) {
      for (let idx = 0; idx < newValue.length; idx += 1) {
        for (const el of this.groupsSelected) {
          if (this.groups[idx].value === el.value) {
            const temp = this.groups[idx];
            this.groups[idx] = { ...temp, disable: true };
          }
        }
      }
    },
    groupsSelected(newValue) {
      for (let idx = 0; idx < this.groups.length; idx += 1) {
        const temp = this.groups[idx];
        this.groups[idx] = { ...temp, disable: false };
        for (const el of newValue) {
          if (this.groups[idx].value === el.value) {
            this.groups[idx] = { ...temp, disable: true };
          }
        }
      }
    },
  },
};
