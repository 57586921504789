import {
  QForm,
  QBtn,
  QSelect,
  QInput,
  QCheckbox,
  QIcon,
  QPopupProxy,
  QDate,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import Validation
from '@vjs/mixins/Validation';
import _ from 'lodash';
import VFormInform from '@vjs/components/VFormInform/VFormInform';
import { mask } from 'vue-the-mask';

export default {
  name: 'VDiplomaContingentForm',

  directives: {
    mask,
  },

  mixins: [Validation],
  components: {
    QForm,
    QBtn,
    QSelect,
    QInput,
    QCheckbox,
    VFormInform,
    QIcon,
    QPopupProxy,
    QDate,
  },
  props: {
    uriAction: {
      type: String,
      required: true,
    },
    uriGetPersonnel: {
      type: String,
      required: true,
    },
    uriGetData: {
      type: String,
      required: true,
    },
    evaluationType: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      options: {
        personnel: [],
        personnelFiltered: [],
      },
      form: {
        theme_ru: '',
        theme_kz: '',
        personnelSelected: null,
        manualPersonnel: false,
        personnelNote: '',
        personnelName: '',
        protectionDate: '',
        setResult: false,
        result: '',
      },
      info: {
        student: '',
        group: '',
      },
      loading: false,
    };
  },

  computed: {
    validate() {
      return (this.form.personnelName !== '' || this.form.personnelSelected !== null)
        && this.form.protectionDate !== ''
        && this.form.theme_ru !== ''
        && this.form.theme_kz !== ''
        && (
          this.form.setResult === false
          || (this.form.setResult && this.form.result !== '')
        );
    },
  },

  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'placeholder',
      'select',
      'warning_text',
      'curriculum',
      'personnel_dicts',
      'table',
      'fields',
    ]);

    await this.requestGetPersonnel();
    await this.requestGetData();
  },
  methods: {
    // запрос на получение данных
    async requestGetData() {
      this.loading = true;
      const res = await requestWrapper.call(this, {
        url: this.uriGetData,
      });

      if (!res.error && res.data) {
        if (res.data.personnelId === null && res.data.personnelName !== null) {
          this.form.manualPersonnel = true;
        }
        this.info.group = res.data.group !== null ? res.data.group : '';
        this.info.student = res.data.student !== null ? res.data.student : '';
        this.form.personnelSelected = res.data.personnelId !== null ? res.data.personnelId : null;
        this.form.personnelName = res.data.personnelName !== null ? res.data.personnelName : '';
        this.form.personnelNote = res.data.personnelNote !== null ? res.data.personnelNote : '';
        this.form.protectionDate = res.data.protectionDate !== null ? res.data.protectionDate : '';
        this.form.theme_ru = res.data.theme_ru !== null ? res.data.theme_ru : '';
        this.form.theme_kz = res.data.theme_kz !== null ? res.data.theme_kz : '';
        if (res.data.result !== null) {
          this.form.setResult = true;
          this.form.result = res.data.result;
        }
      }
      this.loading = false;
    },
    // Метод для сброса персонала при смене состояния чекбокса
    manualPersonnelChange() {
      this.form.personnelName = '';
      this.form.personnelNote = '';
      this.form.personnelSelected = null;
    },

    // Поиск по селекту персонала
    filterPersonnel(val, update) {
      if (val === '') {
        update(() => {
          this.options.personnel = this.options.personnelFiltered;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.options.personnel = this.options.personnelFiltered.filter(item => _.lowerCase(item.label).indexOf(needle) > -1);
      });
    },

    // запрос на получение опций персонала
    async requestGetPersonnel() {
      const res = await requestWrapper.call(this, {
        url: this.uriGetPersonnel,
        params: {
          quasar: true,
        },
      });

      if (!res.error) {
        this.options.personnel = res.data;
        this.options.personnelFiltered = this.options.personnel;
      }
    },

    // запрос на отправку данных для сохранения
    async requestAction() {
      this.loading = true;

      let requestData = {
        theme_ru: this.form.theme_ru,
        theme_kz: this.form.theme_kz,
        protection_date: this.form.protectionDate,
        set_result: this.form.setResult,
      };

      if (this.form.setResult === true) {
        requestData = {
          ...requestData,
          result: this.form.result,
        };
      }

      if (this.form.manualPersonnel) {
        requestData = {
          ...requestData,
          personnel_name: this.form.personnelName,
        };
        if (this.form.personnelNote !== '') {
          requestData = {
            ...requestData,
            personnel_note: this.form.personnelNote,
          };
        }
      } else {
        requestData = {
          ...requestData,
          personnel_id: this.form.personnelSelected,
        };
      }

      const res = await requestWrapper.call(this, {
        method: 'patch',
        url: this.uriAction,
        data: requestData,
      });

      if (res.error && res.data.errors) {
        this.$notify({
          text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
          type: 'error',
        });
      }
      this.loading = false;
    },
  },
};
