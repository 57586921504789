import { mapGetters } from 'vuex';
import _ from 'lodash';
import checkQuestionMapMixin from './mixins/checkQuestionMap';
import VTimer from './VTimer.vue';
import VSubjectList from './VSubjectList.vue';
import VTestStatus from './VTestStatus.vue';
import VTestQuestion from './VTestQuestion.vue';
import VTestNavigation from './VTestNavigation.vue';
import VTestQuestionMap from './VTestQuestionMap.vue';
import VTestResult from './VTestResult.vue';
import VTestMenu from './VTestMenu.vue';
import endOfTest from './mixins/endOfTest';
import store from './store';
import activateMathJax from './helpers/activateMathJax';

export default {
  store,
  name: 'VTestProcess',
  mixins: [checkQuestionMapMixin, endOfTest],
  props: {
    userName: {
      type: String,
      default: '',
    },
    avatar: {
      type: String,
      default: '',
    },
    isContingent: {
      type: Boolean,
      default: true,
    },
    urlLoadData: {
      type: String,
      default: '',
    },
    urlLoadTime: {
      type: String,
      default: '',
    },
    withoutTimer: {
      type: Boolean,
      default: false,
    },
    urlStoreAnswer: {
      type: String,
      default: '',
    },
    urlStoreResult: {
      type: String,
      default: '',
    },
  },
  components: {
    VTimer,
    VSubjectList,
    VTestStatus,
    VTestQuestion,
    VTestNavigation,
    VTestQuestionMap,
    VTestResult,
    VTestMenu,
  },

  data() {
    return {
      getTimeInterval: null,
    };
  },
  mounted() {
    this.activateMathJax();
  },
  updated() {
    this.activateMathJax();
  },
  async created() {
    this.$trans.add(['main', 'test', 'label', 'warning_text']);
    this.$store.commit('SET_ENDPOINTS', {
      loadData: this.urlLoadData,
      loadTime: this.urlLoadTime,
      storeAnswer: this.urlStoreAnswer,
      storeResult: this.urlStoreResult,
    });
    // Запрос тестирования
    await this.$store.dispatch('GET_TEST');

    if (!this.withoutTimer) {
      await this.checkTimeEnd();
    }

    if (this.TEST.status === 'end') {
      this.showResult = true;
    }
    if (this.TEST.status === 'begin' || this.TEST.status === 'wait') {
      window.onbeforeunload = (e) => {
        const dialogText = 'ВНИМАНИЕ! Тест ещё не закончен - вы уверены, что хотите покинуть страницу?';
        e.returnValue = dialogText;
        return dialogText;
      };
    }

    // Если все ответы отправлены но есть время, поставить статус "в ожидании"
    if (this.LEFT_ANSWERS === 0 && this.TEST.testTimeLeft > 0 && this.TEST.status !== 'end') {
      this.$store.commit('SET_TEST_STATUS', 'wait');
    } else if (!this.withoutTimer && this.TEST.testTimeLeft <= 0) {
      // Если время закончилось, то прерываем тест
      this.$store.commit('SET_TEST_STATUS', 'end');
      // console.log('break test...');
    }

    // console.time('localStorage');
    // Попытка восстановить работу с места до обновления страницы
    const curSubjectID = parseInt(localStorage.getItem('curSubjectID'), 10);

    // Если находим нужный предмет по сохранённому id, то делаем его текущим выбранным
    if (curSubjectID && _.find(this.SUBJECTS, o => o.id === curSubjectID)) {
      this.$store.commit('SET_CURRENT_SUBJECT', curSubjectID);
      const curQuestionID = parseInt(localStorage.getItem('curQuestionID'), 10);
      const curQuestionIdx = parseInt(localStorage.getItem('curQuestionIdx'), 10);

      // Если находим сохранённый вопрос, то делаем его текущим выбранным
      if (curQuestionID
        && curQuestionIdx
        && _.find(this.CURRENT_SUBJECT.questions, o => o.id === curQuestionID)) {
        this.$store.commit('SET_CURRENT_QUESTION', { id: curQuestionID, idx: curQuestionIdx });
        this.checkQuestionMap();
      } else {
        localStorage.removeItem('curQuestionIdx');
        localStorage.removeItem('curQuestionID');
      }
    } else {
      // Иначе удаляем все ключи из localStorage и ставим первый попавшийся предмет
      localStorage.removeItem('curSubjectID');
      localStorage.removeItem('curQuestionIdx');
      localStorage.removeItem('curQuestionID');

      if (this.SUBJECTS.length > 0) {
        this.$store.commit('SET_CURRENT_SUBJECT', this.SUBJECTS[0].id);
      }
    }

    // запуск таймера с интервалом в 15sec для получения данных, если платформа юзера АЙОС;
    if (!this.withoutTimer && (this.TEST.status === 'begin' || this.TEST.status === 'wait')) {
      this.getTimeInterval = setInterval(async () => {
        this.$store.dispatch('GET_TIME');
        await this.checkTimeEnd();
      }, 15000);
    }
    // console.timeEnd('localStorage');
  },

  methods: {
    async checkTimeEnd() {
      // Если страница была закрыта, но время истекло - отправляем запрос на сохранение данных при входе на страницу
      // Или если время истекло по проверке через интервал, то завершаем тест и интервал

      if (this.TEST.timeSpent === undefined && this.TEST.testTimeLeft === 0) {
        await this.endOfFinishedTest();
        if (this.getTimeInterval !== null) {
          clearInterval(this.getTimeInterval);
        }
      }
    },
    activateMathJax,
    getOS() {
      const { userAgent } = window.navigator;
      const { platform } = window.navigator;
      const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
      const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
      const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
      let os = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
      } else if (/Android/.test(userAgent)) {
        os = 'Android';
      } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
      }

      return os;
    },
  },

  computed: {
    ...mapGetters([
      'LOADING',
      'TEST',
      'SUBJECTS',
      'CURRENT_SUBJECT',
      'LEFT_ANSWERS',
    ]),
    loadingMessage() {
      return this.$store.state.loading.message;
    },
    showResult: {
      get() {
        return this.TEST.status === 'end' && this.TEST.timeSpent !== undefined;
      },
      set(isShow) {
        return isShow;
      },
    },
  },
};
