<template>
  <div>
    <v-wrapper-q-select
      v-model="form.curriculum_working_group_id"
      :options="refs.curriculumWorkingGroupDisciplines"
      :extra-binds="{
        label: trans('curriculum.curriculum_working_groups'),
        required: true,
      }"
    />
    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :label="trans('button.approve')"
        @click="approve"
      />
    </div>
  </div>
</template>

<script>
import { Helper } from '@common/src/helpers';

export default {
  props: {
    action: {
      type: String,
      default: '',
    },
    uriGetRefs: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {
        curriculum_working_group_id: null,
      },
      refs: {
        curriculumWorkingGroupDisciplines: [],
      },
    };
  },
  async mounted() {
    await this.$trans.add('curriculum');
    await requestGetDataHelper(this, { url: this.uriGetRefs, variableName: 'refs', fullRes: true });
  },
  methods: {
    async approve() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.action,
        method: 'delete',
        data: this.form,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
  },
};
</script>
