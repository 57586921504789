import {
  create,
  percent,
  Container,
  NumberFormatter,
} from '@amcharts/amcharts4/core';
import {
  ColumnSeries,
  CategoryAxis,
  ValueAxis,
  XYChart,
} from '@amcharts/amcharts4/charts';
import QSelect from '@quasar/components/select/QSelect';
import { charts } from '../../mixins';

export default {
  name: 'VPopulationPyramid',
  components: { QSelect },
  mixins: [charts],
  props: {
    select: String,
    selectLabel: String,
    templateStrings: {
      type: Object,
      default: () => ({
        first: '',
        second: '',
      }),
    },
    transformDataArray: {
      type: Function,
      default: data => data,
    },
    axios: {
      type: Object,
      default: () => ({
        Y: '',
        firstX: '',
        secondX: '',
      }),
    },
  },
  data() {
    return {
      options: [],
      selectedValue: {},
      container: null,
      charts: {
        first: null,
        second: null,
      },
    };
  },
  async mounted() {
    if (this.$q.screen.xs) {
      this.show = false;
      return;
    }

    let data = [];

    if (this.select) {
      this.options = await this.request(this.select);
      this.selectedValue = this.options[0];

      if (Array.isArray(this.options)
        && this.options.length === 0) {
        this.show = false;
        return;
      }

      data = await this.request(this.uri, { organization_id: this.selectedValue.id }, true);
    } else {
      data = await this.request(this.uri, null, true);
    }

    if (Array.isArray(data)
      && data.length === 0) {
      this.show = false;
      return;
    }

    this.container = create(this.$refs.populationPyramidChart, Container);
    this.container.width = percent(100);
    this.container.height = percent(100);
    this.container.layout = 'horizontal';
    this.charts.first = await this.createChart(data, this.axios.Y, this.axios.firstX, this.templateStrings.first);
    this.charts.second = await this.createChart(data, this.axios.Y, this.axios.secondX, this.templateStrings.second, 'right');
  },
  methods: {
    async createChart(data, Y, X, tooltipText, pos = 'left') {
      const chart = this.container.createChild(XYChart);
      chart.data = data;

      const categoryAxis = chart.yAxes.push(new CategoryAxis());
      categoryAxis.dataFields.category = Y;
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 15;

      const valueAxis = chart.xAxes.push(new ValueAxis());
      valueAxis.min = 0;
      valueAxis.max = 40;
      valueAxis.strictMinMax = true;
      valueAxis.numberFormatter = new NumberFormatter();
      valueAxis.numberFormatter.numberFormat = "#.#'%'";

      const series = chart.series.push(new ColumnSeries());
      series.dataFields.valueX = X;
      series.dataFields.valueXShow = 'percent';
      series.calculatePercent = true;
      series.dataFields.categoryY = Y;
      series.interpolationDuration = 1000;
      series.columns.template.tooltipText = tooltipText;

      if (pos === 'left') {
        chart.paddingRight = 0;
        valueAxis.renderer.inversed = true;
      } else if (pos === 'right') {
        chart.paddingLeft = 0;
        categoryAxis.renderer.opposite = true;
        valueAxis.renderer.minLabelPosition = 0.01;
        series.fill = chart.colors.getIndex(4);
        series.stroke = series.fill;
      }

      return chart;
    },
    async handleChangeSelect(val) {
      const data = await this.request(this.uri, { organization_id: val.id }, true);
      this.charts.first.data = data;
      this.charts.second.data = data;
    },
  },
};
