<template>
  <div
    class="s-passport-page__panel mb-30"
  >
    <div class="s-passport-page__name mb-30">
      {{ post.name }}
    </div>
    <div class="row-sort-between row-sort--center s-blog__info s-medium mb-30">
      <span class="s-group">
        <span>{{ post.published }}</span>
      </span>
      <span class="s-str-ico s-str-ico--thm-eye views-count">
        {{ post.count_views }}
      </span>
    </div>
    <p
      class="s-plain-text"
      v-html="post.short_body"
    />
    <div class="row-sort-between row-sort--center">
      <div class="s-group">
        <a
          :href="post.url"
          class="s-blog__post-link s-c-blue"
        >
          {{ trans('main.read_full') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VPassportPagePostItem',

  props: {
    post: {
      type: Object,
      required: true,
    },
  },
};
</script>
