<template>
  <td
    v-if="!['chapter_name'].includes(column.attribute)"
    :data-table-head="column.label"
    class="sc-curriculum-program__table--td"
    :style="{'width': lesson[column.attribute] ? lesson[column.attribute].width : 'auto'}"
  >
    <template v-if="column.attribute === 'actions'">
      <control-buttons
        v-if="!isArchived"
        :lesson="lesson"
      />
    </template>
    <template v-else-if="isEdit.includes(lesson.lessons_id.value) && lesson[column.attribute].canEdit">
      <q-input
        v-if="lesson[column.attribute].type === 'number'"
        v-model="lesson[column.attribute].value"
        bg-color="white"
        mask="#.#"
        fill-mask="0"
        reverse-fill-mask
        filled
        dense
      />
      <q-input
        v-else
        v-model="lesson[column.attribute].value"
        bg-color="white"
        filled
        dense
      />
    </template>
    <template v-else>
      <span v-if="!column.withTags">
        {{ getAttribute(lesson, column.attribute) }}
      </span>
      <span
        v-else
        v-html="getAttribute(lesson, column.attribute)"
      />
    </template>
  </td>
</template>

<script>
import { eventBus } from '@common/mixins';
import { QInput } from '@quasar/components';
import helper from '../../../mixins/helper';
import ControlButtons from '../Buttons/ControlButtons';

export default {
  name: 'TableData',
  components: {
    ControlButtons,
    QInput,
  },
  mixins: [helper, eventBus],
  props: {
    column: {
      type: Object,
      default: null,
    },
    lesson: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isEdit: [],
    };
  },
  computed: {
    isArchived() {
      return this.$store.getters.isArchived;
    },
  },
  mounted() {
    eventBus.$on('add-edit', this.updateEdit);
  },
  methods: {
    updateEdit(id) {
      if (!this.isEdit.includes(id)) {
        this.isEdit.push(id);
      } else {
        this.isEdit = this.isEdit.filter(el => el !== id);
      }
    },
  },
};
</script>
