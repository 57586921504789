import {
  QBtn,
  QTooltip,
  QField,
} from '@quasar/components';
import VConfirmDialog from '@vjs/modals/VConfirmDialog.vue';
import {
  requestWrapper,
} from '@vjs/helpers';
import _ from 'lodash';
import VApplicationDocsModal from './VApplicationDocsModal.vue';

export default {
  name: 'VApplicationServiceItem',

  components: {
    QBtn,
    QTooltip,
    QField,
    VConfirmDialog,
    VApplicationDocsModal,
  },

  props: {
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    identifier: {
      type: Number,
      required: true,
    },
    identifierDate: {
      type: String,
      required: true,
    },
    locked: {
      type: Object,
      default: () => ({}),
    },
    docsBtn: {
      type: Object,
      default: () => ({}),
    },
    isNotInterested: Boolean,
    canRemove: Boolean,
    desc: {
      type: Array,
      default: () => ([]),
    },
    descAdditional: {
      type: Array,
      default: () => ([]),
    },
    uriRemove: {
      type: String,
      required: true,
    },
    steps: {
      type: Array,
      default: () => ([]),
    },
  },

  data() {
    return {
      modalConfirmRemove: false,
      loadingRemove: false,
      stepsTrigger: false,
      loading: false,
      docsModal: false,
    };
  },

  methods: {
    getIconByStatus(status = '') {
      switch (status) {
        case 'done': return 'check-circle';
        case 'waiting': return 'waiting';
        case 'inactive': return 'clock';
        case 'failed': return 'na';
        default: return '';
      }
    },
    async removeApplication() {
      this.loadingRemove = true;
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriRemove,
      });
      this.loadingRemove = false;

      if (res.error) {
        if (res.data.errors && res.errorType === 422) {
          this.$notify({
            text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
            type: 'error',
          });
        }
        this.modalConfirmRemove = false;
        return;
      }

      if (!res.error) {
        if (res.alert && res.alert.message) {
          this.$notify({
            text: res.alert.message,
            type: 'success',
          });
        }
        this.modalConfirmRemove = false;
        this.$emit('removed', this.identifier, this.type);
      }
    },
  },
};
