const timeFormatValue = val => ((val !== '' && val < 10) ? `0${val}` : val.toString());

function timeConvert(timestamp, format = { hours: false, minutes: true, seconds: true }) {
  const hours = Math.floor((timestamp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timestamp % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timestamp % (1000 * 60)) / 1000);

  return {
    hours: format.hours ? timeFormatValue(hours) : hours,
    minutes: format.minutes ? timeFormatValue(minutes) : minutes,
    seconds: format.seconds ? timeFormatValue(seconds) : seconds,
  };
}

export default timeConvert;
