import { QForm, QInput, QField } from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';

export default {
  name: 'StatementAttestationAveragePointModal',
  components: { QForm, QInput, QField },
  props: {
    uriSubmit: String,
    isForEgov: Boolean,
    signXml: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {
        pointFive: null,
        pointFour: null,
        pointThree: null,
      },
    };
  },

  async mounted() {
    await this.$trans.add(['label', 'button']);
  },

  computed: {
    validate() {
      const { pointFive, pointFour, pointThree } = this.form;
      const validateValues = ['', null];
      return !validateValues.includes(pointFive)
        && !validateValues.includes(pointFour)
        && !validateValues.includes(pointThree);
    },
    averagePoint() {
      const pointFive = Number(this.form.pointFive);
      const pointFour = Number(this.form.pointFour);
      const pointThree = Number(this.form.pointThree);
      const summary = ((pointFive * 5) + (pointFour * 4) + (pointThree * 3));
      const pointsCount = (pointFive + pointFour + pointThree);
      const result = (summary / pointsCount) || 0;

      return Number(result).toFixed(2);
    },
  },

  methods: {
    async handleAjaxFormSubmit() {
      if (this.isForEgov) {
        return this.$emit('confirmSubmit', this.averagePoint);
      }
      this.$globalLoading.show();

      const data = { ...this.form, averagePoint: this.averagePoint, sign: this.signXml };

      const res = await requestWrapper.call(this, {
        url: this.uriSubmit,
        method: 'post',
        data,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
  },
};
