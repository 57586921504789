<template>
  <div>
    <v-wrapper-q-select
      v-model="form.parent_id"
      :options="parentsOptions"
      :extra-binds="{
        label: trans('fields.parent_discipline_id'),
        placeholder: trans('placeholder.select_from_list'),
        hideBottomSpace: true,
        required: true,
      }"
    />
    <v-wrapper-q-select
      v-model="form.education_result_id"
      :options="educationResultsOptions"
      :extra-binds="{
        label: trans('fields.curriculum_discipline_id'),
        placeholder: trans('placeholder.select_from_list'),
        hideBottomSpace: true,
        required: true,
      }"
    />

    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :label="trans('button.approve')"
        @click="approve"
      />
    </div>
  </div>
</template>

<script>
import { Helper } from '@common/src/helpers';

export default {
  props: {
    parentsOptions: {
      type: Array,
      required: true,
    },
    educationResultsOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      form: {
        parent_id: null,
        education_result_id: null,
      },
    };
  },
  methods: {
    async approve() {
      if (!this.form.parent_id || !this.form.education_result_id) {
        const err = {
          errorType: 422,
          data: {
            errors: {
              parent_id: this.trans('warning_text.contingent_not_exists'),
            },
          },
        };
        Helper.handlerResponseErrorNew(this, err);
      } else {
        this.$emit('getEduResult', this.form);
        this.$emit('onClose');
      }
    },
  },
};
</script>
