import { StoreTranslator } from '@common/mixins';


export default {
  mixins: [StoreTranslator],
  props: {
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    urlBack: {
      type: String,
      required: false,
      default: null,
    },
    resource: {
      type: [Array, Object],
      required: false,
    },
    hides: {
      type: [Array, Object],
      required: false,
      default: () => ([]),
    },
    extraConfigsFields: {
      type: [Array, Object],
      required: false,
    },
    evaluationType: {
      type: Number,
      required: true,
    },
    hasResult: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    const vm = this;
    const fields = {
      result: {
        v_if(vm) {
          return vm.hasResult;
        },
        value: '',
        name: 'result',
        component: 'number-field',
        labelTransKey: 'fields.point',
        required: true,
        config: {
          mask: '9',
          repeat: vm.evaluationType === 0 ? 1 : 3,
        },
        extraAttributes: {
          disabled: false,
        },
      },
      body_result: {
        value: '',
        name: 'body_result',
        component: 'ckeditor-field',
        labelTransKey: 'fields.body_result',
        required: true,
        extraAttributes: {
          disabled: false,
        },
      },
    };

    if (vm.resource !== undefined) {
      _.forEach(vm.resource, (value, key) => {
        if (fields.hasOwnProperty(key)) {
          fields[key].value = value;
        }
      });
    }
    if (vm.hides) {
      _.forEach(vm.hides, (attribute) => {
        if (fields.hasOwnProperty(attribute)) {
          _.set(fields, `${attribute}.extraAttributes.disabled`, true);
        }
      });
    }
    if (vm.extraConfigsFields) {
      _.forEach(vm.extraConfigsFields, (configs, attribute) => {
        if (fields.hasOwnProperty(attribute)) {
          const field = fields[attribute];
          _.set(fields, attribute, _.assignIn(field, configs));
        }
      });
    }
    return {
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
          // TODO привести к одному варианту
          if (data.redirect) {
            window.location = data.redirect;
          }
        },
      },
    };
  },
  beforeCreate() {
    this.$trans.add([
      'fields',
      'warning_text',
      'placeholder',
      'label',
      'button',
      'main',
      'notice',
      'cabinet',
      'curriculum',
      'personnel_dicts',
      'training_schedule',
      'menu',
      'journal',
    ]);
  },
  mounted() {
    const vm = this;
  },

  destroyed() {
  },
  methods: {
    evaluationTypeText() {
      const text = this.trans('main.type_of_evaluation');
      switch (this.evaluationType) {
        case 0: return `${text}: ${this.trans('journal.type_of_evaluation.number')}`;
        case 1: return `${text}: ${this.trans('journal.type_of_evaluation.percent')}`;
        case 2: return `${text}: ${this.trans('journal.type_of_evaluation.credit')}`;
        default: return '';
      }
    },
    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleVShow(field) {
      const vm = this;
      if (field.v_show !== undefined) {
        if (_.isFunction(field.v_show)) {
          return field.v_show(vm);
        }
        return field.v_show;
      }

      return true;
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      let data = {};
      _.forEach(form.fields, (el, key) => {
        if (!el.name) {
          return;
        }
        _.set(data, vm.nameToDot(el.name), el.value);
      });
      data = { ...data, evaluationType: vm.evaluationType, hasResult: vm.hasResult };
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      vm.$globalLoading.show();

      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      if (!fieldName) {
        return [];
      }
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
};
