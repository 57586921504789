import {requestWrapper} from "@vjs/helpers";
import {Helper} from "@common/src/helpers";

export default {
  name: 'VContingentPersonalInfo',
  props: {
    uriGetItems: {
      type: String,
      default: null,
    },
    uriGetOptions: {
      type: String,
      default: null,
    },
    uriUserFiles: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      items: [],
      columns: [],
      hideActions: true,
    };
  },
  async mounted() {
    this.$trans.add([
      'label',
      'button',
      'table',
    ]);
    await this.loadData();
  },
  methods: {
    triggerModal() {
      this.$sModal.open('v-select-file-by-element-modal', {
        component: 'v-select-file-by-element-modal',
        notPersistent: true,
        binds: {
          uriGetOptions: this.uriGetOptions,
          uriUserFiles: this.uriUserFiles,
          selectLabel: this.trans('table.doc_type'),
          action: this.action,
          method: 'post',
        },
      });
    },
    async loadData() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.uriGetItems,
      });
      if (!res.error) {
        this.items = res.data;
        this.columns = res.meta.columns;
      } else {
        Helper.handlerResponseError({ data: res.data, status: res.errorType });
      }
      this.$globalLoading.hide();
    },
    getAttribute(item, attribute) {
      let val = '';
      if (attribute.includes('*')) {
        const pathes = attribute.split('.');
        let values = _.cloneDeep(item);
        _.each(pathes, (path) => {
          if (path === '*') {
            return;
          } if (_.isArray(values)) {
            values = _.map(values, subValue => (subValue && subValue.hasOwnProperty(path) ? subValue[path] : null));
          } else {
            values = _.get(values, path);
          }
          values = _.filter(values, o => o !== null);
        });
        if (_.isArray(values)) {
          val = values.join(', ');
        } else {
          val = values;
        }
      } else {
        val = _.get(item, attribute, '');
      }
      return val;
    },
  },
};
