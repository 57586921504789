<template>
  <div class="sc-questionnaire__navigation">
    <div
      class="sc-questionnaire__navigation--wrapper"
      @click="prev"
    >
      <q-icon
        class="sc-questionnaire__navigation--icon"
        name="long-arrow-left"
        icon-class="s-ico-fa"
      />
    </div>
    <div class="sc-questionnaire__navigation--counter">
      {{ `${(questionGroup.curQuestionIdx + 1)}/${questionGroup.questions.length}` }}
    </div>
    <div
      class="sc-questionnaire__navigation--wrapper"
      @click="next"
    >
      <q-icon
        class="sc-questionnaire__navigation--icon"
        name="long-arrow-right"
        icon-class="s-ico-fa"
      />
    </div>
  </div>
</template>

<script>
import {
  QIcon,
} from '@quasar/components';
import { mapGetters } from 'vuex';

export default {
  name: 'VTestNavigation',
  components: {
    QIcon,
  },
  computed: {
    ...mapGetters([
      'currentQuestion',
      'questions',
      'questionGroup',
    ]),
  },
  methods: {
    prev() {
      this.$store.commit('PREV_QUESTION');
    },
    next() {
      this.$store.commit('NEXT_QUESTION');
    },
  },
};
</script>
