import {
  QTable,
  QBtn,
} from '@quasar/components';
import VNoResults from '@vjs/components/VNoResults';
import {
  requestWrapper,
} from '@vjs/helpers';

export default {
  name: 'VJournalFillingActivity',
  components: {
    VNoResults,
    QTable,
    QBtn,
  },
  props: {
    urlSemesters: String,
    urlTeachersActivityList: String,
    urlExport: String,
  },
  data() {
    return {
      loading: false,
      filters: {
        semester: null,
      },
      pagination: {
        rowsPerPage: 20,
      },
      options: {
        semesters: [],
      },
      table: {
        rows: [],
        columns: [],
      },
    };
  },
  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'warning_text',
      'export',
    ]);
    await this.requestGetOptions();
  },
  methods: {
    async requestGetOptions() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.urlSemesters,
      });
      if (!res.error) {
        this.options.semesters = res.data;
      }
      this.$globalLoading.hide();
    },
    async requestGetTeachersActivityList() {
      this.$globalLoading.show();
      this.loading = true;
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.urlTeachersActivityList,
        params: this.filters,
      });
      if (!res.error) {
        this.table = res;
      }
      this.$globalLoading.hide();
      this.loading = false;
    },
    exportTable() {
      this.$globalLoading.show();
      const config = {
        responseType: 'json',
        method: 'GET',
        params: this.filters,
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };

      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      const request = this.$http.get(this.urlExport, config);

      request.then(
        (response) => {
          show_notice(this.trans('notice.export_start_success'), 'notice');
          this.$globalLoading.hide();
        },
        (response) => {
          console.log(response);
        },
      );
    },
  },
};
