import { requestWrapper } from '@vjs/helpers';
import { QSelect } from '@quasar/components';
import VCard from './components/VCard';
import VDoubleTable from './components/VDoubleTable';
import VTable from './components/VTable';
import VTitle from './components/VTitle';
import VDiploma from './components/VDiploma';

export default {
  components: {
    VCard,
    VDoubleTable,
    VTable,
    VTitle,
    VDiploma,
    QSelect,
  },
  props: {
    apiConfig: {
      type: Object,
      default: () => ({
        title: '',
        changePhoto: '',
        exams: '',
        credits: '',
        labCourseWorks: '',
        practices: '',
        finalExam: '',
        qualExam: '',

        export: '',
        diploma: '',
        academicYears: '',
      }),
    },
    canEditPhoto: Boolean,
  },

  data() {
    return {
      activeTab: 'title',
      prevTab: 'title',
      filters: {
        academicYear: null,
      },
      options: {
        academicYears: [],
      },
      tabs: [
        'title',
        'exams',
        'credits',
        'labCourseWorks',
        'practices',
        'diploma',
        'finalExam',
        'qualExam',
      ],
      forms: {
        title: {},
        exams: {},
        credits: {},
        laboratory: {},
        course: {},
        finalExam: {},
        qualExam: {},
        diploma: [],
      },
    };
  },

  computed: {
    hasFilters() {
      return !['title', 'diploma'].includes(this.activeTab);
    },
    swipeDirection() {
      const prevTabIndex = this.tabs.indexOf(this.prevTab);
      const activeTabIndex = this.tabs.indexOf(this.activeTab);
      return activeTabIndex > prevTabIndex ? 'swipe-left' : 'swipe-right';
    },
    activeComponent() {
      switch (this.activeTab) {
        case 'title':
          return 'v-title';
        case 'labCourseWorks':
          return 'v-double-table';
        case 'diploma':
          return 'v-diploma';
        default:
          return 'v-table';
      }
    },
    activeComponentEvents() {
      switch (this.activeTab) {
        case 'title': return {
          'photo-changed': async () => { await this.requestGetData('title', true, true); },
        };
        default: return {};
      }
    },
    activeComponentProps() {
      switch (this.activeTab) {
        case 'title':
          return {
            info: this.forms.title,
            uriExport: this.apiConfig.export,
            uriChangePhoto: this.apiConfig.changePhoto,
            canEditPhoto: this.canEditPhoto,
          };
        case 'labCourseWorks':
          return {
            firstCols: this.forms.course.columns,
            firstRows: this.forms.course.rows,
            firstTitle: this.trans('contingent-performance-book.courses-table-title'),
            firstAdaptiveWhen: 768,

            secondCols: this.forms.laboratory.columns,
            secondRows: this.forms.laboratory.rows,
            secondTitle: this.trans('contingent-performance-book.labs-table-title'),
            secondAdaptiveWhen: 768,
          };
        case 'diploma':
          return {
            data: this.forms.diploma,
          };
        default:
        case 'exams':
          return {
            cols: this.forms?.[this.activeTab].columns,
            rows: this.forms?.[this.activeTab].rows,
            adaptiveWhen: 840,
          };
      }
    },
  },

  async mounted() {
    await this.$trans.add(['contingent-performance-book', 'notice', 'label']);
    await this.requestGetData('title', true);
    await this.requestGetData('academicYears', false, false, true);
    await this.requestGetData('diploma', false, true);
  },
  methods: {
    async handleTab(tab) {
      if (this.activeTab !== tab) {
        this.prevTab = this.activeTab;
        if (!['title', 'diploma'].includes(tab)) {
          await this.requestGetData(tab, true, true);
        }
        this.activeTab = tab;
      }
    },
    async requestGetData(
      type = this.activeTab,
      enableLoading = false,
      disableLoading = false,
      isOptions = false,
    ) {
      if (enableLoading) {
        this.$globalLoading.show();
      }
      const res = await requestWrapper.call(this, {
        url: this.apiConfig[type],
        params: this.filters,
      });
      if (!res.error) {
        if (isOptions) {
          this.options = { ...this.options, ...res };
        } else {
          this.forms = { ...this.forms, ...res };
        }
      } else {
        this.$notify({
          text: this.trans('notice.error_on_server'),
          type: 'error',
        });
      }
      if (disableLoading) {
        this.$globalLoading.hide();
      }
    },
  },
};
