import { charts } from '../../mixins';

export default {
  name: 'VRelatedPieCharts',
  mixins: [charts],
  props: {
    uriFirst: {
      type: String,
      default: '',
    },
    titleFirst: {
      type: String,
      default: '',
    },
    titleSecond: {
      type: String,
      default: '',
    },
    uriSecond: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pieChartLocked: '',
      charts: {
        pieChartFirst: {},
        pieChartSecond: {},
      },
    };
  },
  computed: {
    pieChartFirstLegend() {
      return this.getPieLegend(this.charts.pieChartFirst);
    },
    pieChartSecondLegend() {
      return this.getPieLegend(this.charts.pieChartSecond);
    },
  },
  async mounted() {
    if (this.$q.screen.xs) {
      this.show = false;
      return;
    }

    const dataFirst = await this.request(this.uriFirst);
    const dataSecond = await this.request(this.uriSecond);

    this.noData = !(this.checkData(dataFirst) && this.checkData(dataSecond));

    if (!this.noData && this.show) {
      this.charts.pieChartFirst = this.createPieChart(this.$refs.pieChartFirst, dataFirst, this.innerRadius);
      this.charts.pieChartFirst.pieSeries.slices.template.events.on('hit', this.handlePieChart('pieChartFirst'));

      this.charts.pieChartSecond = this.createPieChart(this.$refs.pieChartSecond, dataSecond, this.innerRadius);
      this.charts.pieChartSecond.pieSeries.slices.template.events.on('hit', this.handlePieChart('pieChartSecond'));
    }
  },
  beforeDestroy() {
    if (this.charts.pieChartFirst) {
      this.charts.pieChartFirst.chart.dispose();
    }

    if (this.charts.pieChartSecond) {
      this.charts.pieChartSecond.chart.dispose();
    }
  },

  methods: {
    handlePieChart(pieChartName) {
      return async (e) => {
        this.resetActiveSlice(pieChartName, e);
        await this.loadingPieChart(pieChartName, e.target.dataItem.dataContext.id);
      };
    },
    async loadingPieChart(pieChartName, id) {
      let isActive = false;
      this.charts[pieChartName].pieSeries.slices.values.forEach((item) => {
        if (item.isActive) {
          isActive = item.isActive;
        }
      });

      if (pieChartName === 'pieChartFirst') {
        await this.controlPieChartsActions(
          isActive,
          'pieChartSecond',
          this.uriSecond,
          {
            organization_id: id,
          },
        );
      } else {
        await this.controlPieChartsActions(
          isActive,
          'pieChartFirst',
          this.uriFirst,
          {
            gov_service_type: id,
          },
        );
      }
    },
    async controlPieChartsActions(isActive, pieChartName, uri, params) {
      if (isActive) {
        this.pieChartLocked = pieChartName;
        this.charts[pieChartName].pieSeries.slices.template.clickable = false;
        this.charts[pieChartName].chart.data = await this.request(
          uri,
          params,
        );
      } else {
        this.pieChartLocked = '';
        this.charts[pieChartName].pieSeries.slices.template.clickable = true;
        this.charts[pieChartName].chart.data = this.charts[pieChartName].data;
      }
    },
    resetActiveSlice(pieChartName, eventTarget) {
      if (this.charts[pieChartName]) {
        this.charts[pieChartName].pieSeries.slices.values.forEach((item) => {
          if (item.dataItem.dataContext.id !== eventTarget.target.dataItem.dataContext.id) {
            item.isActive = false;
          }
        });
      }
    },
    handleLegendClick(pieChartName, legend) {
      if (this.charts[pieChartName] && this.pieChartLocked !== pieChartName) {
        this.charts[pieChartName].pieSeries.slices.values.forEach((item) => {
          if (item.dataItem.dataContext.id === legend.id) {
            item.dispatchImmediately('hit');
          }
        });
      }
    },
  },
};
