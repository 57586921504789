import { Helper } from '@common/src/helpers';
import { QIcon, QPagination } from '@quasar/components';
import MXListView from '@vjs/mixins/MXListView';
import { scrollTop } from '@vjs/helpers';

export default {
  props: {
    loadUri: {
      type: String,
      default: '',
    },
  },
  components: {
    QIcon,
    QPagination,
  },
  mixins: [MXListView],
  data() {
    return {
      enterprises: [],
      total: 0,
      pagination: {
        links: [],
        currentPage: 1,
      },
    };
  },
  computed: {
    // Отдаёт кол-во очереди на страницу
    paginate() {
      const current = (this.currentPage - 1) * this.countByPage;
      return this.enterprises.slice(current, current + this.countByPage);
    },
    // Считает кол-во страниц в пагинации по выставленным в data параметрам
    countOfPages() {
      let count = parseInt(this.enterprises.length / this.countByPage, 10);
      const remains = this.enterprises.length % this.countByPage;
      count += remains !== 0 ? 1 : 0;
      this.currentPage = 1;
      return count <= 1 ? 0 : count;
    },
  },
  async mounted() {
    await this.$trans.add([
      'warning_text',
    ]);
    await this.loadData();
  },
  methods: {
    // Запускается на событие @input в q-pagination
    async changePagination() {
      // Перевод страницы к началу при выборе страницы
      scrollTop(
        window,
        window.scrollY,
        window.scrollY - Math.abs(this.$refs.catalog.getBoundingClientRect().top),
        500,
      );
    },
    async loadData() {
      const vm = this;
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.loadUri,
      });
      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      } else {
        vm.$set(vm, 'enterprises', res.data);
      }
      this.$globalLoading.hide();
    },
  },
};
