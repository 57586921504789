import NewTable from '@common/views/NewTableWithFilters/NewTable';

export default {
  components: { NewTable },
  props: {
    rows: { type: Array, default: () => [] },
    cols: {
      type: Array,
      default: () => ([]),
    },
    adaptiveWhen: { type: Number, default: 1024 },
    binds: {
      type: Object,
      default: {},
    },
    cans: {
      type: Object,
      default: {},
    },
    status: {
      type: String,
      default: '',
    },
    grade: {
      type: Number,
      default: null,
    },
  },
};
