import _ from 'lodash';
import {
  QSpinnerGears,
  QTooltip,
  QInput,
  QForm,
  QBtn,
} from '@quasar/components';
import { ClosePopup } from '@quasar/directives';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { requestWrapper } from '@vjs/helpers';
import eventHub from '@vjs/config/eventHub';
import VConfirmDialog from '@vjs/modals/VConfirmDialog.vue';

export default {
  name: 'VExamTheme',
  components: {
    QSpinnerGears,
    QTooltip,
    QInput,
    QForm,
    QBtn,
    VConfirmDialog,
  },
  directives: {
    ClosePopup,
  },
  props: {
    themeProp: {
      type: Object,
      required: true,
    },
    uriEditTheme: {
      type: String,
      required: true,
    },
    uriDestroyTheme: {
      type: String,
      required: true,
    },
    loading: Boolean,
  },
  data() {
    return {
      storeData: this.$store,
      themeLoading: false,
      themeEditId: 0,
      themeDestroyId: 0,
      dataTheme: {},
      dataThemeEdit: {},
      modalConfirmThemeRemove: false,
      editor: ClassicEditor,
      editorConfig: {
        language: 'ru',
        toolbar: {
          items: [
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'imageUpload',
            'blockQuote',
            'insertTable',
            'undo',
            'redo',
          ],
        },
      },
      UploadAdapter: function UploadAdapter(loader, store) {
        this.upload = () => loader.file
          .then(file => new Promise((resolve) => {
            const formData = new FormData();
            _.each(loader, (item, index) => {
              formData.append(index, item);
            });
            store.dispatch('FILE_THEME', { payload: formData, file, resolve });
          }));
        this.abort = () => {
          console.log('Abort upload.');
        };
      },
    };
  },
  async mounted() {
    await this.setData();
    eventHub.$on('closeEdit', (evt) => {
      if (evt !== this.themeEditId) {
        this.cancelEdit();
      }
    });
  },
  methods: {
    onEditorReady(instance) {
      const fileRepository = instance.plugins.get('FileRepository');

      if (fileRepository != null) {
        fileRepository.createUploadAdapter = loader => new this.UploadAdapter(
          loader, this.storeData,
        );
      }
    },
    setData() {
      this.dataTheme = _.cloneDeep(this.themeProp);
    },
    openDestroyModal(id) {
      eventHub.$emit('cancelAdding', true);
      this.modalConfirmThemeRemove = true;
      this.themeDestroyId = id;
    },
    changeTheme(id) {
      eventHub.$emit('cancelAdding', true);
      eventHub.$emit('closeEdit', id);
      this.themeEditId = 0;
      this.dataThemeEdit = _.cloneDeep(this.dataTheme);
      this.themeEditId = id;
    },
    cancelEdit() {
      this.themeEditId = 0;
      this.themeDestroyId = 0;
      this.modalConfirmThemeRemove = false;
      this.dataThemeEdit = {};
      this.setData();
    },
    async requestDestroyTheme() {
      this.themeLoading = true;
      const res = await requestWrapper.call(this, {
        url: this.uriDestroyTheme,
        method: 'delete',
        data: {
          exam_theme_id: this.themeDestroyId,
        },
      }, true);

      if (!res.error) {
        if (res.data && res.data.message) {
          this.$notify({
            text: res.data.message,
            type: 'success',
          });
        }
        eventHub.$emit('deletedTheme', true);
        this.cancelEdit();
      }
      if (res.error && res.data.errors) {
        this.$notify({
          text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
          type: 'error',
        });
      }
      this.themeLoading = false;
    },
    async requestEditTheme() {
      this.themeLoading = true;
      const res = await requestWrapper.call(this, {
        url: this.uriEditTheme,
        method: 'patch',
        data: {
          exam_theme_id: this.dataTheme.id,
          name: this.dataTheme.name,
          body: this.dataTheme.body,
        },
      }, true);

      if (!res.error) {
        if (res.data && res.data.message) {
          this.$notify({
            text: res.data.message,
            type: 'success',
          });
        }
        eventHub.$emit('editedTheme', true);
        this.cancelEdit();
      }
      if (res.error && res.data.errors) {
        this.$notify({
          text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
          type: 'error',
        });
      }
      this.themeLoading = false;
    },
  },
};
