import { StoreTranslator } from '@common/mixins';
export default {
  name: 'AdditionalInfoForm',
  mixins: [StoreTranslator],
  props: {
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    organizationId: {
      type: Number,
      required: true,
    },
    sex: {
      type: String,
      required: false,
    },
  },
  data() {
    const vm = this;
    const fields = {
      dormitory: {
        value: null,
        name: 'dormitory[dormitory]',
        component: 'q-select-field',
        labelTransKey: 'dormitory.apply.name',
        values: [],
        required: false,
        loadValues: {
          type: 'dormitories',
          where: {
            organizationId: vm.organizationId,
          },
        },
        events: {
          change: () => {
            if (vm.form.fields.dormitory.value) {
              vm.form.fields.floor.loadValues.where.dormitoryId = vm.form.fields.dormitory.value;
              vm.form.fields.floor.loadValues.reload();
            }
          },
        },
      },
      floor: {
        value: null,
        name: 'dormitory[floor]',
        component: 'q-select-field',
        labelTransKey: 'dormitory.apply.floor',
        values: [],
        required: false,
        loadValues: {
          autoLoad: false,
          type: 'dormitory-floors',
          where: {
            dormitoryId: null,
          },
        },
        events: {
          change: () => {
            if (vm.form.fields.floor.value) {
              vm.form.fields.room.loadValues.where.dormitoryId = vm.form.fields.dormitory.value;
              vm.form.fields.room.loadValues.where.floorId = vm.form.fields.floor.value;
              vm.form.fields.room.loadValues.reload();
            }
          },
        },
      },
      room: {
        value: null,
        name: 'dormitory[room]',
        component: 'q-select-field',
        labelTransKey: 'dormitory.apply.room',
        values: [],
        required: false,
        loadValues: {
          autoLoad: false,
          type: 'dormitory-rooms',
          where: {
            dormitoryId: null,
            floorId: null,
            sex: vm.sex,
          },
        },
      },
    };
    return {
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
        },
      },
    };
  },
  beforeCreate() {
    this.$trans.add(['dormitory', 'statement', 'warning_text', 'placeholder', 'label', 'button', 'main', 'notice']);
  },
  mounted() {
    const vm = this;
  },
  watch: {},
  destroyed() {
  },
  methods: {
    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleVShow(field) {
      const vm = this;
      if (field.v_show !== undefined) {
        if (_.isFunction(field.v_show)) {
          return field.v_show(vm);
        }
        return field.v_show;
      }

      return true;
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(form.fields, (el, key) => {
        if (el.hasOwnProperty('fill')) {
          el.fill(data);
        } else if (el.hasOwnProperty('name')) {
          _.set(data, vm.nameToDot(el.name), el.value);
        }
      });
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
};
