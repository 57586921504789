<template>
  <div class="mt-50 s-w-100 sc-journal__legend">
    <div
      @click="spoiler = !spoiler"
    >
      <div class="sc-journal__legend--title">
        <div
          class="sc-journal__legend--label s-upper"
        >
          {{ trans('label.colors_title') }}
        </div>
        <div class="s-combine-title__line">
          <div
            class="sc-journal__legend--button"
          >
            <span
              class="s-ico"
              :class="`s-ico--thm-angle-double-${spoiler ? 'up' : 'down'}`"
            />
            <span class="sc-journal__legend--button-text">
              {{ trans(`button.${spoiler ? 'hide_spoiler' : 'show_spoiler'}`) }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <q-slide-transition>
      <div v-if="spoiler">
        <table>
          <thead>
            <tr>
              <th
                v-for="(itm, key) in computedColorsArr[0]"
                :key="key"
              />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(colorGroup, groupKey) in computedColorsArr"
              :key="groupKey"
            >
              <td
                v-for="(color, key) in colorGroup"
                :key="`${groupKey}-${key}`"
              >
                <div class="row no-gutters sc-journal__legend--cell">
                  <div class="col-2">
                    <div
                      class="sc-journal__legend--color-squire"
                      style="margin-right: 1rem;"
                      :style="`background-color: ${color.name}`"
                    />
                  </div>
                  <div class="col-10">
                    <span>
                      {{ color.label }}
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </q-slide-transition>
  </div>
</template>

<script>
import { QSlideTransition } from '@quasar/components';

export default {
  name: 'VJournalLegend',
  components: { QSlideTransition },
  data: () => ({ spoiler: false }),
  computed: {
    colorsArr() { return this.$store.getters.colorsArr; },
    computedColorsArr() {
      if (this.$q.screen.width < 661) {
        return _.chunk(this.colorsArr, 1);
      }
      if (this.$q.screen.width < 948) {
        return _.chunk(this.colorsArr, 2);
      }
      if (this.$q.screen.width < 1248) {
        return _.chunk(this.colorsArr, 3);
      }
      return _.chunk(this.colorsArr, 4);
    },
  },
};
</script>
