import VFormInform from '@vjs/components/VFormInform';
import { requestWrapper } from '@vjs/helpers';

export default {
  components: { VFormInform },
  props: {
    uriAction: String,
    uriTemplate: String,
  },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    async handleAction() {
      this.$globalLoading.show();
      const data = new FormData();
      data.append('file', this.file);

      const res = await requestWrapper.call(this, {
        method: 'POST',
        url: this.uriAction,
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      this.$globalLoading.hide();
      if (res.error) {
        this.loading.hide();
        if (res.data.errors && res.errorType === 422) {
          this.$notify({
            text: res.data.errors[Object.keys(res.data.errors)[0]][0],
            type: 'error',
          });
        }
      }
      this.$emit('onClose');
    },

    clearFile() {
      this.file = null;
    },
    fileChange() {
      const file = this.$refs.fileInput.files[0];
      if (file) {
        this.file = file;
      }
      this.$refs.fileInput.value = null;
    },
    triggerFile() {
      this.$refs.fileInput.click();
    },
  },

  computed: {
    validate() {
      return !!this.file;
    },
  },
};
