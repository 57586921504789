import {
  QForm,
  QBtn,
  QInput,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import eventHub from '@vjs/config/eventHub';
import VConfirmDialog from '@vjs/modals/VConfirmDialog.vue';
import VFormInform from '@vjs/components/VFormInform';
import { mask } from 'vue-the-mask';
import _ from 'lodash';
import uuidv4 from 'uuid/v4';
import { Upload as TusUpload } from 'tus-js-client';
import { Base64 } from 'js-base64';
import VTimer from './VTimer.vue';
import FilePreviewModal from './FilePreviewModal';

export default {
  name: 'VTicketProcess',
  directives: {
    mask,
  },
  components: {
    FilePreviewModal,
    QForm,
    QBtn,
    QInput,
    VTimer,
    VConfirmDialog,
    VFormInform,
  },
  props: {
    uriAction: {
      type: String,
      required: true,
    },
    uriBack: {
      type: String,
      required: true,
    },
    uriGetData: {
      type: String,
      required: true,
    },
    uriGetTime: {
      type: String,
      required: false,
    },
    isProcess: {
      type: Boolean,
      required: true,
    },
    isChecking: {
      type: Boolean,
      required: true,
    },
    uriAutoSave: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      currentFilePreview: null,
      storeData: this.$store,
      body: '',
      teacher: {
        result: '',
        body: '',
      },
      ticket: {
        body: '',
        name: '',
        typeOfEvaluationName: '',
        typeOfEvaluationMask: '',
      },
      student: {
        fullname: '',
        group: '',
        answer: '',
        result: '',
        resultBody: '',
        files: [],
      },
      timer: {
        testStartTime: 0,
        testEndTime: 0,
        testTimeLeft: 0,
      },
      editor: ClassicEditor,
      editorConfig: {
        language: 'ru',
        toolbar: {
          items: [
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'blockQuote',
            'insertTable',
            'undo',
            'redo',
          ],
        },
      },
      loading: false,
      openModal: false,
      timeEnd: false,
    };
  },

  computed: {
    validate() {
      if (this.isProcess) {
        return this.body !== ''
          || this.student.files.length !== 0;
      }
      if (this.isChecking) {
        return this.teacher.result !== '';
      }
    },
    typeOfEvaluationName() {
      if (this.ticket.typeOfEvaluationName) {
        return ` (${this.ticket.typeOfEvaluationName})`;
      }
      return '';
    },
  },

  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'placeholder',
      'fields',
      'validation',
      'warning_text',
    ]);

    if (this.isProcess) {
      // запуск таймера с интервалом в минуту для автосохранения ответа студента
      const autoSaveInterval = setInterval(async () => {
        await this.requestAutoSave();
      }, 63000);


      let timeInterval = null;
      // запуск таймера с интервалом в 15sec для получения времени, если платформа юзера АЙОС;
      if (this.getOS() === 'iOS') {
        timeInterval = setInterval(async () => {
          await this.requestGetTime();
        }, 15000);
      }

      eventHub.$on('timeEnd', async (evt) => {
        this.timeEnd = true;
        if (timeInterval !== null) {
          clearInterval(timeInterval);
        }
        clearInterval(autoSaveInterval);
        await this.requestAction();
      });
    }
    this.requestGetData();
  },
  methods: {
    handleFilePreview(file) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        this.currentFilePreview = reader.result;
      }, false);

      reader.readAsDataURL(file);
    },
    fileIsImage(file) {
      return /\.(jpe?g|bmp|png|gif)$/i.test(file.name);
    },
    getOS() {
      const { userAgent } = window.navigator;
      const { platform } = window.navigator;
      const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
      const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
      const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
      let os = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
      } else if (/Android/.test(userAgent)) {
        os = 'Android';
      } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
      }

      return os;
    },
    onEditorReady(instance) {
      const fileRepository = instance.plugins.get('FileRepository');

      if (fileRepository != null) {
        fileRepository.createUploadAdapter = loader => new this.UploadAdapter(
          loader, this.storeData,
        );
      }
    },
    // запрос на получение данных
    async requestGetData() {
      this.loading = true;
      const res = await requestWrapper.call(this, {
        url: this.uriGetData,
      });

      if (!res.error) {
        this.ticket = res.ticket;
        this.student = res.student;
        this.timer = res.ticketAssignment;
        if (this.isProcess) {
          this.body = res.answer;
          if (this.timer.testTimeLeft === 0) {
            this.timeEnd = true;
            await this.requestAction();
          }
        }
      }
      this.loading = false;
    },
    // запрос на получение времени если юзер IOS
    async requestGetTime() {
      const res = await requestWrapper.call(this, {
        url: this.uriGetTime,
      });

      if (!res.error) {
        this.timer = res;
      }
    },
    // запрос на отправку данных для сохранения
    async requestAction() {
      this.loading = true;
      let actionData = {
        answer: this.body,
        files: this.student.files,
        timeEnd: this.timeEnd,
      };
      if (this.isChecking) {
        actionData = {
          result: this.teacher.result,
          result_body: this.teacher.body,
        };
      }
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriAction,
        data: actionData,
      });

      if (res.error && res.data.errors) {
        this.$notify({
          text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
          type: 'error',
        });
        this.loading = false;
      }
    },

    // запрос на автосохранение ответа студента
    async requestAutoSave() {
      if (this.body !== '') {
        await requestWrapper.call(this, {
          method: 'post',
          url: this.uriAutoSave,
          data: {
            answer: this.body,
          },
        });
      }
      return true;
    },
    clearFile(file) {
      const index = _.findIndex(this.student.files, file);
      if (index !== false && index !== undefined) {
        this.$delete(this.student.files, index);
      }
    },
    triggerFile() {
      this.$refs.fileInput.click();
    },
    async fileChange(e) {
      const vm = this;
      vm.$globalLoading.show();
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      const file = files[0];
      vm.$refs.fileInput.value = null;

      const accepts = ['pdf', 'png', 'bmp', 'jpeg', 'jpg', 'zip', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];
      const size = 10485760;
      const extension = file.name.substring(file.name.lastIndexOf('.'));
      if (_.isArray(accepts) && accepts.length && accepts.includes(extension)) {
        vm.$globalLoading.hide();

        this.$notify({
          text: vm.trans('validation.mimes',
            {
              attribute: vm.trans('label.common_file'),
              values: accepts.join(', '),
            }),
          type: 'error',
        });

        return;
      }
      if (typeof size !== 'undefined' && file.size > size) {
        vm.$globalLoading.hide();
        this.$notify({
          text: vm.trans('validation.max.file_mb', { max: 10 }),
          type: 'error',
        });
        return;
      }
      const metaData = {
        full_name: Base64.encode(file.name),
        filename: `${uuidv4()}.${extension}`,
        origin_name: file.name,
      };
      if (file.type) {
        metaData.filetype = file.type;
      }
      const upload = new TusUpload(file, {
        endpoint: '/tus/',
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: metaData,
        onError(error) {
          this.$notify({
            text: vm.trans('notice.error_on_server'),
            type: 'error',
          });
          vm.$globalLoading.hide();
        },
        onProgress(bytesUploaded, bytesTotal) {},
        onSuccess(xhr) {
          const uuid = xhr.getHeader('X-File-UUID');
          if (!_.find(vm.student.files, { uuid })) {
            vm.student.files.push({
              uuid,
              name: upload.file.name,
              file,
            });
          } else {
            this.$notify({
              text: vm.trans('notice.file_already_selected'),
              type: 'error',
            });
          }
        },
      });

      // Start the upload
      upload.start();
      vm.$globalLoading.hide();
    },
  },
};
