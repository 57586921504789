<template>
  <div>
    <div
      v-if="isLinkable"
      :id="'modal-confirm-external-statement-' + statement.id"
      class="s-modal s-modal--small fade"
      tabindex="-1"
    >
      <div class="s-modal__container">
        <div class="s-modal__head">
          <div class="s-modal__title">
            {{ trans('label.choose_qualification') }}
          </div>
          <div
            class="s-modal__close"
            data-toggle="modal"
            :data-target="'#modal-confirm-external-statement-'+ statement.id"
          />
        </div>
        <div class="s-modal__body">
          <form
            :action="externalStatementsLink"
            method="post"
          >
            <input
              type="hidden"
              name="sign"
              :value="signXml"
            >
            <div class="mb-30">
              <input
                type="hidden"
                :value="csrfToken"
                name="_token"
              >
              <div class="mb-15">
                {{ trans('statement.confirm_modal_body') }}
              </div>
              <div
                v-for="option in qualifications"
                class="mb-30"
              >
                <label :for="'qualification_id_' + option.profession_id">{{ option.label }}</label>
                <select
                  :id="`qualification_id_${option.profession_id}`"
                  class="select2-custom"
                  :name="`qualifications[${option.profession_id}]`"
                >
                  <option value="0">
                    {{ trans('label.not_selected') }}
                  </option>
                  <option
                    v-for="qualification in option.items"
                    :value="qualification.id"
                  >
                    {{ qualification.fullName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row-sort-between-btn">
              <div
                class="s-btn s-btn--thm-grey s-btn--w-150"
                data-toggle="modal"
                :data-target="'#modal-confirm-external-statement-'+statement.id"
              >
                {{ trans('button.cancel') }}
              </div>
              <div
                class="s-btn s-btn--thm-green s-btn--w-150"
                data-action="send-ajax"
              >
                {{ trans('button.send') }}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      :id="'modal-decline-external-statement-'+statement.id"
      class="s-modal fade"
      tabindex="-1"
    >
      <div class="s-modal__container">
        <div class="s-modal__head">
          <div class="s-modal__title">
            {{ trans('statement.select_decline_reason') }}
          </div>
          <div
            class="s-modal__close"
            data-toggle="modal"
            :data-target="'#modal-decline-external-statement-'+statement.id"
          />
        </div>
        <div class="s-modal__body">
          <form
            :action="externalStatementDeclineUrl"
            method="post"
          >
            <input
              id="sign-statement"
              type="hidden"
              name="sign"
              :value="signXml"
            >
            <div class="mb-30">
              <input
                type="hidden"
                :value="csrfToken"
                name="_token"
              >
              <label
                class="s-form__label mb-10"
                for="external-statement-decline-message"
              >
                {{ trans('label.decline_message') }}
              </label>
              <select
                id="code"
                class="select2-custom"
                name="code"
              >
                <option
                  v-for="(label, code) in refuses"
                  :value="code"
                >
                  {{ label }}
                </option>
              </select>
              <textarea
                id="external-statement-decline-message"
                class="s-form__textarea mb-10 pt-10 mt-20"
                name="message"
              />
            </div>
            <div class="row-sort-between-btn">
              <div
                class="s-btn s-btn--thm-blue s-btn--w-150"
                data-toggle="modal"
                :data-target="'#modal-decline-external-statement-'+statement.id"
              >
                {{ trans('button.cancel') }}
              </div>
              <div
                class="s-btn s-btn--thm-green s-btn--w-150"
                data-action="send-ajax"
              >
                {{ trans('button.send') }}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      :id="'modal-accept-external-statement-'+statement.id"
      class="s-modal s-modal--small fade"
      tabindex="-1"
    >
      <div class="s-modal__container">
        <div class="s-modal__head">
          <div
            class="s-modal__close"
            data-toggle="modal"
            :data-target="'#modal-accept-external-statement-'+statement.id"
          />
        </div>
        <div class="s-modal__body">
          <form
            :action="isSocStatusModal ? acceptWithSocStatusUrl : acceptExternalRequestUrl"
            method="post"
          >
            <input
              id="sign-statement"
              type="hidden"
              name="sign"
              :value="signXml"
            >
            <div class="mb-30">
              <input
                type="hidden"
                :value="csrfToken"
                name="_token"
              >
              <label
                class="s-form__label mb-10"
                for="radio-free-food-1"
              >
                {{ trans('statement.free_food_access') }}:
              </label>
              <div
                class="d-flex s-w-100"
                style="padding-left: 1rem;"
              >
                <div style="margin-right: 2rem;">
                  <input
                    id="radio-free-food-1"
                    type="radio"
                    name="free-food"
                    value="true"
                    style="margin-right: 1rem; width: 1.8rem; height: 1.8rem;"
                  >
                  {{ trans('main.yes') }}
                </div>
                <div>
                  <div>
                    <input
                      id="radio-free-food-0"
                      type="radio"
                      name="free-food"
                      value="false"
                      style="margin-right: 1rem; width: 1.8rem; height: 1.8rem;"
                    >
                    {{ trans('main.not') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row-sort-between-btn">
              <div
                class="s-btn s-btn--thm-blue s-btn--w-150"
                data-toggle="modal"
                :data-target="'#modal-accept-external-statement-'+statement.id"
              >
                {{ trans('button.cancel') }}
              </div>
              <div
                class="s-btn s-btn--thm-green s-btn--w-150"
                data-action="send-ajax"
              >
                {{ trans('button.send') }}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { StoreTranslator } from '@common/mixins';

export default {
  name: 'VExternalStatementSignModals',
  mixins: [StoreTranslator],
  props: {
    signXml: {
      type: String,
      default: '',
    },
    csrfToken: {
      type: String,
      default: '',
    },
    statement: {
      type: Object,
      required: true,
    },
    refuses: {
      type: Object,
      required: false,
    },
    qualifications: {
      type: Array,
      required: false,
    },
    isLinkable: {
      type: String,
      required: true,
    },
    externalStatementsLink: {
      type: String,
      required: true,
    },
    externalStatementDeclineUrl: {
      type: String,
      required: true,
    },
    acceptExternalRequestUrl: {
      type: String,
      required: true,
    },
    acceptWithSocStatusUrl: {
      type: String,
      required: true,
    },
    isSocStatusModal: {
      type: Boolean,
      required: false,
    },
  },

};
</script>
