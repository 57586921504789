import { StoreTranslator } from '@common/mixins';


export default {
  mixins: [StoreTranslator],
  props: {
    action: {
      type: String,
      required: true,
    },
    refs: {
      type: Object,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    urlBack: {
      type: String,
      required: false,
      default: null,
    },
    urlLoadModuleSubjects: {
      type: String,
      required: false,
      default: null,
    },
    urlLoadSubjects: {
      type: String,
      required: false,
      default: null,
    },
    resource: {
      type: [Array, Object],
      required: false,
    },
    hides: {
      type: [Array, Object],
      required: false,
      default: () => ([]),
    },
    extraConfigsFields: {
      type: [Array, Object],
      required: false,
    },
    userPersonnelId: {
      type: Number,
      required: false,
    },
  },
  data() {
    const vm = this;
    const fields = {
      name: {
        value: null,
        name: 'name',
        component: 'text-field',
        labelTransKey: 'fields.name',
        values: [],
        required: true,
      },
      semesters: {
        value: null,
        name: 'semesters',
        component: 'v-multi-select-field',
        labelTransKey: 'label.semester',
        values: vm.refs.semesters,
        required: true,
        v_show: true,
        extraAttributes: {
          multiple: true,
        },
      },
      is_for_module: {
        value: false,
        name: 'is_for_module',
        component: 'boolean-field',
        checkboxLabelTransKey: 'label.is_for_module',
        required: false,
        events: {
          input: () => {
            if (!this.form.fields.is_for_module.value) {
              this.loadSubjects();
              this.form.fields.education_module_id.value = null;
              this.form.fields.education_discipline_id.value = this.resource?.values?.education_discipline_id ?? null;
              this.form.fields.education_discipline_id.extraAttributes.disabled = this.resource?.hides?.includes('education_discipline_id');
            } else {
              this.$set(this.form.fields.education_discipline_id, 'values', []);
              this.form.fields.education_discipline_id.value = null;
            }
          },
        },
      },
      education_module_id: {
        value: null,
        name: 'education_module_id',
        component: 'v-multi-select-field',
        labelTransKey: 'label.module',
        values: [],
        required: true,
        v_show: true,
        v_if(vm) {
          return vm.form.fields.is_for_module.value;
        },
        loadValues: {
          autoLoad: true,
          type: 'education-modules',
          headers: {
            'X-Permission': 'calendar_thematic_plan-create',
          },
        },
        events: {
          input: () => {
            if (this.form.fields.education_module_id.value) {
              this.loadModuleSubjects();
              this.form.fields.education_discipline_id.extraAttributes.disabled = this.resource?.hides?.includes('education_module_id');
            } else if (!this.form.fields.is_for_module.value) {
              this.loadSubjects();
            }
          },
        },
      },
      education_discipline_id: {
        value: null,
        name: 'education_discipline_id',
        component: 'v-multi-select-field',
        labelTransKey: 'table.discipline',
        values: [],
        required: true,
        v_show: true,
        extraAttributes: {
          disabled: false,
        },
      },
      qualification_id: {
        value: null,
        name: 'qualification_id',
        component: 'v-multi-select-field',
        labelTransKey: 'curriculum.qualification',
        values: [],
        required: true,
        v_show: true,
        loadValues: {
          autoLoad: true,
          type: 'qualifications/by-group',
          headers: {
            'X-Permission': 'calendar_thematic_plan-create',
          },
        },
        events: {
          change: () => {
            if (this.resource) {
              this.$set(this.form.fields.group_id, 'value', this.resource.values.group_id);
            } else {
              this.$set(this.form.fields.group_id, 'value', []);
            }
            if (this.form.fields.qualification_id.value) {
              this.form.fields.group_id.loadValues.where.qualification_id = this.form.fields.qualification_id.value;
              this.form.fields.group_id.v_show = true;
              this.form.fields.group_id.loadValues.reload();
            } else {
              this.form.fields.group_id.loadValues.setEmpty();
              this.form.fields.group_id.v_show = false;
            }
          },
        },
      },
      group_id: {
        value: null,
        name: 'group_id',
        component: 'v-multi-select-field',
        labelTransKey: 'main.group',
        values: [],
        required: true,
        v_show: false,
        loadValues: {
          autoLoad: false,
          type: 'groups/filters',
          where: {
            qualification_id: null,
          },
          headers: {
            'X-Permission': 'calendar_thematic_plan-create',
          },
        },
        extraAttributes: {
          multiple: true,
        },
      },
      personnel_id: {
        value: null,
        name: 'personnel_id',
        component: 'v-multi-select-field',
        labelTransKey: 'label.teacher',
        values: [],
        required: true,
        loadValues: {
          autoLoad: true,
          type: 'personnel',
          headers: {
            'X-Permission': 'calendar_thematic_plan-create',
          },
        },
        extraAttributes: {
          disabled: !!this.userPersonnelId,
        },
      },
      hour_count: {
        value: null,
        name: 'hour_count',
        component: 'text-field',
        labelTransKey: 'calendar-thematic-plan.hour_count',
        required: true,
      },
      credit_count: {
        value: null,
        name: 'credit_count',
        component: 'number-field',
        labelTransKey: 'calendar-thematic-plan.credit_count',
        required: true,
        config: {
          mask: null,
          regex: '\\d{1,3}([.,]\\d{1,2})?',
        },
      },
    };

    if (vm.userPersonnelId) {
      fields.personnel_id.value = vm.userPersonnelId;
    }

    if (vm.resource !== undefined) {
      if (vm.resource.values) {
        _.forEach(vm.resource.values, (value, key) => {
          if (fields.hasOwnProperty(key)) {
            fields[key].value = value;
          }
        });
      }
      if (vm.resource.hides) {
        _.forEach(vm.resource.hides, (attribute) => {
          if (fields.hasOwnProperty(attribute)) {
            _.set(fields, `${attribute}.extraAttributes.disabled`, true);
          }
        });
      }
    }
    if (vm.extraConfigsFields) {
      _.forEach(vm.extraConfigsFields, (configs, attribute) => {
        if (fields.hasOwnProperty(attribute)) {
          const field = fields[attribute];
          _.set(fields, attribute, _.assignIn(field, configs));
        }
      });
    }
    return {
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.redirect) {
            window.location = data.redirect;
          }
        },
      },
      disciplines: [],
    };
  },
  beforeCreate() {
    this.$trans.add([
      'fields', 'warning_text', 'placeholder', 'label', 'button', 'main', 'notice', 'cabinet', 'curriculum',
      'personnel_dicts',
      'menu',
      'table',
      'journal',
      'calendar-thematic-plan',
    ]);
  },
  mounted() {
    if (!this.resource?.values?.is_for_module) {
      this.loadSubjects();
    }
  },
  methods: {
    handleError(response) {
      if (response.status === 422 || response.status === 423) {
        let errors = [];
        const formErrors = {};
        _.forEach(response.data.errors, (value, key) => {
          errors = errors.concat(value);
          _.set(formErrors, key, value);
        });
        show_notice(errors, 'error');
        this.$set(this.form, 'errors', formErrors);
      } else {
        console.log(response);
      }
    },
    loadModuleSubjects() {
      const vm = this;

      const config = {
        responseType: 'json',
        method: 'GET',
        headers: { 'Accept-Language': window.core_project.locale },
        params: {
          module_id: vm.form.fields.education_module_id.value,
          usual: true,
        },
      };

      vm.$http.get(vm.urlLoadModuleSubjects, config)
        .then(
          (response) => {
            vm.$set(vm.form.fields.education_discipline_id, 'values', response.data);
          },
          (response) => {
            vm.handleError(response);
          },
        );
    },
    loadSubjects() {
      const vm = this;

      const config = {
        responseType: 'json',
        method: 'GET',
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };

      if (vm.disciplines !== undefined) {
        if (vm.disciplines.length === 0) {
          vm.$http.get(vm.urlLoadSubjects, config)
            .then(
              (response) => {
                vm.$set(vm, 'disciplines', response.data);
                vm.$set(vm.form.fields.education_discipline_id, 'values', vm.disciplines);
              },
              (response) => {
                vm.handleError(response);
              },
            );
        } else {
          vm.$set(vm.form.fields.education_discipline_id, 'values', vm.disciplines);
        }
      }
    },
    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    handleVShow(field) {
      const vm = this;
      if (field.v_show !== undefined) {
        if (_.isFunction(field.v_show)) {
          return field.v_show(vm);
        }
        return field.v_show;
      }

      return true;
    },
    handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};

      _.forEach(form.fields, (el, key) => {
        if (el.v_if !== undefined && !el.v_if(vm)) {
          return;
        }

        if ((typeof el.value === 'object') && el.value !== null) {
          const arrField = [];
          _.forEach(el.value, (arrEl) => {
            arrField.push(arrEl.id);
          });
          _.set(data, key, arrField);
        } else if (el.hasOwnProperty('fill')) {
          el.fill(data);
        } else {
          _.set(data, vm.nameToDot(el.name), el.value);
        }
      });
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      vm.$globalLoading.show();

      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(form, 'processSend', false);
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$globalLoading.hide();
          vm.$set(form, 'processSend', false);
          vm.handleError(response);
        },
      );
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
  },
};
