import { charts } from '../../mixins';

export default {
  name: 'VPieChart',
  mixins: [charts],
  data() {
    return {
      chart: null,
    };
  },
  async mounted() {
    if (this.$q.screen.xs) {
      this.show = false;
      return;
    }

    const data = await this.request(this.uri);
    this.chart = this.createPieChart(this.$refs.pieChart, data, this.innerRadius);
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  computed: {
    pieChartLegend() {
      return this.getPieLegend(this.chart);
    },
  },
};
