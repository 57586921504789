import _ from 'lodash';
import { Helper } from '@common/src/helpers';
import { QForm, QInput } from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';

export default {
  name: 'VHolidaysModal',
  components: { QForm, QInput },
  props: {
    resource: {
      type: Object,
      required: false,
    },
  },
  data() {
    const fields = {
      first_semester_start: null,
      first_semester_end: null,
      second_semester_start: null,
      second_semester_end: null,
    };
    _.forEach(this.resource, (value, attribute) => {
      if (Object.prototype.hasOwnProperty.call(fields, attribute)) {
        _.set(fields, `${attribute}`, value);
      }
    });
    return {
      fields,
    };
  },
  computed: {
    validate() {
      let validate = true;
      if (this.fields?.second_semester_start !== null && this.fields?.second_semester_start !== '') {
        validate = this.fields?.second_semester_start !== null && this.fields?.second_semester_start !== ''
          && this.fields?.second_semester_end !== null && this.fields?.second_semester_end !== '';
      }
      return this.fields?.first_semester_start !== null && this.fields?.first_semester_start !== ''
        && this.fields?.first_semester_end !== null && this.fields?.first_semester_end !== ''
        && validate;
    },
  },
  methods: {
    async handleAjaxFormSubmit() {
      this.$globalLoading.show();
      const data = this.fields;
      const { attendanceApiConfig, makeTimeSheetUrl, groupId } = this.$store.getters;
      const res = await requestWrapper.call(this, {
        url: makeTimeSheetUrl(attendanceApiConfig.addGroupHolidays.apiUrl, groupId),
        method: 'post',
        data,
      });

      if (!res.error) {
        await this.$store.dispatch('GET_TIMESHEET');
        this.$emit('onClose');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
