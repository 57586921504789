import { charts } from '../../mixins';

export default {
  name: 'VTableChart',
  mixins: [charts],

  data() {
    return {
      dataItems: [],
    };
  },

  computed: {
    columns() {
      if (
        Array.isArray(this.dataItems)
        && this.dataItems.length > 0
        && this.dataItems[0].years
      ) {
        return this.dataItems[0].years;
      }

      return [];
    },

    rows() {
      if (Array.isArray(this.dataItems)) {
        return this.dataItems;
      }

      return [];
    },
  },

  async mounted() {
    if (this.$q.screen.xs) {
      this.show = false;
      return;
    }
    const data = await this.request(this.uri);

    if (Array.isArray(data)) {
      data.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
      data.forEach(item => item.years.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      }));
      this.dataItems = data;
    }
  },

  methods: {
    getSummGender(item, key) {
      return item.reduce((acc, itm) => acc + itm.gender[key], 0);
    },

    getSummGenderAll(item) {
      return item.reduce((acc, itm) => acc + itm.gender.w + itm.gender.m, 0);
    },
  },
};
