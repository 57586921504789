import { StoreTranslator } from '@common/mixins';
import _ from 'lodash';


export default {
  mixins: [StoreTranslator],
  props: {
    urlBack: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    organizationId: {
      type: [Number, String],
      required: true,
    },
    urlGroups: {
      type: String,
      required: true,
    },
    urlListContingents: {
      type: String,
      required: true,
    },
    urlListQuestions: {
      type: String,
      required: true,
    },
    urlListModuleDisciplines: {
      type: String,
      required: true,
    },
    exam: {
      type: [Array, Object],
      required: false,
    },
    showTypes: {
      type: [Array, Object],
      default() {
        return [];
      },
    },
    evaluationTypes: {
      type: [Array, Object],
      default() {
        return [];
      },
    },
    examTypes: {
      type: [Array, Object],
      default() {
        return [];
      },
    },
    extraConfigsFields: {
      type: [Array, Object],
      required: false,
    },
  },
  data() {
    const vm = this;
    const fields = {
      exam_type: {
        value: '',
        name: 'exam_type',
        component: 'v-multi-select-field',
        labelTransKey: 'exam.exam_type',
        values: vm.examTypes,
        required: true,
        v_if(vm) {
          return vm.form.fields.exam_type.values.length !== 0;
        },
        events: {
          change: () => {
            if (vm.method !== 'patch') {
              vm.form.fields.is_complex.value = false;
            }
            if (vm.form.fields.journal_id.value !== null || (vm.form.fields.group_id.value !== null && vm.exam === undefined)) {
              vm.loadContingents();
            }
          },
        },
        // extraAttributes: {
        //   disabled: vm.method === 'patch',
        // },
      },
      use_group: {
        value: false,
        name: 'use_group',
        component: 'boolean-field',
        checkboxLabelTransKey: 'exam.use_group',
        required: false,
        extraAttributes: {
          disabled: vm.method === 'patch',
        },
        events: {
          change: () => {
            if (vm.method !== 'patch') {
              vm.form.fields.journal_id.value = null;
              vm.form.fields.group_id.value = null;
              vm.form.fields.contingents.value = [];
              vm.form.fields.is_complex.value = false;
              vm.form.fields.module_discipline_id.values = [];
            }
            if (!vm.form.fields.use_group.value) {
              vm.form.fields.type.values = vm.showTypes;
            }
          },
        },
      },
      group_id: {
        v_if(vm) {
          return vm.form.fields.use_group.value;
        },
        value: null,
        name: 'group_id',
        component: 'v-multi-select-field',
        labelTransKey: 'main.group',
        values: [],
        required: true,
        loadValues: {
          url: vm.urlGroups,
          where: {
            is_current: true,
          },
          headers: {
            'X-Permission': 'exam-create',
          },
        },
        events: {
          change() {
            if (vm.form.fields.group_id.value) {
              vm.loadQuestions();
            }
            if (vm.exam === undefined) {
              vm.loadContingents();
            }
          },
        },

      },
      type_of_evaluation: {
        v_if(vm) {
          return vm.form.fields.use_group.value;
        },
        value: null,
        name: 'type_of_evaluation',
        component: 'v-multi-select-field',
        labelTransKey: 'main.type_of_evaluation',
        values: vm.evaluationTypes,
        required: true,
      },
      journal_id: {
        v_if(vm) {
          return !vm.form.fields.use_group.value;
        },
        value: null,
        name: 'journal_id',
        component: 'v-multi-select-field',
        labelTransKey: 'menu.journal',
        values: [],
        required: true,
        v_show: true,
        loadValues: {
          autoLoad: true,
          type: 'journals',
          headers: {
            'X-Permission': 'exam-create',
          },
          where: {
            with_parent_journals: true,
          },
        },
        events: {
          change: () => {
            if (vm.form.fields.journal_id.value) {
              vm.loadQuestions();
              vm.loadModuleDisciplines(this.form.fields.journal_id.value);
            }
            if (vm.exam === undefined) {
              vm.loadContingents();
            }
          },
        },
        extraAttributes: {
          disabled: vm.method === 'patch',
          customLabel(option, label) {
            return option.name + option.group_name + option.discipline_name + option.program_type;
          },
        },
      },
      is_complex: {
        v_if(vm) {
          return (vm.form.fields.module_discipline_id.values.length !== 0 && vm.form.fields.exam_type.value !== 'current_control')
            || vm.form.fields.use_group.value;
        },
        value: false,
        name: 'is_complex',
        component: 'boolean-field',
        checkboxLabelTransKey: 'label.complex_exam',
        required: true,
        extraAttributes: {
          disabled: vm.method === 'patch',
        },
        events: {
          input: (val) => {
            if (this.isMounted) {
              this.form.fields.questions_groups.value = val ? [] : null;
              this.form.fields.question_counts.value = [];
            }
            this.form.fields.questions_groups.extraAttributes.multiple = !!val;
            if (this.form.fields.is_complex.value && this.form.fields.use_group.value) {
              this.form.fields.type.values = this.form.fields.type.values.filter(item => item.id !== 'offline-exam');
            } else {
              this.form.fields.type.values = this.showTypes;
            }
          },
        },
      },
      module_discipline_id: {
        v_if(vm) {
          return vm.form.fields.module_discipline_id.values.length !== 0 && !vm.form.fields.is_complex.value && !vm.form.fields.use_group.value;
        },
        value: null,
        name: 'module_discipline_id',
        component: 'v-multi-select-field',
        labelTransKey: 'label.module_subject',
        values: [],
        required: true,
        extraAttributes: {
          disabled: vm.method === 'patch',
        },
        events: {
          change: () => {
            if (vm.exam === undefined) {
              vm.loadContingents();
            }
          },
        },
      },
      discipline_id: {
        v_if(vm) {
          return vm.form.fields.use_group.value;
        },
        value: null,
        name: 'discipline_id',
        component: 'v-multi-select-field',
        labelTransKey: 'label.subject',
        values: [],
        required: true,
        extraAttributes: {
          disabled: vm.method === 'patch',
        },
        loadValues: {
          type: 'disciplines/theories-by-org',
        },
      },
      type: {
        value: '',
        name: 'type',
        component: 'v-multi-select-field',
        labelTransKey: 'label.exam_type',
        values: vm.showTypes,
        required: true,
        v_if(vm) {
          return vm.form.fields.type.values.length !== 0;
        },
        extraAttributes: {
          disabled: vm.method === 'patch',
        },
        events: {
          input: () => {
            if (this.isMounted) {
              this.form.fields.ticket_group_id.value = null;
            }
            this.form.fields.questions_groups.value = null;
          },
        },
      },
      ticket_group_id: {
        v_if(vm) {
          return vm.form.fields.type.value === 'online-ticket-exam';
        },
        value: null,
        name: 'ticket_group_id',
        component: 'v-multi-select-field',
        labelTransKey: 'label.ticket_group',
        values: [],
        loadValues: {
          type: 'exam-ticket-groups/all',
        },
        required: true,
        extraAttributes: {},
      },
      questions_groups: {
        value: null,
        name: 'questions_groups',
        component: 'v-multi-select-field',
        labelTransKey: 'main.questions_groups',
        values: [],
        required: true,
        v_if(vm) {
          return vm.form.fields.type.value === 'online-testing-exam';
        },
        extraAttributes: {
          // disabled: vm.method === 'patch',
          customLabel(option, label) {
            return option.name ? option.name : `${option.id} (id)`;
          },
          multiple: vm.exam?.is_complex === 1,
        },
        events: {
          input: () => {
            vm.form.fields.question_counts.value = vm.form.fields.questions_groups.extraAttributes.multiple
              ? vm.form.fields.questions_groups.value.map(item => ({
                id: item.id,
                name: item.name !== '' ? item.name : item.id,
                count: item.count ?? null,
              }))
              : [];
          },
        },
      },
      question_counts: {
        value: [],
        name: 'question_counts',
        component: 'v-related-text-field',
        labelTransKey: 'label.questions_groups.name',
        labelRelateTransKey: 'label.questions_groups.count',
        required: true,
        showLabel: false,
        v_if(vm) {
          return vm.form.fields.questions_groups.value !== null && vm.form.fields.is_complex.value;
        },
        fill: (data) => {
          data.question_counts = this.form.fields.question_counts.value;
        },
      },
      testing_time: {
        value: '',
        name: 'testing_time',
        component: 'number-field',
        labelTransKey: 'label.testing_time',
        required: true,
        config: {
          mask: ['999'],
          alias: 'integer',
          rightAlign: false,
          min: 1,
        },
        v_if(vm) {
          return vm.form.fields.type.value === 'online-testing-exam' || vm.form.fields.type.value === 'online-ticket-exam';
        },
        extraAttributes: {
          disabled: vm.method === 'patch',
        },
      },
      testing_count_questions: {
        value: '',
        name: 'testing_count_questions',
        component: 'number-field',
        labelTransKey: 'label.testing_count_questions',
        required: true,
        config: {
          mask: ['999'],
          alias: 'integer',
          rightAlign: false,
          min: 1,
        },
        v_if(vm) {
          return vm.form.fields.type.value === 'online-testing-exam' && !vm.form.fields.is_complex.value;
        },
        extraAttributes: {
          disabled: vm.method === 'patch',
        },
      },
      date: {
        value: '',
        name: 'date',
        component: 'date-field',
        labelTransKey: 'label.date',
        required: true,
      },
      datetime: {
        value: '',
        name: 'datetime',
        component: 'select-time-field',
        labelTransKey: 'label.date_time',
        required: true,
      },
      cabinet: {
        value: '',
        name: 'cabinet',
        component: 'text-field',
        labelTransKey: 'fields.cabinet',
        required: true,
      },
      contingents: {
        value: [],
        name: 'contingents',
        component: 'list-statements-field',
        labelTransKey: 'fields.contingents',
        required: true,
        extraAttributes: {
          disabled: vm.method === 'patch',
        },
        fill: (data) => {
          data.contingents = this.form.fields.contingents.value;
        },
      },

    };
    if (vm.exam !== undefined) {
      _.forEach(vm.exam, (value, key) => {
        if (fields.hasOwnProperty(key)) {
          fields[key].value = value;
        }
      });
    }
    if (vm.extraConfigsFields) {
      _.forEach(vm.extraConfigsFields, (configs, attribute) => {
        if (fields.hasOwnProperty(attribute)) {
          const field = fields[attribute];
          _.set(fields, attribute, _.assignIn(field, configs));
        }
      });
    }
    return {
      isMounted: false,
      form: {
        processSend: false,
        action: vm.action,
        method: vm.method,
        errors: [],
        fields,
        afterCallback(vm, response) {
          const { data } = response;
          if (data.url_redirect) {
            window.location = data.url_redirect;
          }
          // TODO привести к одному варианту
          if (data.redirect) {
            window.location = data.redirect;
          }
        },
      },
    };
  },
  beforeCreate() {
    this.$trans.add(['fields', 'warning_text', 'placeholder', 'label', 'button', 'main', 'notice', 'menu', 'exam']);
  },
  mounted() {
    const vm = this;

    if (vm.method === 'patch') {
      vm.loadQuestions();
    }

    this.$nextTick(() => {
      vm.isMounted = true;
    });
  },
  methods: {
    loadContingents() {
      const vm = this;

      if (!vm.form.fields.group_id.value && !vm.form.fields.journal_id.value) {
        vm.form.fields.contingents.value = [];
        return;
      }

      let params = {};
      if (this.form.fields.use_group.value) {
        params = { group_id: vm.form.fields.group_id.value };
      } else {
        params = { journal_id: vm.form.fields.journal_id.value };
      }
      params.type = vm.form.fields.exam_type.value;
      params.discipline_id = vm.form.fields.module_discipline_id.value;
      const config = {
        responseType: 'json',
        method: 'GET',
        params,
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };
      vm.$http.get(vm.urlListContingents, config).then(
        (response) => {
          const { data } = response;
          vm.$set(vm.form.fields.contingents, 'value', data);
        },
        (response) => {
          console.log(response);
        },
      );
    },
    loadQuestions() {
      const vm = this;

      let params = {};
      if (this.form.fields.use_group.value) {
        params = { group_id: vm.form.fields.group_id.value };
      } else {
        params = { journal_id: vm.form.fields.journal_id.value };
      }
      const config = {
        responseType: 'json',
        method: 'GET',
        params,
        headers: {
          'X-Permission': 'exam-create',
        },
      };
      vm.$http.get(vm.urlListQuestions, config).then(
        (response) => {
          vm.form.fields.questions_groups.values = response.data;
          if (vm.exam !== undefined) {
            vm.form.fields.questions_groups.value = vm.exam.questions_groups;
          }
        },
        (response) => {
          console.log(response);
        },
      );
    },
    loadModuleDisciplines(journalId) {
      const vm = this;
      // if (vm.method !== 'patch') {
      //   vm.form.fields.module_discipline_id.value = null;
      // }
      const config = {
        responseType: 'json',
        method: 'GET',
        params: {
          journal_id: journalId,
        },
        headers: {
          'X-Permission': 'exam-create',
        },
      };
      vm.$http.get(vm.urlListModuleDisciplines, config).then(
        (response) => {
          if (response.data) {
            vm.form.fields.module_discipline_id.values = response.data;
          }
        },
        (response) => {
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    handleVif(field) {
      const vm = this;
      if (field.component === undefined) {
        return false;
      }
      if (field.v_if !== undefined) {
        if (_.isFunction(field.v_if)) {
          return field.v_if(vm);
        }
        return field.v_if;
      }

      return true;
    },
    async handleAjaxFormSubmit(form) {
      const vm = this;
      const data = {};
      _.forEach(vm.form.fields, (el, key) => {
        if (el.hasOwnProperty('fill')) {
          el.fill(data);
        } else if ((typeof el.value === 'object') && el.value !== null) {
          const arrField = [];
          _.forEach(el.value, (arrEl) => {
            arrField.push(arrEl.id);
          });
          _.set(data, key, arrField);
        } else {
          _.set(data, vm.nameToDot(el.name), el.value);
        }
      });
      const { action } = form;
      const { method } = form;
      if (form.processSend === true) {
        return false;
      }
      vm.$set(form, 'processSend', true);
      form.errors = [];

      const config = {
        responseType: 'json',
        method,
        headers: {},
      };
      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      let request;
      switch (method.toLowerCase()) {
        case 'post':
          request = vm.$http.post(action, data, config);
          break;
        case 'delete':
          request = vm.$http.delete(action, data, config);
          break;
        case 'patch':
          request = vm.$http.patch(action, data, config);
          break;
      }
      request.then(
        (response) => {
          if (form.afterCallback !== undefined) {
            form.afterCallback(vm, response);
          }
          form.errors = [];
        },
        (response) => {
          vm.$set(form, 'processSend', false);
          if (response.status === 422 || response.status === 423) {
            let errors = [];
            const formErrors = {};
            _.forEach(response.data.errors, (value, key) => {
              errors = errors.concat(value);
              _.set(formErrors, key, value);
            });
            show_notice(errors, 'error');
            form.errors = formErrors;
          } else {
            console.log(response);
          }
        },
      );
    },
    hasError(form, field) {
      return _.has(form.errors, field);
    },
    getError(form, field) {
      return _.first(_.get(form.errors, field));
    },
    getErrors(form, fieldName) {
      return _.get(form.errors, this.nameToDot(fieldName), []);
    },
    nameToDot(name) {
      return name.replace(/\[/g, '.').replace(/]/g, '');
    },
    openConfirmModal() {
      const vm = this;
      const events = {};
      const func = async () => { await vm.handleAjaxFormSubmit(vm.form); };
      events.onApprove = func;
      events.onSuccess = func;
      this.$sModal.open('v-modal-confirm', {
        id: 'v-modal-confirm-new',
        title: this.trans('notice.are_you_sure'),
        component: 'v-modal-confirm-new',
        notPersistent: true,
        binds: { type: 'event', message: this.trans('warning_text.exam_fail_message') },
        events,
      });
    },
  },
};
