import { QForm, QSlideTransition, QCheckbox } from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import _ from 'lodash';
import { Helper } from '@common/src/helpers';

export default {
  name: 'ModalJournalGenerateWeeks',
  components: { QForm, QSlideTransition, QCheckbox },
  props: {},
  data() {
    return {
      rows: [],
      selectedRows: [],
      filters: {
        month: null,
        discipline: null,
      },
    };
  },

  mounted() {
    if (this.isModule) {
      this.$store.commit('CLEAR_GENERATE_MONTHS');
    }
  },

  computed: {
    localDisciplines() { return this.refs.disciplines.filter(item => item.id !== 0); },
    isModule() { return this.$store.getters.isModule; },
    refs() { return this.$store.getters.refs; },
    isAllChecked() { return this.selectedRows.length === this.rows.length; },
  },

  methods: {
    handleSelectAllCheck() {
      this.selectedRows = this.isAllChecked ? [] : this.rows.map(item => item.id);
    },
    handleClickRow(rowId) {
      const result = _.cloneDeep(this.selectedRows);
      // eslint-disable-next-line no-unused-expressions
      result.includes(rowId) ? _.pull(result, rowId) : result.push(rowId);
      this.$set(this, 'selectedRows', result);
    },
    clear() {
      this.selectedRows = [];
      this.rows = [];
    },
    async handleSubjectSelect() {
      this.filters.month = null;
      this.clear();
      await this.$store.dispatch('GET_GENERATE_MONTHS', this.filters.discipline);
    },
    async loadData() {
      this.$globalLoading.show();
      this.clear();

      const { apiConfig, makeJournalUrl, journalId } = this.$store.getters;
      const res = await requestWrapper.call(this, {
        url: makeJournalUrl(apiConfig.generateWeek.data, journalId),
        params: this.filters,
      });

      if (!res.error) {
        this.rows = res.data;
      }

      this.$globalLoading.hide();
    },
    async handleAjaxFormSubmit() {
      this.$globalLoading.show();

      const data = { lessonIds: this.selectedRows };

      const { apiConfig, makeJournalUrl, journalId } = this.$store.getters;
      const res = await requestWrapper.call(this, {
        url: makeJournalUrl(apiConfig.generateWeek.action, journalId),
        method: 'post',
        data,
      });

      if (!res.error) {
        const filter = this.$store.getters.currentFilter;
        await this.$store.dispatch(
          'GET_JOURNAL', { [this.isModule ? 'discipline_id' : 'semester']: filter },
        );
        this.$emit('onClose');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }

      this.$globalLoading.hide();
    },
  },
};
